import { makeStyles } from "@fluentui/react-components";
import { FC } from "react";
import { JoinOperatorType } from "./ConditionCodeGen";
import { Token } from "./Token";

export const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    justifyItems: "center",
    alignItems: "center",
    "> button": {
      width: "auto",
      minWidth: "inherit",
      fontWeight: "normal",
    },
  },
});

export const JoinOperator: FC<{
  joinOperator: JoinOperatorType;
  onChange?: (_: JoinOperatorType) => void;
}> = ({ joinOperator, onChange }) => {
  return (
    <Token
      onClick={() => onChange?.(joinOperator === "and" ? "or" : "and")}
      label={joinOperator}
      appearance="secondary"
    />
  );
};
