import { JSONSchema9 } from "../../types/jsonSchema";
import { Variable } from "..";
import {
  LoopParser,
  LoopLoopParser,
  LoopSignTabParser,
  LoopVariableParser,
  SignTabParser,
  VariableParser,
} from "../parsers";
import resolveRefs from "../resolveRefs";
import { ContentControlType } from "../../liquidx";
import { capitalize } from "../../../taskpane/helpers/formatData";
import pluralize from "pluralize";

export interface InputOption {
  description: string;
  group: string;
  label: string;
  value: string;
  variable?: Variable;
  format?: string;
}

export interface QuickAddOption {
  element: ContentControlType;
  option: InputOption;
}

export interface ParsedInputOptions {
  dateSignedOptions: InputOption[];
  initialsOptions: InputOption[];
  signatureOptions: InputOption[];
  variableOptions: InputOption[];
  loopOptions: InputOption[];
  loopLoopOptions: InputOption[];
  loopVariableOptions: InputOption[];
  loopDateSignedOptions: InputOption[];
  loopInitialsOptions: InputOption[];
  loopSignatureOptions: InputOption[];
  loopSignerOptions: InputOption[];
  formInputOptions: InputOption[];
  signerOptions: InputOption[];
  quickAddOptions: QuickAddOption[];
}

export type SignatureOption = InputOption;
export type InitialsOption = InputOption;
export type DateSignedOption = InputOption;
export type QuickAddTreeNode = {
  value: string;
  children: { [key: string]: QuickAddTreeNode };
  quickAddOption?: QuickAddOption;
};

export class InputOptionsBuilder {
  public static load(inputSchema: JSONSchema9): ParsedInputOptions {
    // TODO: fix typescript type error. Remove @ts-ignore comment to see the error
    // @ts-ignore
    const resolvedSchema = resolveRefs(inputSchema);

    // TODO: fix typescript type error. Remove @ts-ignore comment to see the error
    // @ts-ignore
    const variables: Variable[] = new VariableParser().buildFromObjectSchema(resolvedSchema);
    // TODO: fix typescript type error. Remove @ts-ignore comment to see the error
    // @ts-ignore
    const signTabs: Variable[] = new SignTabParser().buildFromObjectSchema(resolvedSchema);

    // TODO: fix typescript type error. Remove @ts-ignore comment to see the error
    // @ts-ignore
    const loops: Variable[] = new LoopParser().buildFromObjectSchema(resolvedSchema);
    // TODO: fix typescript type error. Remove @ts-ignore comment to see the error
    // @ts-ignore
    const loopLoops: Variable[] = new LoopLoopParser().buildWithoutRoot(resolvedSchema);
    // TODO: fix typescript type error. Remove @ts-ignore comment to see the error
    // @ts-ignore
    const loopVariables: Variable[] = new LoopVariableParser().buildWithoutRoot(resolvedSchema);
    // TODO: fix typescript type error. Remove @ts-ignore comment to see the error
    // @ts-ignore
    const loopSignTabs: Variable[] = new LoopSignTabParser().buildWithoutRoot(resolvedSchema);

    const variableOptions = this.buildVariableOptions(variables).concat(this.buildLoopCountOptions(loops));
    const dateSignedOptions = this.buildDateSignedOptions(signTabs);
    const initialsOptions = this.buildInitialsOptions(signTabs);
    const signatureOptions = this.buildSignatureOptions(signTabs);

    const loopOptions = this.buildVariableOptions(loops);
    const loopLoopOptions = this.buildVariableOptions(loopLoops);
    const loopVariableOptions = this.buildVariableOptions(loopVariables);
    const loopDateSignedOptions = this.buildDateSignedOptions(loopSignTabs);
    const loopInitialsOptions = this.buildInitialsOptions(loopSignTabs);
    const loopSignatureOptions = this.buildSignatureOptions(loopSignTabs);
    const loopSignerOptions = this.buildSignerOptions(loopSignTabs);

    const formInputOptions = this.buildFormInputOptions();
    const signerOptions = this.buildSignerOptions(signTabs);

    let quickAddOptions: QuickAddOption[] = [];

    quickAddOptions = quickAddOptions.concat(
      this.buildQuickAddOptions(ContentControlType.VARIABLE, variableOptions),
      this.buildQuickAddOptions(ContentControlType.LOOP, loopOptions),
    );

    return {
      variableOptions,
      dateSignedOptions,
      initialsOptions,
      signatureOptions,
      loopOptions,
      loopLoopOptions,
      loopVariableOptions,
      loopDateSignedOptions,
      loopInitialsOptions,
      loopSignatureOptions,
      loopSignerOptions,
      formInputOptions,
      signerOptions,
      quickAddOptions,
    };
  }

  public static createTreeViewForQuickAdd(
    quickAddOptions: QuickAddOption[],
    rootName: string = "Walter",
  ): QuickAddTreeNode {
    if (!quickAddOptions.length) {
      return { value: rootName, children: {} };
    }

    const root: QuickAddTreeNode = { value: rootName, children: {} };

    for (const quickAddOption of quickAddOptions) {
      const isCount = quickAddOption.option.label.includes("Count");

      let node = root;
      let variable: Variable | undefined = quickAddOption.option.variable;

      while (variable) {
        const part = variable.localName;
        // TODO: fix typescript type error. Remove @ts-ignore comment to see the error
        // @ts-ignore
        const isLastPart = part === quickAddOption.option.variable.leafName;

        const key = isCount && isLastPart ? `${part}_count` : part;

        if (key === "[]") {
          variable = variable.child;
          continue;
        }

        if (!node.children[key]) {
          node.children[key] = { value: part, children: {} };
        }

        node = node.children[key];
        variable = variable.child;
      }

      node.quickAddOption = quickAddOption;
    }

    return root;
  }

  public static buildLoopCountOptions(loops: Variable[]): InputOption[] {
    const result: InputOption[] = [];

    loops.forEach((loop) => {
      const group = this.buildGroup(loop);
      const label = this.buildVariableLabel(loop);

      loop.addFilter("size");

      result.push({
        description: `The total number of ${label.toLowerCase()}.`,
        group,
        label: `${label} Count`,
        value: loop.fullName,
        variable: loop,
      });
    });

    return result;
  }

  public static buildVariableOptions(variables: Variable[]): InputOption[] {
    const result: InputOption[] = [];

    variables.forEach((variable) => {
      const group = this.buildGroup(variable);
      const label = this.buildVariableLabel(variable);

      result.push({
        description: variable.leafDescription,
        group,
        label,
        value: variable.fullName,
        variable,
        format: variable.leafFormat,
      });
    });

    return result.sort((a, b) => a.value.localeCompare(b.value));
  }

  public static buildSignatureOptions(variables: Variable[]): InputOption[] {
    const result: InputOption[] = [];

    variables.forEach((variable) => {
      const group = this.buildGroup(variable);
      const label = this.buildSignTabLabel(variable, "Signature");

      result.push({
        description: "Add a signature slot.",
        group,
        label,
        value: variable.fullName,
        variable,
      });
    });

    return result;
  }

  public static buildInitialsOptions(variables: Variable[]): InputOption[] {
    const result: InputOption[] = [];

    variables.forEach((variable) => {
      const group = this.buildGroup(variable);
      const label = this.buildSignTabLabel(variable, "Initials");

      result.push({
        description: "Add an initials slot.",
        group,
        label,
        value: variable.fullName,
        variable,
      });
    });

    return result;
  }

  public static buildDateSignedOptions(variables: Variable[]): InputOption[] {
    const result: InputOption[] = [];

    variables.forEach((variable) => {
      const group = this.buildGroup(variable);
      const label = this.buildSignTabLabel(variable, "Date Signed");

      result.push({
        description: "Add a date signed slot.",
        group,
        label,
        value: variable.fullName,
        variable,
      });
    });

    return result;
  }

  public static buildSignerOptions(variables: Variable[]): InputOption[] {
    const result: InputOption[] = [];

    variables.forEach((variable) => {
      const group = this.buildGroup(variable);

      result.push({
        description: "Add input for signer.",
        group: "",
        label: group,
        value: variable.fullName,
        variable,
      });
    });

    return result;
  }

  public static buildFormInputOptions(): InputOption[] {
    return [
      // TODO: fix typescript type error. Remove @ts-ignore comment to see the error
      // @ts-ignore
      {
        description: "Add checkbox for signer.",
        group: "Form Input",
        label: "Checkbox",
        value: "checkbox",
      },
      // TODO: fix typescript type error. Remove @ts-ignore comment to see the error
      // @ts-ignore
      {
        description: "Add radio button for signer.",
        group: "Form Input",
        label: "Radio Button",
        value: "radio",
      },
      // TODO: fix typescript type error. Remove @ts-ignore comment to see the error
      // @ts-ignore
      {
        description: "Add text field for signer.",
        group: "Form Input",
        label: "Text Field",
        value: "text",
      },
    ];
  }

  public static buildQuickAddOptions(element: ContentControlType, options: InputOption[]): QuickAddOption[] {
    return options.map((option) => ({ element, option }));
  }

  /**
   * Builds the grouping text shown in the dropdown lists.
   *
   * @param variable a Variable object
   * @returns a string representing the group
   * @example Given a variable with the name "company.directors.[].address.city", this method will return "Director → Address"
   * @example Given a variable with the name "company.directors.[].end_date", this method will return "Director"
   * @example Given a variable with the name "company.directors.[].relationships[].type", this method will return "Director → Relationship"
   */
  private static buildGroup(variable: Variable) {
    const groupings: string[] = [];

    if (variable.isLoopVariable()) {
      const parts = variable.nameWithoutLeaf.split(".[]").filter(compact);

      parts.forEach((part) => {
        const segments = part
          .split(".")
          .filter(compact)
          .map((segment: string) => {
            return pluralize.singular(capitalize(segment.replace(/_/g, " ")));
          });

        groupings.push(...segments);
      });

      return groupings.join(" \u2192 ");
    } else {
      return variable.nameWithoutLeaf
        .replace(/_/g, " ")
        .split(".")
        .map((name: string) => capitalize(name))
        .join(" \u2192 ");
    }
  }

  private static buildVariableLabel(variable: Variable) {
    if (variable.isLoopVariable()) {
      return variable.leafTitle;
    } else {
      return (variable.leafTitle || variable.localTitle).trim();
    }
  }

  private static buildSignTabLabel(variable: Variable, signTabType: string) {
    if (variable.isLoopVariable()) {
      return signTabType;
    } else {
      return `${variable.prefixTitleWithoutRoot} ${signTabType}`.trim();
    }
  }
}

function compact(entry: string) {
  return entry !== "";
}
