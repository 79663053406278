import { propertyPathToFieldCode } from "@src/lib/liquidx/BasicVariableElement";

export interface ConditionGroupData {
  conditions: Array<ConditionData | ConditionGroupData>;
  joinOperator: JoinOperatorType;
}

export type JoinOperatorType = "and" | "or";

export type OperatorType =
  | "equals"
  | "notEquals"
  | "in"
  | "notIn"
  | "greaterThan"
  | "lessThan"
  | "greaterThanOrEquals"
  | "lessThanOrEquals"
  | "contains"
  | "notContains"
  | "startsWith"
  | "endsWith"
  | "isTrue"
  | "isFalse"
  | "matches"
  | "lengthEquals"
  | "lengthGreaterThan"
  | "lengthGreaterThanOrEqualTo"
  | "lengthLessThan"
  | "lengthLessThanOrEqualTo"
  | "lengthIsTrue"
  | "lengthIsFalse";

export interface ConditionData {
  left: OperandData;
  operator?: OperatorType;
  right?: OperandData;
}

export type OperandData =
  | PropertyOperandData
  | StringOperandData
  | NumberOperandData
  | DateOperandData
  | BooleanOperandData
  | ArrayOperandData;

interface BaseOperandData {
  type: string;
  value: unknown;
}

export interface PropertyOperandData extends BaseOperandData {
  type: "property";
  value: string;
}

export interface StringOperandData extends BaseOperandData {
  type: "string";
  value: string;
}

export interface NumberOperandData extends BaseOperandData {
  type: "number";
  value: number;
}

export interface DateOperandData extends BaseOperandData {
  type: "date";
  value: string;
}

export interface BooleanOperandData extends BaseOperandData {
  type: "boolean";
  value: boolean;
}

export interface ArrayOperandData extends BaseOperandData {
  type: "array";
  value: Array<OperandData>;
}

export const emptyConditionGroup: () => ConditionGroupData = () => ({
  conditions: [emptyCondition()],
  joinOperator: "and",
});

export const emptyCondition: () => ConditionData = () => {
  return {
    left: { type: "property", value: "" },
  };
};

export class ConditionCodeGen {
  static joinOperators = {
    and: " && ",
    or: " || ",
  };

  static generateCode(group: ConditionGroupData): string {
    return this.groupToString(group);
  }

  static operandToString(operand: OperandData): string {
    switch (operand.type) {
      case "property":
        return propertyPathToFieldCode(operand.value);
      case "date":
        return `new Date(${JSON.stringify(operand.value)})`;
      case "string":
      case "number":
        return JSON.stringify(operand.value);
      case "boolean":
        return operand.value ? "true" : "false";
      case "array":
        throw new Error("Array operands are not supported yet");
      default:
        throw new Error(`Unknown operand type: ${operand}`);
    }
  }

  static conditionToString(condition: ConditionData): string {
    const left = this.operandToString(condition.left);
    const right = condition.right ? this.operandToString(condition.right) : null;

    if (condition.left.value === "") return "";
    if (condition.operator === undefined) return "";

    switch (condition.operator) {
      case "equals":
        return `${left} === ${right}`;
      case "notEquals":
        return `${left} !== ${right}`;
      case "in":
        return `${right}?.includes(${left})`;
      case "notIn":
        return `!${right}?.includes(${left})`;
      case "greaterThan":
        return `${left} > ${right}`;
      case "lessThan":
        return `${left} < ${right}`;
      case "greaterThanOrEquals":
        return `${left} >= ${right}`;
      case "lessThanOrEquals":
        return `${left} <= ${right}`;
      case "contains":
        return `${left}?.includes(${right})`;
      case "notContains":
        return `!${left}?.includes(${right})`;
      case "startsWith":
        return `${left}?.startsWith(${right})`;
      case "endsWith":
        return `${left}?.endsWith(${right})`;
      case "isTrue":
        return left;
      case "isFalse":
        return `!${left}`;
      case "matches":
        return `new RegExp(${right}).test(${left})`;
      case "lengthEquals":
        return `${left}?.length === ${right}`;
      case "lengthGreaterThan":
        return `${left}?.length > ${right}`;
      case "lengthGreaterThanOrEqualTo":
        return `${left}?.length >= ${right}`;
      case "lengthLessThan":
        return `${left}?.length < ${right}`;
      case "lengthLessThanOrEqualTo":
        return `${left}?.length <= ${right}`;
      case "lengthIsTrue":
        return `!!${left}?.length`;
      case "lengthIsFalse":
        return `!${left}?.length`;
      default:
        throw new Error(`Unknown operator: ${condition.operator}`);
    }
  }

  static groupToString(group: ConditionGroupData): string {
    if (group.conditions.length === 0) return "";

    const conditions = group.conditions.map((condition) => {
      if ("conditions" in condition) {
        return this.groupToString(condition);
      } else {
        return this.conditionToString(condition);
      }
    });

    const joinedConditions = conditions.filter(Boolean).join(this.joinOperators[group.joinOperator]);

    if (conditions.length === 1) {
      return joinedConditions;
    } else {
      return `(${joinedConditions})`;
    }
  }
}
