import { useEffect, useContext } from "react";
import { ContentControlEvent } from "@src/lib/eventHandlers/contentControl";
import { WalterContext } from "../providers/walter";
import { useInputOptions } from "./useInputOptions";
import Debug from "@src/lib/debug";
import { Variable } from "@src/lib/walter";
import { BasicLoopElement, DynamicElement, LoopElement } from "@src/lib/liquidx/internal";
import { captureErrorInfo } from "@src/taskpane/helpers/errorHandler";
import { focusedSchemaStore } from "@src/lib/schemas";

// Custom hook to manage content control selection
export const useOnSelectionEvent = () => {
  const { setSelectedDynamicElementIds } = useContext(WalterContext);
  const { addInputOptions } = useInputOptions();

  useEffect(() => {
    async function fetchAndSetSelectedIds(ids: number[] = []): Promise<void> {
      let selectedIds: number[] = [];

      try {
        selectedIds = await DynamicElement.updateSelectedIds();
      } catch (error) {
        captureErrorInfo(error);
      }
      setSelectedDynamicElementIds(selectedIds);
      await focusedSchemaStore.save(BasicLoopElement.schemaFor(DynamicElement.focused));

      // TODO: Be more specific about this being the parent content control of the selection
      // TODO: Only reset inputs if the parent content control has changed
      const lastSelectedId = selectedIds[selectedIds.length - 1];
      const lastSelectedDynamicElement = DynamicElement.find(lastSelectedId);
      const variables = lastSelectedDynamicElement?.selfAndAncestors
        .map((dynamicElement) => dynamicElement instanceof LoopElement && dynamicElement.variable)
        .filter(Boolean) as Variable[];

      addInputOptions(variables ?? []);

      // TODO: Consider emitting an exited event too.
      const exitedIds = ids.filter((id) => !selectedIds.includes(id));
      for (const id of exitedIds) DynamicElement.find(id)?.resetPlaceholder();
    }

    async function processSelectedContentControls(event: Event) {
      const customEvent = event as CustomEvent<{ ids: number[] }>;
      Debug.log("processSelectedContentControls", `Processing selected content controls: ${customEvent.detail.ids}`);

      await fetchAndSetSelectedIds(customEvent.detail.ids ?? []);
    }

    void fetchAndSetSelectedIds();

    window.addEventListener(ContentControlEvent.ON_SELECTION_CHANGE, processSelectedContentControls);
    window.addEventListener(ContentControlEvent.ON_DELETED, processSelectedContentControls);
    return () => {
      window.removeEventListener(ContentControlEvent.ON_SELECTION_CHANGE, processSelectedContentControls);
      window.removeEventListener(ContentControlEvent.ON_DELETED, processSelectedContentControls);
    };
  }, []);

  return true;
};
