import { Field } from "@src/lib/schemas";

export function getFieldsType(fields: Field[]) {
  let fieldsType: Record<string, string> = {};

  for (let field of fields) {
    const node = field.node;
    const resolvedSchema = node.resolvedSchema;
    const type = resolvedSchema.type;

    if ("typescript_interface" in resolvedSchema && typeof resolvedSchema.typescript_interface === "string") {
      fieldsType[field.key] = `${resolvedSchema.typescript_interface} | undefined`;
    } else if (type && typeof type === "string") {
      fieldsType[field.key] = `${type} | undefined`;
    }
  }

  return JSON.stringify(fieldsType).replace(/"/g, "");
}
