import { syncEngine } from "../sync-engine/SyncEngine";
import { GetValueFromCustomXmlStore } from "../sync-engine/operations/GetValueFromCustomXmlStore";
import { SetValueInCustomXmlStore } from "../sync-engine/operations/SetValueInCustomXmlStore";
import { JSONSchema9 } from "../types/jsonSchema";

export class WalterStore {
  static async setSchema(schema: JSONSchema9) {
    return this.set("schema", schema);
  }

  static async getSchema() {
    return this.fetch("schema", { type: "object", properties: {} } as JSONSchema9);
  }

  static async get<T>(key: string): Promise<T | undefined> {
    return syncEngine.perform(new GetValueFromCustomXmlStore<T>(key));
  }

  static async fetch<T>(key: string, defaultValue: T): Promise<T> {
    const result = await syncEngine.perform(new GetValueFromCustomXmlStore(key, defaultValue));
    return result ?? defaultValue;
  }

  static async set<T>(key: string, value: T) {
    return syncEngine.perform(new SetValueInCustomXmlStore(key, value));
  }
}
