import { BatchContext } from "./BatchContext";
import { Operation } from "./operations/Operation";

export class OperationContext {
  operation: Operation<unknown>;
  context: BatchContext;

  constructor(operation: Operation<unknown>, context: BatchContext) {
    this.operation = operation;
    this.context = context;
  }

  async sync() {
    return this.context.sync(this.operation);
  }

  get platform() {
    return this.context.platform;
  }

  getContentControl(id: number) {
    return this.context.getContentControl(id);
  }

  get document() {
    return this.context.document;
  }

  get wordContext() {
    return this.context.wordContext;
  }

  trace(message: string) {
    this.context.trace(message);
  }
}
