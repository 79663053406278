import { FC, JSX, MutableRefObject, useContext, useEffect, useRef, useState } from "react";
import {
  Menu,
  MenuDivider,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuProps,
  MenuTrigger,
  TreeItem,
  TreeItemLayout,
} from "@fluentui/react-components";
import { mapDynamicElement } from "@src/taskpane/helpers/mapDynamicElement";
import { useIndentedTree } from "./IndentedTree";
import { useStyles } from "./FieldsPane";
import { Field, SchemaNode } from "@src/lib/schemas";
import { DocumentFieldTreeItem } from "./DocumentFieldTreeItem";
import { insertable } from "../home/Toolbar";
import { InsertElementSubmenu } from "./InsertElementSubmenu";
import { RemoveFieldMenuItem } from "./RemoveFieldMenuItem";
import clsx from "clsx";
import { MoreMenu } from "./ObjectTree";
import { Pencil } from "lucide-react";
import { useHistory } from "react-router-dom";
import { MenuTitle } from "../home/MenuTitle";
import { AddField } from "../fields/Add";
import { BottomDialog } from "../home/BottomDialog";
import { useBottomDialog } from "@src/taskpane/hooks/useBottomDialog";
import { ComputedFieldSchema, EditComputedField } from "./EditVariableField";
import { PluralizeComputedField } from "./PluralizeComputedField";
import { InspectIcon } from "../home/ElementsPane";
import debug from "@src/lib/debug";
import { WalterContext } from "@src/taskpane/providers/walter/index.tsx";

export function scrollToRef<T extends HTMLElement | null>(ref: MutableRefObject<T>) {
  if (ref.current) {
    ref.current.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "center",
    });
  }
}

/**
 * This makes it easy to open the edit dialog for a field.
 *
 * @example
 * const editField = useEditField();
 *
 * return <Link onClick={() => editField(field)}>Edit field</Link>;
 */
export function useEditField() {
  const { openDialog, closeDialog } = useBottomDialog();

  return function (field: Field) {
    if (field.fieldType === "computed") {
      const variableField = new PluralizeComputedField({ schemaNode: field.node });

      openDialog(
        <BottomDialog title="Edit computed field" onOpenChange={({ open }) => !open && closeDialog()}>
          <EditComputedField computedField={variableField} onSubmit={() => closeDialog()} />
        </BottomDialog>,
      );
    } else {
      openDialog(
        <BottomDialog title="Edit field" onOpenChange={({ open }) => !open && closeDialog()}>
          <AddField field={field} onSubmit={() => closeDialog()} />
        </BottomDialog>,
      );
    }
  };
}

export const FieldMenuItems: FC<{ node: SchemaNode; isFirst?: boolean }> = ({ node, isFirst }) => {
  const history = useHistory();
  const editField = useEditField();
  const { showNewUi } = useContext(WalterContext);

  const field = node.field;
  if (!field) return null;

  function handleEdit() {
    if (!field) return;
    if (!showNewUi) {
      history.push(`/fields/${field.key}`);
      return;
    }

    editField(field);
  }

  return (
    <>
      {!isFirst && <MenuDivider />}
      <MenuTitle key="field">Field</MenuTitle>
      {import.meta.env.DEV && (
        <MenuItem
          key={`inspect/${field.key}`}
          onClick={() => {
            debug.log("Inspect field", field.key, field.node.schema);
            if (field.isComputed) {
              const computedSchema = field.node.schema as ComputedFieldSchema;
              debug.log(computedSchema.computed.render.code.replace(/;/g, ";\n"));
            }
          }}
          icon={<InspectIcon />}
        >
          Inspect field
        </MenuItem>
      )}
      <MenuItem key="editField" onClick={handleEdit} icon={<Pencil width="14" strokeWidth="1.5" />}>
        Edit field
      </MenuItem>
      <RemoveFieldMenuItem property={node} />
    </>
  );
};

export const ScalarTreeItem: FC<{ node: SchemaNode }> = ({ node }) => {
  const { padding, focusedItem } = useIndentedTree();
  const styles = useStyles();
  const itemRef = useRef<HTMLDivElement>(null);
  const [menuOpen, setMenuOpen] = useState(false);

  // Scroll the focused item into view
  useEffect(() => {
    if (focusedItem === node.path) {
      scrollToRef(itemRef);
    }
  }, [focusedItem, node.path]);

  let menuItems: JSX.Element;
  if (node.type === "boolean") {
    menuItems = (
      <>
        <InsertElementSubmenu
          property={node}
          items={insertable.conditionals}
          label="Conditionals"
          isFirst
          insertAround
        />
        <FieldMenuItems node={node} />
      </>
    );
  } else if (node.field?.fieldType === "computed") {
    menuItems = (
      <>
        <InsertElementSubmenu property={node} items={insertable.computedFields} label="Variables" isFirst />
        <FieldMenuItems node={node} />
      </>
    );
  } else {
    menuItems = (
      <>
        <InsertElementSubmenu
          property={node}
          items={node.type === "number" ? insertable.numberVariables : insertable.scalarVariables}
          label="Variables"
          isFirst
        />
        <InsertElementSubmenu property={node} items={insertable.conditionals} label="Conditionals" insertAround />
        <FieldMenuItems node={node} />
      </>
    );
  }

  const onMenuOpenChange: MenuProps["onOpenChange"] = (_, { open }) => {
    setMenuOpen(open);
  };

  const treeItem = (
    <Menu positioning="below-end" openOnContext open={menuOpen} onOpenChange={onMenuOpenChange}>
      <MenuTrigger disableButtonEnhancement>
        <TreeItem
          itemType="leaf"
          value={node.path}
          className={clsx(focusedItem === node.path && styles.treeItemFocused)}
          onClick={() => setMenuOpen(true)}
        >
          <TreeItemLayout
            className={styles.treeItemLayout}
            style={{ paddingLeft: padding }}
            actions={<MoreMenu>{menuItems}</MoreMenu>}
          >
            <div className={styles.treeItemContent} ref={itemRef}>
              {mapDynamicElement("variable").iconSmall}
              <span title={node.description}>{node.title}</span>
            </div>
          </TreeItemLayout>
        </TreeItem>
      </MenuTrigger>

      <MenuPopover>
        <MenuList>{menuItems}</MenuList>
      </MenuPopover>
    </Menu>
  );

  if (node.field && !node.field.isContextual) {
    return <DocumentFieldTreeItem property={node}>{treeItem}</DocumentFieldTreeItem>;
  } else {
    return treeItem;
  }
};
