import * as React from "react";
import { FunctionComponent, useState, useEffect } from "react";
import {
  Button,
  Caption1,
  Divider,
  Input,
  InputProps,
  Label,
  makeStyles,
  shorthands,
  useId,
} from "@fluentui/react-components";
import GroupedCombobox from "../shared/GroupedCombobox";
import { group } from "../../helpers/formatData";
import { InputOption, Variable } from "@src/lib/walter";
import { useHistory } from "react-router-dom";
import { InsertLocation } from "@src/lib/liquidx";
import { useDynamicElementFromParams } from "../../hooks/useDynamicElement";
import DeleteButton from "../shared/DeleteButton";
import usePageLoadTransition from "../../hooks/usePageLoadTransition";
import { useInputOptions } from "../../hooks/useInputOptions";
import { LoopElement } from "@src/lib/liquidx/internal";

export const formStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    ...shorthands.gap("8px"),
    // Stack the label above the field (with 2px gap per the design system)
    "> div": {
      display: "flex",
      flexDirection: "column",
      ...shorthands.gap("2px"),
    },
  },
});

export interface LoopFormData {
  variable: Variable;
  parameters: string[];
  insertLocation: InsertLocation;
}

export const onlyNumbers = (event: React.KeyboardEvent<HTMLInputElement>) => {
  // Allow Cmd+A (on Mac) or Ctrl+A (on Windows/Linux)
  if ((event.metaKey || event.ctrlKey) && event.key === "a") {
    return; // Allow default action (select all)
  }

  // Allow Tab key for navigation between inputs
  if (event.key === "Tab") {
    return; // Allow default tabbing action
  }

  // Check if the pressed key is not a digit
  if (
    !event.key.match(/[0-9]/) &&
    event.key !== "Backspace" &&
    event.key !== "ArrowLeft" &&
    event.key !== "ArrowRight"
  ) {
    event.preventDefault();
  }
};

export const Loop: FunctionComponent<{
  label: string;
  submitLabel: string;
}> = ({ label, submitLabel }) => {
  const {
    inputOptions: { loopOptions: options },
  } = useInputOptions();

  const loopElement = useDynamicElementFromParams(new LoopElement());
  const initiallySelectedOption = loopElement.isNew
    ? undefined
    : options.find((option: InputOption) => option.value === loopElement?.variable.fullName);
  const [selected, setSelected] = useState<InputOption | undefined>(initiallySelectedOption);
  const [limit, setLimit] = useState<string>("");
  const [offset, setOffset] = useState<string>("");
  const pageTransitionClassName = usePageLoadTransition();
  const history = useHistory();

  const form = formStyles();

  useEffect(() => {
    setSelected(initiallySelectedOption);
  }, [initiallySelectedOption]);

  useEffect(() => {
    if (loopElement.isNew) return;

    setLimit(loopElement.limit ?? "");
    setOffset(loopElement.offset ?? "");
  }, [loopElement, initiallySelectedOption]);

  const onSelect = (value: string) => {
    const inputOption = options.find((option: InputOption) => option.value === value);
    setSelected(inputOption);
  };

  const limitInputId = useId("limit");
  const onLimitChange: InputProps["onChange"] = (_, data) => {
    setLimit(data.value);
  };
  const offsetInputId = useId("offset");
  const onOffsetChange: InputProps["onChange"] = (_, data) => {
    setOffset(data.value);
  };

  async function handleSubmit() {
    const input = options.find((option: InputOption) => option.value === selected?.value);

    if (!input) return;
    const parameters = [];

    if (limit) parameters.push(`limit: ${limit}`);
    if (offset) parameters.push(`offset: ${offset}`);

    loopElement.applyLoopForm({
      // TODO: fix typescript type error. Remove @ts-ignore comment to see the error
      // @ts-ignore
      variable: input.variable,
      parameters,
      insertLocation: InsertLocation.SELECTION,
    });

    history.push("/");
    await loopElement.save();
  }

  async function handleDelete() {
    await loopElement.delete();
  }

  return (
    <div className={pageTransitionClassName}>
      <GroupedCombobox
        label={label}
        groupedOptions={group(options)}
        onSelect={onSelect}
        selectedOption={selected?.value}
        comboboxProps={{ placeholder: "Choose a field", disabled: !!initiallySelectedOption }}
      ></GroupedCombobox>

      {!!selected && <Divider>Optional Parameters</Divider>}
      <form className={form.root}>
        {!!selected && (
          <>
            <div>
              <Label weight="semibold" htmlFor={limitInputId}>
                Limit
              </Label>
              <Input
                id={limitInputId}
                type="number"
                min={1}
                onChange={onLimitChange}
                value={limit}
                onKeyDown={onlyNumbers}
              />
              <Caption1>Limits the loop to the specified number of iterations.</Caption1>
            </div>
            <div>
              <Label weight="semibold" htmlFor={offsetInputId}>
                Skip
              </Label>
              <Input
                id={offsetInputId}
                type="number"
                min={1}
                onChange={onOffsetChange}
                value={offset}
                onKeyDown={onlyNumbers}
              />
              <Caption1>Skip this many items at the start of the loop.</Caption1>
            </div>
          </>
        )}
      </form>
      <Button appearance="primary" onClick={handleSubmit} disabled={!selected}>
        {submitLabel}
      </Button>
      {loopElement && <DeleteButton onClick={handleDelete} />}
    </div>
  );
};
