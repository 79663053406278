import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { ContentControlEvent } from "@src/lib/eventHandlers/contentControl";
import { useInputOptions } from "./useInputOptions";

// Custom hook to manage content control deletion
export const useOnDeleteEvent = () => {
  const { resetInputOptions } = useInputOptions();
  const history = useHistory();

  useEffect(() => {
    function handleDelete() {
      resetInputOptions();
      history.push(`/`);
    }

    window.addEventListener(ContentControlEvent.ON_DELETED, handleDelete);

    return () => {
      window.removeEventListener(ContentControlEvent.ON_DELETED, handleDelete);
    };
  }, []);

  return true;
};
