import { FC, useState, useEffect } from "react";
import { Button, Checkbox } from "@fluentui/react-components";
import GroupedCombobox from "./GroupedCombobox";
import { group } from "../../helpers/formatData";
import { SignatureOption } from "../../../lib/walter";
import { useDynamicElementFromParams } from "../../hooks/useDynamicElement";
import { ContentControlType, SignTabTypes } from "../../../lib/liquidx";
import { useInputOptions } from "../../hooks/useInputOptions";
import { useHistory } from "react-router-dom";
import { formStyles } from "../loops/Loop";
import { WidthSlider } from "./WidthSlider";
import { SignTabElement } from "@src/lib/liquidx/internal";

export interface FormData {
  signer: string;
  width?: number | string;
  required: boolean;
}

function deriveFormValues(signTabElement: SignTabElement): FormData {
  if (signTabElement.isNew) return { signer: "", required: true };

  return {
    signer: signTabElement.variable.fullName,
    width: signTabElement.metadata.width,
    required: signTabElement.required ?? false,
  };
}

export const SignTab: FC<{
  initialType: SignTabTypes;
  label: string;
  submitText: string;
}> = ({ initialType, label, submitText }) => {
  const signTabElement = useDynamicElementFromParams(SignTabElement.create(initialType));
  const [formData, setFormData] = useState<FormData>(deriveFormValues(signTabElement));
  const [signerOptions, setSignerOptions] = useState<SignatureOption[]>([]);
  const { inputOptions } = useInputOptions();
  const [showRequired, setShowRequired] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const history = useHistory();
  const styles = formStyles();

  useEffect(() => {
    setFormData(deriveFormValues(signTabElement));
  }, [signTabElement]);

  useEffect(() => {
    setIsValid(formData && !!formData.signer);
  }, [formData]);

  useEffect(() => {
    const typeForOptions = signTabElement?.type ?? initialType;

    setShowRequired([ContentControlType.SIGNATURE, ContentControlType.INITIALS].includes(typeForOptions));

    if (typeForOptions === ContentControlType.SIGNATURE) {
      setSignerOptions(inputOptions.signatureOptions);
    } else if (typeForOptions === ContentControlType.INITIALS) {
      setSignerOptions(inputOptions.initialsOptions);
    } else if (typeForOptions === ContentControlType.DATE_SIGNED) {
      setSignerOptions(inputOptions.dateSignedOptions);
    } else {
      throw new Error(`Invalid sign tab type: ${typeForOptions}`);
    }
  }, [
    initialType,
    inputOptions.dateSignedOptions,
    inputOptions.initialsOptions,
    inputOptions.signatureOptions,
    signTabElement,
    signTabElement?.type,
  ]);

  const handleSubmit = () => {
    const el = signTabElement ?? SignTabElement.create(initialType);

    const signer = signerOptions.find((option: SignatureOption) => option.value === formData.signer);

    if (signer === undefined) {
      throw new Error(`Signer not found: ${formData}`);
    }

    // TODO: fix typescript type error. Remove @ts-ignore comment to see the error
    // @ts-ignore
    el.metadata = { variable: signer?.variable?.tagFormat, width: formData.width?.toString() };
    if (el.type != ContentControlType.DATE_SIGNED) el.metadata.required = formData.required;
    void el.save();

    history.push("/");
  };

  return (
    <div className={styles.root}>
      <GroupedCombobox
        label={label}
        groupedOptions={group(signerOptions)}
        onSelect={(value) => setFormData({ ...formData, signer: value })}
        selectedOption={formData?.signer}
        comboboxProps={{ placeholder: "Search for signatory" }}
      ></GroupedCombobox>
      {showRequired && (
        <Checkbox
          defaultChecked={true}
          checked={formData?.required}
          onChange={(_ev, data) => setFormData({ ...formData, required: data.checked as boolean })}
          label="Required"
        />
      )}
      <WidthSlider
        value={formData.width}
        onChange={(value) => setFormData({ ...formData, width: value })}
        defaultValue={140}
      />
      <Button appearance="primary" onClick={handleSubmit} disabled={!isValid}>
        {submitText}
      </Button>
    </div>
  );
};
