import { FC } from "react";
import { CollapsibleSection } from "../CollapsibleSection";
import { makeStyles, tokens } from "@fluentui/react-components";

const useStyles = makeStyles({
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: tokens.colorBackgroundOverlay,
    zIndex: 1000,
  },
  dialog: {
    position: "fixed",
    bottom: 0,
    width: "100%",
    backgroundColor: tokens.colorNeutralBackground1,
    color: tokens.colorNeutralForeground1,
    boxShadow: "0 -2px 5px rgba(0, 0, 0, 0.1)",
    zIndex: 1001, // Ensure the dialog is above the overlay
    display: "flex",
    flexDirection: "column",
    justifyContent: "stretch",
    maxHeight: "90vh", // Limit height to avoid overflow
    overflowY: "auto", // Enable scrolling if content exceeds the height
  },
  content: {
    padding: "12px 12px",
  },
});

export const BottomDialog: FC<{ title: string; onOpenChange: (_: { open: boolean }) => void }> = ({
  title,
  onOpenChange,
  children,
}) => {
  const styles = useStyles();

  return (
    <div onKeyDown={(e) => e.key === "Escape" && onOpenChange({ open: false })}>
      <div className={styles.overlay} onClick={() => onOpenChange({ open: false })} />
      <div className={styles.dialog}>
        <CollapsibleSection title={title} collapsible={false}>
          <div className={styles.content}>{children}</div>
        </CollapsibleSection>
      </div>
    </div>
  );
};
