import { FunctionComponent } from "react";
import { Button, makeStyles } from "@fluentui/react-components";

const useStyles = makeStyles({
  root: { marginTop: "32", color: "#DD1408" },
});

export interface IDeleteButtonProps {
  onClick: () => void;
}

const DeleteButton: FunctionComponent<IDeleteButtonProps> = ({ onClick }) => {
  const deleteButtonStyles = useStyles();

  return (
    <Button onClick={onClick} className={deleteButtonStyles.root}>
      Remove from document
    </Button>
  );
};

export default DeleteButton;
