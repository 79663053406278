import { WalterStore } from "../properties/WalterStore";

export type MigrationTypes = "api" | "local";

export abstract class Migration {
  static async appliedVersions() {
    return WalterStore.fetch("appliedMigrations", [] as number[]);
  }

  static async setAppliedVersions(versions: number[]) {
    return WalterStore.set("appliedMigrations", versions);
  }

  abstract type: MigrationTypes;
  abstract apply(): Promise<boolean>;

  /**
   * Any unique string that identifies this migration. The class name is a good choice.
   *
   * NOTE: Don't use `constructor.name` becaues it is obfuscated in production builds.
   */
  abstract get name(): string;
}
