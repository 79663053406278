import { FC, useState } from "react";
import { Input, Label, Link, Select, Switch, Text, makeStyles, shorthands, tokens } from "@fluentui/react-components";
import { Format } from "liquidx";
import { DateFormatterData } from "@src/lib/liquidx/BasicVariableElement";

const useStyles = makeStyles({
  formGroup: {
    display: "flex",
    flexDirection: "column",
    ...shorthands.gap("8px"),
  },
  formRow: {
    display: "flex",
    flexDirection: "row",
    ...shorthands.gap("8px"),
  },
});

export const DateFormatter: FC<{
  value: DateFormatterData | undefined;
  onChange: (_: DateFormatterData) => void;
}> = ({ value, onChange }) => {
  const [data, setData] = useState<DateFormatterData>(value ?? { details: {}, props: { format: "" }, name: "date" });
  const [preview, setPreview] = useState(generatePreview(data.props.format));
  const styles = useStyles();

  function generatePreview(format: string) {
    try {
      return Format.date(new Date(), { format });
    } catch {
      return "Invalid format";
    }
  }

  function updateDetails(updated: Partial<DateFormatterData["details"]>) {
    setData((prev) => {
      const updatedData = { ...prev, details: { ...prev.details, ...updated } };
      updateValue(updatedData);
      return updatedData;
    });
  }

  function updateProps(updated: Partial<DateFormatterData["props"]>) {
    setData((prev) => {
      const updatedData = { ...prev, props: { ...prev.props, ...updated } };
      updateValue(updatedData);
      return updatedData;
    });
  }

  function updateValue(updated: DateFormatterData) {
    if (!updated.details.customFormat) {
      updated.props.format = [updated.details.dateFormat, updated.details.timeFormat].filter(Boolean).join(" ");
    }

    setPreview(generatePreview(updated.props.format));
    onChange({ ...updated });
  }

  function enableAdvancedMode() {
    updateDetails({ customFormat: true });
  }

  function disableAdvancedMode() {
    updateDetails({ customFormat: false });
  }

  return (
    <>
      {data.details.customFormat && (
        <>
          <Label>Date and time format</Label>
          <Input
            spellCheck={false}
            autoFocus
            value={data.props.format}
            onChange={(_, { value }) => updateProps({ format: value })}
            contentAfter={
              <Link appearance="subtle" href="https://date-fns.org/v1.30.1/docs/format">
                details
              </Link>
            }
          />
        </>
      )}
      {!data.details.customFormat && (
        <div className={styles.formRow}>
          <div className={styles.formGroup}>
            <Label>Date format</Label>
            <Select value={data.details.dateFormat} onChange={(_, { value }) => updateDetails({ dateFormat: value })}>
              <option value="">None</option>
              <option value="yyyy-MM-dd">YYYY-MM-DD</option>
              <option value="MMMM d, yyyy">Month Day, Year</option>
              <option value="d MMMM yyyy">Day Month Year</option>
              <option value="EEEE">Day of the week</option>
            </Select>
          </div>
          <div className={styles.formGroup}>
            <Label>Time format</Label>
            <Select value={data.details.timeFormat} onChange={(_, { value }) => updateDetails({ timeFormat: value })}>
              <option value="">None</option>
              <option value="h:mm a">12-hour</option>
              <option value="HH:mm">24-hour</option>
            </Select>
          </div>
        </div>
      )}
      <Switch
        label="Custom format"
        checked={data.details.customFormat}
        onChange={(_, { checked }) => (checked ? enableAdvancedMode() : disableAdvancedMode())}
      />
      <Label>Preview</Label>
      <div
        style={{
          padding: "12px",
          borderRadius: "4px",
          backgroundColor: tokens.colorNeutralBackground1Hover,
          display: "flex",
          flexDirection: "row",
          gap: "6px",
        }}
      >
        <Text>{preview}</Text>
      </div>
    </>
  );
};
