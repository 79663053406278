import { JSONSchema9 } from "../types/jsonSchema";
import { SchemaStore, SchemaNode, Field } from "@src/lib/schemas";
import { documentSchemaStore } from "./PersistedSchemaStore";

/**
 * This wraps a field store, allowing additional fields to be loaded on top of another field store.
 *
 * We use this to wrap a document field store, making loop fields available without for the UI and
 * for validations without modifying the document fields.
 */

export class ContextualSchemaStore extends SchemaStore {
  constructor(schema: JSONSchema9, private wrappedStore: SchemaStore = new SchemaStore({})) {
    super(schema);
  }

  async save(newSchema?: JSONSchema9 | undefined) {
    // Mark all properties as contextual
    for (const key in newSchema?.properties) {
      const propertySchema = newSchema.properties[key];
      if (propertySchema && typeof propertySchema === "object") {
        (propertySchema as { contextual: boolean }).contextual = true;
      }
    }

    await super.save(newSchema);
  }

  /**
   * Holds the event handler for the wrapped store's change event.
   */
  private bubbleEventHandler: ((_: SchemaStore) => void) | undefined;

  addEventListener(type: "change", callback: (_: SchemaStore) => void): void {
    super.addEventListener(type, callback);

    // Bubble change events from the wrapped store to listeners for this store
    if (!this.bubbleEventHandler) {
      this.bubbleEventHandler = () => this.dispatchEvent("change");
      this.wrappedStore.addEventListener(type, this.bubbleEventHandler);
    }
  }

  removeEventListener(type: "change", callback: (_: SchemaStore) => void): void {
    super.removeEventListener(type, callback);

    // Remove the bubble event handler if there are no more listeners
    if (this.bubbleEventHandler && !this.isOnChangeRegistered) {
      this.wrappedStore.removeEventListener(type, this.bubbleEventHandler);
      this.bubbleEventHandler = undefined;
    }
  }

  get fields(): Field[] {
    const superFields = super.fieldKeys.map((key) => new Field(key, this));
    return this.mergeBy(superFields, this.wrappedStore.fields, "key");
  }

  get fieldKeys() {
    const allKeys = super.fieldKeys.concat(this.wrappedStore.fieldKeys);
    const seen = new Set();

    return allKeys.filter((key) => {
      if (seen.has(key)) {
        return false;
      } else {
        seen.add(key);
        return true;
      }
    });
  }

  find(path: string): SchemaNode | undefined {
    return super.find(path) ?? this.wrappedStore.find(path);
  }

  get children(): SchemaNode[] {
    return this.mergeBy(super.children, this.wrappedStore.children, "path");
  }

  get descendants(): SchemaNode[] {
    return this.mergeBy(super.descendants, this.wrappedStore.descendants, "path");
  }

  /**
   * Deduplicates two lists of {T} objects using a property on {T}.
   *
   * @example
   * mergeBy(
   *   [
   *     { id: 1, name: "Alice" },
   *     { id: 2, name: "Bob" }
   *   ],
   *   [
   *     { id: 2, name: "Bobby" },
   *     { id: 3, name: "Charlie" }
   *   ],
   *   "id"
   * ) == [{id: 1, name: "Alice"}, {id: 2, name: "Bob"}, {id: 3, name: "Charlie}]
   *
   * @param a list of {T} objects
   * @param b list of {T} objects
   * @param mergeBy property on {T} to use for merge
   * @returns deduplicated list of {T} objects
   */
  mergeBy<T>(a: T[], b: T[], mergeBy: keyof T) {
    return [...a, ...b].reduce((acc: T[], node) => {
      // Add to list if it's not already there (first one takes precedence)
      if (!acc.find((n) => n[mergeBy] === node[mergeBy])) acc.push(node);

      return acc;
    }, []);
  }
}

/**
 * This holds the fields for the focused element, which contains both the document and loop fields.
 * It is updated when the focused element changes.
 *
 * TODO: Should this be created within the React component?
 */
export const focusedSchemaStore = new ContextualSchemaStore({}, documentSchemaStore);
