import debug from "../debug";
import { OperationContext } from "./OperationContext";
import { Operation } from "./operations/Operation";

type PromiseResolve<T> = (value: T | PromiseLike<T>) => void;
type PromiseReject = (e: unknown) => void;

/**
 * This stores an operation with a promise resolve/reject function that was returned when the operation was enqueued.
 * This allows the operation to resolve the promise when it is done.
 */
export class OperationWrapper<T> {
  promiseResolves: PromiseResolve<T>[];
  promiseRejects: PromiseReject[];
  operation: Operation<T>;

  constructor(operation: Operation<T>, promiseResolves: PromiseResolve<T>[], promiseRejects: PromiseReject[]) {
    this.promiseResolves = promiseResolves;
    this.promiseRejects = promiseRejects;
    this.operation = operation;
  }

  merge(other: OperationWrapper<unknown>): OperationWrapper<T> | undefined {
    const merged = this.operation.merge(other.operation);

    if (merged) {
      return new OperationWrapper(
        merged,
        [...this.promiseResolves, ...other.promiseResolves],
        [...this.promiseRejects, ...other.promiseRejects],
      );
    }
  }

  async commit(context: OperationContext): Promise<void> {
    const value = await this.operation.commit(context);
    this.promiseResolves.forEach((resolve) => resolve(value));
  }

  reject(e: unknown) {
    this.promiseRejects.forEach((reject) => reject(e));
  }

  inspect() {
    debug.info(JSON.parse(JSON.stringify(this.operation)));
    return `${this.operation.constructor.name}: ${JSON.stringify(this.operation)}`;
  }
}
