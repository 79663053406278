import { SchemaRepository, SchemaStore } from "@src/lib/schemas";
import { JSONSchema9 } from "../types/jsonSchema";
import { WalterStore } from "../properties/WalterStore";

/**
 * This saves the schema into a persistent store.
 */
export class PersistentSchemaStore extends SchemaStore {
  constructor(private persistentStore: SchemaRepository) {
    super({});
  }

  async load() {
    this.schema = await this.persistentStore.getSchema();
    this.dispatchEvent("change");
  }

  /**
   * This persists the fields in the document settings before delegating to the parent class.
   */
  async save(newSchema?: JSONSchema9) {
    await this.persistentStore.setSchema(newSchema ?? this.schema).catch(console.error);
    await super.save(newSchema);
  }
}

/**
 * This holds the fields for the document.
 */
export const documentSchemaStore = new PersistentSchemaStore(WalterStore);
