import { SchemaNode } from "@src/lib/schemas";
import { createContext, useContext } from "react";
import { ConditionGroupData } from "./ConditionCodeGen";

type ConditionBuilderContextType = {
  nodes: SchemaNode[];
  data: ConditionGroupData;
  update: (options: Partial<ConditionGroupData>) => void;
};

const defaultContextValue: ConditionBuilderContextType = {
  nodes: [],
  data: { conditions: [], joinOperator: "and" },
  update: () => {},
};

export const ConditionBuilderContext = createContext<ConditionBuilderContextType>(defaultContextValue);
export const useConditionBuilder = () => {
  const context = useContext(ConditionBuilderContext);
  if (!context) throw new Error("useConditionBuilder must be used within a ConditionBuilderProvider");
  return context;
};
