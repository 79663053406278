import { useEffect } from "react";
import { defaultStorage } from "@src/lib/settings/defaultStorage";
import { FeatureFlagsResponseBody, WalterApi } from "@src/lib/walter/api";
import { captureErrorInfo } from "@src/taskpane/helpers/errorHandler";

type FeatureFlags = {
  enabledFeatureFlags: string[];
  expiresAt: number;
};

const useFeatureFlags = () => {
  const featureFlagManager = FeatureFlagManager.getInstance();

  useEffect(() => {
    return () => {
      featureFlagManager.abortController?.abort("Component unmounted. Aborting feature flag request.");
    };
  }, []);

  return featureFlagManager;
};

class FeatureFlagManager {
  private static instance: FeatureFlagManager;
  public abortController: AbortController | null = null;
  // TODO: Rather than manage this state inside the singleton, can we throwaway the object and recreate?
  //       This makes it easier to use in React, which doesn't do well with objects that either stay around
  //       forever or change every render.
  version: string | null = null;
  private pendingFeatureFlagResponse: Promise<void | FeatureFlagsResponseBody> | null = null;

  public static getInstance(): FeatureFlagManager {
    if (!FeatureFlagManager.instance) {
      FeatureFlagManager.instance = new FeatureFlagManager();
    }
    return FeatureFlagManager.instance;
  }

  /**
   * Check if a specific feature flag is enabled.
   * For a list of features available see: app/services/feature_flag.rb
   */
  public async isEnabled(featureFlagName: string) {
    const flags = await this.getFlags();
    const enabledFlags = flags?.enabledFeatureFlags;

    return enabledFlags?.includes(featureFlagName);
  }

  /**
   * Check if all of the provided feature flags are enabled.
   * For a list of features available see: app/services/feature_flag.rb
   */
  public async areEnabled(featureFlagNames: string[]) {
    const enabledFlags = (await this.getFlags())?.enabledFeatureFlags;
    return featureFlagNames.every((featureFlagName) => enabledFlags?.includes?.(featureFlagName));
  }

  private getCachedFlags() {
    return defaultStorage.get<{
      enabledFeatureFlags: FeatureFlags["enabledFeatureFlags"];
      expiresAt: number;
    }>("walterFeatureFlags");
  }

  private cacheExpired() {
    let featureFlags = this.getCachedFlags();

    if (!featureFlags) {
      return true;
    }

    return this.hasExpired(featureFlags?.expiresAt);
  }

  private async getFlags() {
    if (this.pendingFeatureFlagResponse) {
      // if there is already a pending request, return the promise that resolves to the
      // response of that request.
      return this.pendingFeatureFlagResponse;
    } else if (!this.cacheExpired()) {
      // no need to make a network request if the cache hasn't expired
      return this.getCachedFlags();
    }

    const { response, abortController } = WalterApi.getFeatureFlags();
    this.abortController = abortController;

    const featureFlags = response
      .then((flags) => {
        if (flags) {
          const featureFlags = {
            enabledFeatureFlags: flags.enabledFeatureFlags,
            expiresAt: this.expiresAt(),
          };

          this.version = featureFlags.expiresAt.toString();
          defaultStorage.add("walterFeatureFlags", featureFlags);

          return featureFlags;
        }
      })
      .catch(captureErrorInfo)
      .finally(() => {
        this.pendingFeatureFlagResponse = null;
        this.abortController = null;
      });

    this.pendingFeatureFlagResponse = featureFlags;

    return featureFlags;
  }

  private expiresAt() {
    // expires 1 minute from now so that the values of feature flags are at most 1 minute stale.
    // this is helpful for when the feature flags values are updated in Walter so that features can be quickly enabled/disabled
    // for users.
    return new Date().getTime() + 1000 * 60;
  }

  private hasExpired(expiresAt: number): boolean {
    if (!expiresAt) {
      return true;
    } else {
      return new Date(expiresAt).getTime() - new Date().getTime() < 0;
    }
  }
}

export { useFeatureFlags, FeatureFlagManager };
