import { ContentControlType, InsertLocation, VariableData } from ".";
import { Variable } from "../walter/variable";
import { InputOption, ParsedInputOptions, QuickAddOption, inputOptionsHandler } from "../walter";
import debug from "../debug";
import { LiquidTag, Tag } from "./ParsedTag";
import {
  DynamicElement,
  BranchElement,
  IfElement,
  LoopElement,
  SignTabElement,
  SignTabInputElement,
  VariableElement,
  JsElement,
} from "./internal";

interface LiquidElementClass {
  new (tag: LiquidTag, id?: number | null, title?: string, parentId?: number): DynamicElement;
  supports: (tag: LiquidTag) => boolean;
}

// The superclass for Liquid dynamic elements.
export abstract class LiquidElement extends DynamicElement {
  static fromQuickAdd(quickAddOption: QuickAddOption, insertLocation: InsertLocation) {
    const { element: type } = quickAddOption;
    const tag = `walter:${type}:{}`;

    const newDynamicElement = this.fromProperties({ tag }) as LiquidElement;
    if (!newDynamicElement) return;

    newDynamicElement.applyQuickAdd(quickAddOption, insertLocation);
    return newDynamicElement;
  }

  static build({
    tag,
    id,
    title,
    parentId,
  }: {
    tag: Tag;
    id?: number | null;
    title?: string;
    parentId?: number;
  }): DynamicElement | null {
    if (!tag.valid) return null;
    if (!("data" in tag)) return null;

    const classes: LiquidElementClass[] = [
      VariableElement,
      SignTabInputElement,
      SignTabElement,
      LoopElement,
      IfElement,
      BranchElement,
    ];

    for (const klass of classes) {
      if (klass.supports(tag)) return new klass(tag, id, title, parentId);
    }

    return null;
  }

  _title: string;
  type: ContentControlType;
  data: unknown;

  constructor(tag: LiquidTag, id?: number | null, title?: string, parentId?: number) {
    super();

    this.id = id ?? null;
    this.type = tag.type;
    this.parentId = parentId;
    this._title = title ?? "";
    this.data = tag.data;
  }

  abstract applyQuickAdd(quickAddOption: QuickAddOption, location: InsertLocation): void;

  public buildTag() {
    return `walter:${this.type}:${JSON.stringify(this.data)}`;
  }

  get label(): string {
    return (this.data as any)?.label ?? "";
  }

  get title(): string {
    return this._title;
  }

  set title(value: string) {
    this._title = value;
  }

  /**
   * @deprecated This method is deprecated and will be removed once we migrate to JS-powered variables.
   */
  get variable(): Variable {
    // A dehydrated variable is one that stores only the path information and not title or description, like `{"name": "company", child: {"name": "phone_number"}}`.
    // After retrieving the dehydrated variable, we attempt to hydrate it to ensure it has that additional information.
    const dehydrated = Variable.buildFromVariableData(this.data as VariableData);
    const hydrated = findValidInputOption(dehydrated)?.variable;
    if (hydrated) return hydrated.merge(dehydrated);
    return dehydrated;
  }

  /**
   * Parses and returns a VariableData object from the content control's tag property.
   *
   * @param dynamicElement - a Tag object
   * @returns a VariableData object
   *
   */
  get variableData(): VariableData | null {
    const data = this.data as any;
    const isIfWithVariableCondition = this.type === "if" && data.type === "variable";
    const isIfWithComparison = this.type === "if" && data.type === "comparison";
    const isSignable = DynamicElement.SIGNABLE_TYPES.includes(this.type);
    const isVariable = this.type === "variable";
    const isLoop = this.type === "loop";

    let variable: VariableData | null;

    if (isIfWithVariableCondition || isSignable) {
      variable = data.variable;
    } else if (isLoop) {
      variable = data.enumerable;
    } else if (isIfWithComparison) {
      variable = data.left;
    } else if (isVariable) {
      variable = data;
    } else {
      variable = null;
    }

    return variable;
  }

  inspect() {
    debug.log({
      id: this.id,
      parentId: this.parentId,
      parent: this.parent?.inspect(),
      data: this.data,
      title: this.title,
    });
  }

  validate(): void {
    super.validate();

    // Else branches are used by the new UI.
    if (this.type === "else-branch") return;

    if (DynamicElement.all().some((element) => element instanceof JsElement)) {
      this.errors.push("Elements from old UI can't be used with elements from the new UI.");
    }
  }
}

export function findValidInputOption(variable: Variable) {
  const optionsKeys = Object.keys(inputOptionsHandler.defaultOptions).filter((key) => key !== "quickAddOptions");

  let inputOption: InputOption | undefined;
  for (const key of optionsKeys) {
    const inputOptions = inputOptionsHandler.defaultOptions[key as keyof ParsedInputOptions] as InputOption[];
    inputOption = inputOptions.find((option: InputOption) => {
      return option.variable?.fullName === variable.fullName;
    });

    if (inputOption) return inputOption;
  }
}
