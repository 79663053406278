import { DynamicElement } from "@src/lib/liquidx/internal";
import { OperationContext } from "../OperationContext";
import { type Operation } from "./Operation";
import { InsertLocation } from "@src/lib/liquidx";

export interface InsertConditionalContentControlParams {
  color: string;
  hasElse: boolean;
  tag: string;
  title: string;
  insertLocation: InsertLocation;
}

export class InsertConditionalContentControl implements Operation<void> {
  color: string;
  hasElse: boolean;
  id = null;
  tag: string;
  title: string;
  insertLocation: InsertLocation;

  constructor({ title, color, tag, hasElse, insertLocation }: InsertConditionalContentControlParams) {
    this.color = color;
    this.title = title;
    this.tag = tag;
    this.hasElse = hasElse;
    this.insertLocation = insertLocation;
  }

  merge(_: Operation<void>) {
    return undefined;
  }

  public async commit(context: OperationContext) {
    let selection = context.document.getSelection().load("isEmpty");
    await selection.context.sync();
    const insertAsBlock = selection.isEmpty;

    const thenBranch = insertAsBlock
      ? selection.insertParagraph("", "After").insertContentControl()
      : selection.insertContentControl();

    thenBranch.set({
      appearance: Word.ContentControlAppearance.tags,
      color: DynamicElement.defaultColor,
      tag: "walter:if-branch",
      title: "[IF]",
      cannotDelete: true,
    });

    const wrapper = thenBranch.getRange(Word.RangeLocation.whole).insertContentControl();
    wrapper.set({
      appearance: Word.ContentControlAppearance.boundingBox,
      cannotDelete: true,
      cannotEdit: false,
      title: this.title,
      color: this.color,
      tag: this.tag,
    });

    if (this.hasElse) {
      const elseBranch = insertAsBlock
        ? wrapper.getRange("End").insertParagraph("", "After").insertContentControl()
        : thenBranch.getRange("After").insertText("", "After").insertContentControl();

      elseBranch.set({
        appearance: Word.ContentControlAppearance.tags,
        color: DynamicElement.defaultColor,
        tag: "walter:else-branch",
        title: "[Else]",
        cannotDelete: true,
      });
    }

    // Write changes to the document before locking editing
    await context.sync();

    wrapper.set({ cannotEdit: true });
    await context.sync();
  }
}
