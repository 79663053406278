// Slimmed down version of the sample from:
// https://github.com/microsoft/monaco-editor/blob/8503aef01e4f06dff414c15904a1d961e25eb8eb/samples/browser-esm-vite-react/src/userWorker.ts

// This is used to load the monaco editor worker in a web worker
// which are used to provide language services like type checking

import * as monaco from "monaco-editor";
import editorWorker from "monaco-editor/esm/vs/editor/editor.worker?worker";
import tsWorker from "monaco-editor/esm/vs/language/typescript/ts.worker?worker";

// @ts-ignore
self.MonacoEnvironment = {
  getWorker(_: any, label: string) {
    if (label === "typescript" || label === "javascript") {
      return new tsWorker();
    }
    return new editorWorker();
  },
};

monaco.languages.typescript.typescriptDefaults.setEagerModelSync(true);
