import { FC, useEffect, useState } from "react";
import { Input, Label, Text, Switch, makeStyles, tokens } from "@fluentui/react-components";
import pluralize from "pluralize";
import { PluralizeUiData, PluralizeUpdateProps } from "@src/lib/liquidx/PluralizeElement";
import { CountCombobox } from "./CountCombobox";
import { PluralizeSnippet } from "./PluralizeSnippet";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
  },
});

export const Pluralize: FC<{ snippet: PluralizeSnippet }> = ({ snippet }) => {
  const [plural, setPlural] = useState("");
  const [singular, setSingular] = useState("");
  const [property, setProperty] = useState("");
  const [preview, setPreview] = useState<string[]>([]);
  const [showCount, setShowCount] = useState(snippet.ui.showCount);
  const styles = useStyles();

  useEffect(() => {
    setPlural(snippet.ui.plural);
    setSingular(snippet.ui.singular);
    setProperty(snippet.ui.property);
    setShowCount(snippet.ui.showCount);
    setPreview(generatePreview(snippet.ui));
  }, [snippet]);

  async function setPluralFromSingular() {
    if (singular && !plural) {
      await updateElement({ plural: pluralize.plural(singular) });
    }
  }

  async function setSingularFromPlural() {
    if (plural && !singular) {
      await updateElement({ singular: pluralize.singular(plural) });
    }
  }

  function generatePreview(ui: PluralizeUiData) {
    return [`0 ${ui.plural}`, `1 ${ui.singular}`, `2 ${ui.plural}`];
  }

  async function updateElement(updatedProps: Partial<PluralizeUpdateProps>) {
    updatedProps.plural ??= plural;
    setPlural(updatedProps.plural);
    updatedProps.singular ??= singular;
    setSingular(updatedProps.singular);
    updatedProps.property ??= property;
    setProperty(updatedProps.property);
    updatedProps.showCount ??= showCount;
    setShowCount(updatedProps.showCount);

    setPreview(generatePreview(updatedProps as PluralizeUiData));
    await snippet.update(updatedProps as PluralizeUpdateProps);
  }

  return (
    <div className={styles.root}>
      <Label size="small">Count</Label>
      <CountCombobox element={snippet} property={property} onChange={(value) => updateElement({ property: value })} />
      <Label size="small">Singular</Label>
      <Input
        spellCheck={false}
        value={singular}
        onBlur={setPluralFromSingular}
        onChange={(_e, { value }) => updateElement({ singular: value })}
      />

      <Label size="small">Plural</Label>
      <Input
        spellCheck={false}
        value={plural}
        onBlur={setSingularFromPlural}
        onChange={(_e, { value }) => updateElement({ plural: value })}
      />
      <Switch
        label="Show count"
        checked={showCount}
        onChange={(_, { checked }) => updateElement({ showCount: checked })}
      />
      {showCount && (
        <>
          <Label>Preview</Label>
          <div
            style={{
              padding: "12px",
              borderRadius: "4px",
              backgroundColor: tokens.colorNeutralBackground1Hover,
              display: "flex",
              flexDirection: "column",
              gap: "6px",
            }}
          >
            {preview.map((char, i) => (
              <Text font="monospace" weight="semibold" key={i}>
                {char}
              </Text>
            ))}
          </div>
        </>
      )}
    </div>
  );
};
