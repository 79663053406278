import { Switch, makeStyles } from "@fluentui/react-components";
import { BasicIfElement } from "@src/lib/liquidx/BasicIfElement";
import { SchemaNode } from "@src/lib/schemas";
import { ComponentProps, FC, useEffect, useState } from "react";
import { ConditionBuilder } from "./ConditionBuilder/ConditionBuilder";
import { emptyConditionGroup } from "./ConditionBuilder/ConditionCodeGen";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
  },
});

type UpdateProps = Parameters<BasicIfElement["update"]>[0];
export const BasicIf: FC<{ element: BasicIfElement }> = ({ element }) => {
  const [nodes, setNodes] = useState<SchemaNode[]>([]);
  const [updateProps, setUpdateProps] = useState<UpdateProps>({});
  // Keep local state to allow instant feedback when user clicks it (vs waiting for the update to complete)
  const [elseBranch, setElseBranch] = useState<boolean>(element.hasElse);
  const styles = useStyles();

  useEffect(() => {
    setElseBranch(element.hasElse);
  }, [element]);

  useEffect(() => {
    setNodes(element.nodes);
    setUpdateProps({
      conditionGroup: element.ui.conditionGroup,
    });
  }, [element]);

  function handleUpdate(props: UpdateProps) {
    setUpdateProps({ ...updateProps, ...props });
    void element.update(props);
  }

  const handleElseToggle: ComponentProps<typeof Switch>["onChange"] = (_, { checked }) => {
    setElseBranch(checked);
    handleUpdate({ shouldHaveElse: checked });
  };

  return (
    <div className={styles.root}>
      <ConditionBuilder
        group={updateProps.conditionGroup ?? emptyConditionGroup()}
        nodes={nodes}
        onChange={(conditionGroup) => handleUpdate({ conditionGroup })}
      />
      {/* TODO: Create a small-sized switch and move this beside the add condition button */}
      <Switch label="Else branch" checked={elseBranch} onChange={handleElseToggle} />
    </div>
  );
};
