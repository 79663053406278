import { ContentControlType } from "@src/lib/liquidx";
import { mapDynamicElement } from "@src/taskpane/helpers/mapDynamicElement";
import { MoreHorizontal20Regular } from "@fluentui/react-icons";
import { makeStyles, shorthands, Text } from "@fluentui/react-components";
import { FC, useEffect, useState } from "react";

const useClasses = makeStyles({
  list: {
    listStyleType: "none",
    display: "flex",
    flexDirection: "column",
    ...shorthands.gap("8px"),
  },
  listItem: {
    display: "flex",
    ...shorthands.gap("8px"),
  },
  container: {
    ...shorthands.gap("24px"),
    ...shorthands.padding("12px"),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
});

const Intro: FC<{ alwaysShow?: boolean }> = ({ alwaysShow }) => {
  const classes = useClasses();
  const [showIntro] = useState(alwaysShow || localStorage.getItem("walter_viewed_onboarding") !== "true");

  useEffect(() => {
    if (alwaysShow) return;
    if (!showIntro) return;
    localStorage.setItem("walter_viewed_onboarding", "true");
  }, [alwaysShow, showIntro]);

  if (!showIntro) return null;

  return (
    <section className={classes.container}>
      <Text align="justify">
        Walter revolutionizes document management for law firms and corporate builders within Microsoft Word. Create
        complex workflows tailored to your specific needs, using features such as:
      </Text>
      <ul className={classes.list}>
        <li className={classes.listItem}>
          {mapDynamicElement(ContentControlType.VARIABLE).icon}
          <span>Variables</span>
        </li>
        <li className={classes.listItem}>
          {mapDynamicElement(ContentControlType.IF).icon}
          <span>Conditionals</span>
        </li>
        <li className={classes.listItem}>
          {mapDynamicElement(ContentControlType.SIGNATURE).icon}
          <span>Signatures</span>
        </li>
        <li className={classes.listItem}>
          {mapDynamicElement(ContentControlType.SIGN_TAB_INPUT).icon}
          <span>Custom Inputs</span>
        </li>
        <li className={classes.listItem}>
          <MoreHorizontal20Regular />
          <span>And more!</span>
        </li>
      </ul>
    </section>
  );
};

export { Intro };
