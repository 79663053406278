import { InputOption } from "@src/lib/walter";

export interface GroupOption {
  name: string;
  value: string;
  description: string;
  group: string;
}

export interface GroupedOptions {
  [key: string]: InputOption[];
}

export const group = (options: InputOption[] | undefined): GroupedOptions => {
  if (!options) return {};

  return options.reduce((groups, option) => {
    groups[option.group] ??= [];
    groups[option.group].push(option);

    return groups;
  }, {} as GroupedOptions);
};

export const extractTag = (text: string): string => {
  const regex = /if\s([a-z._]+)?\s?/;
  const matches = text.match(regex);

  if (matches && matches.length > 1) {
    return matches[1];
  }

  return text;
};

export const capitalize = (str: string): string => {
  return str
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export const humanize = (str: string): string => {
  return capitalize(str.replace(/([a-z])([A-Z])/g, "$1 $2").replace(/_+/g, " "));
};

export const camelCaseToSentenceCase = (str: string): string => {
  const result = str.replace(/([A-Z])/g, " $1");
  return result.charAt(0).toUpperCase() + result.slice(1).toLowerCase();
};

export const unicodeToString = (unicode: string): string => {
  const unicodeDecimal = parseInt(unicode, 16);
  return String.fromCharCode(unicodeDecimal);
};
