import { makeStyles, Spinner } from "@fluentui/react-components";

export interface ProgressProps {
  message: string;
}

const useStyles = makeStyles({
  spinner: {
    display: "flex",
    height: "100vh",
    justifyContent: "center",
    alignItems: "center",
    padding: "12px",
  },
});

const Progress = ({ message }: { message: string }) => {
  const classes = useStyles();

  return (
    <div className={classes.spinner} data-testid="progress-spinner">
      <Spinner appearance="primary" size="small" labelPosition="below" label={message} />
    </div>
  );
};

export default Progress;
