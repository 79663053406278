import { ComponentProps, FC, useEffect, useState } from "react";
import { ConditionData } from "./ConditionCodeGen";
import { useConditionBuilder } from "./useConditionBuilder";
import { ConditionModel } from "./ConditionModel";
import { Input } from "@fluentui/react-components";
import { DatePicker } from "@fluentui/react-datepicker-compat";
import { Token } from "./Token";

export const Right: FC<{ condition: ConditionData; onChange?: (_: ConditionData["right"]) => void }> = ({
  condition,
  onChange,
}) => {
  const { nodes } = useConditionBuilder();
  const model = new ConditionModel(condition, nodes);
  const [editing, setEditing] = useState(false);

  function updateRight(right: ConditionData["right"]) {
    onChange?.(right);
  }

  if (!model.hasRight) return null;

  if (model.leftType === "number" || model.leftType === "integer" || model.leftType === "array") {
    return <NumberInput condition={condition} updateRight={updateRight} editing={editing} setEditing={setEditing} />;
  } else if (model.leftType === "date") {
    return <DateInput condition={condition} updateRight={updateRight} editing={editing} setEditing={setEditing} />;
  } else if (model.leftType === "boolean") {
    // No right-side for booleans
    return null;
  } else if (!model.operatorLabel) {
    // Hide right-side until an operator is chosen
    return null;
  } else {
    // Fallback to text inputs
    return <TextInput condition={condition} updateRight={updateRight} editing={editing} setEditing={setEditing} />;
  }
};

const TextInput: FC<{
  condition: ConditionData;
  updateRight: (_: ConditionData["right"]) => void;
  editing: boolean;
  setEditing: (_: boolean) => void;
}> = ({ condition, updateRight, editing, setEditing }) => {
  const value = Array.isArray(condition.right?.value) ? "" : (condition.right?.value ?? "").toString();
  const onChange: ComponentProps<typeof Input>["onChange"] = (_, { value }) => {
    updateRight({ type: "string", value });
  };

  if (editing) {
    return (
      <Input
        autoFocus
        value={value}
        onChange={onChange}
        onBlur={() => setEditing(false)}
        onKeyDown={(e) => ["Escape", "Enter"].includes(e.key) && setEditing(false)}
      />
    );
  } else if (value) {
    return <Token onClick={() => setEditing(true)} label={value} appearance="primary" />;
  } else {
    return <Token onClick={() => setEditing(true)} label="Text" appearance="placeholder" />;
  }
};

const NumberInput: FC<{
  condition: ConditionData;
  updateRight: (_: ConditionData["right"]) => void;
  editing: boolean;
  setEditing: (_: boolean) => void;
}> = ({ condition, updateRight, editing, setEditing }) => {
  const value = Array.isArray(condition.right?.value) ? "" : (condition.right?.value ?? "").toString();
  // We keep a local value so we can update the input field with a non-numeric while they're typing
  const [localValue, setLocalValue] = useState<string>("");

  useEffect(() => {
    if (condition.right?.type === "number") {
      setLocalValue((prev) => {
        const prevNumber = Number(prev);

        // If the local value parses into the same numeric value, keep the string
        // This allows strings like `123.` to remain in the text input rather than being converted to `123`.
        if (condition.right === undefined) {
          return "";
        } else if (prevNumber === condition.right?.value) {
          return prev;
        } else {
          return condition.right.value.toString();
        }
      });
    }
  }, [condition.right]);

  const onChange: ComponentProps<typeof Input>["onChange"] = (_, { value: stringValue }) => {
    setLocalValue(stringValue);

    const value = Number(stringValue);
    if (isNaN(value)) return;

    updateRight({ type: "number", value });
  };

  const onBlur: ComponentProps<typeof Input>["onBlur"] = ({ target: { value } }) => {
    const numberValue = Number(value);

    if (value === "" || isNaN(numberValue)) {
      updateRight(undefined);
    } else {
      updateRight({ type: "number", value: numberValue });
    }

    setEditing(false);
  };

  if (editing) {
    return (
      <Input
        autoFocus
        value={localValue}
        onChange={onChange}
        onBlur={onBlur}
        onKeyDown={(e) => ["Escape", "Enter"].includes(e.key) && setEditing(false)}
      />
    );
  } else if (value) {
    return <Token onClick={() => setEditing(true)} label={value} appearance="primary" />;
  } else {
    return <Token onClick={() => setEditing(true)} label="Number" appearance="placeholder" />;
  }
};

const DateInput: FC<{
  condition: ConditionData;
  updateRight: (_: ConditionData["right"]) => void;
  editing: boolean;
  setEditing: (_: boolean) => void;
}> = ({ condition, updateRight, editing, setEditing }) => {
  const value = condition.right?.type === "date" ? new Date(condition.right.value) : null;
  const onSelectDate: ComponentProps<typeof DatePicker>["onSelectDate"] = (date) => {
    setEditing(false);
    updateRight({ type: "date", value: date?.toISOString() ?? "" });
  };

  if (editing) {
    return (
      <DatePicker
        autoFocus
        disableAutoFocus={false}
        appearance="filled-darker"
        value={!value || isNaN(value.getTime()) ? null : value}
        placeholder="Select a date"
        onSelectDate={onSelectDate}
        onKeyDown={(e) => ["Escape", "Enter"].includes(e.key) && setEditing(false)}
      />
    );
  } else if (value) {
    return <Token onClick={() => setEditing(true)} label={value.toDateString()} appearance="primary" />;
  } else {
    return <Token onClick={() => setEditing(true)} label="Date" appearance="placeholder" />;
  }
};
