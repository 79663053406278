import { DynamicElement, JsElement } from "@src/lib/liquidx/internal";
import { JsTag, VariableRenderData } from "./ParsedTag";
import { JsElementProps } from "./JsElement";

export interface UiData {
  type: "signField";
  label: string;
}

export interface RenderData extends VariableRenderData {
  signField?: {
    type: "signature" | "initials" | "dateSigned" | "radio" | "checkbox" | "text";
    name: string;
    email: string;
    role: string;
    prefix: string;
    signerResource: string;
    required?: boolean;
    width?: string;
    group?: string;
    guid: string;
  };
}

export class SignFieldElement extends JsElement<RenderData, UiData> {
  static supports({ render, ui }: JsTag) {
    return render.type === "variable" && ui.type === "signField";
  }

  render: RenderData;
  ui: UiData;
  defaultTag: JsTag<RenderData, UiData> = {
    render: { type: "variable", code: "" },
    ui: {
      type: "signField",
      label: "Sign Field",
    },
    valid: true,
  };

  constructor();
  constructor(props: JsElementProps);
  constructor(props?: JsElementProps) {
    super();

    // Provide default values
    const tag = props?.tag ?? this.defaultTag;

    // Assign values
    this.id = props?.id ?? null;
    this.parentId = props?.parentId;
    this.render = {
      ...this.defaultTag.render,
      ...(tag?.render ?? {}),
      type: "variable",
    };

    this.ui = {
      ...this.defaultTag.ui,
      ...tag.ui,
      type: "signField",
    };

    // Apply other defaults
    this.font = { highlightColor: DynamicElement.colors.highlight };
  }

  get innerText() {
    return `[${this.render.signField?.name ?? "Sign Field"}]`;
  }
}
