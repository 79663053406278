class Debug {
  log(...messages: any[]) {
    // eslint-disable-next-line no-console
    if (import.meta.env.DEV && !import.meta.env.VITEST) console.log(...messages);
  }

  error(...messages: any[]) {
    // eslint-disable-next-line no-console
    if (import.meta.env.DEV && !import.meta.env.VITEST) console.error(...messages);
  }

  warn(...messages: any[]) {
    // eslint-disable-next-line no-console
    if (import.meta.env.DEV && !import.meta.env.VITEST) console.warn(...messages);
  }

  info(...messages: any[]) {
    // eslint-disable-next-line no-console
    if (import.meta.env.DEV && !import.meta.env.VITEST) console.info(...messages);
  }

  time(label: string) {
    // eslint-disable-next-line no-console
    if (import.meta.env.DEV && !import.meta.env.VITEST) console.time(label);
  }

  timeEnd(label: string) {
    // eslint-disable-next-line no-console
    if (import.meta.env.DEV && !import.meta.env.VITEST) console.timeEnd(label);
  }
}

export default new Debug();
