import { type WalterStyle } from "@src/lib/styles";
import { OperationContext } from "../OperationContext";
import { Operation } from "./Operation";

/**
 * Insert styles used by Walter, which are used for variables and placeholders.
 */
export class InsertStyles implements Operation<void> {
  constructor(private styles: WalterStyle[]) {}

  async commit(context: OperationContext): Promise<void> {
    for (const { name, type, definition } of this.styles) {
      await this.insertOrReplaceStyle(
        {
          context,
          name,
          type: type as unknown as Word.StyleType.character,
        },
        definition,
      );
    }
  }

  /**
   * Insert a new style or replace an existing style (delete and insert).
   */
  async insertOrReplaceStyle(
    { context, name, type }: { context: OperationContext; name: string; type: Word.StyleType },
    callback: (style: Word.Style) => void,
  ) {
    let existingStyle = context.document.getStyles().getByNameOrNullObject(name).load();
    await context.sync();

    if (!existingStyle.isNullObject) {
      existingStyle.delete();
      await context.sync();
    }

    const style = context.document.addStyle(name, type);
    callback(style);
  }

  merge(_: Operation<unknown>) {
    return undefined;
  }
}
