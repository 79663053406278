import debug from "@src/lib/debug";
import { OperationContext } from "@src/lib/sync-engine/OperationContext";
import { Operation } from "@src/lib/sync-engine/operations/Operation";

export class WaitUntilCustomXmlPartsAreReady implements Operation<void> {
  // waitTimeMS * maxAttempts will be the max amount of time that it will retry for.
  maxAttempts = 10;
  waitTimeMS = 200;

  sleep(ms: number) {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  }

  async commit(context: OperationContext) {
    let attempts = 0;
    let ready = false;

    while (!ready && attempts < this.maxAttempts) {
      try {
        context.document.customXmlParts.load("items");
        // eslint-disable-next-line office-addins/no-context-sync-in-loop
        await context.sync();
        ready = true;
      } catch (e) {
        if (e instanceof OfficeExtension.Error) {
          await this.sleep(this.waitTimeMS);
          attempts++;
          debug.warn("Retry attempt: ", attempts);
        } else {
          throw e;
        }
      }
    }

    if (!ready) {
      console.error("Failed to access custom XML parts after max attempts");
    }
  }

  merge(_: Operation<unknown>) {
    return undefined;
  }
}
