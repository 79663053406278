import { FC, useEffect, useState } from "react";
import { Menu, MenuDivider, MenuItem, MenuList, MenuPopover, MenuTrigger } from "@fluentui/react-components";
import { DynamicElementViewHelper } from "@src/taskpane/helpers/mapDynamicElement";
import { SchemaNode } from "@src/lib/schemas";
import { MenuTitle } from "../home/MenuTitle";
import { useSelectionDetails } from "@src/taskpane/hooks/useSelectionDetails";
import { InsertLocation } from "@src/lib/liquidx";

interface InsertAroundOption {
  label: string;
  location: InsertLocation;
}

export const InsertElementSubmenu: FC<{
  label: string;
  items: DynamicElementViewHelper[];
  isFirst?: boolean;
  insertAround?: boolean;
  property?: SchemaNode;
}> = ({ property, isFirst, insertAround, label, items }) => {
  const selectionDetails = useSelectionDetails();
  const [insertAroundOptions, setInsertAroundOptions] = useState<InsertAroundOption[]>([]);

  useEffect(() => {
    const options: InsertAroundOption[] = [];

    if (selectionDetails?.isEmpty) {
      options.push({ label: "Cursor", location: InsertLocation.SELECTION });
    } else {
      options.push({ label: "Selection", location: InsertLocation.SELECTION });
    }

    if (selectionDetails?.paragraphsCount <= 1) {
      options.push({ label: "Paragraph", location: InsertLocation.PARENT_PARAGRAPH });
    }

    if (selectionDetails?.isInTable) {
      options.push({ label: "Table row", location: InsertLocation.TABLE_ROW });
    }

    setInsertAroundOptions(options);
  }, [selectionDetails]);

  async function handleClick(item: DynamicElementViewHelper, insertAround?: InsertLocation) {
    if (item.insert) {
      await item.insert(property, insertAround);
    } else {
      item.visit?.(history, { id: null });
    }
  }

  return (
    <>
      {!isFirst && <MenuDivider key={label} />}
      <MenuTitle key={`title/${label}`}>{label}</MenuTitle>
      {items.map((item) => {
        if (insertAround && insertAroundOptions.length > 1) {
          return (
            <Menu key={item.label}>
              <MenuTrigger disableButtonEnhancement>
                <MenuItem key={item.label} icon={item.icon}>
                  {item.label}
                </MenuItem>
              </MenuTrigger>

              <MenuPopover>
                <MenuTitle key="title/insertAround">Insert location</MenuTitle>
                {insertAroundOptions.map(({ label, location }) => (
                  <MenuList key={label}>
                    <MenuItem onClick={async () => handleClick(item, location)}>{label}</MenuItem>
                  </MenuList>
                ))}
              </MenuPopover>
            </Menu>
          );
        } else {
          return (
            <MenuItem key={item.label} icon={item.icon} onClick={() => handleClick(item)}>
              {item.label}
            </MenuItem>
          );
        }
      })}
    </>
  );
};
