import { type FC, useContext, useState } from "react";
import { Button, Text, Subtitle2, makeStyles, tokens } from "@fluentui/react-components";
import { downloadDocx } from "@src/lib/downloadDocx";
import { WalterApi } from "@src/lib/walter/api";
import { ReplacementDocumentContents } from "@src/lib/sync-engine/operations/ReplaceDocument";
import { syncEngine } from "@src/lib/sync-engine/SyncEngine";
import { DynamicElement } from "@src/lib/liquidx/internal";
import useToasts from "../hooks/useToasts";
import { WordWrapper } from "@src/lib/sync-engine/WordWrapper";
import { WalterContext } from "@src/taskpane/providers/walter/index.tsx";
import { captureErrorInfo } from "../helpers/errorHandler";
import { Sparkles } from "lucide-react";

const useStyles = makeStyles({
  footer: {
    position: "sticky",
    bottom: 0,
    maxWidth: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "12px",
    backgroundColor: tokens.colorNeutralBackgroundInverted,
    color: tokens.colorNeutralForegroundInverted,
    padding: "12px 4px",
    boxShadow: "0 -2px 5px rgba(0, 0, 0, 0.1)",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
});

export const MigrateStickyFooter: FC<{ setMigrating: (_: boolean) => void }> = ({ setMigrating }) => {
  const classes = useStyles();
  const { showMigrateButton } = useContext(WalterContext);
  const { showErrors } = useToasts();
  const [minimize, setMinimize] = useState(true);

  if (!showMigrateButton) return null;

  async function handleUpgradeClick() {
    setMigrating(true);

    try {
      await replaceDocument();
      await DynamicElement.rebuild();
    } catch (e) {
      showErrors({
        title: "Upgrade failed",
        messages: ["If the issue persists, contact support."],
      });

      captureErrorInfo(e);
    } finally {
      setMigrating(false);
    }
  }

  async function handleLaterClick() {
    setMinimize(true);
  }

  if (minimize) {
    return (
      <div className={classes.footer}>
        <Button
          onClick={() => setMinimize(false)}
          appearance="transparent"
          style={{ color: tokens.colorNeutralForegroundInverted, gap: "8px" }}
        >
          <Sparkles strokeWidth="1.5" size={16} />
          Try out the new UI
        </Button>
      </div>
    );
  } else {
    return (
      <div className={classes.footer}>
        <Subtitle2>Introducing the new UI</Subtitle2>
        <Text align="center">
          Try out the latest version of document automation, packed with time-saving and advanced features, like
          pluralization, field ordering, and more.
        </Text>
        <div>
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <Button onClick={handleUpgradeClick} appearance="primary">
              Upgrade
            </Button>
            <Button
              onClick={handleLaterClick}
              appearance="transparent"
              style={{ color: tokens.colorNeutralForegroundInverted }}
            >
              Later
            </Button>
          </div>
        </div>
      </div>
    );
  }
};

class JavaScriptMigrationError extends Error {}

async function replaceDocument() {
  const randomString = Math.random().toString(36).substring(7);

  // 1. Get the docx, migrate it using DOCTR, and return it
  const migratedDocx = await retrieveMigratedDocx();

  // 2. Mark all content controls as deletable and editable
  await WordWrapper.run(async (context) => {
    const contentControls = context.document.contentControls.load("items,items");
    await context.sync();
    contentControls.items.forEach((contentControl) => {
      if (contentControl.cannotDelete) contentControl.cannotDelete = false;
      if (contentControl.cannotEdit) contentControl.cannotEdit = false;
    });
  }).catch((error) => {
    console.error("Error:", error);
  });

  // 3. Insert a paragraph at end of document and select it to ensure the cursor isn't within a content control (which can cause existing content to persist after the migration.)
  await WordWrapper.run(async (context) => {
    const paragraph = context.document.body.insertParagraph(randomString, "End");
    paragraph.font.color = "white";
    // @ts-ignore this unsets the highlight color
    paragraph.font.highlightColor = null;
    paragraph.select();
  });

  try {
    // 4. Replace the document contents with the migrated docx
    await syncEngine.perform(new ReplacementDocumentContents(migratedDocx));
    await DynamicElement.load();
    // 5. Trigger update for dynamic elements locally, generating things DOCTR doesn't know about (e.g., labels, titles, field refs in loops, etc.)
    await DynamicElement.rebuild();
  } finally {
    // 6. Remove the paragraph we inserted at the end of the document (because it is a clean up step, we catch the error and report but don't show the user)
    await WordWrapper.run(async (context) => {
      context.document.search(randomString).getFirstOrNullObject().delete();
    }).catch(captureErrorInfo);
  }
}

/**
 * This downloads a docx file from Word, sends it to the server for migration.
 * @returns base64 string of the migrated docx file
 */
async function retrieveMigratedDocx() {
  const docx = await downloadDocx();
  const body = new FormData();
  body.append("file", docx.blob, docx.properties?.filename ?? "filename.docx");

  const response = await WalterApi.fetch("/word/migrate", {
    method: "POST",
    body,
  });

  if (!response.ok) {
    let errorMessage;

    const reader = response.body?.getReader();

    if (reader) {
      const { value } = await reader.read();
      const str = new TextDecoder().decode(value);
      const parser = new DOMParser();
      const doc = parser.parseFromString(str, "text/html");
      const errorMessages = doc.querySelectorAll(".message");

      if (errorMessages.length > 0) {
        errorMessage = "";

        for (const message of errorMessages) {
          errorMessage += `${message.textContent}\n`;
        }
      }
      throw new JavaScriptMigrationError(errorMessage);
    } else {
      throw new JavaScriptMigrationError();
    }
  }

  const blob = await response.blob();
  const arrayBuffer = await blob.arrayBuffer();
  return arrayBufferToBase64(arrayBuffer);
}

// Helper function to convert ArrayBuffer to base64 string
function arrayBufferToBase64(buffer: ArrayBuffer) {
  let binary = "";
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return btoa(binary);
}
