import { useContext } from "react";
import { makeStyles, shorthands, Button, Divider } from "@fluentui/react-components";
import { SelectFieldSet } from "../onboarding/SelectFieldSet";
import { WalterContext } from "@src/taskpane/providers/walter";
import { useHistory } from "react-router-dom";
import { Fieldset } from "@src/lib/liquidx/Fieldset";
import { documentSchemaStore } from "@src/lib/schemas";

const useClasses = makeStyles({
  container: {
    ...shorthands.gap("24px"),
    ...shorthands.padding("12px"),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "> button": {
      width: "75%",
    },
  },
});

const ChooseFieldSet = () => {
  const classes = useClasses();
  const { setIsFieldSetSelected } = useContext(WalterContext);
  const history = useHistory();

  async function selectFieldSet(optionValue?: string) {
    if (optionValue) {
      const fieldset = Fieldset.find(optionValue);
      if (fieldset) await documentSchemaStore.save(fieldset.schema);
    }

    setIsFieldSetSelected(true);

    if (history.location.pathname !== "/") {
      history.push("/");
    }
  }

  return (
    <section className={classes.container}>
      <SelectFieldSet onOptionSelected={selectFieldSet} />
      <Divider appearance="strong">OR</Divider>
      <Button appearance="primary" onClick={() => selectFieldSet()}>
        Create blank field set
      </Button>
    </section>
  );
};

export { ChooseFieldSet };
