import { FC, useEffect, useRef, useState, type JSX } from "react";
import {
  Button,
  Menu,
  MenuDivider,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
  TreeItem,
  TreeItemLayout,
} from "@fluentui/react-components";
import { useIndentedTree } from "./IndentedTree";
import { useStyles as useFieldPaneStyles } from "./FieldsPane";
import { NodesTree } from "./PropertiesTree";
import { SchemaNode } from "@src/lib/schemas";
import { DocumentFieldTreeItem } from "./DocumentFieldTreeItem";
import clsx from "clsx";
import { FieldMenuItems, scrollToRef } from "./ScalarTreeItem";
import { insertable } from "../home/Toolbar";
import { InsertElementSubmenu } from "./InsertElementSubmenu";
import { Ellipsis } from "lucide-react";
import { TaskIcon } from "../home/ElementsPane";
import { mapDynamicElement } from "@src/taskpane/helpers/mapDynamicElement";
import { syncEngine } from "@src/lib/sync-engine/SyncEngine";
import { InsertText } from "@src/lib/sync-engine/operations/InsertText";
import { MenuTitle } from "../home/MenuTitle";

export const ObjectTree: FC<{ node: SchemaNode }> = ({ node }) => {
  const { padding, focusedItem } = useIndentedTree();
  const styles = useFieldPaneStyles();
  const itemRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);

  // Scroll the focused item into view
  useEffect(() => {
    if (focusedItem === node.path) {
      scrollToRef(itemRef);
    }

    if (focusedItem?.startsWith(node.path)) {
      setOpen(true);
    }
  }, [focusedItem, node.path]);

  const menuItems: JSX.Element[] = [];

  if (node.signTab) {
    menuItems.push(
      <InsertElementSubmenu
        key="signTabs"
        property={node.signTab}
        items={insertable.signTabs}
        label="Sign tabs"
        isFirst
      />,
    );
  }
  if (node.isRemovable) {
    menuItems.push(<FieldMenuItems key="fieldMenuItems" node={node} isFirst={menuItems.length === 0} />);
  }

  // This provides a one-click way to insert every string or number property on an object.
  if (import.meta.env.DEV) {
    async function insertAllProperties(node: SchemaNode) {
      for (const child of node.children) {
        // Insert string and number variables into the document
        if (child.type === "string" || child.type === "number") {
          const dynamicElementHelper = mapDynamicElement("variable/basic");

          if (dynamicElementHelper.insert) {
            await syncEngine.perform(new InsertText(`${child.title}: `, "Before"));
            await dynamicElementHelper.insert(child);
          }
        }
      }
    }

    if (menuItems.length > 0) {
      menuItems.push(<MenuDivider key="divider" />);
    }
    menuItems.push(<MenuTitle key="title/devTools">Dev tools</MenuTitle>);
    menuItems.push(
      <MenuItem key="insertAllProperties" onClick={() => insertAllProperties(node)} icon={<TaskIcon />}>
        Insert all properties
      </MenuItem>,
    );
  }

  let actions: JSX.Element | null = null;
  if (menuItems.length > 0) {
    actions = <MoreMenu>{menuItems}</MoreMenu>;
  }

  let treeItem = (
    <TreeItemLayout className={styles.treeItemLayout} style={{ paddingLeft: padding }} actions={actions}>
      <span ref={itemRef} title={node.description}>
        {node.title}
      </span>
    </TreeItemLayout>
  );

  if (menuItems.length > 0) {
    treeItem = (
      <Menu positioning="below-end" openOnContext>
        <MenuTrigger disableButtonEnhancement>{treeItem}</MenuTrigger>

        <MenuPopover>
          <MenuList>{menuItems}</MenuList>
        </MenuPopover>
      </Menu>
    );
  }

  treeItem = (
    <TreeItem
      itemType="branch"
      value={node.path}
      className={clsx(focusedItem === node.path && styles.treeItemFocused)}
      open={open}
      onOpenChange={(_, { open }) => setOpen(open)}
    >
      {treeItem}
      <NodesTree nodes={node.children} />
    </TreeItem>
  );

  if (node.isRemovable) {
    return <DocumentFieldTreeItem property={node}>{treeItem}</DocumentFieldTreeItem>;
  } else {
    return treeItem;
  }
};

export const MoreMenu: FC = ({ children }) => {
  return (
    <Menu positioning="below-end">
      <MenuTrigger disableButtonEnhancement>
        <Button style={{ padding: 0, minWidth: 20 }} appearance="subtle">
          <Ellipsis width="14" height="14" />
        </Button>
      </MenuTrigger>

      <MenuPopover>
        <MenuList>{children}</MenuList>
      </MenuPopover>
    </Menu>
  );
};
