import { FC, useEffect, useRef, useState } from "react";
import { SchemaNode } from "@src/lib/schemas";
import { draggable, dropTargetForElements } from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import { setCustomNativeDragPreview } from "@atlaskit/pragmatic-drag-and-drop/element/set-custom-native-drag-preview";
import { pointerOutsideOfPreview } from "@atlaskit/pragmatic-drag-and-drop/element/pointer-outside-of-preview";
import {
  attachClosestEdge,
  extractClosestEdge,
  type Edge,
} from "@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge";
import clsx from "clsx";
import invariant from "tiny-invariant";
import { createPortal } from "react-dom";
import { FluentProvider, makeStyles, shorthands, tokens } from "@fluentui/react-components";

const useStyles = makeStyles({
  field: {
    position: "relative",
  },
  dragging: {
    opacity: 0.5,
  },
  dragPreview: {
    backgroundColor: tokens.colorNeutralBackground1,
    color: tokens.colorNeutralForeground1,
    opacity: 0.7,
    height: "24px",
    ...shorthands.padding("5px", "8px", "4px", "8px"),
    ...shorthands.borderRadius("8px"), // Apply border-radius for rounded corners
  },
  dropMarkerContainer: {
    position: "absolute",
    width: "100%",
    height: "6px",
    zIndex: 999,
  },
  dropMarkerContainerTop: {
    top: "-3px",
  },
  dropMarkerContainerBottom: {
    bottom: "-3px",
  },
  dropMarker: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "6px",
  },
  dropMarkerCircle: {
    width: "6px",
    height: "6px",
    backgroundColor: tokens.colorNeutralBackground1,
    ...shorthands.borderRadius("50%"),
    ...shorthands.border("2px", "solid", tokens.colorBrandForeground1),
  },
  dropMarkerLine: {
    height: "2px",
    backgroundColor: tokens.colorBrandForeground1,
    ...shorthands.flex(1),
  },
});

const DropMarker: FC<{ closestEdge: Edge | null }> = ({ closestEdge }) => {
  const styles = useStyles();

  if (closestEdge === null) return null;

  return (
    <div
      className={clsx(
        styles.dropMarkerContainer,
        closestEdge === "top" && styles.dropMarkerContainerTop,
        closestEdge === "bottom" && styles.dropMarkerContainerBottom,
      )}
    >
      <div className={styles.dropMarker}>
        <div className={styles.dropMarkerCircle}></div>
        <div className={styles.dropMarkerLine}></div>
      </div>
    </div>
  );
};

/**
 * Combines multiple callbacks into a single callback function.
 * @param callbacks - The callbacks to be combined.
 * @returns A new callback function that invokes all the provided callbacks.
 */
export const combine =
  (...callbacks: (() => void)[]) =>
  () =>
    callbacks.forEach((cb) => cb());

type DraggableState = { type: "idle" } | { type: "preview"; container: HTMLElement } | { type: "dragging" };

export const idleState: DraggableState = { type: "idle" };
export const draggingState: DraggableState = { type: "dragging" };

export const DragPreview: FC<{ property: SchemaNode }> = ({ property }) => {
  const styles = useStyles();

  // NOTE: Because this is rendered under a separate DOM (using createPortal),
  // we need to wrap it in FluentProvider or the Fluent UI tokens won't work.
  return <FluentProvider className={styles.dragPreview}>{property.title}</FluentProvider>;
};

export const DocumentFieldTreeItem: FC<{ property: SchemaNode }> = ({ property, children }) => {
  const styles = useStyles();
  const ref = useRef<HTMLDivElement>(null);
  const [closestEdge, setClosestEdge] = useState<Edge | null>(null);
  const [draggableState, setDraggableState] = useState<DraggableState>(idleState);

  useEffect(() => {
    const el = ref.current;
    invariant(el, "Element must be present");

    return combine(
      draggable({
        element: el,
        getInitialData: () => ({ property }),
        onDragStart: () => setDraggableState(draggingState),
        onDrop: () => {
          setDraggableState(idleState);
        },
      }),
      dropTargetForElements({
        element: el,
        getData: ({ input, element }) => {
          const data = {
            property,
          };

          return attachClosestEdge(data, {
            input,
            element,
            allowedEdges: ["top", "bottom"],
          });
        },
        onDragLeave: () => {
          setClosestEdge(null);
        },
        onDrag: ({ self }) => setClosestEdge(extractClosestEdge(self.data)),
        onDrop: async (args) => {
          const { self, source } = args;
          setClosestEdge(null);

          const sourceProperty = source.data.property as SchemaNode;
          const destinationProperty = self.data.property as SchemaNode;
          const closestEdge = extractClosestEdge(self.data);

          if (destinationProperty.field) {
            if (closestEdge === "top") {
              await sourceProperty.field?.moveBefore(destinationProperty.field);
            } else {
              await sourceProperty.field?.moveAfter(destinationProperty.field);
            }
          }
        },
        onGenerateDragPreview({ nativeSetDragImage }) {
          setCustomNativeDragPreview({
            nativeSetDragImage,
            getOffset: pointerOutsideOfPreview({
              x: "12px",
              y: "12px",
            }),
            render({ container }) {
              // Render the drag preview into the container and return a cleanup function,
              // which sets it back to dragging state after the render completes.
              setDraggableState({ type: "preview", container });
              return () => setDraggableState(draggingState);
            },
          });
        },
      }),
    );
  }, []);

  return (
    <div key={property.path} className={styles.field} ref={ref}>
      <div className={clsx(draggableState.type === "dragging" && styles.dragging)}>{children}</div>
      <DropMarker closestEdge={closestEdge} />
      {draggableState.type === "preview" && createPortal(<DragPreview property={property} />, draggableState.container)}
    </div>
  );
};
