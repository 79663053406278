import { FC } from "react";
import { MenuItem, makeStyles, tokens } from "@fluentui/react-components";
import { SchemaNode } from "@src/lib/schemas";
import { Trash } from "lucide-react";

export const useStyles = makeStyles({
  dangerMenuItem: {
    "&:hover .fui-MenuItem__icon": {
      color: tokens.colorPaletteRedForeground1,
    },
  },
});

export const RemoveFieldMenuItem: FC<{ property: SchemaNode }> = ({ property }) => {
  const classes = useStyles();

  if (!property.isField) return null;

  return (
    <MenuItem
      key="removeField"
      onClick={() => property.field?.delete()}
      icon={<Trash width="14" strokeWidth="1.5" />}
      className={classes.dangerMenuItem}
    >
      Remove field
    </MenuItem>
  );
};
