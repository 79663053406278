import { FC, useEffect, useState } from "react";
import {
  Button,
  Divider,
  Input,
  Label,
  Switch,
  Tooltip,
  makeStyles,
  shorthands,
  tokens,
} from "@fluentui/react-components";
import { Option } from "@src/lib/schemas";
import { TextCombobox } from "./TextCombobox";
import { ArrowDownNarrowWide, Info, SquareScissors, Trash2 } from "lucide-react";
import { BasicLoopElement, BasicLoopElementUpdateProps, Sorter } from "@src/lib/liquidx/BasicLoopElement";
import { useFields } from "../hooks/useFields";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    ...shorthands.gap("8px"),
  },
  sortBy: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    ...shorthands.gap("8px"),
  },
  transformMenuItem: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    ...shorthands.gap("8px"),
  },
  buttonTransparent: {
    display: "flex",
    justifyContent: "start",
    ...shorthands.gap("6px"),
  },
  dangerButtonTransparent: {
    display: "flex",
    justifyContent: "start",
    ":hover": {
      color: tokens.colorStatusDangerForeground1,
    },
    ...shorthands.gap("6px"),
  },
});

export const BasicLoop: FC<{ element: BasicLoopElement }> = ({ element: basicLoopElement }) => {
  const [showSort, setShowSort] = useState<boolean>(false);
  const [elementProps, setElementProps] = useState<BasicLoopElementUpdateProps>({ property: "" });
  const [showSkipAndLimit, setShowSkipAndLimit] = useState<boolean>(false);
  const [options, setOptions] = useState<Option[]>([]);
  const [sortOptions, setSortOptions] = useState<Option[]>([]);
  const { version } = useFields();
  const styles = useStyles();

  useEffect(() => {
    setElementProps({
      sorter: basicLoopElement.ui.sorter,
      property: basicLoopElement.ui.property ?? "",
      limit: basicLoopElement.ui.limit,
      offset: basicLoopElement.ui.offset,
    });

    setShowSort(!!basicLoopElement.ui.sorter);
    setShowSkipAndLimit(!!basicLoopElement.ui.limit || !!basicLoopElement.ui.offset);
  }, [basicLoopElement.id]);

  // Whenever fields change, update the available options
  useEffect(() => {
    setOptions(basicLoopElement.options);
    setSortOptions(basicLoopElement.sortOptions);
  }, [version]);

  function updateSorter(sorter: Partial<Sorter>) {
    const newSorter = { ...elementProps?.sorter, ...sorter } as Sorter;

    updateElementProps({ sorter: newSorter });
  }

  function updateElementProps(newProps: Partial<BasicLoopElementUpdateProps>) {
    setElementProps((prev) => {
      const newUpdateProps = { ...prev, ...newProps } as BasicLoopElementUpdateProps;
      void basicLoopElement.update(newUpdateProps);
      return newUpdateProps;
    });
  }

  function clearSorter() {
    setShowSort(false);
    updateElementProps({ sorter: undefined });
  }

  function clearSkipAndLimit() {
    setShowSkipAndLimit(false);
    updateElementProps({ limit: undefined, offset: undefined });
  }

  function handlePropertyChange(newProperty: string) {
    updateElementProps({ property: newProperty });
  }

  return (
    <div className={styles.root}>
      <TextCombobox
        autoFocus={basicLoopElement.isNew}
        placeholder="Select collection"
        options={options}
        value={elementProps.property}
        onChange={handlePropertyChange}
      />

      <div style={{ display: "flex", flexDirection: "row", justifyContent: "start", alignItems: "center", gap: "8px" }}>
        {!showSort && (
          <Button
            size="small"
            appearance="transparent"
            className={styles.buttonTransparent}
            onClick={() => setShowSort(true)}
          >
            <ArrowDownNarrowWide size={16} />
            Sort
          </Button>
        )}
        {!showSkipAndLimit && (
          <Button
            size="small"
            appearance="transparent"
            className={styles.buttonTransparent}
            onClick={() => setShowSkipAndLimit(true)}
          >
            <SquareScissors size={16} />
            Skip & Limit
          </Button>
        )}
      </div>

      {showSort && (
        <>
          <Divider>Sort</Divider>
          <TextCombobox
            placeholder="Sort by"
            options={sortOptions}
            value={elementProps.sorter?.property ?? ""}
            onChange={(property) => updateSorter({ property })}
          />
          <Switch
            label="Reverse order"
            checked={elementProps.sorter?.direction === "desc"}
            onChange={(_, { checked }) => updateSorter({ direction: checked ? "desc" : "asc" })}
          />
          <Button
            size="small"
            appearance="transparent"
            onClick={clearSorter}
            className={styles.dangerButtonTransparent}
          >
            <Trash2 size={16} />
            Remove
          </Button>
        </>
      )}

      {showSkipAndLimit && (
        <>
          <Divider>Skip & Limit</Divider>
          <MyLabel description="Skip this many items at the start of the loop.">Skip</MyLabel>
          <Input
            placeholder="None"
            onChange={(_, { value }) => updateElementProps({ offset: tryParseInt(value) })}
            value={elementProps.offset?.toString() ?? ""}
          />
          <MyLabel description="Limits the loop to the specified number of iterations.">Limit</MyLabel>
          <Input
            placeholder="None"
            onChange={(_, { value }) => updateElementProps({ limit: tryParseInt(value) })}
            value={elementProps.limit?.toString() ?? ""}
          />
          <Button
            size="small"
            appearance="transparent"
            onClick={clearSkipAndLimit}
            className={styles.dangerButtonTransparent}
          >
            <Trash2 size={16} />
            Remove
          </Button>
        </>
      )}
    </div>
  );
};

const MyLabel: FC<{ description: string }> = ({ description, children }) => {
  return (
    <Label size="small" style={{ display: "flex", gap: "4px", alignItems: "center" }}>
      {children}
      <Tooltip content={description} relationship="label">
        <Info size="12" strokeWidth="1.5" />
      </Tooltip>
    </Label>
  );
};

/**
 *
 * @param value any value
 * @returns number or undefined
 */
function tryParseInt(value: unknown): number | undefined {
  const parsedInt = parseInt(value as string, 10);

  if (isNaN(parsedInt)) return undefined;
  return parsedInt;
}
