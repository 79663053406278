import { ContentControlType, InsertLocation, VariableData } from "@src/lib/liquidx";
import { LiquidElement } from "@src/lib/liquidx/internal";
import { QuickAddOption, Variable } from "@src/lib/walter";
import { unicodeToString } from "@src/taskpane/helpers/formatData";
import { LiquidTag } from "./ParsedTag";

interface SignTabInputElementData {
  group: string;
  required: boolean;
  type: string;
  variable: VariableData;
  width: string;
}

export class SignTabInputElement extends LiquidElement {
  static supports({ type }: LiquidTag) {
    return type === "sign-tab-input";
  }

  static placeholderByType: Record<string, string | undefined> = {
    text: "[TEXT FIELD]",
    checkbox: unicodeToString("25A2"),
    radio: unicodeToString("25EF"),
  };

  // Overload signatures
  constructor();
  constructor(tag: LiquidTag | null, id?: number | null, title?: string, parentId?: number);
  constructor(tag?: LiquidTag | null, id?: number | null, title?: string, parentId?: number) {
    tag ??= { valid: true, type: ContentControlType.SIGN_TAB_INPUT, data: {} };
    super(tag, id, title, parentId);
    this.font = { color: "blue" };
  }

  applyQuickAdd(_quickAddOption: QuickAddOption, _location: InsertLocation): void {
    throw new Error("Method not implemented.");
  }

  get innerText() {
    const placeholder = SignTabInputElement.placeholderByType[this.inputType] ?? "";
    return `${placeholder}${this.required ? "*" : ""}`;
  }

  get inputType() {
    return (this.data as any)?.type;
  }

  get required(): boolean {
    return (this.data as any)?.required;
  }

  get variable(): Variable {
    if (!this.metadata || !this.metadata.variable) {
      throw new Error(`SignTabInputElement has malformed metadata: ${this.metadata}`);
    }

    return Variable.buildFromVariableData(this.metadata.variable);
  }

  get metadata(): SignTabInputElementData {
    return this.data as SignTabInputElementData;
  }

  set metadata(value: SignTabInputElementData) {
    this.data = value;
  }

  get title(): string {
    return `${this.metadata.type}: ${this.variable.fullName}`;
  }
}
