import { getNewToken } from "../auth/OAuthService";
import { defaultStorage } from "./defaultStorage";

export type OAuthToken = {
  accessToken: string;
  createdAt: number;
  expiresIn: number;
  refreshToken: string;
  scope: string;
  tokenType: "Bearer";
  provider?: string;
  expiresAt?: Date;
};

class TokenStorage {
  private _getPromise: Promise<OAuthToken | null> | null = null;

  setExpiry(token: OAuthToken) {
    if (token && token.expiresAt === undefined) {
      const expiresAtMillisecondsEpoch = (token.createdAt + token.expiresIn) * 1000;
      token.expiresAt = new Date(expiresAtMillisecondsEpoch);
    }
  }

  hasExpired(token?: OAuthToken): boolean {
    if (token === undefined || token === null) {
      return true;
    }

    if (token?.expiresAt === null) {
      return false;
    } else {
      // If the token was stored, it's Date type property was stringified, so it needs to be converted back to Date.
      token.expiresAt = token.expiresAt instanceof Date ? token.expiresAt : new Date(token.expiresAt as any);
      return token.expiresAt.getTime() - new Date().getTime() < 0;
    }
  }

  /**
   * Get the OAuth token for the given provider. Only run one request at a time, and wait for it to complete before returning the promise.
   * @param provider
   * @returns
   */
  async get(provider: string): Promise<OAuthToken | null> {
    if (!this._getPromise) {
      const getPromise = this._get(provider);
      this._getPromise = getPromise;

      await getPromise;
      this._getPromise = null;
      return getPromise;
    }

    return this._getPromise;
  }

  private async _get(provider: string) {
    let token = defaultStorage.get<OAuthToken | null>(provider);
    if (token === null || token === undefined) {
      return token;
    }

    if (this.hasExpired(token)) {
      if (token.refreshToken === undefined) {
        defaultStorage.remove(provider);
        return null;
      }
      // automatically try to get a new token
      token = await getNewToken(token.refreshToken);
      if (token === null) {
        defaultStorage.remove(provider);
        return null;
      } else {
        return this.add(provider, token);
      }
    } else {
      return token;
    }
  }

  remove(provider: string) {
    defaultStorage.remove(provider);
  }

  clear() {
    defaultStorage.clear();
  }

  add(provider: string, token: OAuthToken): OAuthToken {
    if (token) {
      token.provider = provider;
    }

    this.setExpiry(token);

    defaultStorage.add(provider, token);

    return token;
  }
}

export const tokenStorage = new TokenStorage();
