import { useId, OptionOnSelectData, useComboboxFilter, Combobox } from "@fluentui/react-components";
import { FC, useState, useEffect, ChangeEvent, ReactNode } from "react";
import { PluralizeSnippet } from "./PluralizeSnippet";

interface Option {
  children: ReactNode;
  label: string;
  value: string;
}

export const CountCombobox: FC<{ element: PluralizeSnippet; property: string; onChange: (value: string) => void }> = ({
  element,
  property,
  onChange,
}) => {
  // Used to filter options, always equal to the value typed into the combobox
  const [query, setQuery] = useState("");
  // Used to set the value of the combobox (what is shown in the input field)
  const [value, setValue] = useState("");
  // Used to set what option is selected in the combobox
  const [selectedOptionValue, setSelectedOptionValue] = useState("");
  const [options, setOptions] = useState<Option[]>([]);
  const comboId = useId("combo-default");

  useEffect(() => {
    const options: Option[] = element.options.map(({ label, value }) => ({
      value,
      label,
      children: label,
    }));

    setOptions(options);

    const selectedOption = options.find((option) => option.value === property);
    setSelectedOptionValue(selectedOption?.value ?? "");
    setValue(selectedOption?.label ?? "");
  }, [property]);

  const handleInput = (ev: ChangeEvent<HTMLInputElement>) => {
    setValue(ev.target.value);
  };

  function handleOptionSelect(_e: unknown, data: OptionOnSelectData) {
    const property = data.optionValue ?? "";

    onChange(property);
    setSelectedOptionValue(property);
    setValue(data.optionText ?? "");
  }

  const children = useComboboxFilter(query, options, {
    noOptionsMessage: "No number or count match your search.",
    optionToText: (option) => option.label,
  });

  return (
    <Combobox
      aria-labelledby={comboId}
      placeholder="Select property"
      onOptionSelect={handleOptionSelect}
      onInput={handleInput}
      value={value.length ? value : query}
      selectedOptions={[selectedOptionValue]}
      onChange={(ev) => setQuery(ev.target.value)}
    >
      {children}
    </Combobox>
  );
};
