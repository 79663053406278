import { OperationContext } from "../OperationContext";
import { Operation } from "./Operation";
import debug from "../../../lib/debug";
import { DynamicElement, JsElement } from "@src/lib/liquidx/internal";

export interface ContentControlProperties {
  id: number;
  parentId: number | undefined;
  tag: string;
  title: string;
}

export class DynamicElementRead implements Operation<ContentControlProperties[]> {
  async commit(context: OperationContext): Promise<ContentControlProperties[]> {
    context.trace("wContentControl#buildHierarchy");

    const contentControlCollection = context.document.contentControls;
    contentControlCollection.load(["items"]);
    await context.sync();
    // Load every content control in the document (and its parent, so we can determine the hierarchy)
    const contentControls: Word.ContentControl[] = contentControlCollection.items.map((cc) => {
      cc.load(["parentContentControlOrNullObject", "tag", "id", "title"]);
      return cc;
    });

    await context.sync();

    const orderedIds = contentControls.map((cc) => cc.id);
    const propertyContentControls: Record<number, ContentControlProperties> = {};

    for (const contentControl of contentControls) {
      const parentId = contentControl.parentContentControlOrNullObject
        ? contentControl.parentContentControlOrNullObject.id
        : undefined;

      propertyContentControls[contentControl.id] = {
        id: contentControl.id,
        parentId,
        tag: contentControl.tag,
        title: contentControl.title,
      };
    }

    // Correct self-parented content controls
    const selfParentedContentControls = Object.values(propertyContentControls).filter(
      (contentControl) => contentControl.id === contentControl.parentId,
    );

    const refetchedContentControls = [];
    for (const selfParentedContentControl of selfParentedContentControls) {
      const contentControl = context
        .getContentControl(selfParentedContentControl.id)
        .load(["parentContentControlOrNullObject"]);

      refetchedContentControls.push(contentControl);
    }

    if (refetchedContentControls.length > 0) {
      await context.sync();

      for (const contentControl of refetchedContentControls) {
        const parentId = contentControl.parentContentControlOrNullObject
          ? contentControl.parentContentControlOrNullObject.id
          : undefined;

        if (parentId !== contentControl.id) {
          debug.warn("Self-parented content control was fixed by refetch", contentControl.id, parentId);
          propertyContentControls[contentControl.id].parentId = parentId;
        } else {
          debug.error("Content control is self-parented (refetch was ineffective)", contentControl.id);
        }
      }
    }

    for (const contentControl of contentControls) register(contentControl);

    const reorderedPropertyContentControls: ContentControlProperties[] = [];

    // After we've loaded all the content controls, which includes some special case handling
    // that can disrupt the order of the content controls, we reorder them based to order they
    // were originally returned to us.
    for (const id of orderedIds) {
      const value = propertyContentControls[id];
      if (value) reorderedPropertyContentControls.push(value);
    }

    return reorderedPropertyContentControls;
  }

  merge(other: Operation<unknown>) {
    if (other instanceof DynamicElementRead) {
      return this;
    }
  }
}

// TODO: Consider moving this into the sync engine, instead of being part of this operation.
enum ContentControlEvent {
  ON_ADDED = "walter.contentControlAdded",
  ON_DELETED = "walter.contentControlDeleted",
  ON_SELECTION_CHANGE = "walter.contentControlSelectionChange",
}

let isOnAddedSetup = false;
export const contentControlAdded = async (event: Word.ContentControlAddedEventArgs) => {
  debug.log(`${event.eventType} event detected. IDs of content controls that were added:`, event.ids);

  window.dispatchEvent(
    new CustomEvent(ContentControlEvent.ON_ADDED, {
      detail: { ids: event.ids },
    }),
  );

  await DynamicElement.load();
  event.ids.forEach((id) => {
    const newDynamicElement = DynamicElement.find(id);

    if (newDynamicElement instanceof JsElement) {
      // Elements may be pasted from other contexts and the representation within the document may not match the current location.
      // For example, if it is copied from a location where it had errors to a location where it has no errors, the content control
      // needs to be updated to change the color from red to blue.
      void newDynamicElement.save();
    } else {
      void newDynamicElement?.revalidate();
    }
  });
};

const contentControlWatchList = new Map<number, Word.ContentControl>();
function register(contentControl: Word.ContentControl) {
  // This feels out of place so if you have a better idea, feel free to refactor it out.
  if (!isOnAddedSetup) {
    contentControl.context.document.onContentControlAdded.add(contentControlAdded);
    isOnAddedSetup = true;
  }

  if (contentControlWatchList.has(contentControl.id)) return;

  debug.log(`Registering content control ID${contentControl.id}`);

  contentControl.onSelectionChanged.add(onSelectionChanged);
  contentControl.onDeleted.add(onDeleted);
  contentControl.track();

  contentControlWatchList.set(contentControl.id, contentControl);
}

function unregister(id: number) {
  const contentControl = contentControlWatchList.get(id);

  if (contentControl) {
    contentControlWatchList.delete(id);
    contentControl.onSelectionChanged.remove(onSelectionChanged);
    contentControl.onDeleted.remove(onDeleted);
    contentControl.untrack();

    debug.log(`Unregistering content control ID${contentControl.id}`);
  }
}

async function onSelectionChanged(event: Word.ContentControlSelectionChangedEventArgs) {
  window.dispatchEvent(new CustomEvent(ContentControlEvent.ON_SELECTION_CHANGE, { detail: { ids: event.ids } }));
}

async function onDeleted(event: Word.ContentControlDeletedEventArgs) {
  for (const id of event.ids) {
    unregister(id);
    await DynamicElement.load();
    window.dispatchEvent(new CustomEvent(ContentControlEvent.ON_DELETED, { detail: { id } }));
  }
}
