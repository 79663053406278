/**
 * Debounces a function, ensuring that it is only called after a specified delay has passed since the last invocation.
 *
 * @param fn The function to debounce.
 * @param delay The delay in milliseconds.
 * @returns A debounced version of the function.
 */
export function debounce(fn: (...args: any[]) => void, delay: number) {
  let timeoutId: number | undefined;
  return function (...args: any[]) {
    const later = () => {
      timeoutId = undefined;
      fn(...args);
    };

    if (timeoutId !== undefined) {
      clearTimeout(timeoutId);
    }

    timeoutId = window.setTimeout(later, delay);
  };
}
