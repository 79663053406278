import { ContentControlType, InsertLocation } from "@src/lib/liquidx";
import {
  AdvancedIfElement,
  BasicLoopElement,
  BasicVariableElement,
  DynamicElement,
  AdvancedVariableElement,
  PluralizeElement,
} from "@src/lib/liquidx/internal";
import {
  Signature20Regular,
  Signature16Regular,
  BracesVariable20Regular,
  BracesVariableRegular,
  Calendar20Regular,
  Calendar16Regular,
  Pen20Regular,
  Pen16Regular,
  BranchFork20Regular,
  BranchFork16Regular,
  Form20Regular,
  FormRegular,
  ArrowRepeatAll20Regular,
  ArrowRepeatAll16Regular,
  QuestionCircle16Regular,
  QuestionCircle20Regular,
} from "@fluentui/react-icons";
import {
  CalendarFold,
  CircleDot,
  Code,
  GitBranchPlus,
  Layers,
  LinkIcon,
  PenTool,
  SquareAsterisk,
  SquareCheck,
  TextCursorInput,
} from "lucide-react";

import type { JSX } from "react";
import { RouterHistory } from "@sentry/react/types/reactrouter";
import { SchemaNode } from "@src/lib/schemas";
import { SignTabJsElement } from "@src/lib/liquidx/SignTabJsElement";
import { BasicIfElement } from "@src/lib/liquidx/BasicIfElement";
import { ConditionModel } from "../components/ConditionBuilder/ConditionModel";
import { ConditionData } from "../components/ConditionBuilder/ConditionCodeGen";
import { AliasElement } from "@src/lib/liquidx/AliasElement";

export interface DynamicElementViewHelper {
  visit?: (history: RouterHistory, dynamicElemnt: { id: number | null }) => void;
  icon: JSX.Element;
  iconSmall: JSX.Element;
  label: string;
  insert?: (property?: SchemaNode, insertLocation?: InsertLocation) => Promise<void>;
}

const map = new Map<string, DynamicElementViewHelper>([
  [
    ContentControlType.IF,
    {
      visit: visitForLiquid("conditionals"),
      icon: <BranchFork20Regular />,
      iconSmall: <BranchFork16Regular />,
      label: "Conditional",
    },
  ],
  [
    ContentControlType.VARIABLE,
    {
      visit: visitForLiquid("variables"),
      icon: <BracesVariable20Regular />,
      iconSmall: <BracesVariableRegular fontSize={16} />,
      label: "Variable",
    },
  ],
  [
    ContentControlType.SIGNATURE,
    {
      visit: visitForLiquid("signatures"),
      icon: <Signature20Regular />,
      iconSmall: <Signature16Regular />,
      label: "Signature",
    },
  ],
  [
    ContentControlType.INITIALS,
    {
      visit: visitForLiquid("initials"),
      icon: <Pen20Regular />,
      iconSmall: <Pen16Regular />,
      label: "Initials",
    },
  ],
  [
    ContentControlType.DATE_SIGNED,
    {
      visit: visitForLiquid("date-signed"),
      icon: <Calendar20Regular />,
      iconSmall: <Calendar16Regular />,
      label: "Date Signed",
    },
  ],
  [
    ContentControlType.SIGN_TAB_INPUT,
    {
      visit: visitForLiquid("form-inputs"),
      icon: <Form20Regular />,
      iconSmall: <FormRegular />,
      label: "Form Input",
    },
  ],
  [
    ContentControlType.LOOP,
    {
      visit: visitForLiquid("loops"),
      icon: <ArrowRepeatAll20Regular />,
      iconSmall: <ArrowRepeatAll16Regular />,
      label: "Loop",
    },
  ],
  //
  // JS dynamic elements
  //
  [
    "variable/basic",
    {
      icon: <BracesVariableRegular fontSize={18} />,
      iconSmall: <BracesVariableRegular fontSize={16} />,
      label: "Basic",
      insert: async (node?: SchemaNode) => {
        var element = new BasicVariableElement();

        if (node) {
          await element.update({ property: node.path });
        } else {
          await element.save();
        }
      },
    },
  ],
  [
    "variable/advanced",
    {
      insert: async (property?: SchemaNode) => {
        var element = new AdvancedVariableElement();

        if (property) {
          await element.update(`fields.${property.path}`);
        } else {
          await element.save();
        }
      },
      icon: <Code size={14} />,
      iconSmall: <Code size={14} strokeWidth={1.5} />,
      label: "Code",
    },
  ],
  [
    "variable/pluralize",
    {
      insert: async (property?: SchemaNode) => {
        var element = new PluralizeElement();
        await element.snippet.update({ property: property?.path ?? "", singular: "", plural: "", showCount: false });
      },
      icon: <Layers size={15} strokeWidth={1.5} />,
      iconSmall: <Layers size={16} strokeWidth={1.5} />,
      label: "Pluralize",
    },
  ],
  [
    "variable/alias",
    {
      insert: async (property?: SchemaNode) => {
        if (property) {
          var element = AliasElement.forProperty(property.path);
          await element.save();
        } else {
          throw new Error("Can't insert alias element without a property");
        }
      },
      icon: <LinkIcon size={16} strokeWidth={1.5} />,
      iconSmall: <LinkIcon size={16} strokeWidth={1.5} />,
      label: "Alias",
    },
  ],
  [
    "variable/signField",
    {
      icon: <PenTool size={16} strokeWidth={1.5} />,
      iconSmall: <PenTool size={16} strokeWidth={1.5} />,
      label: "Sign field",
    },
  ],

  [
    "variable/signature",
    {
      insert: async (property?: SchemaNode) => {
        var element = new SignTabJsElement();
        await element.update({ property: property?.path ?? "", required: true, signTabType: "signature" });
      },
      icon: <PenTool size="16" strokeWidth={1.5} />,
      iconSmall: <Signature16Regular />,
      label: "Signature",
    },
  ],
  [
    "variable/initials",
    {
      insert: async (property?: SchemaNode) => {
        var element = new SignTabJsElement();
        await element.update({ property: property?.path ?? "", required: true, signTabType: "initials" });
      },
      icon: <SquareAsterisk size="16" strokeWidth={1.5} />,
      iconSmall: <Pen16Regular />,
      label: "Initials",
    },
  ],
  [
    "variable/dateSigned",
    {
      insert: async (property?: SchemaNode) => {
        var element = new SignTabJsElement();
        await element.update({ property: property?.path ?? "", signTabType: "dateSigned" });
      },
      icon: <CalendarFold size="16" strokeWidth="1.5" />,
      iconSmall: <Calendar16Regular />,
      label: "Date Signed",
    },
  ],
  [
    "variable/text",
    {
      insert: async (property?: SchemaNode) => {
        var element = new SignTabJsElement();
        await element.update({ property: property?.path ?? "", signTabType: "text" });
      },
      icon: <TextCursorInput size="16" strokeWidth="1.5" />,
      iconSmall: <FormRegular />,
      label: "Text field",
    },
  ],
  [
    "variable/checkbox",
    {
      insert: async (property?: SchemaNode) => {
        var element = new SignTabJsElement();
        await element.update({ property: property?.path ?? "", signTabType: "checkbox" });
      },
      icon: <SquareCheck size="16" strokeWidth="1.5" />,
      iconSmall: <SquareCheck />,
      label: "Checkbox",
    },
  ],
  [
    "variable/radio",
    {
      insert: async (property?: SchemaNode) => {
        var element = new SignTabJsElement();
        await element.update({ property: property?.path ?? "", signTabType: "radio" });
      },
      icon: <CircleDot size="16" strokeWidth="1.5" />,
      iconSmall: <SquareCheck />,
      label: "Radio button",
    },
  ],
  [
    "if/basic",
    {
      insert: async (property?: SchemaNode, location?: InsertLocation) => {
        var element = new BasicIfElement();
        if (location) element.insertLocation = location;

        if (property) {
          const condition: ConditionData = { left: { type: "property", value: property.path } };
          const model = new ConditionModel(condition, element.nodes);
          condition.operator = model.operators[0].value;

          await element.update({
            conditionGroup: {
              conditions: [condition],
              joinOperator: "and",
            },
          });
        } else {
          await element.save();
        }
      },
      icon: <GitBranchPlus size="16" strokeWidth="1.6" />,
      iconSmall: <BranchFork16Regular />,
      label: "If then",
    },
  ],
  [
    "if/basicWithElse",
    {
      insert: async (property?: SchemaNode, location?: InsertLocation) => {
        var element = new BasicIfElement();
        if (location) element.insertLocation = location;
        element.shouldHaveElse = true;

        if (property) {
          const condition: ConditionData = { left: { type: "property", value: property.path } };
          const model = new ConditionModel(condition, element.nodes);
          condition.operator = model.operators[0].value;

          await element.update({
            conditionGroup: {
              conditions: [condition],
              joinOperator: "and",
            },
          });
        } else {
          await element.save();
        }
      },
      icon: <BranchFork16Regular />,
      iconSmall: <BranchFork16Regular />,
      label: "If then else",
    },
  ],
  [
    "if/advanced",
    {
      insert: async (property?: SchemaNode, location?: InsertLocation) => {
        var element = new AdvancedIfElement();
        if (location) element.insertLocation = location;
        element.shouldHaveElse = true;

        if (property) {
          element.render.code = `fields.${property.path}`;
          await element.save();
        } else {
          await element.save();
        }
      },
      icon: <Code size={14} strokeWidth={2} />,
      iconSmall: <Code size={16} strokeWidth={1.5} />,
      label: "Code",
    },
  ],
  [
    "loop/basic",
    {
      insert: async (property?: SchemaNode, location?: InsertLocation) => {
        var element = new BasicLoopElement();
        if (location) element.insertLocation = location;

        if (property) {
          await element.update({ property: property.path });
        } else {
          await element.save();
        }
      },
      icon: <ArrowRepeatAll16Regular />,
      iconSmall: <ArrowRepeatAll16Regular />,
      label: "Basic",
    },
  ],
  [
    "loop/advanced",
    {
      visit: visitFor("loop/advanced"),
      icon: <Code size={14} strokeWidth={2} />,
      iconSmall: <Code size={16} strokeWidth={1.5} />,
      label: "Code",
    },
  ],
]);

const fallback: DynamicElementViewHelper = {
  icon: <QuestionCircle20Regular />,
  iconSmall: <QuestionCircle16Regular />,
  label: "Unknown",
};

function visitFor(path: string): DynamicElementViewHelper["visit"] {
  return (history, { id }) => history.push(`${path}/${id ? id : "new"}`);
}

function visitForLiquid(path: string): DynamicElementViewHelper["visit"] {
  return (history, { id }) => {
    if (id) {
      return history.push(`${path}/${id}/edit`);
    } else {
      return history.push(`${path}/new`);
    }
  };
}

export const mapDynamicElement = (elementOrType: DynamicElement | string | undefined): DynamicElementViewHelper => {
  if (!elementOrType) return fallback;

  return map.get(elementOrType instanceof DynamicElement ? elementOrType.type : elementOrType) || fallback;
};
