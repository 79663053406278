import { typeOptionsHandler } from ".";
import { JSONSchema9Type, JSONSchema9, JSONSchema9Array } from "../types/jsonSchema";

/**
 * This replaces any $ref with the schema from the template_objects.json file.
 *
 * @param schema
 * @returns schema with $refs resolved
 */
export default function resolveRefs<T extends JSONSchema9 | JSONSchema9Type>(schema: T): T {
  if (Array.isArray(schema)) {
    // Assume that any array passed is a JSONSchema9Array
    return (schema as JSONSchema9Array).map((item) => resolveRefs(item)) as T;
  } else if (typeof schema === "object" && schema !== null) {
    const schemaCopy = { ...schema } as JSONSchema9;

    // When schema has $ref, resolve it.
    if ("$ref" in schemaCopy && schemaCopy.$ref !== undefined) {
      const refPath = schemaCopy.$ref as string;
      delete schemaCopy.$ref;
      const refSchema = { ...schemaCopy, ...lookupRef(refPath) };
      return resolveRefs(refSchema) as T;
    } else {
      // Otherwise, recursively resolve refs for all property values
      for (const key of Object.keys(schemaCopy) as Array<keyof JSONSchema9>) {
        // @ts-ignore
        schemaCopy[key] = resolveRefs(schemaCopy[key]);
      }

      return schemaCopy as T;
    }
  }

  return schema;
}

/**
 * Retrieve the schema for a particular definition, like "stakeholder", from the template_objects.json file.
 *
 * If the definition is not found, an error is thrown.
 *
 * @param definitionUri
 * @returns schema for the definition
 */
export function lookupRef(
  definitionUri: string,
  definitions: Record<string, JSONSchema9> = typeOptionsHandler.refToObjectSchema,
): JSONSchema9 {
  const definition = definitions[definitionUri];

  if (definition) {
    return { ...definition };
  } else {
    throw new Error(`Definition ${definitionUri} not found: ${JSON.stringify(Object.keys(definitions), null, 2)}`);
  }
}
