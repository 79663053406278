import { ContentControlType, ContentControlTypeValues, contentControlTypeValues } from ".";
import { Tag, JsTag, InvalidTag, LiquidTag, BaseUiData, BaseRenderData } from "./ParsedTag";

export const PREFIX = "walter";

export class TagParser {
  /**
   * Returns whether or not a content control is Walter compatible.
   *
   * @returns a Walter tag
   *
   * @throws
   * Throws an error if the tag is not Walter compatible.
   */
  static parse(tag: string | null): Tag {
    if (!tag) {
      return { valid: false, error: "Tag is null" };
    }

    if (this.tagType(tag) === "js") {
      return this.parseJsTag(tag);
    } else {
      return this.parseOldStyle(tag);
    }
  }

  private static tagType(tag: string): "js" | "liquid" {
    const [prefix, type] = tag.split(":");

    if (prefix === "walter" && contentControlTypeValues.includes(type as ContentControlTypeValues)) {
      return "liquid";
    } else {
      return "js";
    }
  }

  private static parseJsTag(tag: string): JsTag<BaseRenderData, BaseUiData> | InvalidTag {
    const [prefix] = tag.split(":");
    const content = tag.substring(tag.indexOf(prefix) + prefix.length + 1);

    if (prefix !== PREFIX) {
      return { valid: false, error: `Invalid prefix: ${prefix}` };
    }

    let data: { ui?: BaseUiData; render?: BaseRenderData } = {};
    if (content) {
      try {
        data = JSON.parse(content);
      } catch (error) {
        return { valid: false, error: `Invalid data (must be JSON): ${content}` };
      }
    }

    if (data.render === undefined) {
      return { valid: false, error: "Missing render data" };
    } else if (data.ui && typeof data.ui !== "object") {
      return { valid: false, error: "Missing UI data" };
    } else if (!data.ui?.type) {
      return { valid: false, error: `Missing UI type: ${data}` };
    } else if (!data.render?.type) {
      return { valid: false, error: `Missing render type: ${data}` };
    } else if (!["if", "variable", "loop"].includes(data.render.type)) {
      return { valid: false, error: `Invalid render type: ${data}` };
    } else {
      return {
        valid: true,
        ui: data.ui,
        render: data.render,
      };
    }
  }

  private static parseOldStyle(tag: string): LiquidTag | InvalidTag {
    const [prefix, type] = tag.split(":");
    const content = tag.substring(tag.indexOf(type) + type.length + 1);

    if (prefix !== PREFIX) {
      return { valid: false, error: `Invalid prefix: ${prefix}` };
    }

    if (!contentControlTypeValues.includes(type as ContentControlType)) {
      return { valid: false, error: `Invalid type: ${type}` };
    }

    let data: unknown = {};
    if (content) {
      try {
        data = JSON.parse(content);
      } catch (error) {
        return { valid: false, error: `Invalid data (must be JSON): ${content}` };
      }
    }

    return {
      valid: true,
      type: type as ContentControlType,
      data,
    };
  }
}
