import { walterStyles } from "../styles";
import { InsertStyles } from "../sync-engine/operations/InsertStyles";
import { syncEngine } from "../sync-engine/SyncEngine";
import { Migration, MigrationTypes } from "./Migration";

export class AddStyles extends Migration {
  name: string = "AddStyles";
  type: MigrationTypes = "local";

  async apply() {
    const styles = Object.values(walterStyles);
    await syncEngine.perform(new InsertStyles(styles));
    return true;
  }
}
