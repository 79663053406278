import { FC, useContext, useEffect, useState } from "react";
import { ConditionData } from "./ConditionCodeGen";
import { TextCombobox } from "../TextCombobox";
import { Option, SchemaNode } from "@src/lib/schemas";
import { humanize } from "@src/taskpane/helpers/formatData";
import { ConditionBuilderContext } from "./useConditionBuilder";
import { Token } from "./Token";
import { ConditionModel } from "./ConditionModel";

export const Left: FC<{ condition: ConditionData; onChange?: (_: ConditionData) => void }> = ({
  condition,
  onChange,
}) => {
  const { nodes } = useContext(ConditionBuilderContext);
  const [selectedNode, setSelectedNode] = useState<SchemaNode>();
  const [options, setOptions] = useState<Option[]>([]);
  const [editing, setEditing] = useState(false);

  useEffect(() => {
    setOptions(nodes.map((node) => ({ label: humanize(node.fullTitle), value: node.path })));
    setSelectedNode(nodes.find((node) => node.path === condition.left.value));
  }, [nodes, condition]);

  function updateLeft(value: string) {
    if (!value) return;

    setEditing(false);
    const updatedCondition: ConditionData = { ...condition, left: { type: "property", value }, operator: "equals" };
    const model = new ConditionModel(updatedCondition, nodes);
    updatedCondition.operator = model.operators[0].value;
    onChange?.(updatedCondition);
  }

  if (editing) {
    return (
      <TextCombobox
        autoFocus
        appearance="underline"
        value={selectedNode ? selectedNode?.fullTitle : ""}
        options={options}
        onChange={updateLeft}
        onKeyDown={(e) => e.key === "Escape" && setEditing(false)}
      />
    );
  } else if (selectedNode) {
    const tooltip = selectedNode.truncatedTitle !== selectedNode.fullTitle ? selectedNode.fullTitle : undefined;

    return (
      <Token
        onClick={() => setEditing(true)}
        label={selectedNode.truncatedTitle}
        tooltip={tooltip}
        appearance="primary"
      />
    );
  } else {
    return <Token onClick={() => setEditing(true)} label="Input" appearance="placeholder" />;
  }
};
