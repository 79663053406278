import { IJsonApiDocument } from "./jsonApi";
import { tokenStorage } from "@src/lib/settings/tokenStorage";
import { JSONSchema9 } from "@src/lib/types/jsonSchema";
import { captureErrorInfo } from "@src/taskpane/helpers/errorHandler";
import { captureException } from "@sentry/react";
import { WALTER_HOST } from "@src/lib/env-vars";
import { defaultStorage } from "@src/lib/settings/defaultStorage";
import type { WalterWorkspace } from "@src/lib/auth/OAuthService";

export type FeatureFlagsResponseBody = {
  enabledFeatureFlags: string[];
};

export interface UserDefinedFieldSet {
  id: number;
  schema: JSONSchema9;
  label: string;
  ownerType: "User" | "Workspace";
  description?: string;
}

export type IWalterApiResponse = IJsonApiDocument<any>;

export async function getOAuthData() {
  return tokenStorage.get("walter");
}

class Api {
  async jsonRequestHeaders() {
    return {
      Accept: "application/json",
      "Content-Type": "application/json",
      ...(await this.authHeaders()),
    };
  }

  async authHeaders(): Promise<{ Authorization: string } | {}> {
    let oAuthData = await getOAuthData();
    let retryCount = 0;
    let waitTimeMS = 50;
    const MAX_RETRIES = 5;

    // retry getting the auth token for up to 250ms (MAX_RETRIES * waitTimeMS)
    while (!oAuthData?.accessToken && retryCount < MAX_RETRIES) {
      await new Promise<void>((resolve) =>
        setTimeout(async () => {
          oAuthData = await getOAuthData();
          resolve();
        }, waitTimeMS),
      );
      retryCount++;
    }

    if (!oAuthData?.accessToken) {
      captureException("Missing auth token required to make API request", {
        level: "info",
      });

      return {};
    }

    return {
      Authorization: `Bearer ${oAuthData.accessToken}`,
    };
  }

  /**
   * Make a HTTP GET request that uses Bearer token auth
   * @param apiRoute - Ex. api/template_field_sets
   */
  get<T>(apiRoute: string) {
    const abortController = new AbortController();

    return {
      response: this.#makeJsonRequest(abortController, apiRoute) as Promise<T> | null,
      abortController,
    };
  }

  /**
   * A wrapper around fetch that includes the necessary headers for Walter API requests.
   *
   * @param path an API route
   * @param options fetch options
   * @returns fetch request promise
   */
  async fetch(path: string, options: RequestInit = {}) {
    const headers: HeadersInit = {
      ...(await this.authHeaders()),
      ...(options.headers ?? {}),
    };

    return fetch(`${WALTER_HOST}${path}`, {
      ...options,
      headers,
    });
  }

  async #makeJsonRequest(abortController: AbortController, apiRoute: string) {
    try {
      const headers = await this.jsonRequestHeaders();
      if (!headers) return null;

      const response = await fetch(`${WALTER_HOST}/${apiRoute}`, {
        method: "GET",
        headers,
        signal: abortController.signal,
      });

      if (!response.ok) {
        captureErrorInfo(new Error("An error occurred while fetching data from Walter"), {
          extra: {
            response,
          },
          level: "warning",
        });

        return null;
      } else {
        return await response.json();
      }
    } catch (e) {
      captureErrorInfo(e, {
        extra: {
          apiRoute,
        },
      });
      return null;
    }
  }

  getUserDefinedFieldSets(currentWorkspaceId: number) {
    if (!currentWorkspaceId) {
      captureErrorInfo(new Error("Failed to get user defined field sets: no workspace info found"));

      return {
        response: Promise.resolve(),
      };
    }

    const { abortController, response } = this.get(`api/template_field_sets?workspace_id=${currentWorkspaceId}`);

    return {
      response: response as Promise<{ fieldSets: UserDefinedFieldSet[] } | null>,
      abortController,
    };
  }

  getFeatureFlags() {
    const { abortController, response } = this.get("api/feature_flags");

    return {
      response: response as Promise<FeatureFlagsResponseBody | void>,
      abortController,
    };
  }

  deleteFieldSet(fieldSetId: number) {
    const abortController = new AbortController();
    const currentWorkspace = defaultStorage.get<WalterWorkspace>("currentWorkspace");

    if (!currentWorkspace) {
      captureErrorInfo(new Error("Failed to delete field set: current workspace not found"));
      return {
        response: Promise.resolve(),
        abortController,
      };
    }

    const response = this.jsonRequestHeaders()
      .then((headers) => {
        return fetch(`${WALTER_HOST}/api/template_field_sets/${fieldSetId}?workspace_id=${currentWorkspace.id}`, {
          method: "DELETE",
          headers,
          signal: abortController.signal,
        })
          .then(async (response) => {
            try {
              return {
                ...(await response.json()),
                status: response.status,
              };
            } catch (e) {
              captureErrorInfo(e);

              return { error: "An error occurred while deleting the field set. Try again.", status: response.status };
            }
          })
          .catch(captureErrorInfo);
      })
      .catch(captureErrorInfo);

    return {
      response: response as Promise<{ message?: string; error?: string; status: number } | void>,
      abortController,
    };
  }

  saveFieldSet(body: { schema: JSONSchema9; label: string; owner_type: "user" | "workspace"; description: string }) {
    const abortController = new AbortController();
    const currentWorkspace = defaultStorage.get<WalterWorkspace>("currentWorkspace");

    if (!currentWorkspace) {
      captureErrorInfo(new Error("Failed to save field set: current workspace not found"));

      return {
        response: Promise.resolve(),
        abortController,
      };
    }

    const response = this.jsonRequestHeaders()
      .then((headers) => {
        return fetch(`${WALTER_HOST}/api/template_field_sets`, {
          method: "POST",
          headers,
          body: JSON.stringify({ template_field_set: body, workspace_id: currentWorkspace.id }),
          signal: abortController.signal,
        })
          .then(async (response) => {
            return await response.json();
          })
          .catch(captureErrorInfo);
      })
      .catch(captureErrorInfo);

    return {
      response: response as Promise<{ error?: string[]; id?: number } | void>,
      abortController,
    };
  }
}

export const WalterApi = new Api();
