import { FunctionComponent } from "react";
import { Link, makeStyles } from "@fluentui/react-components";
import { ChevronLeft16Regular } from "@fluentui/react-icons";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles({
  root: { marginLeft: "-4px", display: "flex", alignItems: "center" },
});

export interface IBackNavigationProps {}

const BackNavigation: FunctionComponent<IBackNavigationProps> = () => {
  const history = useHistory();

  const styles = useStyles();

  const onClick = (): void => {
    history.push("/");
  };

  return (
    <Link onClick={onClick} className={styles.root}>
      <ChevronLeft16Regular />
      Back
    </Link>
  );
};

export default BackNavigation;
