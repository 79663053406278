import { DynamicElement, JsElement, LiquidElement } from "@src/lib/liquidx/internal";
import { syncEngine } from "@src/lib/sync-engine/SyncEngine";
import { ContentControlType, InsertLocation } from ".";
import { QuickAddOption } from "../walter";
import { LiquidTag } from "./ParsedTag";

export class BranchElement extends LiquidElement {
  static supports({ type }: LiquidTag) {
    return ["if-branch", "else-branch"].includes(type);
  }

  applyQuickAdd(_quickAddOption: QuickAddOption, _location: InsertLocation): void {
    throw new Error("Method not implemented.");
  }

  get appearance() {
    return Word.ContentControlAppearance.tags;
  }

  get label() {
    return this.type === "if-branch" ? "if branch" : "else branch";
  }

  validate() {
    super.validate();

    const parent = this.parentId ? DynamicElement.find(this.parentId) : null;
    const parentIsLiquidIf = parent?.type === ContentControlType.IF;
    const parentIsJsIf = parent instanceof JsElement && parent.render.type === "if";

    if (parentIsLiquidIf) return;
    if (parentIsJsIf) {
      const elseBranches = this.parent?.children.filter((child) => child.type === "else-branch");
      if (elseBranches && elseBranches.length > 1) {
        this.errors.push("More than one else branch (only the first will be used)");
      }

      return;
    }

    if (parent) {
      this.errors.push(`Branches must be children of an IF element but parent was ID ${parent.id} (${parent.type})`);
    } else {
      this.errors.push(`Branches must be children of an IF element (parent was missing)`);
    }
  }

  get color() {
    return this.hasErrors ? "red" : DynamicElement.defaultColor;
  }

  get cannotDelete() {
    return this.parent?.type === ContentControlType.IF;
  }

  get iconType() {
    return "warning";
  }

  public async delete() {
    if (this.id) {
      await syncEngine.delete({ id: this.id, keepContents: true });
      await DynamicElement.load();
    } else {
      return Promise.resolve();
    }
  }
}
