import { useEffect, useRef, useState, type FC } from "react";
import { validate } from "@src/taskpane/components/CodeExpression/validator";
import { CodeEditor, CodeEditorOnChange } from "@src/taskpane/components/CodeEditor/CodeEditor";
import { AdvancedVariableElement } from "@src/lib/liquidx/internal";
import { makeStyles } from "@fluentui/react-components";

const useStyles = makeStyles({
  root: {
    margin: "-12px -12px",
  },
});

export const AdvancedVariable: FC<{ element: AdvancedVariableElement }> = ({ element }) => {
  const elementRef = useRef(element);
  const [code, setCode] = useState<string>(element.render.code);
  const classes = useStyles();
  const validReturnTypes = ["string", "VariableExpressionSignTab", "any"];

  useEffect(() => {
    elementRef.current = element;
    setCode(element.render.code);
  }, [element]);

  const onChange: CodeEditorOnChange = async ({ code }) => {
    if (code && code !== elementRef.current.render.code) {
      await elementRef.current.update(code);
    }
  };

  return (
    <div className={classes.root}>
      <CodeEditor
        autoFocus={element.isNew}
        onChange={onChange}
        code={code}
        validator={(codeType) => validate(codeType, validReturnTypes)}
      />
    </div>
  );
};
