import { WALTER_HOST } from "@src/lib/env-vars";
import { downloadDocx } from "@src/lib/downloadDocx";
import { defaultStorage } from "@src/lib/settings/defaultStorage";
import { tokenStorage } from "@src/lib/settings/tokenStorage";
import type { WalterUser, WalterWorkspace } from "@src/lib/auth/OAuthService";
import { captureErrorInfo } from "@src/taskpane/helpers/errorHandler";

export async function generatePreviewUrl(): Promise<{
  url: string;
  errorMessage?: string;
}> {
  const oAuthData = await tokenStorage.get("walter");
  const currentUser = defaultStorage.get<WalterUser>("currentUser");
  const currentWorkspace = defaultStorage.get<WalterWorkspace>("currentWorkspace");
  let errorMessage;

  if (!oAuthData || !currentUser) {
    return onUnauthorized(currentUser, !!oAuthData?.accessToken);
  }

  if (!currentWorkspace) {
    captureErrorInfo("No workspace selected", {
      level: "error",
    });
    throw new Error("No workspace selected");
  }

  const accessToken = oAuthData.accessToken;
  const userId = currentUser.id.toString();

  const { slices, properties } = await downloadDocx();
  let filename = properties?.filename ?? "untitled document.docx";
  const blob = new Blob(slices, { type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document" });
  const formData = new FormData();

  if (!filename) {
    filename = "untitled document.docx";
  }

  formData.append("file", blob, filename);
  formData.append("file_name", filename);
  formData.append("user_id", userId);

  // provide the workspace id so that the preview context can be set based on the selected workspace.
  if (currentWorkspace.id) {
    formData.append("workspace_id", currentWorkspace.id.toString());
  }

  const postUrl = `${WALTER_HOST}/word/assembly_preview/`;

  const previewResponse = await fetch(postUrl, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    body: formData,
  });

  if (!previewResponse.ok) {
    if (previewResponse.status === 401) {
      return onUnauthorized(currentUser, !!oAuthData?.accessToken);
    } else if (previewResponse.status === 422) {
      const result = await previewResponse.json();

      captureErrorInfo("Unprocessable entity error when generating preview", {
        level: "error",
        extra: { result },
      });

      errorMessage = result.error;
    } else {
      captureErrorInfo("Error generating preview", {
        level: "error",
        extra: { httpStatus: previewResponse.status },
      });

      throw new Error("Something went wrong");
    }
  }

  const previewData: { id: number } = await previewResponse.json();

  return {
    url: `${WALTER_HOST}/word/assembly_preview/?file_id=${previewData.id}&user_id=${userId}&access_token=${accessToken}`,
    errorMessage,
  };
}

function onUnauthorized(currentUser: WalterUser | null, hasAccessToken: boolean) {
  captureErrorInfo("User attempting to preview but not authenticated", {
    level: "debug",
    extra: {
      currentUserId: currentUser?.id,
      hasAccessToken,
    },
  });

  return {
    url: "",
    errorMessage: "Your session appears to have expired. Try reloading the page or logging in again.",
  };
}
