import { Children, FC, useState, useEffect, ChangeEvent, useContext } from "react";
import { InputOptionsBuilder, QuickAddOption, QuickAddTreeNode } from "@src/lib/walter";
import {
  Tree,
  TreeItem,
  TreeItemProps,
  TreeItemLayout,
  TreeItemValue,
  TreeOpenChangeEvent,
  TreeOpenChangeData,
  Input,
  makeStyles,
  shorthands,
  useRestoreFocusTarget,
  InputOnChangeData,
  Button,
  Checkbox,
  MenuItem,
  Menu,
  MenuTrigger,
  MenuPopover,
  MenuList,
  MenuProps,
  Tooltip,
  tokens,
  Toast,
  ToastTitle,
  useToastController,
  ToastBody,
} from "@fluentui/react-components";
import { MoreHorizontal20Regular } from "@fluentui/react-icons";
import { mapDynamicElement } from "../../helpers/mapDynamicElement";
import { filter } from "../../helpers/filterData";
import { useInputOptions } from "../../hooks/useInputOptions";
import { camelCaseToSentenceCase, humanize } from "../../helpers/formatData";
import { ContentControlType, InsertLocation } from "@src/lib/liquidx";
import { LiquidElement } from "@src/lib/liquidx/internal";
import { WalterContext } from "../../providers/walter";
import { captureErrorInfo } from "@src/taskpane/helpers/errorHandler";
import { documentSchemaStore } from "@src/lib/schemas";

export interface CustomTreeItemProps extends TreeItemProps {
  node: QuickAddTreeNode;
  openItems: Iterable<TreeItemValue>;
  showFieldActions?: boolean;
}

export interface CustomTreeProps {
  node: QuickAddTreeNode;
  openItems: Iterable<TreeItemValue>;
  showFieldActions?: boolean;
}

export interface InsertAroundSubMenuProps {
  onMenuItemClick: (location: InsertLocation) => void;
  visibleItems?: Record<string, InsertLocation>;
}

export interface TreeItemLeafPopoverProps {
  quickAddOption: QuickAddOption;
  fieldId: string;
}

export interface TreeItemBranchPopoverProps {
  fieldId: string;
}

const useTooltipStyles = makeStyles({
  tooltip: {
    backgroundColor: tokens.colorBrandBackground,
    color: tokens.colorNeutralForegroundInverted,
  },
});

const InsertAroundSubMenu: FC<InsertAroundSubMenuProps> = ({
  onMenuItemClick,
  visibleItems = {
    paragraph: InsertLocation.PARENT_PARAGRAPH,
    selection: InsertLocation.SELECTION,
    tableRow: InsertLocation.TABLE_ROW,
  },
}) => {
  const { platform } = useContext(WalterContext);
  const [open, setOpen] = useState(false);
  const [menuItems, setMenuItems] = useState<Record<string, InsertLocation>>(visibleItems);
  const onOpenChange: MenuProps["onOpenChange"] = (_e, data) => {
    setOpen(data.open);
  };
  const styles = useTooltipStyles();
  const notAvailableOnOfficeOnlineLabel =
    "This feature is not available on Word on the web. Please use the desktop app.";

  useEffect(() => {
    setMenuItems(visibleItems);
  }, [visibleItems]);

  return (
    <Menu open={open} onOpenChange={onOpenChange}>
      <MenuTrigger disableButtonEnhancement>
        <MenuItem>Insert around</MenuItem>
      </MenuTrigger>

      <MenuPopover>
        <MenuList>
          {Object.entries(menuItems)
            .sort()
            .map(([key, value]) =>
              platform === Office.PlatformType.OfficeOnline && key === "tableRow" ? (
                <Tooltip
                  key={key}
                  withArrow
                  content={{ children: notAvailableOnOfficeOnlineLabel, className: styles.tooltip }}
                  relationship="label"
                >
                  <MenuItem key={key} disabled={true}>
                    {camelCaseToSentenceCase(key)}
                  </MenuItem>
                </Tooltip>
              ) : (
                <MenuItem key={key} onClick={() => onMenuItemClick(value)}>
                  {camelCaseToSentenceCase(key)}
                </MenuItem>
              ),
            )}
        </MenuList>
      </MenuPopover>
    </Menu>
  );
};

const removeField = async (key: string) => {
  const field = documentSchemaStore.findField(key);
  if (field) await field.delete();
};

const TreeItemLeafPopover: FC<TreeItemLeafPopoverProps> = ({ quickAddOption, fieldId }) => {
  // Shows up in the tree item actions section and when right-clicking on the tree item
  const { documentSelectionData, toasterId } = useContext(WalterContext);
  const [visibleItems, setVisibleItems] = useState<Record<string, InsertLocation>>({});
  const { dispatchToast } = useToastController(toasterId);

  useEffect(() => {
    getMenuItems();
  }, [documentSelectionData]);

  const insertQuickAddOption = async (quickAddOption: QuickAddOption, insertLocation: InsertLocation) => {
    const dynamicElement = LiquidElement.fromQuickAdd(quickAddOption, insertLocation);

    if (dynamicElement) {
      await dynamicElement.save();
    }
  };

  const getMenuItems = (): void => {
    const { isEmpty, paragraphsCount, isInTable } = documentSelectionData;
    const result = {} as Record<string, InsertLocation>;

    const isIfOrLoopElement =
      quickAddOption?.element === ContentControlType.IF || quickAddOption?.element === ContentControlType.LOOP;
    const showParagraph = paragraphsCount <= 1 && isIfOrLoopElement;
    const showSelection = !isEmpty && !isInTable;
    const showTableRow = isInTable && isIfOrLoopElement;

    if (showParagraph) {
      result["paragraph"] = InsertLocation.PARENT_PARAGRAPH;
    }
    if (showSelection) {
      result["selection"] = InsertLocation.SELECTION;
    }
    if (showTableRow) {
      result["tableRow"] = InsertLocation.TABLE_ROW;
    }

    setVisibleItems(() => result);
  };

  const onMenuItemClick = async (location: InsertLocation) => {
    try {
      await insertQuickAddOption(quickAddOption, location);
    } catch (error) {
      captureErrorInfo(error, { extra: { documentSelectionData, location, quickAddOption } });

      // TODO: Consider switching to error boundary to handle cases like this
      dispatchToast(
        <Toast>
          <ToastTitle>Unable to insert field into document.</ToastTitle>
          <ToastBody>This action is not supported in the current context.</ToastBody>
        </Toast>,
        { intent: "error", timeout: 5000 },
      );
    }
  };

  const insert = async () => {
    const { isInTable, paragraphTextsAreEmpty } = documentSelectionData;

    if (isInTable && paragraphTextsAreEmpty) {
      await onMenuItemClick(InsertLocation.TABLE_CELL);
    } else {
      await onMenuItemClick(InsertLocation.INLINE);
    }
  };

  // TODO: fix typescript type error. Remove @ts-ignore comment to see the error
  // @ts-ignore
  const isField = quickAddOption.option.variable.localName === quickAddOption.option.variable.fullName;

  const commonMenuItems = (
    <>
      <MenuItem onClick={insert}>Insert</MenuItem>
      {Object.keys(visibleItems).length > 0 && (
        <InsertAroundSubMenu onMenuItemClick={onMenuItemClick} visibleItems={visibleItems} />
      )}
      {isField && <MenuItem onClick={() => removeField(fieldId)}>Remove field</MenuItem>}
    </>
  );

  return (
    <MenuPopover>
      <MenuList>{commonMenuItems}</MenuList>
    </MenuPopover>
  );
};

const TreeItemBranchPopOver: FC<TreeItemBranchPopoverProps> = ({ fieldId }) => {
  return (
    <MenuPopover>
      <MenuList>
        <MenuItem onClick={() => removeField(fieldId)}>Remove field</MenuItem>
      </MenuList>
    </MenuPopover>
  );
};

const CustomTreeItem: FC<CustomTreeItemProps> = ({ children, ...props }: CustomTreeItemProps) => {
  const focusTargetAttribute = useRestoreFocusTarget();
  const [layoutChildren, subtree] = Children.toArray(children);
  const { node, itemType, showFieldActions } = props;
  const { quickAddOption } = node;
  const { iconSmall } = mapDynamicElement(quickAddOption?.element);
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    const isOpen = Array.from(props.openItems).includes(node.value);
    setOpen(isOpen);
  }, [props.openItems]);

  // TODO: Confirm this is working as expected
  if (itemType === "branch") {
    if (showFieldActions) {
      return (
        <Menu positioning="below-end" openOnContext>
          <MenuTrigger disableButtonEnhancement>
            <TreeItem {...focusTargetAttribute} {...props} value={node.value} open={open}>
              <TreeItemLayout
                actions={
                  <>
                    <Menu>
                      <MenuTrigger disableButtonEnhancement>
                        <Button aria-label="More options" appearance="subtle" icon={<MoreHorizontal20Regular />} />
                      </MenuTrigger>
                      <TreeItemBranchPopOver fieldId={node.value} />
                    </Menu>
                  </>
                }
              >
                {layoutChildren}
              </TreeItemLayout>
              {subtree}
            </TreeItem>
          </MenuTrigger>
          <TreeItemBranchPopOver fieldId={node.value} />
        </Menu>
      );
    } else {
      return (
        <TreeItem
          {...focusTargetAttribute}
          {...props}
          value={node.value}
          open={open}
          onContextMenu={(e) => e.preventDefault()}
        >
          <TreeItemLayout>{layoutChildren}</TreeItemLayout>
          {subtree}
        </TreeItem>
      );
    }
  }

  return (
    <Menu positioning="below-end" openOnContext>
      <MenuTrigger disableButtonEnhancement>
        <TreeItem {...focusTargetAttribute} {...props} value={node.value} open={open}>
          <TreeItemLayout
            title={node.quickAddOption?.option.description}
            iconBefore={iconSmall}
            style={{ marginLeft: "-20px" }}
            actions={
              <>
                <Menu>
                  <MenuTrigger disableButtonEnhancement>
                    <Button aria-label="More options" appearance="subtle" icon={<MoreHorizontal20Regular />} />
                  </MenuTrigger>
                  <TreeItemLeafPopover quickAddOption={quickAddOption!} fieldId={node.value} />
                </Menu>
              </>
            }
          >
            {layoutChildren}
          </TreeItemLayout>
          {subtree}
        </TreeItem>
      </MenuTrigger>
      <TreeItemLeafPopover quickAddOption={quickAddOption!} fieldId={node.value} />
    </Menu>
  );
};

const CustomTree: FC<CustomTreeProps> = ({ node, openItems, showFieldActions }) => {
  // Skip the root node
  // Perhaps we could include this once we have custom fields so that the user can differentiate
  // which fields are provided by Walter and which are custom
  if (node.value === "Walter") {
    return (
      <>
        {node.children &&
          Object.values(node.children).map((childNode, index) => (
            <CustomTree
              key={`${childNode.value}_${index}`}
              node={childNode}
              openItems={openItems}
              showFieldActions={showFieldActions}
            />
          ))}
      </>
    );
  }

  const isBranch = node.children && Object.keys(node.children).length > 0;
  const itemType = isBranch ? "branch" : "leaf";

  const key = node?.quickAddOption
    ? `${node?.quickAddOption?.option?.value}_${node?.quickAddOption?.element}`
    : humanize(node.value);

  return (
    <CustomTreeItem itemType={itemType} key={key} node={node} openItems={openItems} showFieldActions={showFieldActions}>
      {node?.quickAddOption?.option?.label || humanize(node.value)}
      {isBranch && (
        <Tree aria-label={`${node.value}_branch`}>
          {Object.values(node.children).map((childNode, index) => (
            <CustomTree
              key={`${childNode.value}_${index}`}
              node={childNode}
              openItems={openItems}
              showFieldActions={false}
            />
          ))}
        </Tree>
      )}
    </CustomTreeItem>
  );
};

const useStyles = makeStyles({
  root: {
    display: "grid",
    ...shorthands.gap("8px"),
    ...shorthands.padding("12px", "12px"),
  },
  expandCollapse: {
    display: "flex",
    justifyContent: "space-between",
    ...shorthands.gap("16px"),
  },
  noResults: {
    display: "flex",
    justifyContent: "center",
  },
});

export const QuickAdd: FC = () => {
  const { inputOptions, version } = useInputOptions();
  const [tree, setTree] = useState<QuickAddTreeNode>(
    InputOptionsBuilder.createTreeViewForQuickAdd(inputOptions.quickAddOptions),
  );
  const [noResults, setNoResults] = useState<boolean>(false);
  const [needle, setNeedle] = useState<string>("");
  const [openItems, setOpenItems] = useState<Iterable<TreeItemValue>>([]);
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const styles = useStyles();

  useEffect(() => {
    if (!inputOptions.quickAddOptions) return;

    const result = loopRelatedQuickAddOptions(inputOptions.quickAddOptions);

    if (result.length > 0) {
      setIsChecked(true);
      setTree(InputOptionsBuilder.createTreeViewForQuickAdd(result));
      expand();
    } else {
      filterTree(inputOptions.quickAddOptions, needle);
    }
  }, [version]);

  const onFilter = (e: ChangeEvent<HTMLInputElement>, _data: InputOnChangeData) => {
    setNoResults(false);

    const options = inputOptions.quickAddOptions;
    const needle = e.target.value;

    setNeedle(needle);
    filterTree(options, needle);
  };

  const filterTree = (options: QuickAddOption[], needle: string) => {
    if (needle === "") {
      setTree(InputOptionsBuilder.createTreeViewForQuickAdd(options));
      return;
    }

    const haystack = options.map((option) => [option.option.value]).flat();

    const result = filter(haystack, needle);
    const filteredOptions = options.filter((option) => result.includes(option.option.value));

    if (filteredOptions.length === 0) {
      setNoResults(true);
      return;
    }

    setTree(InputOptionsBuilder.createTreeViewForQuickAdd(filteredOptions));
    expand();
  };

  const handleOpenChange = (_e: TreeOpenChangeEvent, data: TreeOpenChangeData) => {
    const { value, open } = data;

    if (open) {
      setOpenItems((prev) => {
        const set = new Set(prev);
        set.add(value);
        return set;
      });
    } else {
      setOpenItems((prev) => {
        const set = new Set(prev);
        set.delete(value);
        return set;
      });
    }
  };

  const expand = () => {
    const values = getValues();
    const result = new Set<TreeItemValue>(values);
    setOpenItems(result);
  };

  const collapse = () => {
    setOpenItems([]);
  };

  const getValues = () => {
    return inputOptions.quickAddOptions.flatMap((option) => {
      const variable = option.option.variable;

      // TODO: fix typescript type error. Remove @ts-ignore comment to see the error
      // @ts-ignore
      return variable.fullName.split(".");
    });
  };

  const onChange = () => {
    const current = !isChecked;
    setIsChecked(current);

    if (current) {
      setTree(InputOptionsBuilder.createTreeViewForQuickAdd(loopRelatedQuickAddOptions(inputOptions.quickAddOptions)));
      expand();
    } else {
      setTree(InputOptionsBuilder.createTreeViewForQuickAdd(inputOptions.quickAddOptions));
      collapse();
    }
  };

  const loopRelatedQuickAddOptions = (options: QuickAddOption[]) => {
    // TODO: fix typescript type error. Remove @ts-ignore comment to see the error
    // @ts-ignore
    return options.filter((option) => option.option.variable.isLoopVariable());
  };

  if (!tree) return null;

  return (
    <div className={styles.root}>
      <Input value={needle} onChange={onFilter} appearance="outline" placeholder="Filter by keyword" />
      {loopRelatedQuickAddOptions(inputOptions.quickAddOptions).length > 0 && (
        <Checkbox label="Show fields related to your loop context" checked={isChecked} onChange={onChange} />
      )}
      {!noResults && (
        <div className={styles.expandCollapse}>
          <Button size="small" appearance="subtle" onClick={expand}>
            Expand all
          </Button>
          <Button size="small" appearance="subtle" onClick={collapse}>
            Collapse all
          </Button>
        </div>
      )}
      {noResults ? (
        <div className={styles.noResults}>No results found</div>
      ) : (
        <Tree aria-label="walter tree view of variables" onOpenChange={handleOpenChange} defaultOpenItems={[]}>
          <CustomTree node={tree} openItems={openItems} showFieldActions={true} />
        </Tree>
      )}
    </div>
  );
};
