import { WalterUser, WalterWorkspace } from "@src/lib/auth/OAuthService";
import { defaultStorage } from "@src/lib/settings/defaultStorage";

function findAccessibleWorkspace(workspaceId: number | undefined, accessibleWorkspaceOptions: WalterWorkspace[]) {
  if (!workspaceId) return null;

  return accessibleWorkspaceOptions.find((workspace) => workspace.id === workspaceId);
}

/**
 * In React components get currentWorkspace from the React context: const { currentWorkspace } = useContext(WalterContext);
 * In cases where you cannot use context, you can use this function to get the current workspace.
 */
export function getCurrentWorkspace(walterUser: WalterUser, handleErrors: () => void) {
  if (!walterUser) return null;

  let currentWorkspace: WalterWorkspace | null = defaultStorage.get<WalterWorkspace>("currentWorkspace") ?? null;

  if (currentWorkspace) {
    // if the current workspace is already set, we should check if the user still has access to it.
    currentWorkspace = findAccessibleWorkspace(currentWorkspace.id, walterUser.workspaceOptions) ?? null;
  } else if (walterUser.recentWorkspaceId) {
    currentWorkspace = findAccessibleWorkspace(walterUser.recentWorkspaceId, walterUser.workspaceOptions) ?? null;
  }

  if (!currentWorkspace) {
    const fallbackWorkspaceId = walterUser.workspaceOptions[0]?.id;

    // if the user has no recent workspace, we should try to set the current workspace to the first workspace they have access to.
    currentWorkspace = findAccessibleWorkspace(fallbackWorkspaceId, walterUser.workspaceOptions) ?? null;
  }

  if (!currentWorkspace) {
    // If the user has no workspaces, we need to set the current workspace to null
    // a user should rarely hit this situation but if they do, we should show an error.
    // catching a user not having workspaces is also done on auth, however, it is possible that they are removed from having
    // access to any workspaces via having their access to legal entities revoked.
    handleErrors();
  }

  return currentWorkspace;
}
