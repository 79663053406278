import { OperationContext } from "../OperationContext";
import { Operation } from "./Operation";

export class ContentControlDelete implements Operation<void> {
  public id: number;
  keepContents: boolean;

  constructor({ id, keepContents }: { id: number; keepContents?: boolean }) {
    if (keepContents === undefined) {
      keepContents = false;
    }

    this.id = id;
    this.keepContents = keepContents;
  }

  // TODO: fix typescript type error. Remove @ts-ignore comment to see the error
  // @ts-ignore
  merge(other: Operation<unknown>) {
    if (other instanceof ContentControlDelete && other.id === this.id) {
      return other;
    }
  }

  public async commit(context: OperationContext) {
    const contentControl = context.getContentControl(this.id);
    const range = contentControl.getRange();

    contentControl.set({ cannotDelete: false, cannotEdit: false });
    contentControl.delete(this.keepContents);

    // Note: the following line is necessary to remove the highlight color from the content control.
    // @ts-ignore-next-line
    range.font.highlightColor = null;
  }
}
