import { Variable } from "..";
import { SignTabParser, VariableParser, type Property } from ".";
import { JSONSchema9 } from "../../types/jsonSchema";

export class LoopVariableParser extends VariableParser {
  public buildWithoutRoot(schema: JSONSchema9): Variable[] {
    return this.buildFromObjectSchema(schema).filter((variable) => variable.isLoopVariable());
  }

  protected handleObjectSchema(property: Property): Variable[] {
    const { name, schema } = property;
    const { description, title, type, deprecated, format } = schema;

    if (SignTabParser.isSignTab({ name, schema })) {
      return [];
    }

    return this.buildFromObjectSchema(schema).map(
      // TODO: fix typescript type error. Remove @ts-ignore comment to see the error
      // @ts-ignore
      (child) => new Variable(name, title, description, type as string, child, undefined, deprecated, format),
    );
  }

  protected handleArraySchema(property: Property): Variable[] {
    const { name, schema } = property;
    const { description, title, type, deprecated, format } = schema;
    const items = schema.items as JSONSchema9;

    return this.buildFromObjectSchema(items).map((childVariable) => {
      const loopMarker = new Variable(
        "[]",
        // TODO: fix typescript type error. Remove @ts-ignore comment to see the error
        // @ts-ignore
        items.title,
        items.description,
        items.type as string,
        childVariable,
        undefined,
        items.deprecated,
        items.format,
      );
      // TODO: fix typescript type error. Remove @ts-ignore comment to see the error
      // @ts-ignore
      return new Variable(name, title, description, type as string, loopMarker, undefined, deprecated, format);
    });
  }
}
