import { JSONSchema9, JSONSchema9TypeName } from "../types/jsonSchema";
import { DynamicElement } from "../liquidx/internal";
import { SchemaNode, SchemaContext, Field } from "@src/lib/schemas";

export const FIELDS_CHANGED_EVENT = "word-add-in-fields-changed";
export const PRIMITIVES = ["string", "number", "integer", "boolean"];

export interface Option<T extends string = string> {
  label: string;
  value: T;
}

interface Filters {
  type?: JSONSchema9TypeName | RegExp;
  ref?: string | RegExp;
  path?: string | RegExp;
  format?: string | RegExp;
  parent?: string;
  includeDeprecated?: boolean;
  includeHidden?: boolean;
}

export class SchemaStore {
  static deriveKey(name: string): string {
    return name
      .replace(/^[^a-zA-Z]/, "_") // replace first non-alphabetic character with underscore
      .replace(/[^a-zA-Z0-9]/g, "_") // replace non-alphanumeric characters with underscore
      .replace(/_+(?=\d)|^_+|_+$/g, "") // remove underscore before a number, leading and trailing underscores
      .replace(/_+/g, "_") // replace multiple underscores with a single one
      .toLowerCase(); // convert to lowercase
  }

  /**
   * The underlying data for this schema.
   */
  public schema: JSONSchema9;
  /**
   * A checksum of the schema object, which is updated whenever save() is called.
   */
  public schemaChecksum: string = "";
  /**
   * A list of onChange callbacks
   */
  private onChangeListeners: Set<(_: SchemaStore) => void> = new Set();

  constructor(schema: JSONSchema9 = { type: "object", properties: {} }) {
    this.schema = schema;
  }

  /**
   * A map of all nodes in the schema, indexed by their path.
   * This allows reuse of nodes when traversing the schema, which is important for performance reasons, as instantiating a node requires us to resolve its schema.
   */
  #nodes: Record<string, SchemaNode> = {};

  get root(): SchemaNode {
    return this.findNode("");
  }

  findNode(path: string): SchemaNode {
    if (!this.#nodes[path]) {
      this.#nodes[path] = new SchemaNode(path, this);
    }

    return this.#nodes[path];
  }

  get fields(): Field[] {
    return this.fieldKeys.map((key) => new Field(key, this));
  }

  get fieldKeys(): string[] {
    return Object.keys(this.schema.properties ?? {});
  }

  get parent() {
    return this.root.parent;
  }
  get children() {
    return this.root.children;
  }
  get ancestors() {
    return this.root.ancestors;
  }

  get descendants() {
    return this.root.descendants;
  }

  private fetchCache = new Map<string, unknown>();

  /**
   * Cache a value for a given key. If the value is not already cached, it will be calculated.
   * The cache is cleared when the schema checksum changes.
   *
   * @param key any object (it is serialized to JSON and then checksummed)
   * @param callback
   * @returns the callback return value
   */
  fetch<T>(key: unknown, callback: (store: SchemaStore) => T): T {
    const keyChecksum = this.generateChecksum(key);

    if (!this.fetchCache.has(keyChecksum)) {
      this.fetchCache.set(keyChecksum, callback(this));
    }

    return this.fetchCache.get(keyChecksum) as T;
  }

  addEventListener(type: "change", callback: (_: SchemaStore) => void): void {
    if (type === "change") {
      this.onChangeListeners.add(callback);
    } else {
      throw new Error(`Unsupported event type: ${type}`);
    }
  }

  dispatchEvent(type: "change"): boolean {
    if (type === "change") {
      for (const listener of this.onChangeListeners) {
        listener(this);
      }

      return true;
    } else {
      throw new Error(`Unsupported event type: ${type}`);
    }
  }

  removeEventListener(type: "change", callback: (_: SchemaStore) => void): void {
    if (type === "change") {
      this.onChangeListeners.delete(callback);
    } else {
      throw new Error(`Unsupported event type: ${type}`);
    }
  }

  get isOnChangeRegistered() {
    return this.onChangeListeners.size > 0;
  }

  resolveSchema(schema: JSONSchema9): JSONSchema9 {
    return this.fetch(schema, () => SchemaContext.resolveSchema(schema, this.schema.definitions));
  }

  query(filters?: Filters) {
    const { type, ref, parent, path, format, includeDeprecated, includeHidden } = filters ?? {};

    return this.descendants.filter((node) => {
      const matchesType = type === undefined || (!Array.isArray(node.type) && node.type?.match(type));
      const matchesRef = ref === undefined || node.ref?.match(ref);
      const matchesFormat = format === undefined || node.format?.match(format);
      const matchesPath = path === undefined || node.path.match(path);
      const matchesParent = parent === undefined || node.path.startsWith(parent);
      const matchesDeprecated = includeDeprecated || !node.isDeprecated;
      const matchesHidden = includeHidden || !node.isHidden;

      return (
        matchesType && matchesRef && matchesFormat && matchesPath && matchesParent && matchesDeprecated && matchesHidden
      );
    });
  }

  queryOptions(filters?: Filters) {
    return this.query(filters).map((node) => ({ label: node.fullTitle, value: node.path }));
  }

  /**
   * Return the JSON9Schema for a given path. This supports nested properties, including array properties.
   * This resolves references when necessary for traversal. For example, `company.stakeholder.name` will
   * resolve `company` and `stakeholder` (if they include a $ref) but not `name`.
   *
   * @example
   * SchemaContext.findSchema("company.stakeholder.name", schema)
   * SchemaContext.findSchema("company.shareholders[].name", schema)
   *
   * @param path
   * @returns JSONSchema9 or null if not found or schema is unsupported type (boolean or tuple validations)
   */
  findSchema(path: string) {
    return SchemaContext.findSchema(path, this.schema);
  }

  addField(props: { field: Field } | { node: SchemaNode } | { key: string; schema: JSONSchema9 }) {
    this.schema.properties ??= {};

    let key: string | null = null;
    let schema: JSONSchema9 | null = null;

    if ("field" in props) {
      key = props.field.key;
      schema = props.field.node.schema;
    } else if ("node" in props) {
      key = props.node.key;
      schema = props.node.schema;
    } else if ("key" in props) {
      key = props.key;
      schema = props.schema;
    }

    if (key === null || schema === null) {
      throw new Error("Must provide a field, node, or key/schema pair to add a field");
    }

    this.schema.properties[key] = schema;
  }

  findField(key: string): Field | undefined {
    return this.find(key)?.field;
  }

  find(path: string): SchemaNode | undefined {
    return SchemaNode.find(path, this);
  }

  // TODO: Move this to the add field react side
  isLabelAvailable(label: string) {
    const fieldWithSameKey = this.findField(SchemaStore.deriveKey(label));

    if (fieldWithSameKey) {
      throw new Error(
        `"${label}" conflicts with another field, "${fieldWithSameKey.label}"—please choose a different name.`,
      );
    }
  }

  /**
   * @param keyToMove
   * @param otherFieldKey
   * @param offset -1 (move before) or 1 (move after)
   */
  async move(keyToMove: string, otherFieldKey: string, offset: number) {
    const keys = this.fieldKeys;
    const index = keys.indexOf(keyToMove);
    const otherIndex = keys.indexOf(otherFieldKey);

    if (index === -1 || otherIndex === -1) return;

    // Remove keyToMove from the list
    keys.splice(index, 1);
    // Adjust otherIndex to account for the removed item (only if otherFieldKey appears after keyToMove)
    const adjustedOtherIndex = otherIndex > index ? otherIndex - 1 : otherIndex;
    // Calculate the new index for keyToMove
    const newIndex = offset === -1 ? adjustedOtherIndex : adjustedOtherIndex + 1;
    // Insert keyToMove at new index
    keys.splice(newIndex, 0, keyToMove);

    // Create a map with the new order
    const newProperties: JSONSchema9["properties"] = {};
    for (const key of keys) {
      const propertySchema = this.schema.properties?.[key];

      if (propertySchema) {
        newProperties[key] = propertySchema;
      }
    }

    // Update the fields and save
    this.schema.properties = newProperties;
    await this.save();
  }

  async save(newSchema?: JSONSchema9) {
    if (newSchema) this.schema = newSchema;
    this.updateSchemaChecksum();
    this.dispatchEvent("change");
  }

  updateSchemaChecksum() {
    const newChecksum = this.generateChecksum(this.schema);

    if (newChecksum !== this.schemaChecksum) {
      this.schemaChecksum = newChecksum;
      this.#nodes = {};
      this.fetchCache.clear();
    }
  }

  async clear() {
    this.schema.properties = {};
    await this.save();
  }

  async deleteField(key: string) {
    if (delete this.schema.properties?.[key]) {
      await this.save();
      await DynamicElement.revalidate();
    }
  }

  isPrimitive(type: string): boolean {
    return PRIMITIVES.includes(type);
  }

  /**
   * Generates a checksum for the schema object using the DJB2 hash algorithm.
   * The checksum is a hexadecimal string representation of a 32-bit integer.
   *
   * We use this because it is fast, simple, and deterministic.
   *
   * NOTE: It is not a secure hash function.
   *
   * @private
   * @param {unknown} contents The schema object to generate a checksum for.
   * @returns {string} The checksum of the schema.
   */
  private generateChecksum(contents: unknown): string {
    // Convert the schema object to a JSON string
    const jsonString = JSON.stringify(contents);

    // Initialize hash value to 5381 (a magic number found to work well in DJB2)
    let hash = 5381;
    let i = jsonString.length;

    // Iterate over each character in the JSON string
    while (i) {
      // Multiply hash by 33 and XOR with the character code of the current character
      hash = (hash * 33) ^ jsonString.charCodeAt(--i);
    }

    // Convert the hash to an unsigned 32-bit integer and then to a hexadecimal string
    return (hash >>> 0).toString(16);
  }
}
