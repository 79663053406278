import { OperationContext } from "../OperationContext";
import { Operation } from "./Operation";

export class ReplacementDocumentContents implements Operation<void> {
  constructor(private base64Document: string) {}

  async commit(context: OperationContext): Promise<void> {
    context.document.insertFileFromBase64(this.base64Document, "Replace", {
      importTheme: false,
      importStyles: false,
      importParagraphSpacing: false,
      importPageColor: false,
      importChangeTrackingMode: false,
      importCustomProperties: false,
      importCustomXmlParts: false,
      importDifferentOddEvenPages: false,
    });

    // Manually sync this to avoid batching it with any other changes.
    // Because this replaces the entire document, it may fail if paired with other operations.
    try {
      await context.wordContext.sync();
    } catch (error) {
      console.error(
        "Failed to replace document with replacement, see base64-encoded document below:",
        this.base64Document,
      );
      throw error;
    }
  }

  merge(_: Operation<unknown>) {
    return undefined;
  }
}
