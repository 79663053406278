import { FC } from "react";
import { ConditionGroup } from "./ConditionGroup";
import { SchemaNode } from "@src/lib/schemas";
import { ConditionBuilderContext } from "./useConditionBuilder";
import { ConditionGroupData, emptyCondition } from "./ConditionCodeGen";

export const ConditionBuilder: FC<{
  group: ConditionGroupData;
  nodes: SchemaNode[];
  onChange?: (_: ConditionGroupData) => void;
}> = ({ group, nodes, onChange }) => {
  function updateGroup(partialData: Partial<ConditionGroupData>) {
    const updatedGroup = { ...group, ...partialData };

    if (updatedGroup.conditions.length === 0) {
      updatedGroup.conditions.push(emptyCondition());
    }

    onChange?.(updatedGroup);
  }

  return (
    <ConditionBuilderContext.Provider value={{ nodes, data: group, update: updateGroup }}>
      <ConditionGroup group={group} onChange={updateGroup} isFirst />
    </ConditionBuilderContext.Provider>
  );
};
