import { ContentControlType, InsertLocation } from "@src/lib/liquidx";
import { DynamicElement, LiquidElement, findValidInputOption } from "@src/lib/liquidx/internal";
import { QuickAddOption, Variable } from "@src/lib/walter";
import { LiquidTag } from "./ParsedTag";

export class VariableElement extends LiquidElement {
  static supports({ type }: LiquidTag) {
    return type === "variable";
  }

  static readonly highlight = "yellow";
  static readonly highlightSelected = "edbbe7";

  constructor();
  constructor(tag?: LiquidTag, id?: number | null, title?: string, parentId?: number) {
    tag ??= { valid: true, type: ContentControlType.VARIABLE, data: {} };
    super(tag, id, title, parentId);
    this.font = { highlightColor: VariableElement.highlight };
  }

  get color() {
    return this.hasErrors ? "red" : DynamicElement.defaultColor;
  }

  get appearance(): Word.ContentControlAppearance {
    return this.hasErrors
      ? ("Tags" as typeof Word.ContentControlAppearance.tags)
      : ("BoundingBox" as typeof Word.ContentControlAppearance.boundingBox);
  }

  public async resetPlaceholder() {
    await this.save({ isSystemUpdate: false });
  }

  get innerText() {
    return `[${this.labelOrTitle}]`;
  }

  public applyVariable(variable: Variable) {
    const defaultDateFormat = "'%B %e, %Y'";

    if (variable.leafFormat === "date" && !variable.leafFilters.has("date")) {
      variable.addFilter("date", defaultDateFormat);
    }

    this.data = variable.tagFormat;
  }

  get title() {
    const title = this.variable?.title?.trim();
    return title ? title : this.variable?.fullName ?? "Variable";
  }

  public applyQuickAdd(quickAddOption: QuickAddOption, location: InsertLocation) {
    this.insertLocation = location;
    if (quickAddOption?.option?.variable) {
      this.applyVariable(quickAddOption.option.variable);
    }
  }

  validate() {
    super.validate();

    if (this.isNew) {
      return;
    }

    const inputOption = findValidInputOption(this.variable);
    if (!inputOption) {
      this.errors.push(`"${this.variable.fullName}" can't be found—edit or delete this variable`);
    } else if (inputOption.variable?.isDeprecated) {
      this.errors.push(inputOption.variable.deprecatedMessage);
    }
  }
}
