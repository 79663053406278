import { useContext, useEffect, useRef, useState, type FunctionComponent } from "react";
import { Button, Link, Title3, Toaster, makeStyles, mergeClasses, shorthands } from "@fluentui/react-components";
import { OAuthSignInResponse, OAuthTokenResponse } from "../../lib/auth/OAuthService";
import { tokenStorage } from "../../lib/settings/tokenStorage";
import Progress from "./Progress";
import { Intro } from "./onboarding/Intro";
import { captureErrorInfo } from "@src/taskpane/helpers/errorHandler";
import useToasts from "@src/taskpane/hooks/useToasts";
import { WalterContext } from "../providers/walter";
import { WALTER_HOST, WORD_OAUTH_CLIENT_ID } from "@src/lib/env-vars";

export const deviceAuthorizationUrl = `${WALTER_HOST}/device_authorizations/${WORD_OAUTH_CLIENT_ID}`;
export const deviceAutoSignInUrl = `${WALTER_HOST}/device_authorizations/${WORD_OAUTH_CLIENT_ID}/auto`;

export const useStyles = makeStyles({
  inner: {
    display: "flex",
    minHeight: "100vh", // Ensure it takes the full viewport height
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center", // Use alignItems instead of alignContent for single-line centering
    ...shorthands.gap("12px"),
  },
  textCenter: {
    textAlign: "center",
    ...shorthands.padding("12px"),
  },
});

export const LoginPage: FunctionComponent = ({ children }) => {
  const styles = useStyles();
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const [deviceAuthSignInUrl, setDeviceAuthSignInUrl] = useState("");
  const [deviceCodeExpiryDurationMS, setDeviceCodeExpiryDurationMS] = useState<number>();
  const [error, setError] = useState<string>("");
  const { toasterId, setIsLoggedIn } = useContext(WalterContext);
  const { showErrors } = useToasts();

  function getParentDomain() {
    return window.location != window.parent.location ? document.referrer : document.location.href;
  }

  useEffect(() => {
    if (!deviceCodeExpiryDurationMS) {
      return;
    }
    // device codes expire so we need to request a new one every time the previous one expires
    const intervalId = window.setInterval(() => {
      iframeRef.current?.contentWindow?.postMessage("generateDeviceCodeUrl", WALTER_HOST);
    }, deviceCodeExpiryDurationMS);

    return () => {
      clearInterval(intervalId);
    };
  }, [deviceCodeExpiryDurationMS]);

  useEffect(() => {
    const timeoutId = window.setTimeout(() => {
      const parentDomain = getParentDomain();

      setError("Something unexpected happened and we've been notified.");
      captureErrorInfo(new Error("Sign in page did not load after 10 seconds."), {
        extra: { parentDomain },
      });
    }, 10000);

    function handlePostMessage(event: MessageEvent<OAuthSignInResponse | OAuthTokenResponse | "SIGN_IN_PAGE_LOADED">) {
      if (event.origin !== WALTER_HOST) return;

      if (event.data === "SIGN_IN_PAGE_LOADED") {
        // once the page loaded message is received cancel the timeout.
        window.clearTimeout(timeoutId);
      } else {
        handleAuthMessage(event as MessageEvent<OAuthSignInResponse | OAuthTokenResponse>);
      }
    }

    function handleAuthMessage(event: MessageEvent<OAuthSignInResponse | OAuthTokenResponse>) {
      if (event.origin !== WALTER_HOST) return;

      if ("url" in event.data) {
        setDeviceAuthSignInUrl(event.data.url);
        setDeviceCodeExpiryDurationMS(event.data.expiresInSeconds * 1000);
      } else {
        // an empty tokenData means that some error happened when trying to create the token.
        if (event.data.tokenData === "") {
          showErrors({
            title: "Sign in failed",
            messages: ["Please try again."],
          });

          return;
        }

        if (event.data.tokenData?.accessToken) {
          tokenStorage.add("walter", event.data.tokenData);

          if (!event.data.defaultWorkspaceId) {
            showErrors({
              title: "Unable to continue without a Walter workspace.",
              messages: ["Please add a workspace for your user in Walter."],
            });
            captureErrorInfo(new Error("User without workspace attempted to log in"), { level: "info" });

            return;
          }

          setIsLoggedIn(true);
        }
      }
    }

    window.addEventListener("message", handlePostMessage);

    return () => {
      window.removeEventListener("message", handlePostMessage);
      clearTimeout(timeoutId);
    };
  }, [iframeRef, setIsLoggedIn]);

  // Request a device code
  function handleFrameLoad() {
    iframeRef.current?.contentWindow?.postMessage("generateDeviceCodeUrl", WALTER_HOST);
  }

  if (error) {
    return (
      <section className={mergeClasses(styles.inner, styles.textCenter)}>
        <Title3>Failed to load</Title3>

        <p>
          {error} Try{" "}
          <Link href="" type="button" onClick={() => window.location.reload()}>
            refreshing the page
          </Link>{" "}
          to resolve the error.
        </p>
        <p>
          If this issue persists please reach out to us at{" "}
          <Link rel="noopener" href="https://support.getwalter.com" target="_blank">
            https://support.getwalter.com
          </Link>
        </p>
      </section>
    );
  }

  return (
    <>
      <Toaster toasterId={toasterId} position="top" pauseOnHover pauseOnWindowBlur />
      {children}
      <iframe ref={iframeRef} src={deviceAutoSignInUrl} onLoad={handleFrameLoad} hidden />
      <div className={styles.inner}>
        {!deviceAuthSignInUrl && <Progress message="Initializing..." />}
        {deviceAuthSignInUrl && (
          <>
            <Title3>Welcome to Walter</Title3>
            <Intro alwaysShow />
            <Button as="a" href={deviceAuthSignInUrl} size="large" appearance="primary" target="_blank">
              Sign in to Walter
            </Button>
          </>
        )}
      </div>
    </>
  );
};
