import { FC } from "react";
import { IndentedTree } from "./IndentedTree";
import { SchemaNode } from "@src/lib/schemas";
import { PropertyTreeItem } from "./FieldsPane";

export const NodesTree: FC<{ nodes: SchemaNode[] }> = ({ nodes }) => {
  // TODO: Add empty state
  if (!nodes) return null;

  return (
    <IndentedTree aria-label="Object properties">
      {nodes.map((property) => (
        <PropertyTreeItem key={property.path} node={property} />
      ))}
    </IndentedTree>
  );
};
