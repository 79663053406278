import { FC, useState } from "react";
import { Input, Label, Text, Slider, useId, Card, tokens, makeStyles, shorthands } from "@fluentui/react-components";

function parseIntWithFallback(input: string | number | undefined | null, fallback = 0) {
  if (!input) return fallback;
  if (typeof input === "number") return input;

  const parsed = parseInt(input, 10);
  return isNaN(parsed) ? fallback : parsed;
}

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    ...shorthands.gap("2px"),
  },
});

export const WidthSlider: FC<{
  value: string | number | undefined;
  defaultValue?: number;
  onChange: (value: number) => void;
}> = ({ value, onChange, defaultValue = 100 }) => {
  const styles = useStyles();
  function parseWidth(input: string | number | undefined | null) {
    return parseIntWithFallback(input, defaultValue);
  }

  const [usingSlider, setUsingSlider] = useState(false);
  const widthId = useId("width");
  value = parseWidth(value);

  return (
    <div className={styles.root}>
      <Label htmlFor={widthId} style={{ display: "flex", justifyContent: "space-between", flexDirection: "row" }}>
        Width
        <Input
          style={{ width: "70px" }}
          size="small"
          appearance="underline"
          value={value?.toString()}
          onChange={(_e, { value: width }) => onChange(parseWidth(width))}
          contentAfter={<Text size={200}>px</Text>}
        />
      </Label>
      <Slider
        aria-valuetext={`Width is ${value}`}
        value={value}
        min={20}
        max={300}
        onChange={(_e, { value: width }) => onChange(parseWidth(width))}
        onMouseDown={() => setUsingSlider(true)}
        onMouseUp={() => setUsingSlider(false)}
        id={widthId}
      />
      {!usingSlider && (
        <Text size={200} style={{ paddingBottom: "10px" }}>
          How much blank space to leave for the value
        </Text>
      )}
      {usingSlider && <PlaceholderBox width={value} />}
    </div>
  );
};

const PlaceholderBox: FC<{ width: number }> = ({ width }) => {
  // Inline style object
  const style = {
    width: `${width}px`,
    height: "12px",
    border: "1px dashed #ccc",
    borderRadius: "4px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  let range: { color: string; label: string } = { label: `${width}px`, color: "#ccc" };

  if (width < 30) {
    range.label = "";
  } else if (width >= 40 && width <= 50) {
    range = {
      color: tokens.colorNeutralForeground2BrandSelected,
      label: "Initials",
    };
  } else if (width >= 110 && width <= 130) {
    range = {
      color: tokens.colorNeutralForeground2BrandSelected,
      label: "Date signed",
    };
  } else if (width >= 131 && width <= 150) {
    range = {
      color: tokens.colorNeutralForeground2BrandSelected,
      label: "Signature",
    };
  }

  return (
    <Card style={{ ...style, borderColor: range.color }}>
      <Text size={100} style={{ color: range.color }}>
        {range.label}
      </Text>
    </Card>
  );
};
