import debug from "../debug";
import { WalterStore } from "../properties/WalterStore";
import { AddStyles } from "./AddStyles";
import { MigrateToCustomXml } from "./MigrateToCustomXml";
import { Migration, MigrationTypes } from "./Migration";

// Add your new migration to this list
const migrations: Migration[] = [MigrateToCustomXml, AddStyles].map((migration) => new migration());

function checkForDuplicateMigrations() {
  const migrationVersions = migrations.map((m) => m.name);
  const counts = migrationVersions.reduce((acc: Record<string, number>, version) => {
    acc[version] = (acc[version] || 0) + 1;
    return acc;
  }, {});

  const duplicateVersions = Object.keys(counts).filter((version) => counts[version] > 1);
  if (duplicateVersions.length > 0) {
    const duplicateMigrations = migrations
      .filter((m) => duplicateVersions.includes(m.name))
      .map((m) => m.constructor.name);
    throw new Error(
      [
        `Duplicate migration version detected: ${duplicateMigrations.join(", ")}`,
        "To fix this, increment the version number of one of the migrations.",
      ].join("\n\n"),
    );
  }
}

if (import.meta.env.DEV) {
  checkForDuplicateMigrations();
}

/**
 * Run all migrations that have not been applied yet and aren't deferred.
 */
export const migrate = async (type: MigrationTypes) => {
  const alreadyApplied = await WalterStore.fetch("appliedMigrations", [] as string[]);
  const newlyApplied: string[] = [];

  for (const migration of migrations) {
    // Skip if we've already run it
    if (alreadyApplied.includes(migration.name)) continue;
    // Skip if it's not the right type
    if (migration.type !== type) continue;

    debug.info(`Applying migration: ${migration.name}`);
    if (await migration.apply()) {
      newlyApplied.push(migration.name);
    } else {
      console.warn(`Failed to apply migration ${migration.name}`);
    }
  }

  if (newlyApplied.length > 0) {
    await WalterStore.set("appliedMigrations", [...alreadyApplied, ...newlyApplied]);
  }
};
