import uFuzzy from "@leeoniya/ufuzzy";

export const filter = (haystack: string[], needle: string): string[] => {
  const fuzzySearch = new uFuzzy();
  const filteredOptions: string[] = [];

  const idxs = fuzzySearch.filter(haystack, needle);

  // idxs can be null when the needle is non-searchable (has no alpha-numeric chars)
  if (idxs != null && idxs.length > 0) {
    // sort/rank only when <= 1,000 items
    const infoThresh = 1e3;

    if (idxs.length <= infoThresh) {
      const info = fuzzySearch.info(idxs, haystack, needle);

      // order is a double-indirection array (a re-order of the passed-in idxs)
      // this allows corresponding info to be grabbed directly by idx, if needed
      const order = fuzzySearch.sort(info, haystack, needle);

      // render post-filtered & ordered matches
      for (let i = 0; i < order.length; i++) {
        // using info.idx here instead of idxs because uf.info() may have
        // further reduced the initial idxs based on prefix/suffix rules
        const value = haystack[info.idx[order[i]]];
        filteredOptions.push(value);
      }
    } else {
      // render pre-filtered but unordered matches
      for (let i = 0; i < idxs.length; i++) {
        const value = haystack[idxs[i]];
        filteredOptions.push(value);
      }
    }
  }

  return filteredOptions;
};
