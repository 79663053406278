import { humanize } from "@src/taskpane/helpers/formatData";
import debug from "../debug";
import { ContextualSchemaStore, SchemaStore, documentSchemaStore } from "../schemas";
import { BaseRenderData, BaseUiData, JsTag } from "./ParsedTag";
import {
  BasicIfElement,
  BasicLoopElement,
  BasicVariableElement,
  AdvancedIfElement,
  DynamicElement,
  PluralizeElement,
  SignFieldElement,
  SignTabJsElement,
  AdvancedVariableElement,
  AliasElement,
} from "./internal";

export interface JsElementProps {
  tag: JsTag;
  id?: number | null;
  parentId?: number;
}

export interface JsElementClass {
  new (props: JsElementProps): DynamicElement;
  supports: (tag: JsTag) => boolean;
}

/**
 * Add JavaScript-based dynamic element classes to this list.
 */

export abstract class JsElement<R extends BaseRenderData, U extends BaseUiData> extends DynamicElement {
  static build({ tag, id, parentId }: JsElementProps): DynamicElement | null {
    if (!tag.valid) return null;

    const classes: JsElementClass[] = [
      BasicVariableElement,
      BasicLoopElement,
      PluralizeElement,
      SignFieldElement,
      SignTabJsElement,
      BasicIfElement,
      AdvancedIfElement,
      AdvancedVariableElement,
      AliasElement,
    ];
    for (const klass of classes) {
      if (klass.supports(tag)) {
        return new klass({ tag, id, parentId });
      }
    }

    return null;
  }

  abstract render: R;
  abstract ui: U;
  // TODO: Extract snippets from other JsElement subclasses, then uncomment this
  // abstract snippet: Snippet<R, U>;

  #schemaStore: SchemaStore | undefined;

  get hasEditableLabel(): boolean {
    return false;
  }

  get label(): string {
    return this.ui.label ?? "";
  }

  set label(value: string) {
    this.ui.label = value;
  }

  get title(): string {
    return this.label;
  }

  get type(): string {
    return [this.render.type, this.ui.type].join("/");
  }

  get innerText(): string | undefined {
    return this.labelOrTitle;
  }

  get isNew() {
    return this.id == null || this.render.code == "";
  }

  async save(props: { isSystemUpdate: boolean } = { isSystemUpdate: true }) {
    const wasNew = this.isNew;
    await super.save(props);

    // Clear the field store on create to force it to be reloaded with the correct context
    if (wasNew) this.#schemaStore = undefined;
  }

  /**
   * This returns a new field store with loop fields loaded based on this element's context.
   */
  buildContextualFieldStore(): SchemaStore {
    const schema = BasicLoopElement.schemaFor(this.isNew ? DynamicElement.focused : this);
    return new ContextualSchemaStore(schema, documentSchemaStore);
  }

  get schemaStore(): SchemaStore {
    if (this.#schemaStore === undefined) {
      this.#schemaStore = this.buildContextualFieldStore();
    }

    return this.#schemaStore;
  }

  validate(): void {
    super.validate();
    // Force the field store to reload
    this.#schemaStore = undefined;

    if (this.parent instanceof JsElement && this.parent.render.type === "variable") {
      this.errors.push("Cannot be nested inside a variable.");
    }
  }

  buildTag(): string {
    return `walter:${JSON.stringify({ render: this.render, ui: this.ui })}`;
  }

  inspect() {
    debug.log({
      id: this.id,
      parentId: this.parentId,
      parent: this.parent?.inspect(),
      ui: this.ui,
      render: this.render,
      title: this.title,
    });
  }

  get focusedTitle() {
    return `${humanize(this.render.type)}: ${this.label}`;
  }

  get appearance(): Word.ContentControlAppearance {
    return this.hasErrors
      ? ("Tags" as typeof Word.ContentControlAppearance.tags)
      : ("BoundingBox" as typeof Word.ContentControlAppearance.boundingBox);
  }

  get color() {
    return this.hasErrors ? "red" : DynamicElement.defaultColor;
  }

  /**
   * Convert a JavaScript object into a string for use in a JavaScript code snippet.
   * This removes undefined values before serializing to JSON to noise in the code.
   *
   * @param object
   * @returns JavaScript code string
   */
  toJavaScript(object: Record<string, unknown>): string {
    // Copy object to avoid modifying the original
    const props = { ...object };

    // Remove null or undefined values
    Object.keys(props).forEach((key) => {
      if (props[key] === null || props[key] === undefined) {
        delete props[key];
      }
    });

    // Serialize object to JSON
    return JSON.stringify(props);
  }
}
