import { VFC, useRef, useEffect, useState } from "react";
import { captureMessage } from "@sentry/browser";
import * as monaco from "monaco-editor/esm/vs/editor/editor.api";
import { Field } from "@fluentui/react-components";
import { debounce } from "@src/lib/debounce";
import styles from "./CodeEditor.module.css";
import { getFieldsType } from "./lib";
// importing worker code using query suffix. https://vitejs.dev/guide/assets.html#importing-script-as-a-worker
import CodeCheckWorker from "./typeCheckWorker.ts?worker";
import "./codeEditorWorker";
import { useFields } from "@src/taskpane/hooks/useFields";
import { liquidxTypes, templateObjects } from "@src/lib/monacoExtraLibs";

export type CodeEditorOnChange = ({ code, isValid }: { code: string; isValid: boolean }) => void;

type CodeEditorProps = {
  /**
   * A function that will be called when the code editor value changes.
   */
  onChange?: CodeEditorOnChange;

  /**
   * The code that will be displayed in the code editor.
   */
  code: string;

  /**
   * The label text that appears above the code editor field.
   */
  label?: string;

  /**
   * A function that will be called to validate the code. If the code is invalid, the function should return an object with `isValid` set to `false` and an `error` message.
   * If the code is valid, the function should return an object with `isValid` set to `true` and an empty `error` message.
   */
  validator?: (code: string) => { isValid: boolean; error: string };

  /**
   * The flag to set the editor to focus on load.
   */
  autoFocus?: boolean;
};

export const CodeEditor: VFC<CodeEditorProps> = ({ onChange, code, label, validator, autoFocus }) => {
  const editor = useRef<monaco.editor.IStandaloneCodeEditor | null>(null);
  const monacoEl = useRef(null);
  const workerRef = useRef<Worker>();
  const [validationError, setValidationError] = useState("");
  const isDarkModeQuery = window.matchMedia("(prefers-color-scheme: dark)");
  const subscriptionRef = useRef<monaco.IDisposable>();
  const onDidChangeMarkersRef = useRef<monaco.IDisposable>();
  const fieldsDefinition = useRef("");
  const { fields } = useFields();

  function placeCursorAtCodeEnd() {
    if (!editor.current) return;

    if (autoFocus) editor.current.focus();
    const model = editor.current.getModel();

    if (model) {
      const lineCount = model.getLineCount();
      editor.current.setPosition({ lineNumber: lineCount, column: model?.getLineMaxColumn(lineCount) });
    }
  }

  const typeCheckCode = debounce(() => {
    if (!editor.current) return;

    const model = editor.current.getModel();
    const code = model?.getValue();

    // Send the code to the worker
    workerRef.current?.postMessage({ code, globalFieldDefinition: fieldsDefinition.current });
  }, 50);

  const markErrors = debounce((monacoURIs: monaco.Uri[]) => {
    const modelUri = monacoURIs.at(0);

    if (modelUri !== undefined) {
      const markers = monaco.editor.getModelMarkers({ resource: modelUri });

      for (const marker of markers) {
        if (marker.severity === monaco.MarkerSeverity.Error) {
          onChange?.({ code: "", isValid: false });
          setValidationError(marker.message);
          break;
        }
      }
    }
  }, 50);

  useEffect(() => {
    fieldsDefinition.current = `const fields: ${getFieldsType(fields)}`;
  }, [fields]);

  useEffect(() => {
    function handleTypeCheckerResponse(event: {
      data: { status: string; error?: string; type?: string; code?: string };
    }) {
      if (event.data.status === "error" && event.data.error) {
        captureMessage(`Error in code editor type checker worker: ${event.data.error}`);
        return;
      }

      if (!event.data.code || !event.data.type) {
        captureMessage(`Error in code editor type checker worker: Code: ${event.data.code} Type: ${event.data.type}`);
        return;
      }

      if (validator) {
        const { isValid, error } = validator(event.data.type);

        onChange?.({ code: event.data.code, isValid });
        setValidationError(error);
        return;
      }

      onChange?.({ code: event.data.code, isValid: true });
      setValidationError("");
    }

    if (monacoEl && monacoEl.current && !editor.current) {
      workerRef.current = new CodeCheckWorker();
      workerRef.current.onmessage = handleTypeCheckerResponse;

      monaco.languages.typescript.typescriptDefaults.addExtraLib(liquidxTypes, "liquidx.d.ts");
      monaco.languages.typescript.typescriptDefaults.addExtraLib(fieldsDefinition.current, "fields.ts");
      monaco.languages.typescript.typescriptDefaults.addExtraLib(templateObjects, "template_objects.d.ts");

      monaco.languages.typescript.typescriptDefaults.setCompilerOptions({
        ...monaco.languages.typescript.typescriptDefaults.getCompilerOptions(),
        checkJs: true,
        allowJs: true,
        strict: true,
      });

      editor.current = monaco.editor.create(monacoEl.current!, {
        value: code,
        language: "typescript",
        theme: isDarkModeQuery.matches ? "vs-dark" : "vs-light",
        minimap: {
          enabled: false,
        },
        colorDecorators: true,
        lineNumbers: "off",
        lightbulb: {
          enabled: monaco.editor.ShowLightbulbIconMode.Off,
        },
        suggest: {
          // Only show some things in intellisense suggestions to keep people on track and not to get confused.
          showEvents: true,
          showFields: true,
          showFunctions: true,
          showKeywords: true,
          showMethods: true,
          showProperties: true,
          showVariables: true,
          showInlineDetails: true,
          showConstructors: false,
          showDeprecated: false,
          showClasses: false,
          showStructs: false,
          showInterfaces: false,
          showModules: false,
          showOperators: true,
          showUnits: false,
          showValues: false,
          showConstants: false,
          showEnums: false,
          showEnumMembers: false,
          showWords: false,
          showColors: false,
          showFiles: false,
          showReferences: true,
          showFolders: false,
          showTypeParameters: false,
          showIssues: true,
          showUsers: false,
          showSnippets: false,
        },
        contextmenu: false,
        automaticLayout: true,
        lineNumbersMinChars: 3,
        padding: {
          top: 24,
          bottom: 12,
        },
      });

      if (editor.current) {
        typeCheckCode();
      }

      subscriptionRef.current = editor.current.onDidChangeModelContent((_event) => {
        if (!editor.current) return;

        typeCheckCode();
      });

      placeCursorAtCodeEnd();

      onDidChangeMarkersRef.current = monaco.editor.onDidChangeMarkers(markErrors);
    }

    return () => {
      subscriptionRef.current?.dispose();
      onDidChangeMarkersRef.current?.dispose();
      editor.current?.dispose();
      editor.current = null;
      workerRef.current?.terminate();
    };
  }, []);

  useEffect(() => {
    editor.current?.updateOptions({
      theme: isDarkModeQuery.matches ? "vs-dark" : "vs-light",
    });
  }, [isDarkModeQuery.matches]);

  useEffect(() => {
    if (editor.current && editor.current.getValue() !== code) {
      editor.current.setValue(code);
    }
  }, [code]);

  return (
    <Field validationMessage={validationError} label={label}>
      <div className={styles.Editor} ref={monacoEl}></div>
    </Field>
  );
};
