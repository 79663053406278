import { Operation } from "./operations/Operation";
import { OperationWrapper } from "./OperationWrapper";

type PlatformType = keyof typeof Office.PlatformType;

export class BatchContext {
  public wordContext: Word.RequestContext;
  public waitingOperations: Set<Operation<unknown>> = new Set();
  public successfulOperations: Set<Operation<unknown>> = new Set();
  public failedOperations: Set<FailedOperation> = new Set();

  // These assigned in this.reset, which is called in the constructor
  private allOperationsWaitingOrDonePromise!: Promise<void>;
  private unpauseResolve!: () => void;
  private unpauseReject!: (error: unknown) => void;
  private operationCount: number;

  constructor(context: Word.RequestContext, operationCount: number) {
    this.wordContext = context;
    this.operationCount = operationCount;
    this.reset();
  }

  getContentControl(id: number): Word.ContentControl {
    return this.wordContext.document.contentControls.getByIdOrNullObject(id);
  }

  get document(): Word.Document {
    return this.wordContext.document;
  }

  trace(message: string) {
    this.wordContext.trace(message);
  }

  get platform(): PlatformType {
    if (typeof Office !== "undefined") {
      return Office?.context?.diagnostics?.platform as unknown as PlatformType;
    } else {
      return "Mac";
    }
  }

  /**
   * This initializes the transaction for updates.
   * It is called in the constructor and also when each update has paused or finished.
   *
   * @returns A the previous unpause resolve function
   */
  reset() {
    this.waitingOperations.clear();

    const previousUnpauseResolve = this.unpauseResolve;
    const previousUnpauseReject = this.unpauseReject;
    this.allOperationsWaitingOrDonePromise = new Promise<void>((resolve, reject) => {
      this.unpauseResolve = resolve;
      this.unpauseReject = reject;
    });

    return { resolve: previousUnpauseResolve, reject: previousUnpauseReject };
  }

  async unpause() {
    try {
      await this.wordContext.sync();
      const { resolve } = this.reset();
      resolve();
    } catch (e) {
      const { reject } = this.reset();
      reject(e);
      throw e;
    }
  }

  get isWaitingOrDone() {
    return this.waitingCounter + this.doneCounter >= this.operationCount;
  }

  get isDone() {
    return this.doneCounter >= this.operationCount;
  }

  get inProgress() {
    return !this.isDone;
  }

  get waitingCounter() {
    return this.waitingOperations.size;
  }

  get successfulCounter() {
    return this.successfulOperations.size;
  }

  get failedCounter() {
    return this.failedOperations.size;
  }

  get doneCounter() {
    return this.successfulCounter + this.failedCounter;
  }

  async sync(operation: Operation<unknown>): Promise<void> {
    this.waitingOperations.add(operation);

    if (this.isWaitingOrDone) {
      await this.unpause();
    } else {
      await this.waitUntilOperationsWaitingOrDone();
    }
  }

  async successful(operation: Operation<unknown>): Promise<void> {
    this.successfulOperations.add(operation);
    if (this.isWaitingOrDone) await this.unpause();
  }

  async failed(operation: OperationWrapper<unknown>, error: unknown) {
    this.failedOperations.add(new FailedOperation(operation, error));

    if (this.isWaitingOrDone) {
      await this.unpause().catch(() => {
        // Ignore the error (it is emitted to the failed operation listeners and also to the callsite that enqueued it)
      });
    }
  }

  waitUntilOperationsWaitingOrDone(): Promise<void> {
    return this.allOperationsWaitingOrDonePromise;
  }
}

class FailedOperation {
  constructor(public operation: OperationWrapper<unknown>, public error: unknown) {}

  reject() {
    this.operation.reject(this.error);
  }
}
