/**
 * This it NOT automatically generated from the liquidx schema
 */

import type { InputOption } from "../../walter";

export type ContentControlTypeValues =
  | "if"
  | "if-branch"
  | "else-branch"
  | "variable"
  | "signature"
  | "initials"
  | "date-signed"
  | "sign-tab-input"
  | "loop"
  | "variable-expression"
  | "conditional-expression";

export const contentControlTypeValues: ContentControlTypeValues[] = [
  "if",
  "if-branch",
  "else-branch",
  "variable",
  "signature",
  "initials",
  "date-signed",
  "sign-tab-input",
  "loop",
  "variable-expression",
];

export enum ContentControlType {
  IF = "if",
  IF_BRANCH = "if-branch",
  ELSE_BRANCH = "else-branch",
  VARIABLE = "variable",
  SIGNATURE = "signature",
  INITIALS = "initials",
  DATE_SIGNED = "date-signed",
  SIGN_TAB_INPUT = "sign-tab-input",
  LOOP = "loop",
  VARIABLE_EXPRESSION = "variable-expression",
  CONDITIONAL_EXPRESSION = "conditional-expression",
}

export type SignTabTypes = ContentControlType.SIGNATURE | ContentControlType.INITIALS | ContentControlType.DATE_SIGNED;

export type ConditionalContentControlAddProperties = {
  left: InputOption;
  operator: string;
  right?: string;
  elseBranch: boolean;
};

export enum InsertLocation {
  INLINE = "inline",
  PARENT_PARAGRAPH = "parent-paragraph",
  SELECTION = "selection",
  TABLE_CELL = "table-cell",
  TABLE_ROW = "table-row",
}

export type ContentControl = {
  id: number;
  title: string;
  tag: string;
};

/**
 * A schema that represents either a condition, compound condition, or a list of conditions.
 */
export type If = SingularCondition | CompoundCondition;
export type SingularCondition = VariableCondition | Comparison;
export type VariableData = {
  name: string;
  child?: VariableData;
  filters?: Map<string, string | undefined>;
};

export type VariableCondition = {
  type: "variable";
  negate?: boolean;
  variable: VariableData;
};

/**
 * An object containing either an 'and' or 'or' property with a list of conditions or compound conditions.
 */
export type CompoundCondition = {
  type: "compound";
  /**
   * A logical 'and' compound condition.
   */
  and?: (CompoundCondition | VariableData | Comparison)[];
  /**
   * A logical 'or' compound condition.
   */
  or?: (CompoundCondition | VariableData | Comparison)[];
};

/**
 * The comparison operator used in the conditional expression. Must be one of the specified values.
 */
export const enum ComparisonOperator {
  EQUALS = "==",
  NOT_EQUALS = "!=",
  LESS_THAN = "<",
  GREATER_THAN = ">",
  LESS_THAN_OR_EQUAL = "<=",
  GREATER_THAN_OR_EQUAL = ">=",
}

export type Operator = ComparisonOperator;

export type Comparison = {
  type: "comparison";
  left: Operand;
  operator: Operator;
  right: Operand;
};

export type Operand = string | number | VariableData;
