import { JsElement } from "@src/lib/liquidx/internal";
import { JsTag, VariableRenderData } from "./ParsedTag";
import { JsElementProps } from "./JsElement";
import { Format, FormatCurrencyProps, FormatDateProps, FormatNumberProps, FormatPercentProps } from "liquidx";
import { walterStyles } from "../styles";

interface UpdateProps {
  property: string;
  required?: boolean;
  formatter?: FormatterData;
}

export type FormatterData = DateFormatterData | NumberFormatterData | CurrencyFormatterData | PercentFormatterData;

export interface DateFormatterData {
  name: "date";
  props: FormatDateProps;
  // This stores additional detail about the format, broken
  // down by date and time and whether it is in advanced mode.
  details: {
    dateFormat?: string;
    timeFormat?: string;
    customFormat?: boolean;
    useCustomFormat?: boolean;
  };
}

export interface NumberFormatterData {
  name: "number" | "currency" | "percent";
  props: FormatNumberProps;
}

export interface CurrencyFormatterData {
  name: "currency";
  props: FormatCurrencyProps;
}

export interface PercentFormatterData {
  name: "percent";
  props: FormatPercentProps;
}

export interface UiData {
  formatter?: FormatterData;
  required?: boolean;
  type: "basic";
  label: string;
  property: string;
}

export class BasicVariableElement extends JsElement<VariableRenderData, UiData> {
  static supports({ render, ui }: JsTag) {
    return render.type === "variable" && ui.type === "basic";
  }

  get options() {
    return this.schemaStore
      .queryOptions({ type: /number|integer|string/ })
      .sort((a, b) => a.label.localeCompare(b.label));
  }

  render: VariableRenderData;
  ui: UiData;
  defaultTag: JsTag<VariableRenderData, UiData> = {
    render: { type: "variable", code: "" },
    ui: {
      type: "basic",
      property: "",
      label: "New variable",
    },
    valid: true,
  };

  constructor();
  constructor(props: JsElementProps);
  constructor(props?: JsElementProps) {
    super();

    // Provide default values
    const tag = props?.tag ?? this.defaultTag;

    // Assign values
    this.id = props?.id ?? null;
    this.parentId = props?.parentId;
    this.render = {
      ...this.defaultTag.render,
      ...(tag?.render ?? {}),
      type: "variable",
    };

    this.ui = {
      ...this.defaultTag.ui,
      ...tag.ui,
      type: "basic",
    };
  }

  get style() {
    return this.isNew ? walterStyles.variable.name : undefined;
  }

  async rebuild() {
    return this.update({ property: this.ui.property, formatter: this.ui.formatter, required: this.render.required });
  }

  validate(): void {
    super.validate();

    const validProperties = this.options.map(({ value }) => value);
    if (!validProperties.includes(this.ui.property) && !this.isNew) {
      this.errors.push(`${JSON.stringify(this.ui.property)} is not a valid property`);
    }
  }

  get innerText() {
    if (this.node) {
      return `[${this.node.truncatedTitle}${this.ui.required ? "*" : ""}]`;
    } else {
      return `[${this.ui.label}]`;
    }
  }

  /**
   * Returns the JavaScript code to access the field.
   * To safely ignore null fields, this code uses optional chaining operator (`?.`).
   */
  get fieldPropertyAccessCode() {
    return propertyPathToFieldCode(this.ui.property);
  }

  buildCode() {
    if (this.ui.formatter) {
      const props = JSON.stringify(this.ui.formatter.props);

      switch (this.ui.formatter.name) {
        case "date":
          return `Format.date(${this.fieldPropertyAccessCode}, ${props})`;
        case "currency":
          return `Format.currency(${this.fieldPropertyAccessCode}, ${props})`;
        case "number":
          return `Format.number(${this.fieldPropertyAccessCode}, ${props})`;
        case "percent":
          return `Format.percent(${this.fieldPropertyAccessCode}, ${props})`;
        default:
          return this.fieldPropertyAccessCode;
      }
    } else {
      return this.fieldPropertyAccessCode;
    }
  }

  async update({ property, formatter, required }: UpdateProps) {
    this.ui = {
      type: "basic",
      label: this.ui.label,
      property,
      required,
      formatter,
    };

    // Update the label afterwards (it relies on the property and formatter being set in this.ui)
    this.ui.label = this.buildLabel();

    this.render = {
      type: "variable",
      required,
      placeholder: this.node?.fullTitle,
      code: this.buildCode(),
    };

    return this.save();
  }

  /**
   * Fetches the schema node for the selected property.
   * This value is cached to make subsequent lookups fast.
   */
  get node() {
    return this.schemaStore.fetch(this.ui.property, (store) => store.find(this.ui.property));
  }

  buildLabel(): string {
    let fullTitle = this.node?.fullTitle ?? "Variable";
    if (this.ui.required) fullTitle += "*";

    switch (this.ui.formatter?.name) {
      case "date":
        return `${fullTitle} (${this.ui.formatter.props.format})`;
      case "currency":
        return `${fullTitle} (${Format.currency("1234.56789", this.ui.formatter.props)})`;
      case "number":
        return `${fullTitle} (${Format.number("1234.56789", this.ui.formatter.props)})`;
      case "percent":
        return `${fullTitle} (${Format.percent("0.0123", this.ui.formatter.props)})`;
      default:
        return fullTitle;
    }
  }
}

export function propertyPathToFieldCode(property: string) {
  const safeNav = property.split(".").join("?.");
  return `fields.${safeNav}`;
}
