import { VariableData } from "../liquidx";
import { TagParser } from "../liquidx/TagParser";
import { DynamicElement } from "../liquidx/internal";

export class Variable {
  localName: string;
  localTitle: string;
  description: string;
  type: string;
  child?: Variable;
  filters: Map<string, string | undefined>;
  deprecated: boolean = false;
  format?: string;

  constructor(
    name: string,
    title: string,
    description: string,
    type: string,
    child: Variable | undefined = undefined,
    filters: Map<string, string | undefined> | undefined = undefined,
    deprecated: boolean = false,
    format?: string,
  ) {
    this.localName = name;
    this.localTitle = title;
    this.description = description;
    this.type = type;
    this.child = child;
    this.filters = filters || new Map();
    this.deprecated = deprecated;
    this.format = format;
  }

  merge(other: Variable | undefined): Variable {
    if (other === undefined) {
      return this;
    }

    const filters = [...Array.from(this.filters), ...Array.from(other.filters)];
    const mergedFilters = new Map(filters);
    return new Variable(
      this.localName || other.localName,
      this.localTitle || other.localTitle,
      this.description || other.description,
      this.type || other.type,
      this.child ? this.child.merge(other.child) : other.child,
      mergedFilters,
      this.deprecated || other.deprecated,
      this.format || other.format,
    );
  }

  public get isDeprecated(): boolean {
    return this.deprecated || this.child?.isDeprecated || false;
  }

  public get deprecatedMessage(): string {
    if (!this.isDeprecated) return "";
    let variable: Variable = this;

    while (variable) {
      if (variable.deprecated) return variable.description;
      if (!variable.child) break;

      variable = variable.child;
    }

    return "";
  }

  /* Returns the title of the variable without the root.
   * Example:
   * Given "Commitment Signatory Name"
   * Returns "Signatory Name"
   */
  public get titleWithoutRoot() {
    if (this.child) {
      return this.child.buildTitle();
    } else {
      return "";
    }
  }

  public get title(): string {
    if (this.child) {
      return `${this.localTitle} ${this.child.title}`;
    } else {
      return this.localTitle;
    }
  }

  public get fullName(): string {
    if (this.child) {
      return `${this.localName}.${this.child.fullName}`;
    } else {
      return this.localName;
    }
  }

  /* Returns the name of the variable without the leaf.
   * Example:
   * Given "consenting_parties.[].loop2", returns "consenting_parties.[]"
   * Given "option_grant.company.name", returns "option_grant.company"
   */
  public get nameWithoutLeaf() {
    return this.fullName.split(".").slice(0, -1).join(".");
  }

  /* Returns the name of the closest parent loop. If the variable is not a loop, returns an empty string.
   * Example:
   * Given "consenting_parties.[].loop2", returns "consenting_parties"
   * Given "consenting_parties.[].addresses[].postal_code", returns "consenting_parties.[].addresses"
   * Given "company.directors[].stakeholder.name", returns "company.directors"
   * Given "option_grant.company.name", returns ""
   */
  public get parentLoopName() {
    if (!this.isLoopVariable()) return "";

    const index = this.fullName.lastIndexOf(".[].");
    return this.fullName.substring(0, index);
  }

  /* Returns the title of the variable without the root title.
   * Example:
   * Given "Commitment Investor Signatory Sign Tab"
   * Returns "Investor Signatory"
   */
  public get prefixTitleWithoutRoot() {
    if (this.child) {
      return this.child.titleWithoutLocal;
    } else {
      return "";
    }
  }

  public get titleWithoutLocal(): string {
    if (this.child) {
      return `${this.localTitle} ${this.child.titleWithoutLocal}`.trim();
    } else {
      return "";
    }
  }

  public get leafName(): string {
    if (this.child) {
      return this.child.leafName;
    } else {
      return this.localName;
    }
  }

  public get leafTitle(): string {
    if (this.child) {
      return this.child.leafTitle;
    } else {
      return this.localTitle;
    }
  }

  public get leafDescription(): string {
    if (this.child) {
      return this.child.leafDescription;
    } else {
      return this.description;
    }
  }

  public get leafFormat(): string {
    if (this.child) {
      return this.child.leafFormat;
    } else {
      return this.format ?? "";
    }
  }

  public get leafFilters(): Map<string, string | undefined> {
    if (this.child) {
      return this.child.leafFilters;
    } else {
      return this.filters;
    }
  }

  public get tagFormat(): VariableData {
    let tag: VariableData;

    if (this.child) {
      tag = { name: this.localName, child: this.child.tagFormat };
    } else {
      tag = { name: this.localName };

      // filters are only added if there is no child
      if (this.filters?.size > 0) {
        // TODO: fix typescript type error
        // @ts-ignore
        tag["filters"] = Array.from(this.filters.entries()).map(([key, value]) => {
          if (value) {
            return `${key}: ${value}`;
          } else {
            return key;
          }
        });
      }
    }

    return tag;
  }

  /**
   * Adds a filter to the leaf variable in the variable chain.
   *
   * @param filter - the filter to add. See Liquid documentation for applicable filters.
   */
  public addFilter(filter: string, value?: string) {
    if (this.child) {
      this.child.addFilter(filter, value);
    } else {
      this.filters.set(filter, value || undefined);
    }
  }

  public removeFilter(filter: string) {
    if (this.child) {
      this.child.removeFilter(filter);
    } else {
      this.filters.delete(filter);
    }
  }

  public isLoopVariable(): boolean {
    return this.fullName.includes("[]");
  }

  private buildTitle(): string {
    if (this.child) {
      return `${this.localTitle} ${this.child.buildTitle()}`.trim();
    } else {
      return this.localTitle;
    }
  }

  /**
   * Build a variable from a content control's tag.
   * The tag doesn't contain title, description, or type so those are left blank on the Variable instance.
   *
   * @param tag found on the content control
   */
  public static buildFromTag(tag: string): Variable {
    const variable = this.parseForVariable(tag);

    if (variable) {
      return this.buildFromVariableData(variable);
    } else {
      throw new Error(`Could not parse variable from tag: ${tag}`);
    }
  }

  public static buildFromVariableData(data: VariableData): Variable {
    const child = data.child ? this.buildFromVariableData(data.child) : undefined;

    const variable = new Variable(data.name, "", "", "", child);
    data.filters?.forEach((filter) => {
      const parts = filter?.split(":");
      if (parts) {
        const key = parts[0].trim();
        const value = parts[1]?.trim();
        variable.addFilter(key, value);
      }
    });

    return variable;
  }

  /**
   * Parses and returns a VariableData object from the content control's tag property.
   *
   * @param dynamicElement - a Tag object
   * @returns a VariableData object
   *
   */
  private static parseForVariable(tagString: string): VariableData | null {
    const tag = TagParser.parse(tagString);

    if (!tag.valid) throw new Error(`Could not parse tag: ${tagString}`);
    if ("render" in tag) throw new Error(`Variable can't be parsed from JsTag-style: ${tagString}`);

    const data = tag.data as any;
    const isIfWithVariableCondition = tag.type === "if" && data.type === "variable";
    const isIfWithComparison = tag.type === "if" && data.type === "comparison";
    const isSignable = DynamicElement.SIGNABLE_TYPES.includes(tag.type);
    const isVariable = tag.type === "variable";
    const isLoop = tag.type === "loop";

    let variable: VariableData | null;

    if (isIfWithVariableCondition || isSignable) {
      variable = data.variable;
    } else if (isLoop) {
      variable = data.enumerable;
    } else if (isIfWithComparison) {
      variable = data.left;
    } else if (isVariable) {
      variable = data;
    } else {
      variable = null;
    }

    return variable;
  }
}
