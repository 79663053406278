import { ContentControlTypeValues, InsertLocation } from ".";
import { syncEngine } from "../sync-engine/SyncEngine";
import { Params } from "../sync-engine/operations/ContentControlUpdate";
import debug from "../debug";
import { ContentControlProperties, DynamicElementRead } from "../sync-engine/operations/DynamicElementRead";
import { SelectContentControl } from "../sync-engine/operations/SelectContentControl";
import { GetSelectedAndFocused } from "../sync-engine/operations/GetSelectedAndFocused";
import { TagParser } from "./TagParser";
import { BasicLoopElement, JsElement, LiquidElement } from "./internal";

/**
 * Represents a Walter content control tag.
 */
export abstract class DynamicElement {
  /**
   * Save all dynamic elements, causing them to be updated with the latest placeholder, tag, etc.
   *
   * @returns promise that resolves once all dynamic elements have been saved
   */
  static async resave() {
    return Promise.all(this.all().map((dynamicElement) => dynamicElement.save()));
  }

  /**
   * Run validations for each dynamic element and re-save if their validation state changes.
   * Call this when you've added or removed a field, which may cause a field to become valid or invalid.
   */
  static async revalidate() {
    await Promise.all(DynamicElement.all().map((dynamicElement) => dynamicElement.revalidate()));
  }

  /**
   * Run validations for this dynamic element and re-save if the validation state changes.
   * @returns bool indicating if the element is valid
   */
  async revalidate() {
    const validBefore = !this.hasErrors;
    this.validate();
    const validAfter = !this.hasErrors;

    if (validBefore !== validAfter) await this.save();
    return validAfter;
  }

  /**
   * Calling this function is the equivalent of focusing each dynamic element and saving it.
   * This applies any before save logic, like building the label or, for loops, fetching the field $ref.
   *
   * This is useful because DOCTR doesn't return a fully formed UI object for each element.
   * It may also be useful if the before save logic has changed since the last time the document was saved.
   */
  static async rebuild() {
    let loopElements = this.all().filter((element) => element instanceof BasicLoopElement && !element.parentLoop);

    // Rebuild loops, one level at a time, as nested loops may depend on their parent.
    while (loopElements.length > 0) {
      await Promise.all(loopElements.map((element) => element.rebuild()));

      // Get dependent loops (i.e., their closest loop ancestor is one we just rebuilt)
      const parentIds = loopElements.map((element) => element.id);
      loopElements = DynamicElement.all().filter(
        (element) =>
          element instanceof BasicLoopElement && element.parentLoop?.id && parentIds.includes(element.parentLoop.id),
      );
    }

    const otherElements = this.all().filter((element) => !(element instanceof BasicLoopElement));
    await Promise.all(otherElements.map((element) => element.rebuild()));

    // Because we rebuild all elements in parallel, some may have validation errors
    // because their parents weren't yet saved (e.g., variables within loops).
    await DynamicElement.revalidate();
  }

  static readonly colors = {
    highlight: "yellow",
    highlightSelected: "edbbe7",
    default: "0f6cbd", // colorBrandStroke1
    focused: "c239b3", // colorPaletteBerryBorderActive
  };

  static ON_LOAD_EVENT = "dynamic-elements-load";
  static ON_FOCUS_EVENT = "dynamic-elements-focus";
  /**
   * The IDs of the currently selected dynamic elements in the document.
   */
  static selectedIds: number[] = [];
  static #focusedId: number | null = null;
  static dynamicElementTree: DynamicElement[] = [];
  static dynamicElementsById: Map<number, DynamicElement> = new Map();
  static readonly PREFIX = "walter";
  static readonly NON_SIGNABLE_TYPES: ContentControlTypeValues[] = [
    "if",
    "variable",
    "if-branch",
    "else-branch",
    "loop",
    "variable-expression",
    "conditional-expression",
  ];
  static readonly SIGNABLE_TYPES: ContentControlTypeValues[] = [
    "signature",
    "initials",
    "date-signed",
    "sign-tab-input",
  ];
  static readonly PREASSEMBLY_TYPES: ContentControlTypeValues[] = [
    ...DynamicElement.NON_SIGNABLE_TYPES,
    ...DynamicElement.SIGNABLE_TYPES,
  ];
  #color: string = DynamicElement.defaultColor;

  /**
   * Find and return a dynamic element by ID.
   *
   * If not found, returns undefined.
   *
   * @param id
   * @returns
   */
  static find(id: number) {
    return this.dynamicElementsById.get(id);
  }

  static all(): DynamicElement[] {
    return Array.from(this.dynamicElementsById.values());
  }

  static async updateSelectedIds(): Promise<number[]> {
    const { selectedIds, focusedId } = await syncEngine.perform(new GetSelectedAndFocused());

    this.selectedIds = selectedIds;
    this.focusedId = focusedId;
    return this.selectedIds;
  }

  static get focused(): DynamicElement | undefined {
    if (this.focusedId) return this.find(this.focusedId);
    return undefined;
  }

  static get focusedId() {
    return this.#focusedId;
  }

  static set focusedId(id: number | null) {
    if (this.#focusedId === id) return;

    this.#focusedId = id;
    window.dispatchEvent(new CustomEvent(this.ON_FOCUS_EVENT, { detail: id }));
  }

  static from(contentControl: ContentControlProperties): DynamicElement | null {
    return this.fromProperties({
      tag: contentControl.tag,
      id: contentControl.id,
      title: contentControl.title,
      parentId: contentControl.parentId,
    });
  }

  /**
   * Instantiate the correct subclass by parsing the tag.
   *
   * @param tag
   * @param id
   * @param title
   * @param parentId
   * @returns
   */
  static fromProperties({
    tag: tagString,
    id,
    title,
    parentId,
  }: {
    tag: string | null;
    title?: string;
    id?: number | null;
    parentId?: number;
  }): DynamicElement | null {
    if (!tagString) return null;
    const tag = TagParser.parse(tagString);
    if (!tag.valid) {
      debug.log("invalid dynamic element tag", tag);
      return null;
    }

    return "data" in tag ? LiquidElement.build({ tag, id, title, parentId }) : JsElement.build({ tag, id, parentId });
  }

  /**
   * Load dynamic elements from Word document.
   */
  static async load() {
    const contentControlProperties: ContentControlProperties[] = await syncEngine.perform(new DynamicElementRead());

    // TODO: Right now, we call `DynamicElement.load()` very often.
    //       Because the DynamicElementRead operations are merged,
    //       it is still efficient but we do repeat the work below
    //       this line every time. In future, we should consider a
    //       more efficient approach.
    const dynamicElementsById: Map<number, DynamicElement> = new Map();
    const dynamicElements = contentControlProperties
      .map((properties) => DynamicElement.from(properties))
      .filter(Boolean) as DynamicElement[];

    dynamicElements.forEach((dynamicElement) => {
      if (dynamicElement?.id) {
        dynamicElementsById.set(dynamicElement.id, dynamicElement);
      }
    });

    const dynamicElementsTree: DynamicElement[] = [];

    // Iterate over the dynamic elements and organize them into a hierarchy
    for (const dynamicElement of dynamicElements) {
      if (dynamicElement.parentId) {
        const parent = dynamicElementsById.get(dynamicElement.parentId);
        parent?.children.push(dynamicElement);
      } else {
        dynamicElementsTree.push(dynamicElement);
      }
    }

    DynamicElement.dynamicElementTree = dynamicElementsTree;
    DynamicElement.dynamicElementsById = dynamicElementsById;
    DynamicElement.all().map((dynamicElement) => dynamicElement.validate());

    window.dispatchEvent(new CustomEvent(this.ON_LOAD_EVENT));
  }

  id: number | null = null;
  errors: string[] = [];
  parentId: number | undefined;
  children: DynamicElement[] = [];
  #font: Word.Interfaces.FontData | undefined = undefined;
  // What context/position/location the content control is within.
  // NOTE: Right now, this is only used at insert but we may allow it to be updated later too.
  insertLocation: InsertLocation = InsertLocation.SELECTION;

  abstract get label(): string;
  abstract set label(value: string);
  abstract get title(): string;
  abstract get type(): string;

  abstract buildTag(): string;
  abstract inspect(): void;

  get font(): Word.Interfaces.FontData | undefined {
    return this.#font;
  }

  /**
   * The Word style to apply to the content control.
   */
  get style(): string | undefined {
    return undefined;
  }

  set font(updated: Word.Interfaces.FontData | undefined) {
    this.#font = updated;
  }

  get appearance(): "BoundingBox" | "Tags" | "Hidden" {
    return "BoundingBox";
  }

  async rebuild() {
    return this.save();
  }

  validate() {
    this.errors = [];
  }

  get parent(): DynamicElement | undefined {
    if (this.parentId) return DynamicElement.find(this.parentId);
    return undefined;
  }

  get hasErrors() {
    return this.errors.length > 0;
  }

  get color() {
    return this.hasErrors ? "red" : this.#color;
  }

  get labelOrTitle() {
    return this.label ? this.label : this.title;
  }

  get isNew() {
    return this.id === null;
  }

  get cannotEdit() {
    return false;
  }

  get cannotDelete() {
    return false;
  }

  /**
   * This method saves the tag to the content control.
   * Eventually, I'd like this to replace the existing services.
   * For now, it only allows us to update the tag and the placeholder text (i.e., the content).
   */
  public async save({ isSystemUpdate }: { isSystemUpdate: boolean } = { isSystemUpdate: true }) {
    this.validate();

    const params: Params = {
      id: this.id,
      location: this.insertLocation,
      title: this.title,
      tag: this.buildTag(),
      color: this.color,
      innerText: this.innerText,
      font: this.font,
      style: this.style,
      appearance: this.appearance as Word.ContentControlAppearance,
      isSystemUpdate,
      errors: this.errors,
      cannotDelete: this.cannotDelete,
      cannotEdit: this.cannotEdit,
      placeholderText: this.placeholderText,
    };

    if (this.id) {
      await syncEngine.update(params);
    } else {
      await syncEngine.insert(params);
    }

    // TODO: Remove this once two-way sync is implemented (i.e., upon save, we update the loaded dynamic elements).
    return DynamicElement.load();
  }

  async delete() {
    if (this.id) {
      await syncEngine.delete({ id: this.id, keepContents: false });

      // TODO: Remove this once two-way sync is implemented (i.e., upon save, we update the loaded dynamic elements).
      return DynamicElement.load();
    } else {
      return Promise.resolve();
    }
  }

  get innerText(): string | undefined {
    return undefined;
  }

  public resetPlaceholder() {
    // no-op
  }

  public async focus() {
    DynamicElement.focusedId = this.id;
    if (this.id) await syncEngine.perform(new SelectContentControl(this.id));
  }

  static get defaultColor() {
    return "0f6cbd"; // colorBrandStroke1
  }

  get placeholderText(): string | undefined {
    return undefined;
  }

  get focusedColor() {
    return "c239b3"; // colorPaletteBerryBorderActive
  }

  get selfAndAncestors(): DynamicElement[] {
    const ancestors: DynamicElement[] = [];

    let current: DynamicElement | undefined = this;

    while (current?.parentId) {
      current = DynamicElement.find(current.parentId);
      if (current) ancestors.push(current);
    }

    return [...ancestors, this];
  }

  get descendants(): DynamicElement[] {
    return this.findDescendants(this.children, []);
  }

  get descendantIds(): number[] {
    return this.descendants.map((descendant) => descendant.id).filter(Boolean) as number[];
  }

  findDescendants(children: DynamicElement[], acc: DynamicElement[]): DynamicElement[] {
    for (const child of children) {
      if (acc.includes(child)) {
        debug.warn("found cyclical descendants", child.id);
        continue;
      }
      acc.push(child);
      this.findDescendants(child.children, acc);
    }

    return acc;
  }

  get iconType(): string | undefined {
    return undefined;
  }
}
