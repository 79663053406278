import { Field, SchemaStore, focusedSchemaStore } from "@src/lib/schemas";
import { useEffect, useState } from "react";

interface UseFieldReturn {
  /**
   * Fields for the current cursor position.
   */
  fields: Field[];
  /**
   * The field store for the current cursor position.
   */
  store: SchemaStore;
  /**
   * A version number that increments anytime this.store changes.
   * Because the field store object may not change in a way that
   * React can detect, use this to force a re-render.
   */
  version: number;
}

export const useFields = (): UseFieldReturn => {
  const [fields, setFields] = useState<Field[]>(focusedSchemaStore.fields);
  const [store, setStore] = useState(focusedSchemaStore);
  const [version, setVersion] = useState(0);

  useEffect(() => {
    function updateFields() {
      setFields(focusedSchemaStore.fields);
      setStore(focusedSchemaStore);
      setVersion((prev) => prev + 1);
    }

    focusedSchemaStore.addEventListener("change", updateFields);
    return () => focusedSchemaStore.removeEventListener("change", updateFields);
  }, []);

  return { fields, store, version };
};
