import {
  Body1Strong,
  Combobox,
  ComboboxProps,
  Label,
  Option,
  useId,
  makeStyles,
  shorthands,
} from "@fluentui/react-components";
import { FunctionComponent, useState } from "react";

export interface DateFormatProps {
  placeholder?: string;
  dateFormat?: string;
  onSelect: (value: string) => void;
}

const dateFormats = {
  "Month Day, Year": "'%B %e, %Y'",
  "Day Month Year": "'%e %B %Y'",
  "YYYY-MM-DD": "'%Y-%m-%d'",
};

const useStyles = makeStyles({
  root: {
    display: "grid",
    ...shorthands.gap("8px"),
  },
});

export const DateFormat: FunctionComponent<DateFormatProps> = ({ placeholder, dateFormat, onSelect }) => {
  const comboId = useId("combo");
  const styles = useStyles();

  const findLabel = (format: string) => {
    return Object.entries(dateFormats).find(([_label, f]) => f === format)?.[0];
  };

  const [value, setValue] = useState<string>(dateFormat ? findLabel(dateFormat) ?? "YYYY-MM-DD" : "YYYY-MM-DD");

  const comboboxProps: ComboboxProps = {
    placeholder,
    onOptionSelect: (_ev, { optionValue }) => {
      if (!optionValue) return;

      onSelect(optionValue);

      const label = findLabel(optionValue);
      if (label) setValue(label);
    },
  };

  return (
    <div className={styles.root}>
      <Label id={comboId}>
        <Body1Strong>Date format</Body1Strong>
      </Label>
      <Combobox {...comboboxProps} id={comboId} value={value}>
        {Object.entries(dateFormats).map(([label, format]) => (
          <Option key={label} text={label} value={format}>
            {label}
          </Option>
        ))}
      </Combobox>
    </div>
  );
};
