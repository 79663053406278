import { useState, useEffect, useMemo, useContext } from "react";
import { DynamicElement } from "@src/lib/liquidx/internal";
import { useParams } from "react-router-dom";
import { WalterContext } from "../providers/walter";

export const useDynamicElement = (id: number) => {
  const [dynamicElement, setDynamicElement] = useState<DynamicElement>();

  useEffect(() => {
    function set() {
      setDynamicElement(DynamicElement.find(id));
    }

    set();

    window.addEventListener(DynamicElement.ON_LOAD_EVENT, set);
    return () => window.removeEventListener(DynamicElement.ON_LOAD_EVENT, set);
  }, [id]);

  return dynamicElement;
};

/**
 * Retrieve a dynamic element using the ID stored in the URL params.
 * If the ID param is not found or is "new", it returns the fallback provided.
 */
export const useDynamicElementFromParams = <T extends DynamicElement>(fallback: T) => {
  const { id } = useParams<{ id: string }>();

  const parsedId = Number(id);
  const dynamicElementFromId = useDynamicElement(parsedId);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedFallback = useMemo(() => fallback, []);

  if (isNaN(parsedId)) {
    return memoizedFallback as T;
  } else {
    return (dynamicElementFromId || memoizedFallback) as T;
  }
};

/**
 * @returns The selected dynamic elements
 */
export const useSelectedDynamicElements = (): DynamicElement[] => {
  const { selectedDynamicElementIds } = useContext(WalterContext);
  const [selected, setSelected] = useState<DynamicElement[]>([]);

  useEffect(() => {
    setSelected(selectedDynamicElementIds.map((id) => DynamicElement.find(id)).filter(Boolean) as DynamicElement[]);
  }, [selectedDynamicElementIds]);

  return selected;
};

/**
 * Returns the dynamic element that is (1) the only selected element,
 * or (2) every other selected element is a descendant of this element.
 */
export const useFocusedDynamicElement = (): DynamicElement | undefined => {
  const [focusedElement, setFocusedElement] = useState<DynamicElement>();

  useEffect(() => {
    function handleChange() {
      setFocusedElement(DynamicElement.focused);
    }

    window.addEventListener(DynamicElement.ON_FOCUS_EVENT, handleChange);
    return () => window.removeEventListener(DynamicElement.ON_FOCUS_EVENT, handleChange);
  }, []);

  return focusedElement;
};
