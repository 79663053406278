import { FC, useEffect, useState } from "react";
import { InputOption } from "../../lib/walter";
import { Button, Checkbox, Input, Label, useId, Body1Strong } from "@fluentui/react-components";
import WCombobox from "./shared/Combobox";
import { useHistory } from "react-router-dom";
import { useInputOptions } from "../hooks/useInputOptions";
import { useDynamicElementFromParams } from "../hooks/useDynamicElement";
import { SignTabInputElement } from "../../lib/liquidx/internal";
import { formStyles } from "./loops/Loop";
import { WidthSlider } from "./shared/WidthSlider";

export interface SignTabInputForm {
  signer: string;
  inputType: string;
  required: boolean;
  width: string;
  group?: string;
}

const defaultWidth = "100";

function deriveFormValues(signTabInputElement: SignTabInputElement): SignTabInputForm {
  if (signTabInputElement.isNew) return { signer: "", width: defaultWidth, inputType: "", required: false, group: "" };

  return {
    signer: signTabInputElement.variable.fullName,
    inputType: signTabInputElement.metadata.type,
    required: signTabInputElement.metadata.required,
    width: signTabInputElement.metadata.width ?? defaultWidth,
    group: signTabInputElement.metadata.group,
  };
}

// These are the input types that shouldn't show a width slider
const fixedWidthInputTypes = ["checkbox", "radio"];

export const SignTabInput: FC<{ submitText: string }> = ({ submitText }) => {
  const signTabInputElement = useDynamicElementFromParams(new SignTabInputElement());
  const { inputOptions } = useInputOptions();
  const formInputOptions = inputOptions.formInputOptions;
  const signerOptions = inputOptions.signerOptions;
  const history = useHistory();

  const [formValues, setFormValues] = useState<SignTabInputForm>(deriveFormValues(signTabInputElement));
  const [disableSubmit, setDisableSubmit] = useState<boolean>(true);

  const inputId = useId("radio-input");
  const styles = formStyles();

  useEffect(() => {
    setFormValues(deriveFormValues(signTabInputElement));
  }, [signTabInputElement]);

  useEffect(() => {
    if (formValues.inputType && formValues.signer) {
      setDisableSubmit(false);
    } else {
      setDisableSubmit(true);
    }
  }, [formValues]);

  const onClick = () => {
    if (formValues.inputType !== "radio") {
      formValues.group = "";
    }

    const el = signTabInputElement ?? new SignTabInputElement();
    // TODO: what is the impact if the variable is not found? What is the meaning can we infer from this?
    const variable = inputOptions.signerOptions.find(
      (option: InputOption) => option.value === formValues.signer,
    )?.variable;

    el.metadata = {
      // TODO: fix typescript type error. Remove @ts-ignore comment to see the error
      // @ts-ignore
      variable: variable?.tagFormat,
      type: formValues.inputType,
      required: !!formValues.required,
      group: formValues.group || "",
      width: fixedWidthInputTypes.includes(formValues.inputType) ? "0" : formValues.width,
    };
    void el.save();
    history.push("/");
  };

  const showRequiredInput = formValues?.inputType === "radio" || formValues?.inputType === "text";

  return (
    <div className={styles.root}>
      <WCombobox
        label="Select signer"
        options={signerOptions}
        onSelect={(signer) => setFormValues({ ...formValues, signer: signer ?? "" })}
        selectedOption={formValues?.signer}
        comboboxProps={{ placeholder: "Search for signer" }}
      ></WCombobox>
      <WCombobox
        label="Select input type"
        options={formInputOptions}
        onSelect={(inputType) => setFormValues({ ...formValues, inputType: inputType ?? "" })}
        selectedOption={formValues?.inputType}
        comboboxProps={{ placeholder: "Search for input type" }}
      ></WCombobox>
      {!fixedWidthInputTypes.includes(formValues.inputType) && (
        <WidthSlider
          value={formValues?.width}
          onChange={(value) => setFormValues({ ...formValues, width: value.toString() })}
        />
      )}
      {formValues?.inputType === "radio" && (
        <>
          <Label id={inputId}>
            <Body1Strong>Group name</Body1Strong>
          </Label>
          <Input
            id={inputId}
            value={formValues?.group || ""}
            placeholder="Name to group radio buttons"
            spellCheck={false}
            onChange={(_ev, data) => setFormValues({ ...formValues, group: data.value })}
          />
        </>
      )}
      {showRequiredInput && (
        <Checkbox
          checked={formValues?.required || false}
          onChange={(_ev, data) => setFormValues({ ...formValues, required: data.checked as boolean })}
          label="Required"
        />
      )}
      <Button appearance="primary" onClick={onClick} disabled={disableSubmit}>
        {submitText}
      </Button>
    </div>
  );
};
