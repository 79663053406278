import { JsElement } from "@src/lib/liquidx/internal";
import { JsTag, VariableRenderData } from "./ParsedTag";
import { JsElementProps } from "./JsElement";
import { walterStyles } from "../styles";
import { ComputedFieldSchema } from "@src/taskpane/components/FieldsPane/EditVariableField";
import { propertyPathToFieldCode } from "./BasicVariableElement";

interface AliasUiData {
  type: "alias";
  property: string;
  label?: string;
}

export interface SimpleComboboxOption {
  label: string;
  value: string;
}

export class AliasElement extends JsElement<VariableRenderData, AliasUiData> {
  static supports({ render, ui }: JsTag) {
    return render.type === "variable" && ui.type === "alias";
  }

  render: VariableRenderData;
  ui: AliasUiData;
  static defaultTag: JsTag<VariableRenderData, AliasUiData> = {
    render: { type: "variable", code: "" },
    ui: {
      type: "alias",
      property: "",
    },
    valid: true,
  };

  static forProperty(property: string) {
    const props = {
      tag: {
        ...this.defaultTag,
        render: {
          ...this.defaultTag.render,
          code: propertyPathToFieldCode(property),
        },
        ui: {
          ...this.defaultTag.ui,
          property,
        },
      },
    } as JsElementProps;

    return new AliasElement(props);
  }

  constructor();
  constructor(props: JsElementProps);
  constructor(props?: JsElementProps) {
    super();

    // Provide default values
    const tag = props?.tag ?? AliasElement.defaultTag;

    // Assign values
    this.id = props?.id ?? null;
    this.parentId = props?.parentId;
    this.render = {
      ...AliasElement.defaultTag.render,
      ...(tag?.render ?? {}),
      type: "variable",
    };

    this.ui = {
      ...AliasElement.defaultTag.ui,
      ...tag.ui,
      type: "alias",
    };
  }

  get style() {
    return this.isNew ? walterStyles.variable.name : undefined;
  }

  get innerText() {
    const schema = this.node?.schema as ComputedFieldSchema;

    if (schema && "placeholder" in schema && schema.placeholder) {
      return schema.placeholder;
    }

    return this.node?.truncatedTitle ?? "Computed field";
  }

  get label() {
    return this.ui.label ?? this.node?.title ?? this.ui.property;
  }

  get title() {
    return `Alias: ${this.label}`;
  }

  get node() {
    return this.schemaStore.findField(this.ui.property)?.node;
  }

  get iconType() {
    return "link";
  }

  validate() {
    super.validate();

    if (!this.node) {
      this.errors.push(`Field not found: ${this.ui.property}`);
    }
  }
}
