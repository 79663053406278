import { IfJsElement, JsElementProps } from "@src/lib/liquidx/internal";
import { BaseUiData, IfRenderData, JsTag } from "./ParsedTag";
import {
  ConditionCodeGen,
  ConditionData,
  ConditionGroupData,
} from "@src/taskpane/components/ConditionBuilder/ConditionCodeGen";
import { ConditionModel } from "@src/taskpane/components/ConditionBuilder/ConditionModel";

export interface UiData extends BaseUiData {
  type: "basic";
  conditionGroup: ConditionGroupData;
  label: string;
}

export interface UpdateProps {
  conditionGroup: ConditionGroupData;
  shouldHaveElse: boolean;
}

export class BasicIfElement extends IfJsElement<IfRenderData, UiData> {
  static supports({ render, ui }: JsTag) {
    return render.type === "if" && ui.type === "basic";
  }

  render: IfRenderData;
  ui: UiData;
  defaultTag: JsTag<IfRenderData, UiData> = {
    render: { type: "if", code: "" },
    ui: {
      type: "basic",
      conditionGroup: {
        conditions: [{ left: { type: "property", value: "" }, joinOperator: "and" }],
        joinOperator: "and",
      },
      label: "new if",
    },
    valid: true,
  };

  constructor();
  constructor(props?: JsElementProps) {
    super();

    this.id = props?.id ?? null;
    this.parentId = props?.parentId;

    const tag = props?.tag ?? this.defaultTag;
    this.render = {
      ...this.defaultTag.render,
      ...tag.render,
      type: "if",
    };

    this.ui = {
      ...this.defaultTag.ui,
      ...tag.ui,
      type: "basic",
    };
  }

  get nodes() {
    return this.schemaStore.fetch("nodes", (store) => {
      return store.query({ type: /^(?!object$).+$/ });
    });
  }

  async rebuild() {
    return this.update({ conditionGroup: this.ui.conditionGroup });
  }

  async update({ conditionGroup, shouldHaveElse }: Partial<UpdateProps>) {
    if (shouldHaveElse !== undefined) this.shouldHaveElse = shouldHaveElse;

    conditionGroup ??= this.ui.conditionGroup;

    this.ui = {
      ...this.ui,
      label: this.buildLabel(conditionGroup),
      conditionGroup,
    };

    this.render = {
      ...this.render,
      code: ConditionCodeGen.generateCode(this.ui.conditionGroup),
    };

    await this.save();
  }

  buildLabel(conditionGroup: ConditionGroupData) {
    if (conditionGroup.conditions.length === 0) return "new if";
    if (conditionGroup.conditions.length > 1) return `${conditionGroup.conditions.length} conditions`;

    const condition = conditionGroup.conditions[0] as ConditionData;
    const model = new ConditionModel(condition, this.nodes);
    const left = model.node?.title;
    const operator = model.operatorLabel;
    const right = condition.right?.value;

    if (left && operator && !right) return `${left} ${operator}`;
    if (left && operator && right) return `${left} ${operator} ${right}`;
    return "new if";
  }
}
