import { FC, useState, useEffect } from "react";
import { group } from "../helpers/formatData";
import GroupedCombobox from "./shared/GroupedCombobox";
import { Input, Switch, Field } from "@fluentui/react-components";
import { InputOption } from "@src/lib/walter";
import { useDynamicElementFromParams } from "../hooks/useDynamicElement";
import { ConditionalContentControlAddProperties } from "@src/lib/liquidx";
import { useHistory } from "react-router-dom";
import { useInputOptions } from "../hooks/useInputOptions";
import usePageLoadTransition from "../hooks/usePageLoadTransition";
import DeleteButton from "./shared/DeleteButton";
import { IfElement } from "@src/lib/liquidx/internal";
import { SubmitButton } from "@src/taskpane/components/SubmitButton";
import { ConditionsDropdown } from "@src/taskpane/components/ConditionsDropdown";

export interface IDropdownOption {
  key: string;
  text: string;
}

export const operatorOptions: IDropdownOption[] = [
  { key: "blank", text: "Blank" },
  { key: "notBlank", text: "Not Blank" },
  { key: "true", text: "Exists" },
  { key: "false", text: "Not Exists" },
  { key: "==", text: "Equals To" },
  { key: "!=", text: "Not Equals To" },
  { key: ">", text: "Greater Than" },
  { key: "<", text: "Less Than" },
  { key: ">=", text: "Greater or Equal To" },
  { key: "<=", text: "Less or Equal To" },
];

export interface BuildConditionalProps {
  submitLabel: string;
}

function findOperatorOption(ifElement: IfElement | undefined): IDropdownOption {
  const operator = ifElement?.operator;
  if (!operator) return operatorOptions[0];

  return operatorOptions.find((option: IDropdownOption) => option.key === operator) ?? operatorOptions[0];
}

export const Conditional: FC<BuildConditionalProps> = ({ submitLabel }) => {
  const pageTransitionClassName = usePageLoadTransition();
  const ifElement = useDynamicElementFromParams(new IfElement());
  const [variable, setVariable] = useState<string>(ifElement.isNew ? "" : ifElement.variable.fullName || "");
  const [operatorOption, setOperatorOption] = useState<IDropdownOption>(findOperatorOption(ifElement));
  const [assertion, setAssertion] = useState(ifElement?.right || "");
  const [elseBranch, setElseBranch] = useState(!!ifElement?.elseBranch);
  const [variableFieldErrorMessage, setVariableFieldErrorMessage] = useState<string>("");
  const history = useHistory();
  const { inputOptions } = useInputOptions();

  useEffect(() => {
    if (variable) {
      setVariableFieldErrorMessage("");
    }
  }, [variable]);

  const onSubmit = (): void => {
    const resourceInput = inputOptions.variableOptions.find((option: InputOption) => option.value === variable);

    if (!variable) {
      setVariableFieldErrorMessage("Please select a variable");
      return;
    }

    handleSubmit({
      left: resourceInput as InputOption,
      operator: operatorOption?.key,
      right: assertion,
      elseBranch,
    });
  };

  function handleSubmit(formData: ConditionalContentControlAddProperties) {
    void (async function () {
      ifElement.applyEdit(formData);
      // TODO: Show error if save fails
      history.push("/");
      await ifElement.save();
    })();
  }

  useEffect(() => {
    if (ifElement.isNew) return;

    setVariable(ifElement.left.fullName || "");
    setOperatorOption(findOperatorOption(ifElement));
    setAssertion(ifElement.right || "");
    setElseBranch(!!ifElement.elseBranch);
  }, [ifElement]);

  const showAssertionField = () => {
    return !["blank", "notBlank", "true", "false"].includes(operatorOption?.key) && operatorOption?.key;
  };

  const handleDelete = async () => {
    await ifElement.delete();
    history.push("/");
  };

  return (
    <div className={pageTransitionClassName}>
      <GroupedCombobox
        groupedOptions={group(inputOptions.variableOptions)}
        onSelect={(value) => setVariable(value)}
        label="If"
        selectedOption={variable}
        errorMessage={variableFieldErrorMessage}
      />

      <ConditionsDropdown
        selectedOption={operatorOption}
        onSelect={(_e, data) => setOperatorOption({ key: data.optionValue, text: data.optionText } as IDropdownOption)}
      ></ConditionsDropdown>

      {showAssertionField() && (
        <Field label="Assertion">
          <Input spellCheck={false} value={assertion} onChange={(_e, { value }) => setAssertion(value)} />
        </Field>
      )}

      <Switch
        disabled={!!ifElement?.id}
        checked={elseBranch}
        label="Else Branch"
        onChange={(_e, { checked }) => setElseBranch(checked)}
      />
      <SubmitButton onClick={onSubmit} disabled={!!variableFieldErrorMessage} tooltipText={variableFieldErrorMessage}>
        {submitLabel}
      </SubmitButton>
      {ifElement && <DeleteButton onClick={handleDelete} />}
    </div>
  );
};
