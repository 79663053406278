import { Button, Caption1, Input, Label, useId, makeStyles } from "@fluentui/react-components";
import { FunctionComponent, useState, ChangeEvent, useEffect } from "react";
import { Field, SchemaStore, documentSchemaStore } from "@src/lib/schemas";
import { useTypeOptions } from "../../hooks/useTypeOptions";
import { TypeOption } from "../../../lib/walter";
import { useHistory, useParams } from "react-router-dom";
import { DynamicElement } from "@src/lib/liquidx/DynamicElement";
import { JSONSchema9 } from "@src/lib/types/jsonSchema";
import { captureErrorInfo } from "@src/taskpane/helpers/errorHandler";
import { useFields } from "@src/taskpane/hooks/useFields";
import { TextCombobox } from "../TextCombobox";

const formStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
  },
  error: {
    color: "red",
  },
});

function fieldToFieldType(field: Field): string | undefined {
  const schema = field.node.schema;

  if (schema.$ref) {
    return schema.$ref.split("/").pop();
  } else if (typeof schema.type === "string") {
    return schema.type;
  }
}

export const AddField: FunctionComponent<{ onSubmit?: () => void; field?: Field }> = ({
  onSubmit,
  field: fieldArg,
}) => {
  const { key } = useParams<{ key: string }>();
  const { fields } = useFields();
  const field = fieldArg ?? fields.find((field) => field.key === key);
  const isNew = !field;
  const [name, setName] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [type, setType] = useState<TypeOption>();
  const [error, setError] = useState<string>("");
  const { typeOptions } = useTypeOptions();
  const history = useHistory();
  const hasError = error !== "";
  const isIncomplete = !name || !type?.value;
  const nameInputId = useId("name");
  const descriptionInputId = useId("description");
  const typeInputId = useId("type");

  const form = formStyles();

  useEffect(() => {
    if (field) {
      setName(field.node.title);
      setDescription(field.description || "");
      const typeValue = fieldToFieldType(field);
      const selectedType = typeOptions.find((option) => option.value === typeValue);
      setType(selectedType);
    }
  }, [field, typeOptions]);

  const handleTypeChange = (value: string) => {
    var selectedType = typeOptions.find((option) => option.value === value);
    setType(selectedType);

    // Set name to the selected type if it's empty
    setName((prev) => {
      return prev || selectedType?.label || "";
    });
  };

  const onNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const onDescriptionChange = (event: ChangeEvent<HTMLInputElement>) => {
    setDescription(event.target.value);
  };

  function isLabelAvailable(label: string) {
    const fieldKey = SchemaStore.deriveKey(label);
    const fieldWithSameKey = documentSchemaStore.findField(fieldKey);

    if (fieldWithSameKey) {
      throw new Error(
        `"${label}" conflicts with another field, "${fieldWithSameKey.label}"—please choose a different name.`,
      );
    }
  }

  const onBlur = () => {
    if (!isNew || !name) return;

    try {
      isLabelAvailable(name);
      setError("");
    } catch (e: any) {
      setError(e.message);
    }
  };

  const handleSubmit = async () => {
    if (onSubmit) {
      onSubmit();
    } else {
      history.push("/");
    }

    if (field) {
      // Update existing field
      field.node.schema.title = name;
      field.node.schema.description = description;
    } else {
      // Add new field
      const key = SchemaStore.deriveKey(name);
      const schema: JSONSchema9 = {
        description,
        title: name,
      };

      if (type?.ref) {
        schema.$ref = type?.ref;
      } else {
        schema.type = type?.value;
      }

      documentSchemaStore.addField({ key, schema });
    }

    await documentSchemaStore.save();
    await DynamicElement.rebuild().catch(captureErrorInfo);
  };

  return (
    <form className={form.root}>
      <Label size="small" htmlFor={typeInputId}>
        Type
      </Label>
      <TextCombobox
        id={typeInputId}
        disabled={!isNew}
        autoFocus={isNew}
        options={typeOptions}
        value={type?.value ?? ""}
        onChange={handleTypeChange}
      />
      <Label size="small" htmlFor={nameInputId}>
        Name
      </Label>
      <Input id={nameInputId} type="text" onChange={onNameChange} value={name} onBlur={onBlur} />
      {hasError && <Caption1 className={form.error}>{error}</Caption1>}
      <Label size="small" htmlFor={descriptionInputId}>
        Description
      </Label>
      <Input id={descriptionInputId} type="text" onChange={onDescriptionChange} value={description} />
      <Button appearance="primary" onClick={handleSubmit} disabled={hasError || isIncomplete}>
        {field ? "Update field" : "Add field"}
      </Button>
    </form>
  );
};
