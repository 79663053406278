import { JSONSchema9 } from "../types/jsonSchema";
import { SchemaStore } from "@src/lib/schemas";

export class Fieldset extends SchemaStore {
  static TEMPLATE_PRESETS_CHANGED_EVENT = "word-add-in-template-presets-changed";
  static presets: Map<string, Fieldset> = new Map();

  static find(name: string) {
    return this.presets.get(name);
  }

  static add(schema: JSONSchema9) {
    const templatePreset = new Fieldset(schema);
    this.presets.set(templatePreset.schema.title ?? "", templatePreset);
    return templatePreset;
  }
}
