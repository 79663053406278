import { ContentControlType, InsertLocation, VariableData } from "@src/lib/liquidx";
import { LiquidElement } from "@src/lib/liquidx/internal";
import { inputOptionsHandler, QuickAddOption, Variable } from "@src/lib/walter";
import { unicodeToString } from "@src/taskpane/helpers/formatData";
import { LiquidTag } from "./ParsedTag";

interface SignTabElementData {
  variable: VariableData;
  width?: string;
  required?: boolean;
}

export class SignTabElement extends LiquidElement {
  static supports({ type }: LiquidTag) {
    return ["signature", "initials", "date-signed"].includes(type);
  }

  static readonly highlight = "yellow";
  static readonly highlightSelected = "edbbe7";

  static create(type: ContentControlType) {
    const tag: LiquidTag = { valid: true, type, data: {} };
    return new SignTabElement(tag);
  }

  constructor();
  constructor(tag: LiquidTag);
  constructor(tag?: LiquidTag, id?: number | null, title?: string, parentId?: number) {
    tag ??= { valid: true, type: ContentControlType.VARIABLE, data: {} };
    super(tag, id, title, parentId);
    this.font = { highlightColor: SignTabElement.highlight };
  }

  applyQuickAdd(_quickAddOption: QuickAddOption, _location: InsertLocation): void {
    throw new Error("Method not implemented.");
  }

  public async resetPlaceholder() {
    await this.save({ isSystemUpdate: false });
  }

  get innerText() {
    return this.placeholder;
  }

  get labelOrTitle() {
    return this.label ? this.label : this.title;
  }

  get title() {
    return `${this.type}: ${this.variable.fullName}${this.required ? "*" : ""}`;
  }

  /**
   * @deprecated This method is deprecated and will be removed once we migrate to JS-powered variables.
   */
  get variable(): Variable {
    return Variable.buildFromVariableData(this.metadata.variable);
  }

  get placeholder() {
    // TODO: fix typescript type error. Remove @ts-ignore comment to see the error
    // @ts-ignore
    const signer = this.inputOptions.find((option) => option.variable.fullName === this.variable.fullName);
    if (!signer) return "";

    // TODO: fix typescript type error. Remove @ts-ignore comment to see the error
    // @ts-ignore
    const text = `${signer.variable.titleWithoutLocal} ${this.type}${this.required ? "*" : ""}`.trim();

    if (this.type === ContentControlType.SIGNATURE) {
      return `${unicodeToString("2326")} ${text}`;
    }

    return `[${text}]`;
  }

  private get inputOptions() {
    if (this.type === "signature") {
      return inputOptionsHandler.inputOptions.signatureOptions;
    } else if (this.type === "initials") {
      return inputOptionsHandler.inputOptions.initialsOptions;
    } else if (this.type === "date-signed") {
      return inputOptionsHandler.inputOptions.dateSignedOptions;
    } else {
      throw new Error(`Invalid sign tab type: ${this.type}`);
    }
  }

  get required() {
    return this.metadata.required;
  }

  get metadata(): SignTabElementData {
    return this.data as SignTabElementData;
  }

  set metadata(value: SignTabElementData) {
    this.data = value;
  }
}
