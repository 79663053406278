import { OperationContext } from "../OperationContext";
import { Operation } from "./Operation";

export interface SelectionDetails {
  isEmpty: boolean;
  paragraphsCount: number;
  paragraphTextsAreEmpty: boolean;
  isInTableCell: boolean;
  isInTable: boolean;
}

export class GetSelection implements Operation<SelectionDetails> {
  async commit(context: OperationContext): Promise<SelectionDetails> {
    const selection = context.document
      .getSelection()
      .load("isEmpty, paragraphs, parentTableCellOrNullObject, parentTableOrNullObject");
    const parentTable = selection.parentTableOrNullObject.load("isNullObject");
    const parentTableCell = selection.parentTableCellOrNullObject.load("isNullObject");

    await context.sync();

    return {
      isEmpty: selection.isEmpty,
      paragraphsCount: selection.paragraphs.items.length,
      paragraphTextsAreEmpty: selection.paragraphs.items.map((p) => p.text).join("\n") === "",
      isInTableCell: !parentTable.isNullObject,
      isInTable: !parentTableCell.isNullObject,
    };
  }

  merge(other: Operation<unknown>) {
    if (other instanceof GetSelection) {
      return this;
    }
  }
}
