import { FunctionComponent } from "react";
import {
  OptionGroup,
  Combobox,
  Option,
  Body1,
  Caption1,
  makeStyles,
  shorthands,
  useId,
  Body1Strong,
  type ComboboxProps,
} from "@fluentui/react-components";
import { useFieldSets } from "@src/taskpane/hooks/useFieldSets";

export interface SelectFieldSetProps {
  disabled?: boolean;
  onOptionSelected: (value: string | undefined) => void;
  placeholder?: string;
  selectedValue?: string;
}

const useStyles = makeStyles({
  labelled: {
    minWidth: "max-content",
    width: "75%",
    display: "grid",
    ...shorthands.gap("8px"),
    ...shorthands.padding(0, "12px"),
  },
  container: {
    ...shorthands.gap("2px"),
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
  },
});

export const SelectFieldSet: FunctionComponent<SelectFieldSetProps> = ({
  onOptionSelected,
  placeholder = "Select a field set",
}) => {
  const comboId = useId("combo");
  const styles = useStyles();
  const fieldSets = useFieldSets();

  const comboboxProps: ComboboxProps = {
    placeholder,
    onOptionSelect: (_ev, { optionValue }) => {
      if (!optionValue) return;
      onOptionSelected(optionValue);
    },
    defaultValue: "",
    defaultSelectedOptions: [] as string[],
  };

  return (
    <div className={styles.labelled}>
      <label id={comboId}>
        <Body1Strong>Field sets</Body1Strong>
      </label>
      <Combobox {...comboboxProps} id={comboId}>
        {fieldSets.userPersonalFieldSets.length > 0 ? (
          <OptionGroup label="Personal">
            {fieldSets.userPersonalFieldSets.map((fieldSet) => (
              <Option key={fieldSet.label} text={fieldSet.label}>
                <div className={styles.container}>
                  <Body1>{fieldSet.label}</Body1>
                  {fieldSet.description ? <Caption1>{fieldSet.description}</Caption1> : null}
                </div>
              </Option>
            ))}
          </OptionGroup>
        ) : null}
        {fieldSets.workspaceFieldSets.length > 0 ? (
          <OptionGroup label="Workspace">
            {fieldSets.workspaceFieldSets.map((fieldSet) => (
              <Option key={fieldSet.label} text={fieldSet.label}>
                <div className={styles.container}>
                  <Body1>{fieldSet.label}</Body1>
                  {fieldSet.description ? <Caption1>{fieldSet.description}</Caption1> : null}
                </div>
              </Option>
            ))}
          </OptionGroup>
        ) : null}
        <OptionGroup label="Presets">
          {fieldSets.presetFieldSets.map((fieldSet) => (
            <Option key={fieldSet.label} text={fieldSet.label}>
              <div className={styles.container}>
                <Body1>{fieldSet.label}</Body1>
                <Caption1>{fieldSet.description}</Caption1>
              </div>
            </Option>
          ))}
        </OptionGroup>
      </Combobox>
    </div>
  );
};
