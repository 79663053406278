import { FunctionComponent, type JSX } from "react";
import { useHistory } from "react-router-dom";
import { mapDynamicElement } from "../../helpers/mapDynamicElement";
import {
  Button,
  Caption1Strong,
  makeStyles,
  shorthands,
  Menu,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
} from "@fluentui/react-components";
import { ContentControlType } from "@src/lib/liquidx";
import { TextBulletListAdd20Regular } from "@fluentui/react-icons";

const useStyles = makeStyles({
  container: {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    ...shorthands.gap("8px"),
    ...shorthands.padding(0, "12px"),
    "& button": {
      minWidth: "82px",
      minHeight: "82px",
      ...shorthands.padding("4px"),
      "> div": {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        alignContent: "center",
        ...shorthands.gap("4px"),
      },
    },
  },
});

export interface InsertElementProps {}

export const InsertDynamicElement: FunctionComponent<InsertElementProps> = () => {
  const history = useHistory();
  const styles = useStyles();

  return (
    <>
      <div className={styles.container}>
        <DynamicElement
          onClick={() => history.push("/variables/new")}
          icon={mapDynamicElement(ContentControlType.VARIABLE).icon}
          label="Variable"
        />
        <DynamicElement
          onClick={() => history.push("/conditionals/new")}
          icon={mapDynamicElement(ContentControlType.IF).icon}
          label="Conditional"
        />
        <DynamicElement
          onClick={() => history.push("/signatures/new")}
          icon={mapDynamicElement(ContentControlType.SIGNATURE).icon}
          label="Signature"
        />
        <DynamicElement
          onClick={() => history.push("/initials/new")}
          icon={mapDynamicElement(ContentControlType.INITIALS).icon}
          label="Initials"
        />
        <DynamicElement
          onClick={() => history.push("/date-signed/new")}
          icon={mapDynamicElement(ContentControlType.DATE_SIGNED).icon}
          label="Date Signed"
        />
        <DynamicElement icon={<TextBulletListAdd20Regular name="add more" />}>
          <MenuItem icon={mapDynamicElement(ContentControlType.LOOP).icon} onClick={() => history.push("/loops/new")}>
            Loops
          </MenuItem>
          <MenuItem
            icon={mapDynamicElement(ContentControlType.SIGN_TAB_INPUT).icon}
            onClick={() => history.push("/form-inputs/new")}
          >
            Form Inputs
          </MenuItem>
        </DynamicElement>
      </div>
    </>
  );
};

interface DynamicElementProps {
  onClick?: () => void;
  icon: JSX.Element;
  label?: string;
  hasMenu?: boolean;
}

const DynamicElement: FunctionComponent<DynamicElementProps> = ({ onClick, icon, label, children }) => {
  if (children) {
    return (
      <Menu>
        <MenuTrigger disableButtonEnhancement>
          <Button size="small" data-testid="menu-button">
            <div>
              {icon}
              {label && <Caption1Strong>{label}</Caption1Strong>}
            </div>
          </Button>
        </MenuTrigger>

        <MenuPopover>
          <MenuList>{children}</MenuList>
        </MenuPopover>
      </Menu>
    );
  }

  return (
    <Button size="small" onClick={onClick}>
      <div>
        {icon}
        <Caption1Strong>{label}</Caption1Strong>
      </div>
    </Button>
  );
};

DynamicElement.displayName = "DynamicElement";
