import { FC, useState } from "react";
import { Checkbox, Input, Label, Select, Text, tokens } from "@fluentui/react-components";
import { NumberFormatterData } from "@src/lib/liquidx/BasicVariableElement";
import { Format } from "liquidx";

export const NumberFormatter: FC<{
  value: NumberFormatterData;
  onChange: (_: NumberFormatterData) => void;
}> = ({ value, onChange }) => {
  const [data, setData] = useState<NumberFormatterData>(value);
  const [preview, setPreview] = useState<string[]>(generatePreview(data.props));

  function generatePreview(props: NumberFormatterData["props"]) {
    try {
      return ["123.456789", "1234567.543210", "0.0123"].map((value) => Format.number(value, props));
    } catch (e: unknown) {
      if (import.meta.env.DEV && e instanceof Error) {
        return [`Invalid format: ${e.message} -- ${JSON.stringify(props)}`];
      } else {
        return ["Invalid format"];
      }
    }
  }

  function updateProps(updated: Partial<NumberFormatterData["props"]>) {
    setData((prev) => {
      const updatedValue = { ...prev, props: { ...prev.props, ...updated } };
      updateValue(updatedValue);
      return updatedValue;
    });
  }

  function updateValue(updated: NumberFormatterData) {
    setPreview(generatePreview(updated.props));
    onChange(updated);
  }

  return (
    <>
      {data.name === "currency" && (
        <>
          <Label>Currency</Label>
          <Select value={data.props.currency ?? "USD"} onChange={(_, { value }) => updateProps({ currency: value })}>
            <option value="USD">US Dollars ($)</option>
            <option value="CAD">Canadian Dollars ($)</option>
            <option value="EUR">Euro (€)</option>
            <option value="GBP">British Pound (£)</option>
          </Select>
        </>
      )}
      <Label>Decimal places</Label>
      <Input
        type="number"
        placeholder="Auto"
        min={0}
        max={10}
        onChange={(_, { value }) => {
          var parsedValue = parseInt(value, 10);
          updateProps({ decimalPlaces: isNaN(parsedValue) ? undefined : parsedValue });
        }}
        value={data.props.decimalPlaces?.toString() ?? ""}
      />
      <Checkbox
        checked={data.props.thousandsSeparator ?? false}
        onChange={(_, { checked }) => updateProps({ thousandsSeparator: checked === "mixed" || checked })}
        label="Thousands separator"
      />
      <Label>Preview</Label>
      <div
        style={{
          padding: "12px",
          borderRadius: "4px",
          backgroundColor: tokens.colorNeutralBackground1Hover,
          display: "flex",
          flexDirection: "column",
          gap: "6px",
        }}
      >
        {preview.map((char, i) => (
          <Text font="monospace" weight="semibold" key={i}>
            {char}
          </Text>
        ))}
      </div>
    </>
  );
};
