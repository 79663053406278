import {
  Button,
  makeStyles,
  Menu,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
  SplitButton,
} from "@fluentui/react-components";
import type { MenuButtonProps } from "@fluentui/react-components";
import { useFields } from "@src/taskpane/hooks/useFields";

type Props = {
  onClick: () => void;
  onSaveFieldSetClick: () => void;
};

const useStyles = makeStyles({
  button: {
    width: "100%",
  },
  wrapper: {
    paddingTop: "12px",
    paddingLeft: "12px",
    paddingRight: "12px",
  },
});

export const AddFieldButton = ({ onClick, onSaveFieldSetClick }: Props) => {
  const styles = useStyles();
  const { fields } = useFields();

  return (
    <div className={styles.wrapper}>
      {fields.length === 0 ? (
        <Button className={styles.button} onClick={onClick}>
          Add field
        </Button>
      ) : (
        <Menu positioning="below-end">
          <MenuTrigger disableButtonEnhancement>
            {(triggerProps: MenuButtonProps) => (
              <SplitButton className={styles.button} menuButton={triggerProps} primaryActionButton={{ onClick }}>
                Add field
              </SplitButton>
            )}
          </MenuTrigger>

          <MenuPopover>
            <MenuList>
              <MenuItem onClick={onSaveFieldSetClick}>Save field set</MenuItem>
            </MenuList>
          </MenuPopover>
        </Menu>
      )}
    </div>
  );
};
