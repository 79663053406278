import { OperationContext } from "../OperationContext";
import { ContentControlUpdate } from "./ContentControlUpdate";
import { ContentControlInsertionHelper } from "./ContentControlInsertionHelper";

export class VariableContentControlInsert extends ContentControlUpdate {
  public async commit(context: OperationContext) {
    context.trace("VariableContentControl#add");
    const newContentControl = await ContentControlInsertionHelper.insertContentControl(
      context.document.context,
      this.location,
    );
    await this.updateContentControl(context, newContentControl, Word.RangeLocation.after);
  }
}
