import * as React from "react";
import { JSX, FC, useRef, useState } from "react";
import { makeStyles, shorthands, tokens, Caption1 } from "@fluentui/react-components";
import { ChevronDown12Regular, ChevronRight12Regular } from "@fluentui/react-icons";
import clsx from "clsx";

export const useCollapsibleSectionStyles = makeStyles({
  container: {
    ...shorthands.borderTop("1px", "solid", tokens.colorNeutralStroke1),
    ...shorthands.borderBottom("1px", "solid", tokens.colorNeutralStroke1),
    ...shorthands.padding("2px", "8px"),
    color: tokens.colorNeutralForeground2,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: "3px",
  },
  containerCollapsed: {
    marginBottom: "-1px",
  },
  content: {
    scrollbarWidth: "none",
    ...shorthands.overflow("hidden", "scroll"),
  },
  contentScrollbars: {
    "&:hover": {
      scrollbarWidth: "thin",
      scrollbarGutter: "stable",
    },
  },
  title: {
    textTransform: "uppercase",
    fontSize: "11px",
    fontWeight: "600",
    userSelect: "none",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    cursor: "default",
  },
  icon: {
    width: "10px",
    fill: tokens.colorNeutralForeground2,
  },
  actions: {
    marginLeft: "auto",
    lineHeight: "16px",
    display: "flex",
    alignItems: "center",
    gap: "4px",
    flexDirection: "row",
  },
});

export const CollapsibleSection: FC<{
  title: string;
  isCollapsed?: boolean;
  maxHeight?: number;
  actions?: JSX.Element;
  collapsible?: boolean;
}> = ({ title, children, isCollapsed, maxHeight, collapsible = true, actions = null }) => {
  const [expanded, setExpanded] = useState(!isCollapsed);
  const styles = useCollapsibleSectionStyles();
  const containerRef = useRef<HTMLDivElement>(null);
  const actionsRef = useRef<HTMLDivElement>(null);

  const handleHeaderClick = (e: React.MouseEvent) => {
    if (!collapsible) return;

    // Collapse/expand if click was inside container but not within the container actions
    if (
      e.target instanceof Node &&
      containerRef?.current?.contains(e.target) &&
      !actionsRef?.current?.contains(e.target)
    ) {
      setExpanded(!expanded);
    }
  };

  return (
    <>
      <div
        ref={containerRef}
        className={clsx(styles.container, !expanded && styles.containerCollapsed)}
        onClick={handleHeaderClick}
      >
        {collapsible &&
          (expanded ? (
            <ChevronDown12Regular className={styles.icon} />
          ) : (
            <ChevronRight12Regular className={styles.icon} />
          ))}
        <Caption1 className={styles.title} title={title}>
          {title}
        </Caption1>
        {actions && (
          <div ref={actionsRef} className={styles.actions}>
            {actions}
          </div>
        )}
      </div>
      <div className={clsx(styles.content, maxHeight && styles.contentScrollbars)} style={{ maxHeight }}>
        {expanded && children}
      </div>
    </>
  );
};
