import { Variable } from "..";
import { VariableParser, type Property } from ".";

export class LoopParser extends VariableParser {
  protected handleArraySchema(property: Property): Variable[] {
    const { name, schema } = property;
    const { description, title, type, deprecated } = schema;

    // TODO: fix typescript type error. Remove @ts-ignore comment to see the error
    // @ts-ignore
    return [new Variable(name, title, description, type as string, undefined, undefined, deprecated)];
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected handleStringSchema(_property: Property): Variable[] {
    return [];
  }
}
