import { Variable } from "..";
import { SignTabParser, type Property } from ".";
import { JSONSchema9 } from "../../types/jsonSchema";

export class LoopSignTabParser extends SignTabParser {
  public buildWithoutRoot(schema: JSONSchema9): Variable[] {
    return this.buildFromObjectSchema(schema).filter((variable) => variable.isLoopVariable());
  }

  protected handleObjectSchema(property: Property): Variable[] {
    const { name, schema } = property;
    const { description, title, deprecated } = schema;
    const type = schema.type as string;

    if (SignTabParser.isSignTab({ name, schema: schema })) {
      // TODO: fix typescript type error. Remove @ts-ignore comment to see the error
      // @ts-ignore
      return [new Variable(name, title, description, type, undefined, undefined, deprecated)];
    }

    return this.buildFromObjectSchema(schema).map(
      // TODO: fix typescript type error. Remove @ts-ignore comment to see the error
      // @ts-ignore
      (child) => new Variable(name, title, description, type, child, undefined, deprecated),
    );
  }

  protected handleArraySchema(property: Property): Variable[] {
    const { name, schema } = property;
    const { description, title, type, deprecated } = schema;
    const items = schema.items as JSONSchema9;

    return this.buildFromObjectSchema(items).map((childVariable) => {
      const loopMarker = new Variable(
        "[]",
        // TODO: fix typescript type error. Remove @ts-ignore comment to see the error
        // @ts-ignore
        items.title,
        description,
        items.type as string,
        childVariable,
        undefined,
        items.deprecated,
      );
      // TODO: fix typescript type error. Remove @ts-ignore comment to see the error
      // @ts-ignore
      return new Variable(name, title, description, type as string, loopMarker, undefined, deprecated);
    });
  }
}
