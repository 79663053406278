import { FC, createContext, useContext } from "react";
import { Tree, TreeProps } from "@fluentui/react-components";

interface IndentedTreeContextType {
  level: number;
  pixelsPerLevel: number;
  padding: string;
  focusedItem?: string;
}

const IndentedTreeContext = createContext<IndentedTreeContextType>({
  level: 0,
  pixelsPerLevel: 16,
  padding: "0px",
});

/**
 * Hook for accessing the current tree level.
 * @returns The current tree level.
 */
export const useIndentedTree = (): IndentedTreeContextType => {
  return useContext(IndentedTreeContext);
};

interface IndentedTreeProps extends TreeProps {
  pixelsPerLevel?: number;
  level?: number;
  focusedItem?: string;
}

/**
 * A utility component that tracks the level of the current tree.
 * Because it forwards all properties to the Tree component, use it in place of a Tree component.
 *
 * You can set the level or pixelsPerLevel when using this component. If not set, it'll increment
 * the parent's level by 1 and use the parent's pixelsPerLevel.
 *
 * @example
 * ```tsx
 * import { IndentedTree, useIndentedTree } from "./IndentedTree";
 *
 * const MyComponent = () => {
 *   const { padding } = useIndentedTree();
 *
 *   return (
 *     <div styles={{paddingLeft: padding}}>Appropriately indented content</div>
 *   );
 * };
 * ```
 */
export const IndentedTree: FC<IndentedTreeProps> = ({
  level: levelOverride,
  pixelsPerLevel: pixelsPerLevelOverride,
  focusedItem: focusedItemOverride,
  ...props
}) => {
  const context = useIndentedTree();
  const level = levelOverride ?? context.level + 1;
  const pixelsPerLevel = pixelsPerLevelOverride ?? context.pixelsPerLevel;
  const focusedItem = focusedItemOverride ?? context.focusedItem;
  const padding = `${pixelsPerLevel * level}px`;

  return (
    <IndentedTreeContext.Provider value={{ ...context, level, pixelsPerLevel, padding, focusedItem }}>
      <Tree {...props} />
    </IndentedTreeContext.Provider>
  );
};
