import { syncEngine } from "@src/lib/sync-engine/SyncEngine";
import { GetSelectionText } from "@src/lib/sync-engine/operations/GetSelectedText";
import pluralize from "pluralize";
import { PluralizeUiData, PluralizeUpdateProps, SimpleComboboxOption } from "@src/lib/liquidx/PluralizeElement";
import { VariableRenderData } from "@src/lib/liquidx/ParsedTag";
import { propertyPathToFieldCode } from "@src/lib/liquidx/BasicVariableElement";
import { Snippet } from "./Snippet";

/**
 * This contains logic shared between the variable element and field, like validation and code generation.
 */
export class PluralizeSnippet extends Snippet<VariableRenderData, PluralizeUiData> {
  buildCode() {
    const node = this.schemaStore.find(this.ui.property);
    const fieldPropertyAccessCode = propertyPathToFieldCode(this.ui.property);

    // Property not valid for schema
    if (!node) return '""';

    // Append `length` to array properties
    const count = `${fieldPropertyAccessCode}${node.treeType === "array" ? "?.length" : ""}`;
    const plural = JSON.stringify(this.ui.plural);
    const singular = JSON.stringify(this.ui.singular);
    const countPrefix = this.ui.showCount ? "`${count} `" : `""`;

    return `const count = ${count} ?? 0; const countPrefix = ${countPrefix}; count === 1 ? countPrefix + ${singular} : countPrefix + ${plural}`;
  }

  private async derivePluralAndSingularFromSelectedText() {
    const selectedText = await this.getSelectedText();
    if (!selectedText) return { plural: "", singular: "" };

    return this.derivePluralAndSingular(selectedText.trim());
  }

  private async getSelectedText() {
    return await syncEngine.perform(new GetSelectionText());
  }

  private derivePluralAndSingular(value: string) {
    return pluralize.isPlural(value)
      ? { plural: value, singular: pluralize.singular(value) }
      : { plural: pluralize.plural(value), singular: value };
  }

  async update({ property, singular, plural, showCount }: PluralizeUpdateProps) {
    if (this.isNew) {
      const derived = await this.derivePluralAndSingularFromSelectedText();

      if (!singular) singular = derived.singular;
      if (!plural) plural = derived.plural;

      const title = this.schemaStore.find(property)?.title;
      if (title && (!singular || !plural)) {
        const derivedFromTitle = this.derivePluralAndSingular(title.toLowerCase());
        if (!singular) singular = derivedFromTitle.singular;
        if (!plural) plural = derivedFromTitle.plural;
      }
    }

    const selectedOption = this.options.find((option) => option.value === property);

    this.ui = {
      type: "pluralize",
      label: `Pluralize ${singular}${selectedOption ? ` using "${selectedOption.label}"` : ""}`,
      property,
      singular,
      plural,
      showCount,
    };

    this.render = {
      type: "variable",
      placeholder: this.placeholder,
      code: this.buildCode(),
    };

    this.validate();
    await this.dataStore.save();
  }

  get combinedWord() {
    const singular = this.ui.singular;
    const plural = this.ui.plural;

    if (plural.startsWith(singular)) {
      // Combine forms (e.g., "share(s)")
      const remainder = plural.slice(singular.length);
      return `${singular}(${remainder})`;
    } else {
      // When Show both words (e.g., "person/people")
      return `${singular}/${plural}`;
    }
  }

  get placeholder() {
    if (!this.ui.plural && !this.ui.singular) return "";
    return (this.ui.showCount ? "3 " : "") + this.combinedWord;
  }

  validate(): void {
    super.validate();

    const validProperties = this.options.map(({ value }) => value);
    if (!validProperties.includes(this.ui.property)) {
      this.errors.push(`${JSON.stringify(this.ui.property)} is not a valid property`);
    }
  }

  get options(): SimpleComboboxOption[] {
    return this.schemaStore.fetch("options", (store) => {
      return store.queryOptions({ type: /array|number/ });
    });
  }
}
