import { Button, makeStyles, tokens, Tooltip } from "@fluentui/react-components";
import { FC } from "react";

const useTooltipStyles = makeStyles({
  tooltip: {
    backgroundColor: tokens.colorNeutralBackground3Hover,
    color: tokens.colorNeutralForeground1,
  },
});

export const SubmitButton: FC<{ disabled: boolean; onClick: () => void; tooltipText?: string }> = ({
  disabled,
  onClick,
  children,
  tooltipText,
}) => {
  const tooltipStyles = useTooltipStyles();

  if (disabled) {
    return (
      <Tooltip
        withArrow
        content={{ children: tooltipText ?? "There are outstanding errors", className: tooltipStyles.tooltip }}
        relationship="label"
      >
        <Button disabled={true} appearance="primary">
          {children}
        </Button>
      </Tooltip>
    );
  }

  return (
    <Button onClick={onClick} appearance="primary">
      {children}
    </Button>
  );
};
