import debug from "@src/lib/debug";
import { OperationContext } from "../OperationContext";
import { Operation } from "./Operation";
import { findWalterCustomXmlPart } from "./SetValueInCustomXmlStore";

export class GetValueFromCustomXmlStore<T> implements Operation<T | undefined> {
  constructor(public key: string, public defaultValue?: T) {}

  merge(other: Operation<unknown>): Operation<T> | undefined {
    if (other instanceof GetValueFromCustomXmlStore && other.key === this.key) {
      return other as unknown as Operation<T>;
    }
  }

  async commit(context: OperationContext) {
    const partAndDocument = await findWalterCustomXmlPart(context);

    if (partAndDocument) {
      const customXmlStore = partAndDocument.customXmlStore;
      const propertyValue = customXmlStore.getProperty(this.key) as T;

      return propertyValue ?? this.defaultValue;
    } else {
      return this.defaultValue;
    }
  }
}

export class InspectCustomXmlStore implements Operation<void> {
  merge(_: Operation<unknown>): Operation<void> | undefined {
    return undefined;
  }

  async commit(context: OperationContext) {
    const partAndDocument = await findWalterCustomXmlPart(context);

    if (partAndDocument) {
      debug.log("Custom XML", partAndDocument.customXmlStore.document);
      debug.log("Custom XML schema", partAndDocument.customXmlStore.getProperty("schema"));
      debug.log("Custom XML appliedMigrations", partAndDocument.customXmlStore.getProperty("appliedMigrations"));
    } else {
      debug.error("Custom XML part not found");
    }
  }
}
