import { FC, ReactElement } from "react";
import { CollapsibleSection } from "../CollapsibleSection";
import { useFocusedDynamicElement } from "@src/taskpane/hooks/useDynamicElement";
import {
  AdvancedIfElement,
  AdvancedVariableElement,
  BasicLoopElement,
  BasicVariableElement,
  BranchElement,
  DynamicElement,
  JsElement,
  PluralizeElement,
  SignTabJsElement,
} from "@src/lib/liquidx/internal";
import { BasicVariable } from "../BasicVariable";
import { makeStyles, shorthands } from "@fluentui/react-components";
import { BasicLoop } from "../BasicLoop";
import { SignTab } from "../SignTab";
import { BasicIf } from "../If";
import { BasicIfElement } from "@src/lib/liquidx/BasicIfElement";
import { AdvancedIf } from "../CodeExpression/AdvancedIf";
import { AdvancedVariable } from "../CodeExpression/AdvancedVariable";
import { Pluralize } from "../Pluralize";
import { AliasElement } from "@src/lib/liquidx/AliasElement";
import { Alias } from "../Alias";

const useStyles = makeStyles({
  root: {
    ...shorthands.padding("12px"),
  },
});

const routes: Record<string, (_: DynamicElement) => ReactElement> = {
  "variable/basic": (element: DynamicElement) => {
    return <BasicVariable element={element as BasicVariableElement} />;
  },
  "variable/signTab": (element: DynamicElement) => {
    return <SignTab element={element as SignTabJsElement} />;
  },
  "variable/pluralize": (element: DynamicElement) => {
    return <Pluralize snippet={(element as PluralizeElement).snippet} />;
  },
  "variable/advanced": (element: DynamicElement) => {
    return <AdvancedVariable element={element as AdvancedVariableElement} />;
  },
  "variable/alias": (element: DynamicElement) => {
    return <Alias element={element as AliasElement} />;
  },
  "loop/basic": (element: DynamicElement) => {
    return <BasicLoop element={element as BasicLoopElement} />;
  },
  "if/basic": (element: DynamicElement) => {
    return <BasicIf element={element as BasicIfElement} />;
  },
  "if/advanced": (element: DynamicElement) => {
    return <AdvancedIf element={element as AdvancedIfElement} />;
  },
};

export const FocusedElementPane: FC = () => {
  const styles = useStyles();
  let focusedElement = useFocusedDynamicElement();

  if (focusedElement === undefined) return null;

  // When focused on an else branch, show the parent `if` element instead
  if (focusedElement instanceof BranchElement) {
    focusedElement = focusedElement.parent;
  }

  if (focusedElement instanceof JsElement) {
    const elementFunc = routes[focusedElement.type];

    if (!elementFunc) return null;

    const elementComponent = elementFunc(focusedElement);
    return (
      <CollapsibleSection title={focusedElement.focusedTitle}>
        <div className={styles.root}>{elementComponent}</div>
      </CollapsibleSection>
    );
  } else {
    return null;
  }
};
