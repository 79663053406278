import { FC } from "react";
import { ConditionData, ConditionGroupData, JoinOperatorType } from "./ConditionCodeGen";
import { Condition } from "./Condition";
import { ConditionGroup } from "./ConditionGroup";

/**
 * Wraps a condition or group in a list item, forwarding the onChange event to the appropriate handler.
 */
export const ConditionOrGroup: FC<{
  data: ConditionData | ConditionGroupData;
  joinOperator: JoinOperatorType;
  isFirst: boolean;
  onChange?: (_: ConditionData | ConditionGroupData, joinOperator: JoinOperatorType) => void;
}> = ({ data, joinOperator: joinOperator, onChange, isFirst: isFirst }) => {
  if ("left" in data) {
    return <Condition condition={data} joinOperator={joinOperator} onChange={onChange} isFirst={isFirst} />;
  } else {
    return <ConditionGroup group={data} joinOperator={joinOperator} onChange={onChange} isFirst={isFirst} />;
  }
};
