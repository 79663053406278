import { OperationContext } from "../OperationContext";
import { InsertLocation } from "../../liquidx";
import { Operation } from "./Operation";

export type Params = {
  id: number | null;
  tag: string;
  title: string;
  innerText?: string;
  errors: string[];
  style?: string;
  font?: Word.Interfaces.FontData;
  color: string;
  appearance: Word.ContentControlAppearance;
  isSystemUpdate: boolean;
  location: InsertLocation;
  cannotEdit?: boolean;
  cannotDelete?: boolean;
  placeholderText?: string | undefined;
};

export class ContentControlUpdate implements Operation<void> {
  public id: Params["id"];
  public tag: Params["tag"];
  public title: Params["title"];
  public color: Params["color"];
  public font: Params["font"];
  public style: Params["style"];
  public errors: Params["errors"];
  public appearance: Params["appearance"];
  public innerText: Params["innerText"];
  public isSystemUpdate: Params["isSystemUpdate"];
  public location: Params["location"];
  public placeholderText: Params["placeholderText"];
  public cannotEdit: boolean;
  public cannotDelete: boolean;

  constructor({
    id,
    tag,
    title,
    font,
    style,
    errors,
    location,
    appearance,
    color,
    innerText,
    placeholderText,
    cannotDelete = false,
    cannotEdit = false,
    isSystemUpdate = false,
  }: Params) {
    this.id = id;
    this.tag = tag;
    this.title = title;
    this.color = color;
    this.font = font;
    this.style = style;
    this.location = location;
    this.appearance = appearance;
    this.innerText = innerText;
    this.errors = errors;
    this.cannotDelete = cannotDelete;
    this.cannotEdit = cannotEdit;
    this.isSystemUpdate = isSystemUpdate;
    this.placeholderText = placeholderText;
  }

  public async getContentControl(context: OperationContext) {
    if (!this.id) throw new Error("Cannot commit an update without an ID");

    const contentControl = context
      .getContentControl(this.id)
      .load(["id", "title", "tag", "color", "text", "isNullObject"]);
    await context.sync();

    return contentControl;
  }

  public async commit(context: OperationContext) {
    const contentControl = await this.getContentControl(context);

    if (contentControl.isNullObject) return;
    await this.updateContentControl(context, contentControl);
  }

  protected isTextLoaded(contentControl: Word.ContentControl) {
    try {
      contentControl.text;
    } catch (e) {
      if (
        e instanceof OfficeExtension.Error &&
        e.code === "PropertyNotLoaded" &&
        e.debugInfo.errorLocation === "ContentControl.text"
      ) {
        return false;
      }

      throw e;
    }

    return true;
  }

  protected async updateContentControl(
    context: OperationContext,
    contentControl: Word.ContentControl,
    moveCursorTo?: Word.RangeLocation,
  ) {
    if (this.innerText !== undefined) {
      const isTextLoaded = this.isTextLoaded(contentControl);

      // When text property is loaded and it's not a system update (i.e., update came from editing the document),
      if (isTextLoaded && !this.isSystemUpdate) {
        moveUserAddedText(contentControl, this.innerText);
      }

      // Update the placeholder text if (a) the text is different from the current text, or (b) text isn't loaded so we can't compare
      if (!isTextLoaded || contentControl.text !== this.innerText) {
        contentControl.insertText(this.innerText, Word.InsertLocation.replace);
      }
    }

    if (this.font) contentControl.font.set(this.font);

    // NOTE: Applying a style resets any existing font properties.
    if (this.style) {
      contentControl.getRange("Content").style = this.style;
    }

    contentControl.set({
      appearance: this.appearance,
      cannotDelete: this.cannotDelete,
      cannotEdit: this.cannotEdit,
      title: this.title,
      color: this.color,
      tag: this.tag,
    });

    if (typeof this.placeholderText === "string" && Office.context.platform !== Office.PlatformType.OfficeOnline) {
      contentControl.placeholderText = this.placeholderText;
    }

    if (moveCursorTo) contentControl.getRange(moveCursorTo).select();

    await context.sync();
  }

  /**
   * This combines two updates for the same content control into a single update.
   * Last update wins, except for `isSystemUpdate`, which is &&'d together (any non-system updates causes it to be false)
   *
   * @param other another update to merge with this one
   * @returns
   */
  // TODO: fix typescript type error. Remove @ts-ignore comment to see the error
  // @ts-ignore
  public merge(other: Operation<void>) {
    if (other instanceof ContentControlUpdate && this.id === other.id) {
      const errors = new Set([...this.errors, ...other.errors]);

      return new ContentControlUpdate({
        id: this.id,
        location: other.location,
        tag: other.tag,
        title: other.title,
        color: other.color,
        appearance: other.appearance,
        errors: Array.from(errors),
        font: { ...this.font, ...other.font },
        innerText: other.innerText ?? this.innerText,
        isSystemUpdate: this.isSystemUpdate && other.isSystemUpdate,
      });
    }
  }
}

function moveUserAddedText(contentControl: Word.ContentControl, placeholder: string) {
  const oldText = contentControl.text;
  const [diff, position] = diffAndPosition(oldText, placeholder);

  if (diff) {
    contentControl.getRange(position).insertText(diff, position);
  }
}

/**
 * Calculates the difference between two strings and determines the position of the difference.
 *
 * Only works when changes appear at the beginning or end of the strings.
 *
 * @param str1 - The first string to compare.
 * @param str2 - The second string to compare.
 * @returns A tuple containing the difference between the strings and the position of the difference.
 */
function diffAndPosition(str1: string, str2: string): [string, "Before" | "After"] {
  if (str1 === "") {
    return ["", "Before"];
  }

  let prefixLength = 0;
  const minLength = Math.min(str1.length, str2.length);
  // Find prefix match length
  while (prefixLength < minLength && str1[prefixLength] === str2[prefixLength]) {
    prefixLength++;
  }

  // Check for suffix match starting from the end of the shortest string
  let suffixLength = 0;
  while (
    suffixLength < minLength - prefixLength &&
    str1[str1.length - 1 - suffixLength] === str2[str2.length - 1 - suffixLength]
  ) {
    suffixLength++;
  }

  // Determine which part is different and its location
  let diff;
  if (prefixLength + suffixLength < str1.length) {
    diff = str1.substring(prefixLength, str1.length - suffixLength);
  } else if (prefixLength + suffixLength < str2.length) {
    diff = str2.substring(prefixLength, str2.length - suffixLength);
  } else {
    // No difference or unable to determine
    return ["", "After"];
  }

  const position = prefixLength === 0 ? "Before" : "After";

  return [diff, position];
}
