import { lookupRef } from "../resolveRefs";
import { SignTabParser, type Property } from ".";
import { Variable } from "../";
import { JSONSchema9 } from "../../types/jsonSchema";

export class VariableParser {
  public buildFromObjectSchema(schema: JSONSchema9): Variable[] {
    // Initial as empty object if no properties are defined (this mitigates a bug that stored an empty object as the schema)
    if (Object.keys(schema).length === 0) schema = { type: "object", properties: {} };
    if (schema.type !== "object") throw new Error(`Schema must be an object: ${JSON.stringify(schema, null, 2)}`);
    if (schema.properties === undefined)
      throw new Error(`Schema must contain properties: ${JSON.stringify(schema, null, 2)}`);

    return Object.entries(schema.properties).flatMap(([propertyName, propertySchema]) => {
      if (typeof propertySchema === "boolean") return [];
      return this.buildFromSchema({ schema: propertySchema, name: propertyName });
    });
  }

  /**
   * Build a list of variables from a schema under a given name.
   * This works for complex schemas, like an object with properties, or simple ones (like a string).
   * This also supports references to other schemas via the $ref property.
   * This recursively builds variables for object properties but doesn't for array properties.
   *
   * To build a list of variables from a root schema, use `buildFromObjectSchema` instead.
   *
   * @param name of the variable
   * @param schema that defines the variable
   */
  public buildFromSchema({ schema, name }: { schema: JSONSchema9; name: string }): Variable[] {
    const { type, $ref } = schema;

    if ($ref) {
      // Lookup referenced schema and build variables from that schema
      const refSchema = lookupRef($ref);
      return this.buildFromSchema({ schema: refSchema, name });
    } else if (type === "object") {
      return this.handleObjectSchema({ name, schema });
    } else if (type === "array") {
      return this.handleArraySchema({ name, schema });
    } else if (typeof type === "string") {
      return this.handleStringSchema({ name, schema });
    } else {
      throw new Error(`Unexpected schema type, ${type}: ${JSON.stringify(schema, null, 2)}`);
    }
  }

  protected handleObjectSchema(property: Property): Variable[] {
    const { name, schema } = property;
    const { description, title, type, deprecated, format } = schema;

    if (SignTabParser.isSignTab({ name, schema })) {
      return [];
    }

    // Recursively build variables from the object's properties
    return this.buildFromObjectSchema(schema).map(
      (childVariable) =>
        // TODO: fix typescript type error. Remove @ts-ignore comment to see the error
        // @ts-ignore
        new Variable(name, title, description, type as string, childVariable, undefined, deprecated, format),
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected handleArraySchema(_property: Property): Variable[] {
    return [];
  }

  protected handleStringSchema(property: Property): Variable[] {
    const { name, schema } = property;
    const { description, title, type, deprecated, format } = schema;

    // TODO: fix typescript type error. Remove @ts-ignore comment to see the error
    // @ts-ignore
    return [new Variable(name, title, description, type as string, undefined, undefined, deprecated, format)];
  }
}
