import { BatchContext } from "./BatchContext";
import { ContentControlDelete } from "./operations/ContentControlDelete";
import { ContentControlUpdate, Params } from "./operations/ContentControlUpdate";
import { VariableContentControlInsert } from "./operations/VariableContentControlInsert";
import debug from "../debug";
import { OperationContext } from "./OperationContext";
import { OperationWrapper } from "./OperationWrapper";
import { WordWrapper } from "./WordWrapper";
import { SelectionDetails } from "./operations/GetSelection";
import { SelectionEventHandler } from "./event-handlers/SelectionEventHandler";
import { Operation } from "./operations/Operation";
import { SYNC_INTERVAL_MS } from "./constants";
import { captureErrorInfo } from "@src/taskpane/helpers/errorHandler";
/**
 * A queue for processing read/write operations to the Word document.
 */
export class SyncEngine {
  maxBatchSize = 25;
  syncInterval = SYNC_INTERVAL_MS;
  intervalId: number | undefined;
  fatalErrorListeners: ((e: unknown) => void)[] = [];
  failedOperationListeners: ((operation: Operation<unknown>, e: unknown) => void)[] = [];
  withinContext: typeof WordWrapper.run;
  queue: Array<OperationWrapper<unknown>> = [];

  private isSyncing = false;
  private selectionEventHandler = new SelectionEventHandler(this);

  constructor(withinContext: typeof WordWrapper.run = WordWrapper.run) {
    this.withinContext = withinContext;
  }

  get enqueuedOperations() {
    return [...this.queue];
  }

  /**
   * Start a background sync loop that will run every `syncInterval` milliseconds.
   */
  start() {
    this.intervalId = setInterval(async () => {
      await this.sync();
    }, this.syncInterval) as unknown as number;
  }

  stop() {
    if (this.intervalId) {
      window.clearInterval(this.intervalId);
    }
  }

  /**
   * When an exception is thrown from within the sync engine, not within an operation, it is caught and passed to this listener.
   *
   * @param listener
   */
  onFatalError(listener: (e: unknown) => void) {
    this.fatalErrorListeners.push(listener);
  }

  /**
   * When an operation fails—throws an error during initial and subsequent retries—it is caught and passed to this listener.
   *
   * @param listener
   */
  onFailedOperation(listener: (operation: Operation<unknown>, e: unknown) => void) {
    this.failedOperationListeners.push(listener);
  }

  /**
   * Start a background sync loop that will run during the execution of the callback.
   * Once the callback function returns, the sync loop will stop.
   *
   * Useful if you want to run a series of operations and ensure they are all completed before continuing.
   *
   * @param callback
   * @returns whatever the callback returned
   */
  async run<T>(callback: () => Promise<T>): Promise<T> {
    const intervalId = setInterval(async () => {
      void this.sync();
    }, this.syncInterval);

    const value = await callback();

    clearInterval(intervalId);

    return value;
  }

  public perform<T>(operation: Operation<T>) {
    return new Promise<T>((resolve, reject) => {
      const wrapper = new OperationWrapper<T>(operation, [resolve], [reject]);

      let wasMerged = false;
      for (let i = 0; i < this.queue.length; i++) {
        const existingOperation = this.queue[i];
        const merged = existingOperation.merge(wrapper as OperationWrapper<unknown>);

        if (merged) {
          this.queue[i] = merged;
          wasMerged = true;
          break;
        }
      }

      if (!wasMerged) {
        // If not merged, add to the end of the queue.
        this.queue.push(wrapper as OperationWrapper<unknown>);
      }
    });
  }

  public insert(params: Params) {
    return this.perform(new VariableContentControlInsert(params));
  }

  public update(params: Params) {
    return this.perform(new ContentControlUpdate(params));
  }

  public delete({ id, keepContents = false }: { id: number; keepContents?: boolean }): Promise<void> {
    return this.perform(new ContentControlDelete({ id, keepContents }));
  }

  public dequeueBatch(): OperationWrapper<unknown>[] {
    const operations: OperationWrapper<unknown>[] = [];

    while (this.queue.length > 0 && operations.length < this.maxBatchSize) {
      const newOperation = this.queue.shift();
      if (!newOperation) continue;
      operations.push(newOperation);
    }

    return operations;
  }

  get queueEmpty() {
    return this.queue.length === 0;
  }

  public async sync(): Promise<void> {
    if (this.isSyncing) return;
    if (this.queueEmpty) return;

    this.isSyncing = true;
    try {
      const operations = this.dequeueBatch();
      if (operations.length === 0) return;
      const length = operations.length;

      debug.time(`sync (size: ${length})`);
      try {
        await this.applyOperations(operations);
      } catch (e) {
        debug.error("fatal error occurred while syncing", e);

        if (e instanceof OfficeExtension.Error) {
          (e.debugInfo as unknown as string) = JSON.stringify(e.debugInfo);
        }

        this.fatalErrorListeners.forEach((listener) => listener(e));
      }

      debug.timeEnd(`sync (size: ${length})`);
    } finally {
      this.isSyncing = false;
    }
  }

  public async applyOperations(operations: OperationWrapper<unknown>[], retryFailedOperations: boolean = true) {
    if (operations.length === 0) return;

    debug.log(
      "applyingOperations",
      operations.map((op) => op.inspect()),
    );
    // Apply updates to the content controls.
    await this.withinContext(async (context) => {
      const batchContext = new BatchContext(context, operations.length);

      for (const operation of operations) {
        const operationContext = new OperationContext(operation, batchContext);

        operation
          .commit(operationContext)
          .then(() => batchContext.successful(operation))
          .catch(async (error) => void batchContext.failed(operation, error));
      }

      while (batchContext.inProgress) {
        try {
          await batchContext.waitUntilOperationsWaitingOrDone();
        } catch (e) {
          const nextAction = retryFailedOperations ? "will attempt retry" : "skipping failed operations";
          console.warn(`error occurred while syncing, ${nextAction}`, e);
        }
      }

      if (retryFailedOperations) {
        // Retry operations, one-by-one.
        for (const failedOperation of batchContext.failedOperations) {
          console.warn("retrying failed operation", failedOperation.operation.operation);
          await this.applyOperations([failedOperation.operation], false);
        }
      } else {
        // Reject all failed operations.
        for (const failedOperation of batchContext.failedOperations) {
          this.failedOperationListeners.forEach((listener) =>
            listener(failedOperation.operation.operation, failedOperation.error),
          );

          // Capture error details for debugging.
          try {
            const error = failedOperation.error;
            if (typeof OfficeExtension !== "undefined" && error instanceof OfficeExtension.Error) {
              captureErrorInfo(error);
            }
          } catch (e) {
            console.warn("error occurred while capturing additional error details", e);
            captureErrorInfo(failedOperation.error);
          }

          failedOperation.reject();
        }
      }
    });
  }

  public addSelectionChangeEventListener(handler: (selectionDetails: SelectionDetails) => void) {
    this.selectionEventHandler.addSelectionChangedEventListener(handler);
  }

  public removeSelectionChangeEventListener(handler: (selectionDetails: SelectionDetails) => void) {
    this.selectionEventHandler.addSelectionChangedEventListener(handler);
  }
}

export const syncEngine = new SyncEngine();
