import { ConditionData, OperatorType } from "./ConditionCodeGen";
import { Option, SchemaNode } from "@src/lib/schemas";

export class ConditionModel {
  operatorsByType: Record<string, Option<OperatorType>[]> = {
    string: [
      { label: "is", value: "equals" },
      { label: "is not", value: "notEquals" },
      { label: "is not blank", value: "isTrue" },
      { label: "is blank", value: "isFalse" },
      { label: "contains", value: "contains" },
      { label: "does not contain", value: "notContains" },
      { label: "starts with", value: "startsWith" },
      { label: "ends with", value: "endsWith" },
      { label: "matches", value: "matches" },
      // TODO: Add support for arrays
      // { label: "in", value: "in" },
      // { label: "not in", value: "notIn" },
    ],
    number: [
      { label: "is", value: "equals" },
      { label: "is not", value: "notEquals" },
      { label: "has any value", value: "isTrue" },
      { label: "does not have any value", value: "isFalse" },
      { label: "is larger than", value: "greaterThan" },
      { label: "is larger than or equal to", value: "greaterThanOrEquals" },
      { label: "is smaller than", value: "lessThan" },
      { label: "is smaller than or equal to", value: "lessThanOrEquals" },
      // TODO: Add support for arrays
      // { label: "in", value: "in" },
      // { label: "not in", value: "notIn" },
      // TODO: Add support for ranges
      // { label: "is between", value: "between" },
    ],
    boolean: [
      { label: "is true", value: "isTrue" },
      { label: "is false", value: "isFalse" },
    ],
    date: [
      { label: "is", value: "equals" },
      { label: "is not", value: "notEquals" },
      { label: "is after", value: "greaterThan" },
      { label: "is before", value: "lessThan" },
      { label: "is on or after", value: "greaterThanOrEquals" },
      { label: "is on or before", value: "lessThanOrEquals" },
      { label: "has any value", value: "isTrue" },
      { label: "does not have any value", value: "isFalse" },
    ],
    array: [
      { label: "has length of", value: "lengthEquals" },
      { label: "has length of more than", value: "lengthGreaterThan" },
      { label: "has length of at least", value: "lengthGreaterThanOrEqualTo" },
      { label: "has length of at most", value: "lengthLessThanOrEqualTo" },
      { label: "has length of less than", value: "lengthLessThan" },
      { label: "has items", value: "lengthIsTrue" },
      { label: "doesn't have items", value: "lengthIsFalse" },
    ],
  };

  node: SchemaNode | undefined;
  constructor(public data: ConditionData, private nodes: SchemaNode[]) {
    this.node = this.nodes.find((node) => node.path === this.data.left.value);
  }

  get operators(): Option<OperatorType>[] {
    return this.operatorsByType[this.leftType] ?? this.operatorsByType["string"];
  }

  get operatorLabel(): string {
    return this.operators.find((operator) => operator.value === this.data.operator)?.label ?? "";
  }

  operatorWithNoRightOperand: OperatorType[] = ["isTrue", "isFalse", "lengthIsTrue", "lengthIsFalse"];
  get hasRight(): boolean {
    if (this.leftType === "boolean") return false;
    if (!this.operatorLabel) return false;

    return !this.operatorWithNoRightOperand.includes(this.data.operator as OperatorType);
  }

  get leftType(): string {
    return this.node?.format || this.node?.type?.toString() || "string";
  }
}
