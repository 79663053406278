// TODO: How can we use an alias for this?
import liquidxTypesUnsafe from "../../../liquidx-js/dist/liquidx.es.d.ts?raw";
import templateObjectsUnsafe from "@src/lib/types/template_objects.d.ts?raw";

function stripExportDeclare(input: string) {
  return input
    .replace(/^\s*export\s+declare/gm, "\ndeclare")
    .replace("export { }", "")
    .replace(/^(\s*)export(\s+)interface/gm, "$1declare$2interface")
    .replace(/^(\s*)export(\s+)type/gm, "$1declare$2type");
}

export const liquidxTypes = stripExportDeclare(liquidxTypesUnsafe);
export const templateObjects = stripExportDeclare(templateObjectsUnsafe);
