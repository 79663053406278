import { Operation } from "./Operation";
import { OperationContext } from "../OperationContext";

export interface SelectedAndFocused {
  selectedIds: number[];
  focusedId: number | null;
}

export class GetSelectedAndFocused implements Operation<SelectedAndFocused> {
  async commit(context: OperationContext): Promise<SelectedAndFocused> {
    if (context.platform === "OfficeOnline") {
      return this.forOnline(context);
    } else {
      return this.forDesktop(context);
    }
  }

  async forDesktop(context: OperationContext): Promise<SelectedAndFocused> {
    const range = context.document.getSelection();
    range.load([
      "parentContentControlOrNullObject/isNullObject",
      "contentControls/items",
      "contentControls/items",
      "parentContentControlOrNullObject",
    ]);

    await context.sync();

    const contentControls = [...range.contentControls.items];
    // When the selection is wholly contained within a content control, it isn't returned in selection.contentControls.
    // However, we do consider it to be "focused" in the UI, so we add it to the list of content controls.
    if (contentControls.length === 0 && !range.parentContentControlOrNullObject.isNullObject) {
      contentControls.push(range.parentContentControlOrNullObject);
    }

    const selectedIds = contentControls.map((cc) => cc.id);

    return {
      selectedIds,
      focusedId: selectedIds.length > 0 ? selectedIds[0] : null,
    };
  }

  /**
   * This returns the selected/focused content control IDs for Word Online.
   * This is more complex than desktop because Word Online retrieves the entire
   * hierarchy of content controls above the selection. For example, if you place
   * the cursor inside a variable within a loop, you'd get back both the variable
   * and the loop IDs.
   *
   * To manage this, we retrieve both the content control ID and its parent ID, allowing
   * us to filter out parent content controls that are returned within and without the selection.
   *
   * This requires more calls to `context.sync` than the desktop version, but because Word Online
   * tends to be more performant, it still feels snappy.
   *
   * How do we pick the focused element?
   * 1. When there is one or more descendants, the focused element is the first descendant (i.e., child)
   * 2. When there is no descendant, the focused element is the first ancestor (i.e., parent)
   *
   * @param context
   * @returns
   */
  async forOnline(context: OperationContext): Promise<SelectedAndFocused> {
    const selection = context.document.getSelection();
    const contentControlsForSelection = await this.contentControlIdsFor(selection, context);
    const contentControlsForSelectionAfter = await this.contentControlIdsFor(selection.getRange("After"), context);
    // Anything in the "after" selection is considered to be an ancestor of the selection (i.e., contains the selection)
    const ancestorIds = contentControlsForSelectionAfter.map((cc) => cc.id);
    // Anything in the selection that isn't in the after selection is considered to be a descendant of the selection (i.e., contained within the selection)
    const descendantIds = contentControlsForSelection.map((cc) => cc.id).filter((id) => !ancestorIds.includes(id));
    const selectedIds = contentControlsForSelection.map((cc) => cc.id);

    return {
      selectedIds,
      focusedId: descendantIds.length === 0 ? ancestorIds[0] : descendantIds[0],
    };
  }

  async contentControlIdsFor(
    range: Word.Range,
    context: OperationContext,
  ): Promise<Array<{ id: number; parentId: number | null }>> {
    range.load(["contentControls", "contentControls/items", "contentControls/items/parentContentControlOrNullObject"]);

    await context.sync();

    const contentControls = [...range.contentControls.items];
    const parentContentControls = contentControls.reduce((obj: Record<number, Word.ContentControl>, cc) => {
      obj[cc.id] = cc.parentContentControlOrNullObject;
      return obj;
    }, {});

    await context.sync();

    const contentControlIds = contentControls.map((cc) => ({
      id: cc.id,
      parentId: parentContentControls[cc.id].isNullObject ? null : parentContentControls[cc.id].id,
    }));

    return contentControlIds;
  }

  merge(other: Operation<unknown>) {
    if (other instanceof GetSelectedAndFocused) {
      return this;
    }
  }
}
