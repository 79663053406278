import { FC } from "react";
import { typographyStyles, makeStyles } from "@fluentui/react-components";

export interface ComboboxOptionProps {
  name: string;
  description: string;
}

const nameFont = typographyStyles.body1Strong;
const descriptionFont = typographyStyles.caption1;

const useStyles = makeStyles({
  cell: { minHeight: "24px", display: "flex", alignItems: "center" },
  content: {},
  name: { fontFamily: nameFont.fontFamily, fontSize: nameFont.fontSize, fontWeight: nameFont.fontWeight },
  description: {
    fontFamily: descriptionFont.fontFamily,
    fontSize: descriptionFont.fontSize,
    fontWeight: descriptionFont.fontWeight,
  },
});

const ComboboxOption: FC<ComboboxOptionProps> = ({ name, description }) => {
  const styles = useStyles();

  return (
    <div className={styles.cell}>
      <div className={styles.content}>
        <div className={styles.name}>{name}</div>
        <div className={styles.description}>{description}</div>
      </div>
    </div>
  );
};

export default ComboboxOption;
