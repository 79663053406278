import * as React from "react";
import { FC, useState, useEffect } from "react";
import { Combobox, makeStyles, Option, shorthands, useId, Body1Strong, Label } from "@fluentui/react-components";
import type { ComboboxProps, OptionOnSelectData, SelectionEvents } from "@fluentui/react-components";
import ComboboxOption from "./ComboboxOption";
import { InputOption } from "../../../lib/walter";

export interface WComboboxProps {
  options: InputOption[];
  onSelect: (value: string | undefined) => void;
  label: string;
  selectedOption?: string;
  comboboxProps?: Partial<ComboboxProps>;
}

const useStyles = makeStyles({
  root: {
    display: "grid",
    ...shorthands.gap("8px"),
  },
});

const WCombobox: FC<WComboboxProps> = ({
  options,
  onSelect,
  label,
  selectedOption,
  comboboxProps = { defaultOpen: false, placeholder: "Search for variable" },
}) => {
  const findOption = (value: string): InputOption | undefined => {
    return options.find((option) => option.value === value);
  };

  const getSelectedOption = (): InputOption | undefined => {
    if (selectedOption) {
      return findOption(selectedOption);
    }

    return undefined;
  };

  const [selected, setSelected] = useState<InputOption | undefined>(getSelectedOption());
  const [value, setValue] = useState<string>(selected?.label || "");
  const [matchingOptions, setMatchingOptions] = useState<InputOption[]>(options || []);
  const comboId = useId("combo-controlled");
  const styles = useStyles();

  useEffect(() => {
    const option = getSelectedOption();

    setSelected(option);
    setValue(option?.label || "");
  }, [selectedOption]);

  const onOptionSelect = (_: SelectionEvents, data: OptionOnSelectData) => {
    if (!data.optionValue) return;

    const option = findOption(data.optionValue);

    setSelected(option);
    setValue(option?.label || "");
    onSelect(data.optionValue);
  };

  const onFilter = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const value = ev.target.value;

    const matches = options.filter((option) => option.label.toLowerCase().indexOf(value.toLowerCase()) !== -1);

    setValue(value);
    setMatchingOptions(matches);

    if (matches.length === 0 || value === "") {
      onSelect(undefined);
    }
  };

  const noResults = matchingOptions.length === 0;

  return (
    <div className={styles.root}>
      <Label id={comboId}>
        <Body1Strong>{label}</Body1Strong>
      </Label>
      <Combobox
        aria-labelledby={comboId}
        id={comboId}
        style={{ width: "100%" }}
        {...comboboxProps}
        selectedOptions={selected?.value ? [selected.value] : []}
        value={value}
        onChange={onFilter}
        onOptionSelect={onOptionSelect}
      >
        {noResults ? (
          <Option key="no-results" text="">
            No results found
          </Option>
        ) : (
          matchingOptions.map((option) => (
            <Option key={option.value} text={option.label} value={option.value}>
              <ComboboxOption name={option.label} description={option.description} />
            </Option>
          ))
        )}
      </Combobox>
    </div>
  );
};

export default WCombobox;
