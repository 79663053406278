import { JsElement } from "@src/lib/liquidx/internal";
import { JsTag, VariableRenderData } from "./ParsedTag";
import { JsElementProps } from "./JsElement";
import { walterStyles } from "../styles";
import { PluralizeSnippet } from "@src/taskpane/components/PluralizeSnippet";

export interface PluralizeUpdateProps {
  property: string;
  singular: string;
  plural: string;
  showCount: boolean;
}

export interface PluralizeUiData {
  type: "pluralize";
  label?: string;
  property: string;
  singular: string;
  plural: string;
  showCount: boolean;
}

export interface SimpleComboboxOption {
  label: string;
  value: string;
}

export class PluralizeElement extends JsElement<VariableRenderData, PluralizeUiData> {
  static supports({ render, ui }: JsTag) {
    return render.type === "variable" && ui.type === "pluralize";
  }

  snippet: PluralizeSnippet;
  render: VariableRenderData;
  ui: PluralizeUiData;
  defaultTag: JsTag<VariableRenderData, PluralizeUiData> = {
    render: { type: "variable", code: "" },
    ui: {
      type: "pluralize",
      property: "",
      singular: "",
      plural: "",
      showCount: false,
    },
    valid: true,
  };

  constructor();
  constructor(props: JsElementProps);
  constructor(props?: JsElementProps) {
    super();

    // Provide default values
    const tag = props?.tag ?? this.defaultTag;

    // Assign values
    this.id = props?.id ?? null;
    this.parentId = props?.parentId;
    this.render = {
      ...this.defaultTag.render,
      ...(tag?.render ?? {}),
      type: "variable",
    };

    this.ui = {
      ...this.defaultTag.ui,
      ...tag.ui,
      type: "pluralize",
    };

    this.snippet = new PluralizeSnippet(this);
  }

  get style() {
    return this.isNew ? walterStyles.variable.name : undefined;
  }

  get innerText() {
    return this.render.placeholder;
  }
}
