import { walterStyles } from "../styles";
import { JsElementProps } from "./JsElement";
import { BaseUiData, JsTag, VariableRenderData } from "./ParsedTag";
import { JsElement } from "./internal";

export interface UiData extends BaseUiData {
  type: "advanced";
}

export class AdvancedVariableElement extends JsElement<VariableRenderData, UiData> {
  static supports({ render, ui }: JsTag) {
    return render.type === "variable" && ui.type === "advanced";
  }

  render: VariableRenderData;
  ui: UiData;
  defaultTag: JsTag<VariableRenderData, UiData> = {
    render: { type: "variable", code: "" },
    ui: { type: "advanced" },
    valid: true,
  };

  constructor();
  constructor(props?: JsElementProps) {
    super();

    this.id = props?.id ?? null;
    this.parentId = props?.parentId;

    const tag = props?.tag ?? this.defaultTag;
    this.render = {
      ...this.defaultTag.render,
      ...tag.render,
      type: "variable",
    };

    this.ui = {
      ...this.defaultTag.ui,
      ...tag.ui,
      type: "advanced",
    };
  }

  get style() {
    return this.isNew ? walterStyles.variable.name : undefined;
  }

  public async resetPlaceholder() {
    // no-op
  }

  async update(code: string) {
    this.render.code = code;
    this.ui.label = this.buildLabel();
    await this.save();
  }

  get innerText() {
    return `[${this.labelOrTitle}]`;
  }

  buildLabel() {
    const codeLines = this.render.code.split("\n");
    return codeLines.length ? codeLines[codeLines.length - 1] : "Advanced variable";
  }
}
