import {
  Button,
  Menu,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
  makeStyles,
  tokens,
} from "@fluentui/react-components";
import { FC } from "react";
import { ConditionData, JoinOperatorType, OperatorType } from "./ConditionCodeGen";
import { Left } from "./Left";
import { JoinOperator } from "./JoinOperator";
import { Operator } from "./Operator";
import { Right } from "./Right";
import { Token } from "./Token";
import clsx from "clsx";
import { Copy, Ellipsis, Trash } from "lucide-react";
import { useConditionBuilder } from "./useConditionBuilder";

export const useStyles = makeStyles({
  rootroot: {
    display: "flex",
    position: "relative",
    flexDirection: "row",
    justifyContent: "flex-start",
    justifyItems: "flex-start",
    flexWrap: "wrap",
    alignItems: "center",
    "&:hover .element-actions": {
      display: "flex",
    },
  },
  actions: {
    display: "none",
    alignItems: "center",
    marginLeft: "auto",
    gap: "8px",
    height: "100%",
    position: "absolute",
    top: "0",
    right: "0",
    zIndex: 1,
    "> button": {
      backgroundColor: tokens.colorNeutralBackground1,
    },
  },
  root: {
    position: "relative",
    flexGrow: 1,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    justifyItems: "flex-start",
    alignItems: "center",
  },
  item: {},
  dangerMenuItem: {
    "&:hover .fui-MenuItem__icon": {
      color: tokens.colorPaletteRedForeground1,
    },
  },
  joinOperator: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    justifyItems: "center",
    alignItems: "center",
    "> button": {
      width: "auto",
      minWidth: "inherit",
    },
  },
});

export const Condition: FC<{
  condition: ConditionData;
  joinOperator: JoinOperatorType;
  onChange?: (_: ConditionData, _2: JoinOperatorType) => void;
  isFirst: boolean;
}> = ({ condition, onChange, isFirst, joinOperator }) => {
  const classes = useStyles();
  const { data, update } = useConditionBuilder();

  function updateCondition(partialCondition: Partial<ConditionData>) {
    onChange?.({ ...condition, ...partialCondition }, joinOperator);
  }

  function updateJoinOperator(joinOperator: JoinOperatorType) {
    onChange?.(condition, joinOperator);
  }

  function deleteCondition() {
    const updatedData = { ...data, conditions: data.conditions.filter((c) => c !== condition) };
    update(updatedData);
  }

  /**
   * Duplicates the current condition and adds it to the list of conditions.
   * This preserves the order, adding the duplicate after the original condition.
   */
  function duplicateCondition() {
    const conditionIndex = data.conditions.indexOf(condition);
    let updatedConditions;

    if (conditionIndex !== -1) {
      updatedConditions = [
        ...data.conditions.slice(0, conditionIndex + 1),
        condition,
        ...data.conditions.slice(conditionIndex + 1),
      ];
    } else {
      updatedConditions = [...data.conditions, condition];
    }

    const updatedData = { ...data, conditions: updatedConditions };
    update(updatedData);
  }

  return (
    <div className={classes.rootroot}>
      {isFirst && <Token label="If" appearance="secondary" />}
      {!isFirst && <JoinOperator joinOperator={joinOperator} onChange={updateJoinOperator} />}
      <Left condition={condition} onChange={(condition) => updateCondition(condition)} />
      <Operator
        condition={condition}
        onChange={(operator) => updateCondition({ operator: operator as OperatorType })}
      />
      <Right condition={condition} onChange={(right) => updateCondition({ right })} />
      <div className={clsx("element-actions", classes.actions)}>
        <Menu positioning="below-end">
          <MenuTrigger>
            <Button style={{ padding: 0, minWidth: 20 }} appearance="subtle">
              <Ellipsis width="14" height="14" />
            </Button>
          </MenuTrigger>

          <MenuPopover>
            <MenuList>
              <MenuItem onClick={duplicateCondition} icon={<Copy width="14" strokeWidth="1.5" />}>
                Duplicate
              </MenuItem>
              <MenuItem
                onClick={deleteCondition}
                icon={<Trash width="14" strokeWidth="1.5" />}
                className={classes.dangerMenuItem}
              >
                Delete
              </MenuItem>
            </MenuList>
          </MenuPopover>
        </Menu>
      </div>
    </div>
  );
};
