import { useContext, JSX } from "react";
import { WalterContext } from "../providers/walter";

export const useBottomDialog = () => {
  const { bottomDialog, setBottomDialog } = useContext(WalterContext);

  function openDialog(dialog: JSX.Element) {
    setBottomDialog(dialog);
  }

  function closeDialog() {
    setBottomDialog(null);
  }

  return { dialog: bottomDialog, closeDialog, openDialog };
};
