import { type FC, type ChangeEvent, useState, useEffect } from "react";
import { Combobox, makeStyles, Option, shorthands, useId, OptionGroup, Field } from "@fluentui/react-components";
import type { ComboboxProps } from "@fluentui/react-components";
import ComboboxOption from "./ComboboxOption";
import { GroupedOptions } from "../../helpers/formatData";
import { InputOption } from "../../../lib/walter";

export interface GroupedComboboxProps {
  groupedOptions: GroupedOptions;
  onSelect: (value: string) => void;
  label: string;
  selectedOption?: string;
  comboboxProps?: Partial<ComboboxProps>;
  errorMessage?: string;
}

const useStyles = makeStyles({
  root: {
    display: "grid",
    ...shorthands.gap("8px"),
  },
});

const GroupedCombobox: FC<GroupedComboboxProps> = ({
  groupedOptions,
  onSelect,
  label,
  selectedOption,
  errorMessage,
  comboboxProps = { defaultOpen: false, placeholder: "Search for variable" },
}) => {
  const findOption = (value: string) => {
    return Object.keys(groupedOptions)
      .map((group) => groupedOptions[group])
      .flat()
      .find((option) => option.value === value);
  };

  const getSelectedOption = () => {
    if (selectedOption) {
      return findOption(selectedOption);
    }

    return undefined;
  };

  const [selected, setSelected] = useState<InputOption | undefined>(getSelectedOption());
  const [value, setValue] = useState<string>(selected?.label || "");
  const [matchingOptions, setMatchingOptions] = useState<GroupedOptions>(groupedOptions);
  const comboId = useId("combo-controlled");
  const styles = useStyles();

  useEffect(() => {
    const option = getSelectedOption();

    setSelected(option);
    setValue(option?.label || "");
  }, [selectedOption]);

  useEffect(() => {
    setMatchingOptions(groupedOptions);
  }, [groupedOptions]);

  comboboxProps.onOptionSelect = (_event, data) => {
    if (!data.optionValue) return;

    const option = findOption(data.optionValue);

    setSelected(option);
    setValue(option?.label || "");
    onSelect(data.optionValue);
  };

  const onFilter = (ev: ChangeEvent<HTMLInputElement>) => {
    const value = ev.target.value;

    const matches: { [key: string]: InputOption[] } = {};

    Object.keys(groupedOptions).forEach((group) => {
      matches[group] = groupedOptions[group].filter(
        (option) => option.label.toLowerCase().indexOf(value.toLowerCase()) !== -1,
      );
    });

    setValue(value);
    setMatchingOptions(matches);

    if (Object.keys(matches).length === 0 || value === "") {
      onSelect("");
    }
  };

  return (
    <div className={styles.root}>
      <Field validationMessage={errorMessage} label={label}>
        <Combobox
          aria-labelledby={comboId}
          id={comboId}
          style={{ width: "100%" }}
          {...comboboxProps}
          selectedOptions={selected?.value ? [selected.value] : []}
          value={value}
          onChange={onFilter}
        >
          {Object.keys(matchingOptions).map((group, index) => {
            if (matchingOptions[group].length === 0) return null;

            return (
              <OptionGroup key={index} label={group}>
                {matchingOptions[group].map((option) => (
                  <Option key={option.value} text={option.label} value={option.value}>
                    <ComboboxOption name={option.label} description={option.description} />
                  </Option>
                ))}
              </OptionGroup>
            );
          })}
          {Object.keys(matchingOptions).reduce((acc, group) => acc + matchingOptions[group].length, 0) === 0 && (
            <Option key="no-results" text="">
              No results found
            </Option>
          )}
        </Combobox>
      </Field>
    </div>
  );
};

export default GroupedCombobox;
