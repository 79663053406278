export const defaultStorage = {
  get<T>(name: string): T | null {
    const value = localStorage.getItem(name);

    if (value === null) {
      return value;
    }

    return JSON.parse(value);
  },
  add<T>(name: string, value: T): T {
    const stringifiedValue = JSON.stringify(value);
    localStorage.setItem(name, stringifiedValue);

    return value;
  },
  remove(provider: string) {
    localStorage.removeItem(provider);
  },
  clear() {
    localStorage.clear();
  },
  clearWalterData() {
    localStorage.removeItem("walter");
    localStorage.removeItem("walterFeatureFlags");
    localStorage.removeItem("currentUser");
  },
};
