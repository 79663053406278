import { FC } from "react";
import { ConditionData, ConditionGroupData, emptyCondition, JoinOperatorType } from "./ConditionCodeGen";
import { ConditionOrGroup } from "./ConditionOrGroup";
import { Button, makeStyles, shorthands, tokens } from "@fluentui/react-components";
import { Plus } from "lucide-react";
import clsx from "clsx";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    gap: "4px",
  },
  nestedGroup: {
    marginLeft: "12px",
    paddingLeft: "12px",
    ...shorthands.border("1px", "solid", tokens.colorNeutralStroke1),
    borderRadius: "4px",
  },
});

export const ConditionGroup: FC<{
  group: ConditionGroupData;
  joinOperator?: JoinOperatorType;
  onChange?: (_: ConditionGroupData, joinOperator: JoinOperatorType) => void;
  // Set to true when this group is the first condition in the list
  isFirst?: boolean;
}> = ({ group, joinOperator = "and", onChange, isFirst = false }) => {
  const styles = useStyles();

  function updateGroup(partialGroup: Partial<ConditionGroupData>) {
    const updatedGroup = { ...group, ...partialGroup };

    // If the latest update has no conditions, add an empty one.
    if (updatedGroup.conditions.length === 0) {
      updatedGroup.conditions.push(emptyCondition());
    }

    // NOTE: You can't change the join operator for the parent group yet.
    onChange?.({ ...group, ...partialGroup }, joinOperator);
  }

  function updateCondition(
    updatedCondition: ConditionData | ConditionGroupData,
    joinOperator: JoinOperatorType,
    index: number,
  ) {
    const updatedConditions = [...group.conditions];
    updatedConditions[index] = updatedCondition;

    updateGroup({ conditions: updatedConditions, joinOperator });
  }

  return (
    <div className={clsx(styles.root, !isFirst && styles.nestedGroup)}>
      {group.conditions.map((condition, index) => (
        <ConditionOrGroup
          key={index}
          data={condition}
          joinOperator={group.joinOperator}
          onChange={(data, joinOperator) => updateCondition(data, joinOperator, index)}
          isFirst={index === 0}
        />
      ))}
      <div style={{ display: "flex" }}>
        <Button
          size="small"
          appearance="subtle"
          icon={<Plus size="12" strokeWidth="1.5" />}
          onClick={() => updateGroup({ conditions: [...group.conditions, emptyCondition()] })}
        >
          Add condition
        </Button>
      </div>
    </div>
  );
};
