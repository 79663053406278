import primitiveFieldsJsonSchema from "../../schemas/primitive_fields.json";
import templateObjectsSchema from "@src/lib/schemas/template_objects.json";
import { JSONSchema9Definition, JSONSchema9TypeName } from "@src/lib/types/jsonSchema";
import { FeatureFlagManager } from "@src/taskpane/hooks/useFeatureFlags";

export const TYPE_OPTIONS_CHANGED_EVENT = "walter-type-options-changed";

export type TypeOption = {
  label: string;
  ref?: string;
  value: JSONSchema9TypeName;
  featureFlags?: string[];
};

export const REF_PREFIX = "#/definitions/";

class TypeOptionsHandler {
  refToObjectSchema: Record<string, any>;
  #typeOptions: TypeOption[];

  constructor() {
    this.refToObjectSchema = Object.keys(templateObjectsSchema.definitions).reduce(
      (definitionsByRef: Record<string, JSONSchema9Definition>, key) => {
        const ref = `${REF_PREFIX}${key}`;
        const definition = templateObjectsSchema.definitions[
          key as keyof (typeof templateObjectsSchema)["definitions"]
        ] as JSONSchema9Definition;

        if (definition) definitionsByRef[ref] = definition;

        return definitionsByRef;
      },
      {},
    );
    this.#typeOptions = this.buildTypeOptions();
  }

  get typeOptions() {
    const res = this.#typeOptions.map(async (option) => {
      if (option.featureFlags === undefined) {
        return true;
      } else {
        return await FeatureFlagManager.getInstance().areEnabled(option.featureFlags);
      }
    });

    return (async () =>
      (await Promise.all(res)).reduce((allowedOptions, allowed, index) => {
        if (allowed) {
          allowedOptions.push(this.#typeOptions[index]);
        }

        return allowedOptions;
      }, [] as TypeOption[]))();
  }

  private buildTypeOptions() {
    const templateObjectTypes = Object.keys(this.refToObjectSchema).map((ref) => {
      const objectSchema = this.refToObjectSchema[ref];

      return {
        label: objectSchema.title,
        value: ref.split("/").pop(),
        ref,
        // Only include feature flags if they exist
        ...(objectSchema.feature_flags && { featureFlags: objectSchema.feature_flags }),
      } as TypeOption;
    });

    const primitiveTypes = Object.values(primitiveFieldsJsonSchema.definitions).map((schema) => {
      return {
        label: schema.title,
        value: schema.type,
      } as TypeOption;
    });

    return templateObjectTypes.concat(primitiveTypes).sort((a, b) => a.label.localeCompare(b.label));
  }
}

export const typeOptionsHandler = new TypeOptionsHandler();
export type { TypeOptionsHandler };
