import { OperationContext } from "../OperationContext";
import { Operation } from "./Operation";

export class GetSelectionText implements Operation<string> {
  async commit(context: OperationContext): Promise<string> {
    const selection = context.document.getSelection().load("text");

    await context.sync();

    return selection.text ?? "";
  }

  canMerge(other: Operation<unknown>): boolean {
    return other instanceof GetSelectionText;
  }

  merge(): Operation<string> {
    return this;
  }
}
