const DEFAULT_VALID_TYPES = ["string", "VariableExpressionSignTab"];

export function validate(typeName: string, validTypes: string[] = DEFAULT_VALID_TYPES) {
  let isValid = false;
  const error = `Unexpected type in last line: ${typeName} (should be ${validTypes.join(" or ")}`;

  // Allow the type to be a string literal. Ex. "hello", "", "123"
  if (validTypes.includes("string") && typeName.startsWith('"') && typeName.endsWith('"')) {
    isValid = true;
  } else if (validTypes.includes("boolean") && (typeName === "true" || typeName === "false")) {
    isValid = true;
  } else {
    isValid = validTypes.includes(typeName);
  }

  return {
    isValid,
    error: isValid ? "" : error,
  };
}
