import { useEffect, useState } from "react";
import { TypeOption, TYPE_OPTIONS_CHANGED_EVENT, typeOptionsHandler } from "@src/lib/walter";
import { captureErrorInfo } from "@src/taskpane/helpers/errorHandler";

export const useTypeOptions = () => {
  const [typeOptions, setTypeOptions] = useState<TypeOption[]>([]);

  useEffect(() => {
    function handleTypeOptionsChanged() {
      typeOptionsHandler.typeOptions
        .then((options) => {
          setTypeOptions(options);
        })
        .catch((e) => {
          captureErrorInfo(e);
        });
    }

    void typeOptionsHandler.typeOptions.then((options) => {
      setTypeOptions(options);
    });

    window.addEventListener(TYPE_OPTIONS_CHANGED_EVENT, handleTypeOptionsChanged);
    return () => {
      window.removeEventListener(TYPE_OPTIONS_CHANGED_EVENT, handleTypeOptionsChanged);
    };
  }, []);

  return {
    refToObjectSchema: typeOptionsHandler.refToObjectSchema,
    typeOptions,
  };
};
