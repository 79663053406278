import { DocumentSettings } from "../settings/DocumentSettings";
import { WalterStore } from "../properties/WalterStore";
import { JSONSchema9 } from "../types/jsonSchema";
import { REF_PREFIX } from "@src/lib/walter/typeOptions/handler";
import { downloadDocx } from "../downloadDocx";
import JSZip from "jszip";
import debug from "../debug";
import { documentSchemaStore } from "../schemas";
import { Migration, MigrationTypes } from "../migrations/Migration";
import { DynamicElement } from "../liquidx/internal";
export const FIELDS_CHANGED_EVENT = "word-add-in-fields-changed";

/**
 * Migrate inputs to custom XML part.
 *
 * If nothing given, it will try to get inputs from the document settings.
 */
async function migrateInputsToFields(inputs?: any): Promise<boolean> {
  inputs ??= await DocumentSettings.getInputs();

  if (Object.keys(inputs).length === 0) {
    return false;
  }

  const migratedSchema: JSONSchema9 = { type: "object", properties: {} };
  Object.keys(inputs).map((key) => {
    const input = inputs[key];
    migratedSchema.properties![key] = {
      description: input.description,
      title: input.title,
      $ref: `${REF_PREFIX}${key}`,
    };
  });

  await documentSchemaStore.save(migratedSchema);

  void DocumentSettings.delete("walter.settings");
  void DocumentSettings.delete("walter.inputs");

  debug.log("Migrated inputs to fields");
  return true;
}

/**
 * This migrates previous schema storage formats and locations to the custom XML part,
 * which is used as the current schema storage location.
 *
 * We have a variety of situations to cover, so here are the steps:
 * 1. Try to migrate walter.inputs to schema in custom XML part
 * 2. Try to migrate walter.fields from this extension's DocumentSettings to schema in custom XML part
 * 3. Try to migrate walter.fields from other extension's DocumentSettings to schema in custom XML part
 * 4. Try to migrate walter.inputs from other extension's DocumentSettings to walter.fields
 * 5. Try to migrate walter.fields from this extension's DocumentSettings to schema in custom XML part
 *
 * The first two steps are much faster than the subsequent steps, which require downloading the entire docx file from Word.
 */
export async function migrateToCustomXml({
  retryingAfterExtractingSchemaFromZip,
}: {
  retryingAfterExtractingSchemaFromZip?: boolean;
} = {}): Promise<boolean> {
  const existingSchema = await WalterStore.getSchema();

  if (Object.keys(existingSchema.properties ?? {}).length > 0) {
    debug.log("Fields already migrated to custom XML");
    return true;
  }

  const migratedInputs = await migrateInputsToFields();
  if (migratedInputs) return true;

  let schema: JSONSchema9 = await DocumentSettings.getFields();

  if (Object.keys(schema.properties ?? {}).length === 0 && !retryingAfterExtractingSchemaFromZip) {
    // This migration path is expensive so we only want to try this approach when the document appears to be in a broken state.
    // Specifically, if there are dynamic elements but we can't find a schema.
    await documentSchemaStore.load();
    await DynamicElement.load();

    const hasWalterContentControlsButNoSchema =
      DynamicElement.all().length > 0 && documentSchemaStore.fieldKeys.length === 0;
    if (hasWalterContentControlsButNoSchema) {
      const schemaFromZipOrResult = await extractSchemaFromZip();
      if (typeof schemaFromZipOrResult === "object") {
        schema = schemaFromZipOrResult;
      } else {
        return !!schemaFromZipOrResult;
      }
    }
  }

  await WalterStore.setSchema(schema);
  await documentSchemaStore.load();
  window.dispatchEvent(new CustomEvent(FIELDS_CHANGED_EVENT));

  void DocumentSettings.delete("walter.fields");
  debug.log("Migrated fields to custom XML");
  return true;
}

async function extractSchemaFromZip(): Promise<JSONSchema9 | boolean | undefined> {
  const { slices } = await downloadDocx();

  const zip = await new JSZip().loadAsync(slices[0]);
  const webExtensionFiles = zip.file(/word\/webextensions\/webextension\d*.xml/);
  const parser = new DOMParser();
  for (const file of webExtensionFiles) {
    const xml = await file.async("string");
    const xmlDocument = parser.parseFromString(xml, "text/xml");
    const fieldsProperty = xmlDocument.querySelector("property[name='walter.fields']");

    if (fieldsProperty) {
      const fieldsJson = fieldsProperty.getAttribute("value");
      try {
        if (fieldsJson) {
          debug.log("Extracted fields from zip");
          return JSON.parse(fieldsJson);
        }
      } catch {
        console.warn(`Failed to parse fields JSON in ${file.name}`);
      }
    }

    const inputsProperty = xmlDocument.querySelector("property[name='walter.inputs']");
    if (inputsProperty) {
      const inputsJson = inputsProperty.getAttribute("value");
      try {
        if (inputsJson) {
          const inputs = JSON.parse(inputsJson);
          debug.log("Extracted inputs from zip", inputs);
          await migrateInputsToFields(inputs);
        }
      } catch {
        console.warn(`Failed to parse inputs JSON in ${file.name}`);
      }
    }
  }
}

export class MigrateToCustomXml extends Migration {
  name: string = "MigrateToCustomXml";
  type: MigrationTypes = "local";

  async apply() {
    return migrateToCustomXml();
  }
}
