import { ContentControlType } from "../../liquidx";
import { Variable } from "../variable";
import { InputOptionsBuilder, InputOption, ParsedInputOptions, QuickAddOption } from "./builder";
import { JSONSchema9 } from "../../types/jsonSchema";

export type InputOptions = Omit<
  ParsedInputOptions,
  "loopVariableOptions" | "loopDateSignedOptions" | "loopInitialsOptions" | "loopSignatureOptions"
> &
  Record<
    | "loopOptions"
    | "dateSignedOptions"
    | "initialsOptions"
    | "signatureOptions"
    | "signerOptions"
    | "variableOptions"
    | "quickAddOptions",
    InputOption[] | QuickAddOption[]
  >;

export type InputOptionsNotUndefined = Exclude<InputOptions, undefined>;

export const INPUT_OPTIONS_CHANGED_EVENT = "walter-input-options-changed";

class InputOptionsHandler {
  private _defaultOptions: ParsedInputOptions;
  private _inputOptions: InputOptions;

  public constructor() {
    this._defaultOptions = {
      dateSignedOptions: [],
      initialsOptions: [],
      signatureOptions: [],
      variableOptions: [],
      loopOptions: [],
      loopLoopOptions: [],
      loopVariableOptions: [],
      loopDateSignedOptions: [],
      loopInitialsOptions: [],
      loopSignatureOptions: [],
      loopSignerOptions: [],
      formInputOptions: [],
      signerOptions: [],
      quickAddOptions: [],
    };

    this._inputOptions = {
      loopOptions: [],
      dateSignedOptions: [],
      initialsOptions: [],
      signatureOptions: [],
      signerOptions: [],
      variableOptions: [],
      quickAddOptions: [],
      loopLoopOptions: [],
      loopSignerOptions: [],
      formInputOptions: [],
    };
  }

  public get defaultOptions(): ParsedInputOptions {
    return this._defaultOptions;
  }

  public get inputOptions(): InputOptions {
    return this._inputOptions;
  }

  public load(inputSchema: JSONSchema9): InputOptions {
    this._defaultOptions = InputOptionsBuilder.load(inputSchema);
    this.setInputOptions(this._defaultOptions);
    return this._inputOptions;
  }

  public reset(): InputOptions {
    this.setInputOptions(this._defaultOptions);
    return this._inputOptions;
  }

  public addLoopOptions(variables: Variable[]): InputOptions {
    const filteredLoopVariableOptions = this.filterLoopOptions(this._defaultOptions.loopVariableOptions, variables);
    const filteredLoopLoopOptions = this.filterLoopOptions(this._defaultOptions.loopLoopOptions, variables);

    const defaults = this._defaultOptions;

    this.setInputOptions({
      ...defaults,
      loopOptions: this.uniqueInputs(defaults.loopOptions, this.filterLoopOptions(defaults.loopLoopOptions, variables)),
      dateSignedOptions: this.uniqueInputs(
        defaults.dateSignedOptions,
        this.filterLoopOptions(defaults.loopDateSignedOptions, variables),
      ),
      initialsOptions: this.uniqueInputs(
        defaults.initialsOptions,
        this.filterLoopOptions(defaults.loopInitialsOptions, variables),
      ),
      signatureOptions: this.uniqueInputs(
        defaults.signatureOptions,
        this.filterLoopOptions(defaults.loopSignatureOptions, variables),
      ),
      signerOptions: this.uniqueInputs(
        defaults.signerOptions,
        this.filterLoopOptions(defaults.loopSignerOptions, variables),
      ),
      variableOptions: this.uniqueInputs(defaults.variableOptions, filteredLoopVariableOptions),
      quickAddOptions: this.uniqueQuickAdd(defaults.quickAddOptions, [
        ...InputOptionsBuilder.buildQuickAddOptions(ContentControlType.VARIABLE, filteredLoopVariableOptions),
        ...InputOptionsBuilder.buildQuickAddOptions(ContentControlType.LOOP, filteredLoopLoopOptions),
      ]),
    });

    return this._inputOptions;
  }

  private filterLoopOptions(options: InputOption[], variables: Variable[]): InputOption[] {
    return options.filter((option: InputOption) =>
      variables.some(
        (variable) =>
          // TODO: fix typescript type error. Remove @ts-ignore comment to see the error
          // @ts-ignore
          (variable.parentLoopName === option.variable.parentLoopName ||
            // TODO: fix typescript type error. Remove @ts-ignore comment to see the error
            // @ts-ignore
            variable.fullName.startsWith(option.variable.parentLoopName)) &&
          !option.variable?.isDeprecated,
      ),
    );
  }

  private setInputOptions(options: ParsedInputOptions) {
    const isNotDeprecated = (option: InputOption) => !option.variable?.isDeprecated;
    const isNotDeprecatedForQuickAdd = (quickAdd: QuickAddOption) => !quickAdd.option.variable?.isDeprecated;

    Object.entries(options).forEach(([key, value]) => {
      if (key === "quickAddOptions") {
        this._inputOptions[key] = value.filter(isNotDeprecatedForQuickAdd);
      } else {
        // TODO: fix typescript type error. Remove @ts-ignore comment to see the error
        // @ts-ignore
        this._inputOptions[key] = value.filter(isNotDeprecated);
      }
    });

    window.dispatchEvent(new CustomEvent(INPUT_OPTIONS_CHANGED_EVENT));
  }

  private uniqueInputs(options: InputOption[], optionsToBeAdded: InputOption[]): InputOption[] {
    const uniqueValues = new Set(options.map((option) => option.value));
    const uniqueOptions = optionsToBeAdded.filter(({ value }) => {
      if (!uniqueValues.has(value)) {
        uniqueValues.add(value);
        return true;
      }
      return false;
    });

    return [...options, ...uniqueOptions];
  }

  private uniqueQuickAdd(options: QuickAddOption[], optionsToBeAdded: QuickAddOption[]): QuickAddOption[] {
    const uniqueValues = new Set(options.map(({ option }) => option.value));
    const uniqueOptions = optionsToBeAdded.filter(({ option: addedOption }) => {
      if (!uniqueValues.has(addedOption.value)) {
        uniqueValues.add(addedOption.value);
        return true;
      }
      return false;
    });
    return [...options, ...uniqueOptions];
  }
}

export const inputOptionsHandler = new InputOptionsHandler();
export type { InputOptionsHandler };
