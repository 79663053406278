type WordStyleType = "Character" | "List" | "Paragraph" | "Table";

export interface WalterStyle {
  name: string;
  type: WordStyleType;
  definition: (style: Word.Style) => void;
}

export const walterStyles: Record<string, WalterStyle> = {
  variable: {
    name: "Walter Variable",
    type: "Character",
    definition: (style) => {
      style.shading.backgroundPatternColor = "yellow";
    },
  },
  placeholder: {
    name: "Walter Placeholder",
    type: "Character",
    definition: (style) => {
      style.shading.backgroundPatternColor = "cyan";
    },
  },
};
