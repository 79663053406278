import { useEffect, useState } from "react";
import { inputOptionsHandler, type InputOptions, Variable, INPUT_OPTIONS_CHANGED_EVENT } from "@src/lib/walter";
import type { JSONSchema9 } from "@src/lib/types/jsonSchema";

export const useInputOptions = () => {
  const [inputOptions, setInputOptions] = useState<InputOptions>(inputOptionsHandler.inputOptions);
  const [version, setVersion] = useState(0);

  useEffect(() => {
    function handleInputOptionsChanged() {
      setInputOptions(inputOptionsHandler.inputOptions);
      setVersion((v) => v + 1);
    }

    handleInputOptionsChanged();
    window.addEventListener(INPUT_OPTIONS_CHANGED_EVENT, handleInputOptionsChanged);
    return () => {
      window.removeEventListener(INPUT_OPTIONS_CHANGED_EVENT, handleInputOptionsChanged);
    };
  }, []);

  return {
    inputOptions,
    version,
    loadSchema: (schema: JSONSchema9) => inputOptionsHandler.load(schema),
    resetInputOptions: () => inputOptionsHandler.reset(),
    addInputOptions: (variables: Variable[]) => inputOptionsHandler.addLoopOptions(variables),
  };
};
