import { BaseRenderData, BaseUiData } from "@src/lib/liquidx/ParsedTag";
import { SnippetDataStore } from "./SnippetDataStore";

export abstract class Snippet<R extends BaseRenderData = BaseRenderData, U extends BaseUiData = BaseUiData> {
  constructor(public dataStore: SnippetDataStore<R, U>) {}

  get ui() {
    return this.dataStore.ui;
  }

  set ui(ui: U) {
    this.dataStore.ui = ui;
  }

  get render() {
    return this.dataStore.render;
  }

  set render(render: R) {
    this.dataStore.render = render;
  }

  get schemaStore() {
    return this.dataStore.schemaStore;
  }

  get errors() {
    return this.dataStore.errors;
  }

  get isNew() {
    return this.dataStore.isNew;
  }

  validate() {
    this.dataStore.validate();
  }
}
