import { FC, useContext, useEffect, useState } from "react";
import { Switch, Route } from "react-router-dom";
import { Home } from "./home";
import Progress from "./Progress";
import { Conditional } from "./Conditional";
import Layout from "./Layout";
import { WalterContext } from "@src/taskpane/providers/walter/index.tsx";
import { defaultSelectionDetails } from "@src/taskpane/providers/walter/app-state.ts";
import { Loop } from "./loops/Loop";
import { useInputOptions } from "../hooks/useInputOptions";
import { useOnSelectionEvent } from "../hooks/useOnSelectionEvent";
import { useOnDeleteEvent } from "../hooks/useOnDeleteEvent";
import { syncEngine } from "../../lib/sync-engine/SyncEngine";
import { useInitialized } from "../hooks/useInitialized";
import { Variable } from "./Variable";
import { Link } from "@fluentui/react-components";
import { ContentControlType } from "../../lib/liquidx";
import { SignTabInput } from "./SignTabInput";
import { SignTab } from "./shared/SignTab";
import { AddField } from "./fields";
import { useFields } from "../hooks/useFields";
import { captureErrorInfo } from "@src/taskpane/helpers/errorHandler";
import ManageFieldSets from "@src/taskpane/components/FieldSets/ManageFieldSets";
import { NewFieldSet } from "@src/taskpane/components/FieldSets/NewFieldSet";
import { ChooseFieldSet } from "@src/taskpane/components/FieldSets/ChooseFieldSet";
import useToasts from "@src/taskpane/hooks/useToasts";
import { documentSchemaStore } from "@src/lib/schemas";
import { SelectionDetails } from "@src/lib/sync-engine/operations/GetSelection";

const App: FC = () => {
  const { inputOptions, loadSchema } = useInputOptions();
  const initialized = useInitialized();
  const { setDocumentSelectionData, showNewUi } = useContext(WalterContext);
  const { showErrors } = useToasts();
  const [migrating, setMigrating] = useState(false);

  useOnSelectionEvent();
  useOnDeleteEvent();
  useFields();

  // Listen for changes to the document selection
  useEffect(() => {
    if (!initialized) return;

    function handleSelectionChanged(value?: SelectionDetails) {
      setDocumentSelectionData(value ?? defaultSelectionDetails);
    }

    syncEngine.addSelectionChangeEventListener(handleSelectionChanged);
    return () => syncEngine.removeSelectionChangeEventListener(handleSelectionChanged);
  }, [setDocumentSelectionData, initialized]);

  useEffect(() => {
    // Display toast when an operation fails
    syncEngine.onFatalError((e) => {
      const message = JSON.stringify(e);

      showErrors({
        title: "Error updating document",
        messages: import.meta.env.DEV ? [message] : [],
        footerContent: <Link onClick={() => window.location.reload()}>Reload</Link>,
      });

      captureErrorInfo(e);
    });

    syncEngine.onFailedOperation((operation, e) => {
      const errorMessages = import.meta.env.DEV
        ? [`Unable to apply operation ${operation.constructor.name}`, JSON.stringify(e)]
        : [];

      showErrors({
        title: "Error updating document",
        messages: errorMessages,
        footerContent: <Link onClick={() => window.location.reload()}>Reload</Link>,
      });

      captureErrorInfo(e);
    });
  }, []);

  useEffect(() => {
    if (!initialized) return;

    syncEngine.start();
    return () => syncEngine.stop();
  }, [initialized]);

  // Update the input options when the focused fields changes
  useEffect(() => {
    if (!initialized) return;

    function reloadInputs() {
      loadSchema(documentSchemaStore.schema);
    }

    reloadInputs();
    documentSchemaStore.addEventListener("change", reloadInputs);
    return () => documentSchemaStore.removeEventListener("change", reloadInputs);
  }, [initialized]);

  if (!initialized || !inputOptions) {
    return (
      <Layout>
        <Progress message="Initializing..." />
      </Layout>
    );
  }

  if (migrating) {
    return (
      <Layout>
        <Progress message="Upgrading..." />
      </Layout>
    );
  }

  return (
    <Layout>
      <Switch>
        <Route exact path="/">
          <Home setMigrating={setMigrating} />
        </Route>
        <Route exact path="/choose-field-set">
          <ChooseFieldSet />
        </Route>
        <Route exact path="/fields/:key">
          <AddField />
        </Route>
        <Route exact path="/field-sets">
          <ManageFieldSets />
        </Route>
        <Route exact path="/field-sets/new">
          <NewFieldSet />
        </Route>
        {!showNewUi && <LiquidRoutes />}
        <Route>
          <div style={{ padding: "12px" }}>
            <h1>404 - Not found</h1>
          </div>
        </Route>
      </Switch>
    </Layout>
  );
};

const LiquidRoutes: FC = () => {
  return (
    <>
      <Route exact path="/variables/new">
        <Variable label="Insert variable" submitLabel="Insert" />
      </Route>
      <Route exact path="/variables/:id/edit">
        <Variable label="Update variable" submitLabel="Update" />
      </Route>
      <Route exact path="/conditionals/new">
        <Conditional submitLabel="Insert" />
      </Route>
      <Route exact path="/conditionals/:id/edit">
        <Conditional submitLabel="Update" />
      </Route>
      <Route exact path="/form-inputs/new">
        <SignTabInput submitText="Insert" />
      </Route>
      <Route exact path="/form-inputs/:id/edit">
        <SignTabInput submitText="Update" />
      </Route>
      <Route exact path="/signatures/new">
        <SignTab initialType={ContentControlType.SIGNATURE} label="Signature" submitText="Insert signature" />
      </Route>
      <Route exact path="/signatures/:id/edit">
        <SignTab initialType={ContentControlType.SIGNATURE} label="Signature" submitText="Update signature" />
      </Route>
      <Route exact path="/initials/new">
        <SignTab initialType={ContentControlType.INITIALS} label="Initials" submitText="Insert initials" />
      </Route>
      <Route exact path="/initials/:id/edit">
        <SignTab initialType={ContentControlType.INITIALS} label="Initials" submitText="Update initials" />
      </Route>
      <Route exact path="/date-signed/new">
        <SignTab initialType={ContentControlType.DATE_SIGNED} label="Date signed" submitText="Insert date signed" />
      </Route>
      <Route exact path="/date-signed/:id/edit">
        <SignTab initialType={ContentControlType.DATE_SIGNED} label="Date signed" submitText="Update date signed" />
      </Route>
      <Route exact path="/loops/new">
        <Loop label="Insert loop" submitLabel="Insert" />
      </Route>
      <Route exact path="/loops/:id/edit">
        <Loop label="Update loop" submitLabel="Update" />
      </Route>
    </>
  );
};

export default App;
