import { type FC, useEffect, useState, useRef } from "react";
import { validate } from "@src/taskpane/components/CodeExpression/validator";
import { CodeEditor } from "@src/taskpane/components/CodeEditor/CodeEditor";
import { Switch, makeStyles } from "@fluentui/react-components";
import { AdvancedIfElement } from "@src/lib/liquidx/AdvancedIfElement";

const useStyles = makeStyles({
  root: {
    margin: "-12px -12px",
  },
});

export const AdvancedIf: FC<{ element: AdvancedIfElement }> = ({ element }) => {
  const [code, setCode] = useState<string>(element.render.code);
  const [elseBranch, setElseBranch] = useState(element.hasElse);
  // Using ref because the updateElement callback was being called with the old element
  // (even when using useCallback w/ element as a dependency).
  const elementRef = useRef(element);
  const validReturnTypes = ["boolean", "any"];
  const classes = useStyles();

  useEffect(() => {
    elementRef.current = element;
    setCode(element.render.code);
    setElseBranch(!!element?.elseBranch);
  }, [element]);

  async function updateElement({ code, elseBranch }: { code: string; elseBranch: boolean }) {
    if (code) await elementRef.current.update({ code, elseBranch });
  }

  return (
    <div className={classes.root}>
      <CodeEditor
        autoFocus={element.isNew}
        onChange={async ({ code, isValid }) => {
          if (isValid) {
            setCode(code);
            await updateElement({ code, elseBranch });
          }
        }}
        code={code}
        validator={(codeType: string) => validate(codeType, validReturnTypes)}
      />

      <Switch
        checked={elseBranch}
        label="Else Branch"
        onChange={(_e, { checked }) => {
          setElseBranch(!!checked);
          void updateElement({ code: code ?? "", elseBranch: !!checked });
        }}
      />
    </div>
  );
};
