import { ChangeEvent, FC, ReactNode, useEffect, useState } from "react";
import { Combobox, ComboboxProps, OptionOnSelectData, useComboboxFilter, useId } from "@fluentui/react-components";
import { Option } from "@src/lib/schemas";
import uFuzzy from "@leeoniya/ufuzzy";

const fuzzySearch = new uFuzzy();
export const textMatches = (text: string, needle: string): boolean => {
  if (!needle) return true;

  const matches = fuzzySearch.filter([text], needle);
  return !!(matches && matches.length > 0);
};

export interface ComboboxOption {
  children: ReactNode;
  label: string;
  value: string;
}

export const TextCombobox: FC<
  Omit<ComboboxProps, "onChange"> & {
    value: string;
    options: Option[];
    onChange?: (value: string) => void;
  }
> = (props) => {
  const { value, options, onChange, ...restProps } = props;

  // Used to filter options, always equal to the value typed into the combobox
  const [query, setQuery] = useState("");
  // Used to set the value of the combobox (what is shown in the input field)
  const [localValue, setLocalValue] = useState("");
  // Used to set what option is selected in the combobox
  const [selectedOptionValue, setSelectedOptionValue] = useState("");
  const [comboboxOptions, setComboboxOptions] = useState<ComboboxOption[]>([]);
  const comboId = useId("combo-default");

  useEffect(() => {
    const comboboxOptions: ComboboxOption[] = options.map(({ label, value }) => ({
      value,
      label,
      children: label,
    }));

    setComboboxOptions(comboboxOptions);
    const selectedOption = comboboxOptions.find((option) => option.value === value);
    setSelectedOptionValue(selectedOption?.value ?? "");
    setLocalValue(selectedOption?.label ?? "");
  }, [options, value]);

  const handleInput = (ev: ChangeEvent<HTMLInputElement>) => {
    setLocalValue(ev.target.value);
  };

  function handleOptionSelect(_e: unknown, data: OptionOnSelectData) {
    const property = data.optionValue ?? "";

    onChange?.(property);
    setSelectedOptionValue(property);
    setLocalValue(data.optionText ?? "");
  }

  const children = useComboboxFilter(query, comboboxOptions, {
    noOptionsMessage: "Nothing matched your search.",
    optionToText: (option) => option.label,
    filter: textMatches,
  });

  return (
    <Combobox
      {...restProps}
      onOptionSelect={handleOptionSelect}
      onInput={handleInput}
      value={localValue.length ? localValue : query}
      aria-labelledby={comboId}
      selectedOptions={[selectedOptionValue]}
      onChange={(ev) => setQuery(ev.target.value)}
    >
      {children}
    </Combobox>
  );
};
