interface DocxDownloadResult {
  slices: Uint8Array[];

  /**
   * The filename and URL of the downloaded file.
   * These aren't available in certain contexts, like WOPI.
   */
  properties?: {
    filename: string;
    url: string;
  };
}

export async function downloadDocx(): Promise<DownloadedDocument> {
  return new Promise((resolve, reject) => {
    Office.context.document.getFileAsync(Office.FileType.Compressed, async (asyncOfficeResult) => {
      try {
        const docxDownloadResult = await handleFileResult(asyncOfficeResult);
        const downloadedDocument = new DownloadedDocument(docxDownloadResult.slices, docxDownloadResult.properties);

        resolve(downloadedDocument);
      } catch (e) {
        reject(e);
      }
    });
  });
}

class DownloadedDocument {
  constructor(public readonly slices: Uint8Array[], public readonly properties?: { filename: string; url: string }) {}

  get base64(): string {
    return btoa(this.slices.map((slice) => String.fromCharCode(...slice)).join(""));
  }

  get blob(): Blob {
    return new Blob(this.slices, {
      type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    });
  }
}

async function handleFileResult(asyncOfficeResult: Office.AsyncResult<Office.File>): Promise<DocxDownloadResult> {
  if (asyncOfficeResult.status !== Office.AsyncResultStatus.Succeeded) {
    asyncOfficeResult.value.closeAsync();
    throw asyncOfficeResult;
  }

  try {
    const slices = await getFileSlicesData(asyncOfficeResult.value);
    const getFileProperties = (): Promise<DocxDownloadResult> => {
      return new Promise((resolve, reject) => {
        try {
          Office.context.document.getFilePropertiesAsync((properties) => {
            if (properties.status === Office.AsyncResultStatus.Succeeded) {
              const url = properties.value.url;
              const fileParts = url.split(/[\\/]+/);
              const filename = fileParts[fileParts.length - 1];
              resolve({ slices, properties: { filename, url } });
            } else {
              resolve({ slices });
            }
          });
        } catch (e: unknown) {
          reject(e);
        }
      });
    };

    return getFileProperties();
  } finally {
    asyncOfficeResult.value.closeAsync();
  }
}

async function getFileSlicesData(file: Office.File): Promise<Uint8Array[]> {
  // NOTE: This is only the right type if you use Office.FileType.Compressed
  let slices: Uint8Array[] = [];

  for (let index = 0; index < file.sliceCount; index++) {
    await new Promise<void>((resolve, reject) => {
      file.getSliceAsync(index, (asyncResult) => {
        if (asyncResult.status === Office.AsyncResultStatus.Failed) {
          file.closeAsync();
          reject(`${asyncResult.error.code} ${asyncResult.error.message}`);
        } else {
          slices.push(asyncResult.value.data);
          resolve();
        }
      });
    });
  }

  file.closeAsync();

  return slices;
}
