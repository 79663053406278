import { OperationContext } from "../OperationContext";
import { Operation } from "./Operation";
import { syncEngine } from "../SyncEngine";
import { FlushDocumentSettings } from "./FlushDocumentSettings";

export class WriteDocumentSettings implements Operation<void> {
  key: string;
  value: string;

  constructor(prop: { key: string; value: string }) {
    this.key = prop.key;
    this.value = prop.value;
  }

  merge(other: Operation<unknown>): Operation<void> | undefined {
    if (other instanceof WriteDocumentSettings && other.key === this.key) {
      return other;
    }
  }

  async commit(_: OperationContext): Promise<void> {
    if (this.value === undefined) {
      Office.context.document.settings.remove(this.key);
    } else {
      Office.context.document.settings.set(this.key, this.value);
    }

    // We don't have to wait for the flush to complete because the
    // above set/remove are synchronously written into memory and
    // the new values are available immediately.
    void syncEngine.perform(new FlushDocumentSettings());
  }
}

export class GetDocumentSettings<T> implements Operation<T> {
  constructor(public key: string) {}

  merge(other: Operation<unknown>): Operation<T> | undefined {
    if (other instanceof GetDocumentSettings && other.key === this.key) {
      return other as unknown as Operation<T>;
    }
  }

  async commit(_: OperationContext): Promise<T> {
    if (!Office.context.document.settings) {
      throw new Error(`Attempt to get document settings before Office is loaded: ${this.key}`);
    }

    return Promise.resolve(Office.context.document.settings.get(this.key));
  }
}
