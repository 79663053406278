import { AliasElement } from "@src/lib/liquidx/AliasElement";
import { DynamicElement } from "@src/lib/liquidx/DynamicElement";
import { BaseRenderData, BaseUiData } from "@src/lib/liquidx/ParsedTag";
import { SchemaStore, documentSchemaStore, SchemaNode } from "@src/lib/schemas";
import { ComputedFieldSchema } from "./EditVariableField";
import { Snippet } from "../Snippet";

export abstract class ComputedField<R extends BaseRenderData = BaseRenderData, U extends BaseUiData = BaseUiData> {
  abstract snippet: Snippet<R, U>;

  key: string;
  isNew: boolean;
  schema: ComputedFieldSchema<R, U>;
  schemaStore: SchemaStore;
  errors: string[] = [];

  constructor({
    defaultSchema,
    schemaNode,
    schemaStore = documentSchemaStore,
  }: {
    defaultSchema: ComputedFieldSchema<R, U>;
    schemaNode?: SchemaNode;
    schemaStore?: SchemaStore;
  }) {
    if (schemaNode) {
      this.key = schemaNode.key;
      this.isNew = false;
      this.schema = schemaNode.schema as ComputedFieldSchema<R, U>;
    } else {
      this.key = "";
      this.isNew = true;
      this.schema = defaultSchema;
    }

    this.schemaStore = schemaStore;
  }

  get hasErrors() {
    return this.errors.length > 0;
  }

  get computed(): { render: R; ui: U } {
    return this.schema.computed;
  }

  get ui(): U {
    return this.computed.ui;
  }

  set ui(ui: U) {
    this.computed.ui = ui;
  }

  get render(): R {
    return this.computed.render;
  }

  set render(render: R) {
    this.computed.render = render;
  }

  async save() {
    // This is called every time the form is changed but we don't want
    // to persist things until they hit save, so this is a no-op.
  }

  validate() {
    this.errors = [];

    const title = this.schema.title ?? "";
    if (this.isNew && title) {
      const fieldWithSameKey = this.schemaStore.findField(SchemaStore.deriveKey(title));
      if (fieldWithSameKey) {
        this.errors.push(
          `"${title}" conflicts with another field, "${fieldWithSameKey.label}"—please choose a different name.`,
        );
      }
    }
  }

  async saveSchema() {
    // Derive key from title if it's new
    if (this.isNew) this.key = SchemaStore.deriveKey(this.schema.title ?? "");

    this.schemaStore.addField({ key: this.key, schema: this.schema });
    await this.schemaStore.save();
    this.isNew = false;

    // Rebuild elements that use this field
    DynamicElement.all()
      .filter((e) => e instanceof AliasElement && e.ui.property === this.key)
      .forEach((e) => e.rebuild());
  }
}
