import {
  Link,
  Toast,
  ToastBody,
  ToastFooter,
  ToastTitle,
  ToastTrigger,
  useToastController,
} from "@fluentui/react-components";
import { WalterContext } from "@src/taskpane/providers/walter";
import { ReactChild, useCallback, useContext } from "react";
import { Dismiss20Regular } from "@fluentui/react-icons";

const useToasts = () => {
  const { toasterId } = useContext(WalterContext);
  const { dispatchToast } = useToastController(toasterId);

  const showErrors = useCallback(
    ({
      messages,
      title,
      footerContent,
    }: {
      messages?: string[];
      title?: string;
      footerContent?: ReactChild | string | null;
    }) => {
      if (!title) title = "Error";
      if (messages === undefined) messages = [];

      dispatchToast(
        <Toast>
          <ToastTitle
            action={
              <ToastTrigger>
                <Link>
                  <Dismiss20Regular />
                </Link>
              </ToastTrigger>
            }
          >
            {title}
          </ToastTitle>
          <ToastBody>
            {messages.length > 1 ? (
              <ul>
                {messages.map((message, i) => (
                  <li key={i}>{message}</li>
                ))}
              </ul>
            ) : (
              messages[0]
            )}
          </ToastBody>
          {footerContent && <ToastFooter>{footerContent}</ToastFooter>}
        </Toast>,
        { intent: "error", timeout: 5000, pauseOnHover: true, pauseOnWindowBlur: true },
      );
    },
    [],
  );

  const showSuccess = useCallback((message: string = "Success") => {
    dispatchToast(
      <Toast>
        <ToastTitle
          action={
            <ToastTrigger>
              <Link>
                <Dismiss20Regular />
              </Link>
            </ToastTrigger>
          }
        >
          {message}
        </ToastTitle>
      </Toast>,
      { intent: "success", timeout: 2000 },
    );
  }, []);

  return { showErrors, showSuccess };
};

export default useToasts;
