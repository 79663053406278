import { FC, MouseEventHandler } from "react";
import { Button, Tooltip, makeStyles, shorthands, tokens } from "@fluentui/react-components";
import clsx from "clsx";

const useStyles = makeStyles({
  root: {
    "> button": {
      width: "auto",
      minWidth: "inherit",
      fontWeight: "normal",
      ...shorthands.padding("4px"),
    },
  },
  default: {
    // ...shorthands.border("1px", "solid", tokens.colorNeutralStroke1),
    // ...shorthands.borderRadius("4px"),
  },
  primary: {
    "> button": {
      color: tokens.colorBrandForeground2,
      backgroundColor: tokens.colorBrandBackground2,
      "&:hover": {
        color: tokens.colorBrandForeground2Hover,
        backgroundColor: tokens.colorBrandBackground2Hover,
      },
    },
  },
  secondary: {
    "> button": {
      color: tokens.colorNeutralForeground1,
      "&:hover": {
        color: tokens.colorNeutralForeground1Hover,
      },
    },
  },
  placeholder: {
    "> button": {
      color: tokens.colorNeutralForeground3,
      ...shorthands.textDecoration("underline"),
      "&:hover": {
        color: tokens.colorNeutralForeground2,
      },
    },
  },
});

export const Token: FC<{
  label: string;
  tooltip?: string;
  onClick?: MouseEventHandler;
  appearance?: "default" | "secondary" | "placeholder" | "primary";
}> = ({ onClick, label, tooltip, appearance = "default" }) => {
  const styles = useStyles();

  if (tooltip) {
    return (
      <div className={clsx(styles.root, appearance in styles && styles[appearance])}>
        <Tooltip content={tooltip} relationship="label" appearance="inverted">
          <Button appearance="subtle" onClick={onClick}>
            {label}
          </Button>
        </Tooltip>
      </div>
    );
  } else {
    return (
      <div className={clsx(styles.root, appearance in styles && styles[appearance])}>
        <Button appearance="subtle" onClick={onClick}>
          {label}
        </Button>
      </div>
    );
  }
};
