import { syncEngine } from "@src/lib/sync-engine/SyncEngine";
import { GetDocumentSettings, WriteDocumentSettings } from "../sync-engine/operations/WriteDocumentSettings";
import { JSONSchema9 } from "../types/jsonSchema";

const inputsKey = "walter.inputs";
const fieldsKey = "walter.fields";

export class DocumentSettingsStore {
  private onChangeEvent = "walter.documentSettingsChanged";

  public addChangeListener(callback: () => void): void {
    window.addEventListener(this.onChangeEvent, callback);
  }

  public removeChangeListener(callback: () => void): void {
    window.removeEventListener(this.onChangeEvent, callback);
  }

  public dispatchChangeEvent(): void {
    window.dispatchEvent(new Event(this.onChangeEvent));
  }

  async get<T>(key: string): Promise<T> {
    return syncEngine.perform(new GetDocumentSettings(key));
  }

  async set(key: string, value: any) {
    await syncEngine.perform(new WriteDocumentSettings({ key, value }));
    this.dispatchChangeEvent();
  }

  async delete(key: string) {
    await this.set(key, undefined);
  }

  async getInputs(): Promise<any> {
    return (await this.get(inputsKey)) || {};
  }

  async getFields(): Promise<JSONSchema9> {
    return (await this.get(fieldsKey)) || { type: "object", properties: {} };
  }

  async setInputs(inputs: any) {
    await this.set(inputsKey, inputs);
  }

  async setFields(fields: JSONSchema9) {
    await this.set(fieldsKey, fields);
  }
}

export const DocumentSettings = new DocumentSettingsStore();
