import { DynamicElement, JsElement } from "@src/lib/liquidx/internal";
import { JsTag, LoopRenderData } from "./ParsedTag";
import { JsElementProps } from "./JsElement";
import pluralize from "pluralize";
import { JSONSchema9 } from "../types/jsonSchema";
import { humanize } from "@src/taskpane/helpers/formatData";
import debug from "../debug";
import { Option } from "@src/lib/schemas";
import { syncEngine } from "../sync-engine/SyncEngine";
import { SelectContentControl } from "../sync-engine/operations/SelectContentControl";
import { propertyPathToFieldCode } from "./BasicVariableElement";

export interface BasicLoopElementUpdateProps {
  property: string;
  sorter?: Sorter;
  limit?: number;
  offset?: number;
}

export interface Sorter {
  property: string;
  direction: "asc" | "desc";
}

export interface UiData {
  sorter?: Sorter;
  type: "basic";
  title: string;
  label: string;
  property: string;
  limit?: number;
  offset?: number;
  field?: {
    key: string;
    schema: JSONSchema9;
  };
}

export class BasicLoopElement extends JsElement<LoopRenderData, UiData> {
  static schemaFor(element: DynamicElement | undefined): JSONSchema9 {
    let target = element;
    const loopElementAncestors: BasicLoopElement[] = [];
    while (target) {
      if (target instanceof BasicLoopElement) {
        loopElementAncestors.push(target);
      }

      target = target.parent;
    }

    const schema: JSONSchema9 = { type: "object", properties: {} };
    for (const loopElement of loopElementAncestors.reverse()) {
      if (loopElement.ui.field && schema.properties) {
        schema.properties[loopElement.ui.field.key] = loopElement.ui.field.schema;
      }
    }

    return schema;
  }

  static supports({ render, ui }: JsTag) {
    return render.type === "loop" && ui.type === "basic";
  }

  render: LoopRenderData;
  ui: UiData;
  defaultTag: JsTag<LoopRenderData, UiData> = {
    render: { type: "loop", iterableName: "", code: "" },
    ui: {
      type: "basic",
      property: "",
      title: "New loop",
      label: "New loop",
    },
    valid: true,
  };

  constructor();
  constructor(props: JsElementProps);
  constructor(props?: JsElementProps) {
    super();

    // Provide default values
    const tag = props?.tag ?? this.defaultTag;

    // Assign values
    this.id = props?.id ?? null;
    this.parentId = props?.parentId;
    this.render = {
      ...this.defaultTag.render,
      ...(tag?.render ?? {}),
      type: "loop",
    };

    this.ui = {
      ...this.defaultTag.ui,
      ...tag.ui,
      type: "basic",
    };
  }

  async rebuild() {
    return this.update({ property: this.ui.property, limit: this.ui.limit, offset: this.ui.offset });
  }

  async focus() {
    DynamicElement.focusedId = this.id;
    if (this.id) await syncEngine.perform(new SelectContentControl(this.id));
  }

  get appearance() {
    return "Tags" as Word.ContentControlAppearance.tags;
  }

  get placeholderText() {
    const itemName = pluralize.singular(this.ui.field?.schema.title ?? "item").toLowerCase();
    return `Loop over each ${itemName}`;
  }

  get innerText() {
    return undefined;
  }

  get title() {
    return this.ui.title;
  }

  get options() {
    return this.schemaStore.fetch("options", (store) => store.queryOptions({ type: "array" }));
  }

  get parentLoop() {
    let target = this.parent;
    while (target) {
      if (target instanceof BasicLoopElement) {
        return target;
      }

      target = target.parent;
    }

    return target;
  }

  validate(): void {
    super.validate();

    if (!this.schemaStore.find(this.ui.property)) {
      this.errors.push(`${JSON.stringify(this.ui.property)} is not a valid property`);
    }
  }

  get sortOptions(): Option[] {
    const key = this.ui.field?.key;
    if (!key) return [];

    return this.schemaStore.fetch("sortOptions", (store) => {
      return store
        .query({ parent: key, type: /string|number/ })
        .map((node) => ({
          label: node.relativeTitle(key),
          value: node.relativePath(key),
        }))
        .sort((a, b) => a.label.localeCompare(b.label));
    });
  }

  buildCode() {
    let code = propertyPathToFieldCode(this.ui.property);

    if (this.ui.sorter) {
      const options = `{ sortBy: (o) => o.${this.ui.sorter.property}, direction: ${JSON.stringify(
        this.ui.sorter.direction ?? "asc",
      )} }`;
      code = `Collection.sort(${code}, ${options})`;
    }

    if (this.ui.limit || this.ui.offset) {
      code = `Collection.applyLimitOffset(${code}, ${JSON.stringify({
        limit: this.ui.limit,
        offset: this.ui.offset,
      })})`;
    }

    return code;
  }

  async update({ property, sorter, limit, offset }: BasicLoopElementUpdateProps) {
    const selectedNode = this.schemaStore.find(property);

    if (selectedNode == null) {
      // TODO: Throw or something so an error is shown in the UI
      debug.error("can't find property in field store", property);
      return;
    }

    const fieldSchema = selectedNode.schema.items;
    const parts = property.split(".");
    const fieldKey = pluralize.singular(parts[parts.length - 1]);

    if (fieldSchema && typeof fieldSchema === "object" && !Array.isArray(fieldSchema)) {
      this.ui = {
        type: "basic",
        label: this.buildLabel(property, sorter),
        title: this.buildTitle(property),
        property,
        sorter,
        limit,
        offset,
        field: {
          key: fieldKey,
          schema: {
            ...fieldSchema,
            title: humanize(fieldKey),
          },
        },
      };

      this.render = {
        type: "loop",
        iterableName: pluralize.singular(fieldKey),
        code: this.buildCode(),
      };
    } else {
      // TODO: Throw or something so an error is shown in the UI
      debug.error("fieldSchema isn't what we expect (an object schema)", fieldSchema);
    }

    await this.save();
  }

  buildLabel(property: string, sorter: Sorter | undefined): string {
    const option = this.options.find((option) => option.value === property);
    let label = option?.label ?? "Loop";

    if (sorter) {
      // TODO: Switch so we're always dealing with SchemaProperty objects (and rename that object )
      // const sortByProperty = new SchemaProperty(`${property}.${sorter?.property}`, this.fieldStore);
      let sortDescription = `sorted by ${sorter.property}`;
      sortDescription += sorter.direction === "asc" ? "" : " descending";

      label += ` (${sortDescription})`;
    }

    return label;
  }

  buildTitle(property: string): string {
    return this.schemaStore.find(property)?.title ?? "Loop";
  }
}
