import { useEffect, useState } from "react";
import { DynamicElement } from "@src/lib/liquidx/internal";

export const useDynamicElements = () => {
  const [dynamicElements, setDynamicElements] = useState<DynamicElement[]>(DynamicElement.dynamicElementTree);
  const [dynamicElementsById, setDynamicElementsById] = useState<Map<number, DynamicElement>>(
    DynamicElement.dynamicElementsById,
  );

  useEffect(() => {
    async function handleLoad() {
      try {
        setDynamicElements(DynamicElement.dynamicElementTree);
        setDynamicElementsById(DynamicElement.dynamicElementsById);
      } catch (error) {
        console.error(error);
        throw error;
      }
    }

    window.addEventListener(DynamicElement.ON_LOAD_EVENT, handleLoad);
    return () => window.removeEventListener(DynamicElement.ON_LOAD_EVENT, handleLoad);
  }, []);

  return {
    loadDynamicElements: async () => DynamicElement.load(),
    dynamicElements,
    dynamicElementsById,
  };
};
