import debug from "@src/lib/debug";
import { OperationContext } from "../OperationContext";
import { Operation } from "./Operation";
import { captureException } from "@sentry/react";

export class FlushDocumentSettings implements Operation<void> {
  private commitRetries = 0;

  commit(_: OperationContext): Promise<void> {
    return new Promise((resolve, reject) => {
      Office.context.document.settings.saveAsync((result) => {
        if (result.status === Office.AsyncResultStatus.Failed) {
          if (this.commitRetries > 5) {
            reject(result.error);
          }

          this.commitRetries++;
          // wait 500ms and try again
          setTimeout(() => {
            debug.log("Failed to save settings, retrying...");

            this.commit(_)
              .then(() => {
                resolve();
              })
              .catch((e) => {
                captureException(new Error("Failed to save settings after 5 retries"));
                reject(e);
              });
          }, 500);
        } else {
          resolve();
        }
      });
    });
  }

  merge(other: Operation<unknown>) {
    if (other instanceof FlushDocumentSettings) {
      return this;
    }
  }
}
