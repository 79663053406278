import { ContentControlType } from "@src/lib/liquidx";
import { DynamicElement, JsElement } from "@src/lib/liquidx/internal";
import { ConditionalContentControlUpdate } from "@src/lib/sync-engine/operations/ConditionalContentControlUpdate";
import { InsertConditionalExpressionContentControl } from "@src/lib/sync-engine/operations/InsertConditionalExpressionContentControl";
import { syncEngine } from "@src/lib/sync-engine/SyncEngine";
import { BaseRenderData, BaseUiData } from "./ParsedTag";

/**
 * An abstract class for any dynamic element representing an `if...else`.
 */
export abstract class IfJsElement<R extends BaseRenderData, U extends BaseUiData> extends JsElement<R, U> {
  #shouldHaveElseBranch: boolean | undefined;

  /**
   * Return true if the element should have an else branch.
   * This defaults to true if the element has an else branch, and false if it doesn't.
   */
  get shouldHaveElse() {
    if (this.#shouldHaveElseBranch === undefined) {
      return this.elseBranch !== undefined;
    } else {
      return this.#shouldHaveElseBranch;
    }
  }

  set shouldHaveElse(value: boolean) {
    this.#shouldHaveElseBranch = value;
  }

  /**
   * When shouldHaveElseBranch is null and we have an else branch, return the ID of that else branch
   * This allows it to be deleted upon save.
   */
  get elseBranchIdToDelete() {
    return this.shouldHaveElse ? null : this.elseBranch?.id ?? null;
  }

  public async save({ isSystemUpdate }: { isSystemUpdate: boolean } = { isSystemUpdate: true }) {
    this.validate();

    if (this.id) {
      const updateOperation = new ConditionalContentControlUpdate({
        params: {
          id: this.id,
          location: this.insertLocation,
          title: this.title,
          tag: this.buildTag(),
          color: this.color,
          innerText: this.innerText,
          font: this.font,
          appearance: this.appearance,
          isSystemUpdate,
          errors: this.errors,
          cannotDelete: this.cannotDelete,
          cannotEdit: this.cannotEdit,
        },
        addElseBranch: !this.hasElse && this.shouldHaveElse,
        elseBranchIdToDelete: this.elseBranchIdToDelete,
      });

      await syncEngine.perform(updateOperation);
    } else {
      const insert = new InsertConditionalExpressionContentControl({
        title: this.title,
        color: this.color,
        tag: this.buildTag(),
        hasElse: this.shouldHaveElse,
        insertLocation: this.insertLocation,
      });

      await syncEngine.perform(insert);
    }

    return DynamicElement.load();
  }

  public async delete() {
    if (this.id) {
      // Delete wrapping element (wait for it to be deleted before deleting children, to ensure the children are editable)
      await syncEngine.delete({ id: this.id, keepContents: true });

      // Delete in the same sync
      await this.elseBranch?.delete();

      return DynamicElement.load();
    } else {
      return Promise.resolve();
    }
  }

  get innerText() {
    return undefined;
  }

  get elseBranch() {
    return this.children.find((child) => child.type === ContentControlType.ELSE_BRANCH);
  }

  get hasElse() {
    return this.elseBranch !== undefined;
  }

  get appearance() {
    return Word.ContentControlAppearance.tags;
  }

  get iconType() {
    return this.hasElse ? "ifElse" : "if";
  }
}
