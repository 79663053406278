import { FC, useEffect, useRef, useState } from "react";
import {
  Menu,
  MenuList,
  MenuPopover,
  MenuProps,
  MenuTrigger,
  TreeItem,
  TreeItemLayout,
} from "@fluentui/react-components";
import { mapDynamicElement } from "@src/taskpane/helpers/mapDynamicElement";
import { useIndentedTree } from "./IndentedTree";
import { useStyles } from "./FieldsPane";
import { DocumentFieldTreeItem } from "./DocumentFieldTreeItem";
import { InsertElementSubmenu } from "./InsertElementSubmenu";
import { insertable } from "../home/Toolbar";
import clsx from "clsx";
import { FieldMenuItems, scrollToRef } from "./ScalarTreeItem";
import { SchemaNode } from "@src/lib/schemas";
import { MoreMenu } from "./ObjectTree";

export const ArrayTreeItem: FC<{ node: SchemaNode }> = ({ node }) => {
  const { padding, focusedItem } = useIndentedTree();
  const styles = useStyles();
  const itemRef = useRef<HTMLDivElement>(null);
  const [menuOpen, setMenuOpen] = useState(false);

  // Scroll the focused item into view
  useEffect(() => {
    if (focusedItem === node.path) {
      scrollToRef(itemRef);
    }
  }, [focusedItem, node.path]);

  const menuItems = (
    <>
      <InsertElementSubmenu
        key="variables"
        property={node}
        items={insertable.arrayVariables}
        label="Variables"
        isFirst
      />
      <InsertElementSubmenu key="loops" property={node} items={insertable.loops} label="Loops" insertAround />
      <FieldMenuItems node={node} />
    </>
  );

  const onMenuOpenChange: MenuProps["onOpenChange"] = (_, { open }) => {
    setMenuOpen(open);
  };

  const treeItem = (
    <Menu positioning="below-end" openOnContext open={menuOpen} onOpenChange={onMenuOpenChange}>
      <MenuTrigger disableButtonEnhancement>
        <TreeItem
          ref={itemRef}
          itemType="leaf"
          value={node.path}
          className={clsx(focusedItem === node.path && styles.treeItemFocused)}
          onClick={() => setMenuOpen(true)}
        >
          <TreeItemLayout
            className={styles.treeItemLayout}
            style={{ paddingLeft: padding }}
            actions={<MoreMenu>{menuItems}</MoreMenu>}
          >
            <div className={styles.treeItemContent}>
              {mapDynamicElement("loop").iconSmall}
              <span title={node.description}>{node.title}</span>
            </div>
          </TreeItemLayout>
        </TreeItem>
      </MenuTrigger>

      <MenuPopover>
        <MenuList>{menuItems}</MenuList>
      </MenuPopover>
    </Menu>
  );

  if (node.field && !node.field.isContextual) {
    return <DocumentFieldTreeItem property={node}>{treeItem}</DocumentFieldTreeItem>;
  } else {
    return treeItem;
  }
};
