export enum ContentControlEvent {
  ON_ADDED = "walter.contentControlAdded",
  ON_DELETED = "walter.contentControlDeleted",
  ON_SELECTION_CHANGE = "walter.contentControlSelectionChange",
}

export enum Status {
  LOADING = "loading",
  READY = "ready",
}
