import { FC } from "react";
import { Link } from "@fluentui/react-components";
import { AliasElement } from "@src/lib/liquidx/internal";
import { useEditField } from "./FieldsPane/ScalarTreeItem";

export const Alias: FC<{ element: AliasElement }> = ({ element }) => {
  const editField = useEditField();

  return (
    <div>
      Alias to{" "}
      <Link inline as="a" onClick={() => element.node?.field && editField(element.node?.field)}>
        {element.node?.field?.label}
      </Link>
    </div>
  );
};
