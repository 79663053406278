import { init as initSentry, browserTracingIntegration, replayIntegration } from "@sentry/react";
import ReactDOM from "react-dom";
import { AppShell } from "./AppShell";
import { captureErrorInfo } from "@src/taskpane/helpers/errorHandler";

if (!import.meta.env.DEV) {
  const tracesSampleRate = Number(import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE ?? 0.1);

  let environment = "unknown";

  if (window.location.hostname === "word.getwalter.com") {
    environment = "production";
  } else if (window.location.hostname === "word.staging.getwalter.com") {
    environment = "staging";
  }

  initSentry({
    dsn: "https://17e3acb7f792024bdcadb94fe8f0b13f@o1352302.ingest.sentry.io/4505840787521536",
    environment,
    integrations: [
      browserTracingIntegration(),
      replayIntegration({
        blockAllMedia: false,
        maskAllInputs: false,
        maskAllText: false,
      }),
    ],
    // Performance Monitoring
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    // https://docs.sentry.io/platforms/javascript/guides/react/performance/instrumentation/automatic-instrumentation/
    tracePropagationTargets: ["localhost", /https:\/\/.*\.getwalter\.com/],
    tracesSampleRate,
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    beforeSend(event, _hint) {
      if (event.message?.trim()?.startsWith("Operation failed because the data is not current on the server")) {
        event.level = "info";
        event.tags = { ...event.tags, report_error_without_disruption: "true" }; // This tag will be used to filter out these events from triggering an alert. See https://minutebook.sentry.io/alerts/rules/word-add-in/15161015/
      }

      return event;
    },
  });
}

Office.onReady(() => {
  try {
    ReactDOM.render(<AppShell onOfficeReady={Office.onReady} />, document.getElementById("container"));
    document.getElementById("load-screen")?.remove();
  } catch (e) {
    captureErrorInfo(e);
  }
}).catch(captureErrorInfo);
