import { Variable } from "..";
import { type Property } from ".";
import { JSONSchema9 } from "../../types/jsonSchema";

export class SignTabParser {
  /**
   * This recursively walks the schema, finding objects that can be used as signatories.
   *
   * @param schema
   * @returns signatory variables for a given schema.
   */
  public buildFromObjectSchema(schema: JSONSchema9): Variable[] {
    // Initial as empty object if no properties are defined (this mitigates a bug that stored an empty object as the schema)
    if (Object.keys(schema).length === 0) schema = { type: "object", properties: {} };
    if (schema.type !== "object") throw new Error(`Schema must be an object: ${JSON.stringify(schema, null, 2)}`);

    // TODO: fix typescript type error. Remove @ts-ignore comment to see the error
    // @ts-ignore
    return Object.entries(schema.properties).flatMap(([propertyName, propertySchema]) => {
      if (typeof propertySchema === "boolean") {
        return this.handleBooleanSchema({ name: propertyName, schema: propertySchema });
      } else if (propertySchema.type === "array") {
        return this.handleArraySchema({ name: propertyName, schema: propertySchema });
      } else if (propertySchema.type === "object") {
        return this.handleObjectSchema({ name: propertyName, schema: propertySchema });
      } else {
        return [];
      }
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected handleBooleanSchema(_property: { name: string; schema: boolean }): Variable[] {
    return [];
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected handleArraySchema(_property: Property): Variable[] {
    return [];
  }

  protected handleObjectSchema(property: Property): Variable[] {
    const { name, schema } = property;
    const { title, description, type, deprecated } = schema;

    const variables = this.buildFromObjectSchema(schema).map(
      // TODO: fix typescript type error. Remove @ts-ignore comment to see the error
      // @ts-ignore
      (childVariable) => new Variable(name, title, description, type as string, childVariable, undefined, deprecated),
    );

    const isSignTab = SignTabParser.isSignTab({ name, schema });
    if (isSignTab) {
      // TODO: fix typescript type error. Remove @ts-ignore comment to see the error
      // @ts-ignore
      const variable = new Variable(name, title, description, type as string, undefined, undefined, deprecated);
      variables.push(variable);
    }

    return variables;
  }

  /**
   *
   * @param schema for object
   * @returns true when the object is a signatory
   */
  public static isSignTab({ name, schema }: { name: string; schema: JSONSchema9 }): boolean {
    if (name !== "sign_tab") return false;
    return !!schema.properties?.name && !!schema.properties?.email;
  }
}
