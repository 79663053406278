import { JSONSchema9 } from "@src/lib/types/jsonSchema";
import { humanize } from "@src/taskpane/helpers/formatData";
import { Field, SchemaContext, SchemaStore } from ".";

export class NodeNotFoundError extends Error {}

/**
 * Represents a property in the schema, providing access to its key, title, schema, and subproperties.
 *
 * It is useful when traversing a JSON schema to extract properties.
 */
export class SchemaNode {
  static find(path: string, store: SchemaStore): SchemaNode | undefined {
    try {
      return store.findNode(path);
    } catch (e: unknown) {
      if (e instanceof NodeNotFoundError) {
        return undefined;
      }

      throw e;
    }
  }
  /**
   * Returns the properties of an object at a given path. If the path points to something other than
   * an object (i.e., a type without properties), an empty array is returned.
   *
   * This filters out deprecated properties.
   *
   * @param {string} path - The path where to look for the properties.
   * @param {SchemaStore} store - The field store used to look up the property schema.
   * @returns {SchemaNode[]} An array of `SchemaProperty` objects.
   */
  static findProperties(path: string, store: SchemaStore): SchemaNode[] {
    return store.findNode(path).children;
  }

  public readonly key: string;
  public readonly schema: JSONSchema9;
  public readonly resolvedSchema: JSONSchema9;

  constructor(
    /**
     * The path to the property in the schema, like `foo.bar.baz`.
     * This value is used to reference the property in JavaScript as `fields.foo.bar.baz`.
     */
    public path: string,
    /**
     * Field store, which is used for property schema lookups.
     */
    public store: SchemaStore,
  ) {
    const parentPath = SchemaContext.parentPath(this.path);
    if (parentPath !== null) {
      const parentNode = store.findNode(parentPath);
      if (!parentNode) {
        throw new NodeNotFoundError(`Parent node not found at path: ${parentPath}`);
      }
      this.key = this.path.split(".").pop() || "";
      const isArrayNode = this.key.endsWith("[]");
      const parentResolvedSchema = parentNode.resolvedSchema;
      if (isArrayNode) {
        // Look for schema in parent's `items` property
        const items = parentResolvedSchema.items;
        if (items && typeof items === "object" && !Array.isArray(items)) {
          this.schema = items;
        } else {
          throw new NodeNotFoundError(`Items schema not found or malformed for ${this.path}: ${JSON.stringify(items)}`);
        }
      } else {
        // Look for schema in parent's properties
        const schema = parentResolvedSchema.properties?.[this.key];
        if (schema && typeof schema === "object") {
          this.schema = schema;
        } else {
          throw new NodeNotFoundError(`Property not found at path: ${this.path}`);
        }
      }
    } else {
      this.key = "";
      this.schema = store.schema;
    }

    this.resolvedSchema = store.resolveSchema(this.schema);
  }

  get format(): string | undefined {
    return this.resolvedSchema.format;
  }

  /**
   * @returns {Field} when this node is top-level property, otherwise return undefined.
   */
  get field(): Field | undefined {
    try {
      if (!this.parent) return new Field(this.path, this.store);
      return undefined;
    } catch (e: unknown) {
      if (e instanceof NodeNotFoundError) {
        return undefined;
      }

      throw e;
    }
  }

  /**
   * Sign tab node for this property, if it exists.
   *
   * @returns {SchemaNode} when sign tab exists, otherwise return undefined.
   */
  get signTab(): SchemaNode | undefined {
    return this.children.find((child) => child.ref === "#/definitions/sign_tab");
  }

  get isRemovable() {
    return this.field && !this.field.isContextual;
  }

  get isDeprecated(): boolean {
    return !!this.resolvedSchema.deprecated || !!this.parent?.isDeprecated;
  }

  /**
   * This returns true when this schema or its parent are marked as hidden.
   *
   * We check a custom property, `hidden`, on the schema to determine if the schema is marked as hidden.
   * We use this to hide certain objects or properties from the UI, like sign tabs.
   *
   * NOTE: We only look at this and the parent's resolved schema to determine if it is hidden!
   *       We don't recursively check ancestors for performance reasons.
   *
   * @param schema
   * @returns true when the schema is marked as hidden, otherwise return false.
   */
  get isHidden(): boolean {
    const resolvedSchema = this.resolvedSchema;
    const thisIsHidden = "hidden" in resolvedSchema && !!resolvedSchema.hidden;

    return !!thisIsHidden || !!this.parent?.isHidden;
  }

  /**
   * Returns `true` if this property's path points to a field (i.e., field === top-level property)
   * For example, `option_grant` is a field in the schema, but `option_grant.quantity` is not.
   */
  get isField(): boolean {
    return this.field !== undefined;
  }

  get title(): string {
    return this.schema.title || humanize(this.key);
  }

  get description(): string {
    return this.schema.description || "";
  }

  get parent(): SchemaNode | null {
    const parentPath = SchemaContext.parentPath(this.path);

    if (parentPath) {
      return this.store.findNode(parentPath);
    } else {
      return null;
    }
  }

  get children(): SchemaNode[] {
    return Object.keys(this.resolvedSchema.properties ?? [])
      .map((key) => {
        const path = [this.path, key].filter(Boolean).join(".");
        return SchemaNode.find(path, this.store) as SchemaNode;
      })
      .filter(Boolean);
  }

  get ancestors(): SchemaNode[] {
    const parent = this.parent;
    if (!parent) return [];

    return [parent, ...parent.ancestors];
  }

  get descendants(): SchemaNode[] {
    return this.children.flatMap((p) => [p, ...p.descendants]);
  }

  get treeType(): "object" | "array" | "scalar" {
    const resolvedSchema = this.resolvedSchema;
    if ("properties" in resolvedSchema && resolvedSchema.properties && typeof resolvedSchema.properties === "object") {
      return "object";
    }

    if ("items" in resolvedSchema) return "array";

    return "scalar";
  }

  get type(): JSONSchema9["type"] {
    return this.resolvedSchema.type;
  }

  get ref(): string | undefined {
    return this.schema.$ref;
  }

  get fullTitle(): string {
    return this.buildTitle(this.ancestors);
  }

  /**
   * Apply two truncation rules to the label:
   *
   * 1. If the label has more than 3 levels, show the first and last levels only.
   * 2. If the label is longer than 30 characters, show the last level only.
   *
   * @param input
   * @returns truncated label (if label short enough, return the original label)
   */
  get truncatedTitle(): string {
    let truncated = this.fullTitle;
    if (!truncated.includes(" > ")) return truncated;

    const levels = truncated.split(" > ");
    if (levels.length > 3) {
      truncated = `${levels[0]} > ... > ${levels[levels.length - 1]}`;
    }

    // Only show the last level
    if (truncated.length >= 30) truncated = `...${levels[levels.length - 1]}`;

    return truncated;
  }

  relativePath(path: string): string {
    if (this.path.startsWith(path)) {
      return this.path.replace(path, "").replace(/^\./, "");
    } else {
      return path;
    }
  }

  relativeTitle(parentPath: string): string {
    const relevantAncestors = this.ancestors.filter((a) => a.path.startsWith(parentPath) && a.path !== parentPath);
    return this.buildTitle(relevantAncestors);
  }

  private buildTitle(ancestors: SchemaNode[]): string {
    return ancestors
      .reverse()
      .map((a) => a.title)
      .concat(this.title)
      .filter(Boolean)
      .join(" > ");
  }
}
