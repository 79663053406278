import { JSONSchema9Object } from "../types/jsonSchema";
import { Fieldset } from "../liquidx/Fieldset";
import { WalterApi } from "@src/lib/walter/api";
import { captureException } from "@sentry/react";
import debug from "@src/lib/debug";
import { camelToSnakeCase } from "@src/lib/stringUtils";
const workflowSchemas = import.meta.glob<{ default: JSONSchema9Object }>("../schemas/workflows/*.json", {
  eager: true,
});

export type UserDefinedFieldSet = { id: number; key: string; label: string; description?: string };

type FieldSetResponse = {
  userPersonalFieldSets: UserDefinedFieldSet[];
  workspaceFieldSets: UserDefinedFieldSet[];
};

export function getUserDefinedFieldSets(currentWorkspaceId: number) {
  let fieldSets: Promise<FieldSetResponse> = Promise.resolve({
    userPersonalFieldSets: [],
    workspaceFieldSets: [],
  });
  const { response, abortController } = WalterApi.getUserDefinedFieldSets(currentWorkspaceId);
  const userPersonalFieldSets: UserDefinedFieldSet[] = [];
  const workspaceFieldSets: UserDefinedFieldSet[] = [];

  fieldSets = response
    .then((res) => {
      res?.fieldSets?.forEach(({ schema, label, ownerType, id, description }) => {
        if (ownerType === "User") {
          userPersonalFieldSets.push({
            id,
            key: label,
            label,
            description,
          });
        } else {
          workspaceFieldSets.push({
            id,
            key: label,
            label,
            description,
          });
        }

        let snakeCaseKey;

        if (schema.properties !== undefined) {
          Object.keys(schema.properties).forEach((key) => {
            snakeCaseKey = camelToSnakeCase(key);
            if (schema.properties === undefined) {
              return;
            }
            schema.properties[snakeCaseKey] = schema.properties[key];
            // This is to prevent deleting the property if its key is already in snake case
            if (snakeCaseKey !== key) {
              delete schema.properties[key];
            }
          });
        }

        Fieldset.add(schema);
      });

      return {
        userPersonalFieldSets,
        workspaceFieldSets,
      };
    })
    .catch((e) => {
      debug.error(e);
      captureException(e);

      return {
        userPersonalFieldSets,
        workspaceFieldSets,
      };
    });

  return {
    fieldSets,
    abortController,
  };
}

export function getBuiltinFieldSets() {
  const presetFieldSets: { key: string; label: string; description?: string }[] = [];

  for (const path in workflowSchemas) {
    const module = workflowSchemas[path].default;
    const fileName = path.split("/").pop()?.replace(".json", "");

    if (fileName) {
      presetFieldSets.push({
        key: fileName,
        label: (module.title as string) || fileName,
        description: (module.description as string) ?? undefined,
      });

      Fieldset.add(module);
    }
  }

  return presetFieldSets;
}
