import { JSONSchema9, JSONSchema9Definition } from "../types/jsonSchema";
import templateObjectsSchema from "@src/lib/schemas/template_objects.json";

type PathSegment = { key: string } | { isArray: true };

/**
 * This class provides a simple interface for working with JSON Schemas containing $refs.
 * It allows you to find properties in a schema by path without the complexity of managing and resolving $refs.
 */
export abstract class SchemaContext {
  static defaultDefinitions = templateObjectsSchema.definitions as JSONSchema9["definitions"];

  static parsePath(path: string): PathSegment[] {
    const unparsedSegments = path.split(".");

    return unparsedSegments.reduce((acc: PathSegment[], segment) => {
      const isArray = segment.endsWith("[]");
      const key = isArray ? segment.slice(0, -2) : segment;

      if (key) acc.push({ key });
      if (isArray) acc.push({ isArray });
      return acc;
    }, []);
  }

  static joinPathSegments(segments: PathSegment[]): string {
    return segments.reduce((path, segment) => {
      if ("isArray" in segment) {
        return `${path}[]`;
      } else {
        return [path, segment.key].filter(Boolean).join(".");
      }
    }, "");
  }

  static parentPath(path: string): string | null {
    if (path === "") return null;

    const segments = this.parsePath(path);
    return this.joinPathSegments(segments.slice(0, -1));
  }

  static findSchema(path: string, rootSchema: JSONSchema9): JSONSchema9 | null {
    const pathSegments = SchemaContext.parsePath(path);
    let currentSchema: JSONSchema9Definition | JSONSchema9Definition[] = rootSchema;

    for (const pathSegment of pathSegments) {
      // We can't lookup properties in boolean schemas
      if (currentSchema === false || currentSchema === true) return null;

      // We don't support lookups into tuple validations yet
      // https://json-schema.org/understanding-json-schema/reference/array#tuple-validation
      if (Array.isArray(currentSchema)) return null;

      currentSchema = this.resolveSchema(currentSchema, rootSchema.definitions);

      if ("isArray" in pathSegment) {
        if (currentSchema.type === "array" && currentSchema.items) {
          currentSchema = currentSchema.items;
        } else {
          // Can't find the array items, return null
          return null;
        }
      } else {
        // look for object properties
        if (currentSchema.type === "object" && currentSchema.properties && currentSchema.properties[pathSegment.key]) {
          currentSchema = currentSchema.properties[pathSegment.key];
        } else {
          // Can't find the property, return null
          return null;
        }
      }
    }

    // We can't lookup properties in boolean schemas
    if (currentSchema === false || currentSchema === true) return null;

    // We don't support lookups into tuple validations yet
    // https://json-schema.org/understanding-json-schema/reference/array#tuple-validation
    if (Array.isArray(currentSchema)) return null;

    return currentSchema;
  }
  static resolveSchema<T extends JSONSchema9>(
    currentSchema: T,
    definitions: JSONSchema9["definitions"],
    options?: {
      recursive?: boolean;
    },
  ): T {
    const { recursive } = { recursive: false, ...options };
    definitions ??= this.defaultDefinitions;

    while (currentSchema.$ref) {
      const refPath = currentSchema.$ref.replace(/^#\/definitions\//, "");
      const definition = definitions?.[refPath];

      if (definition && typeof definition === "object") {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars -- using destructuring to remove $ref
        const { $ref, ...restOfSchema } = currentSchema;
        currentSchema = { ...definition, ...restOfSchema } as T;
      } else {
        // Can't find the definition, return the current schema
        break;
      }

      // Optionally, keep resolving $refs until we can't find any more
      // This allows a property to point to a definition that points to another definition.
      if (!recursive) break;
    }

    return currentSchema;
  }
}
