import { createContext, FunctionComponent, useEffect } from "react";
import { JsElement } from "../../../lib/liquidx/internal";
import { useInitialized } from "@src/taskpane/hooks/useInitialized";
import { useDynamicElements } from "@src/taskpane/hooks/useDynamicElements";
import { useFeatureFlags } from "@src/taskpane/hooks/useFeatureFlags";
import { captureErrorInfo } from "@src/taskpane/helpers/errorHandler";
import useToasts from "@src/taskpane/hooks/useToasts";
import type { WalterUser } from "@src/lib/auth/OAuthService";
import { WalterApi } from "@src/lib/walter/api";
import { DocumentSettings } from "@src/lib/settings/DocumentSettings";
import { defaultAppState, useAppState } from "@src/taskpane/providers/walter/app-state";
import { getCurrentWorkspace } from "@src/lib/walter/authContext";

export const WalterContext = createContext(defaultAppState);

export const WalterProvider: FunctionComponent = ({ children }) => {
  const initialized = useInitialized();
  const featureFlagName = "js_templates_word_add_in";
  const { dynamicElements } = useDynamicElements();
  const featureFlagManager = useFeatureFlags();
  const { showErrors } = useToasts();
  const { state, dispatch, setCurrentUser, switchWorkspace } = useAppState();

  useEffect(() => {
    // TODO: Change how the platform is resolved (right now, this may be called before Office.context is available)
    if (initialized) dispatch({ type: "SET_PLATFORM", payload: Office.context.platform });
  }, [initialized]);

  useEffect(() => {
    async function checkIfNewUIEnabled() {
      let enabled = true;

      if (!state.currentUser?.id) {
        return {
          enabled,
          updatedShowNewUi: true,
        };
      }

      try {
        enabled = (await featureFlagManager.isEnabled(featureFlagName)) ?? true;
      } catch (e) {
        captureErrorInfo(e);
        enabled = true;
      }

      const hasJsElements = dynamicElements.some((element) => element instanceof JsElement);
      const hasNoElements = dynamicElements.length === 0;
      const updatedShowNewUi = hasNoElements || hasJsElements;

      return {
        enabled,
        updatedShowNewUi,
      };
    }

    checkIfNewUIEnabled()
      .then(({ enabled, updatedShowNewUi }: { enabled: boolean; updatedShowNewUi: boolean }) => {
        dispatch({
          type: "SET_SHOW_NEW_UI",
          payload: {
            showNewUi: enabled && updatedShowNewUi,
            updatedShowNewUi: enabled && !updatedShowNewUi,
          },
        });
      })
      .catch(captureErrorInfo);
  }, [dynamicElements, state.currentUser, featureFlagManager]);

  useEffect(() => {
    if (initialized) {
      // By default, show the taskpane when the document is opened.
      DocumentSettings.get("Office.AutoShowTaskpaneWithDocument")
        .then(async (autoShow) => {
          await DocumentSettings.set("Office.AutoShowTaskpaneWithDocument", autoShow ?? true);
        })
        .catch(captureErrorInfo);
    }
  }, [initialized]);
  // Run migrations again once we have a token (this allows migrations to defer themselves until we have a token)
  useEffect(() => {
    let whoamiReqAbortController: AbortController;

    if (initialized && state.isLoggedIn) {
      let whoamiResponse: Promise<WalterUser> | null;
      ({ abortController: whoamiReqAbortController, response: whoamiResponse } =
        WalterApi.get<WalterUser>("api/users/whoami"));

      if (whoamiResponse) {
        whoamiResponse
          .then((user) => {
            const currentWorkspace = getCurrentWorkspace(user, () => {
              showErrors({
                title: "No workspaces found",
                messages: ["You must have a workspace in walter with accessible legal entities to use this add-in."],
              });
            });

            switchWorkspace(currentWorkspace);
            setCurrentUser(user);
          })
          .catch(captureErrorInfo);
      } else {
        captureErrorInfo(new Error("Failed to set current user info"));
        showErrors({
          title: "Error initializing user",
        });
      }
    }

    return () => {
      if (whoamiReqAbortController) whoamiReqAbortController.abort();
    };
  }, [initialized, state.isLoggedIn]);

  return <WalterContext.Provider value={state}>{children}</WalterContext.Provider>;
};
