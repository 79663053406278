import { GetSelection, SelectionDetails } from "../operations/GetSelection";
import { SyncEngine } from "../SyncEngine";

/**
 * This efficiently handles one or more DocumentSelectionChangedEvent handler.
 *
 * Here's how it works:
 * 1. When the first handler is added, it registers the event handler with the Office API.
 * 2. When the event fires, it resolves the current selection and calls all the handlers.
 * 3. When the last handler is removed, it unregisters the event handler with the Office API.
 *
 */
export class SelectionEventHandler {
  private syncEngine: SyncEngine;
  private selectionChangedHandlers: Array<(event: SelectionDetails) => void> = [];
  private isRegistered = false;
  private boundHandler: ((event: Office.AsyncResult<void>) => void) | undefined = undefined;

  constructor(syncEngine: SyncEngine) {
    this.syncEngine = syncEngine;
  }

  async handler(_: Office.AsyncResult<void>) {
    const selectionDetails = await this.fetchSelectionDetails();
    this.selectionChangedHandlers.forEach((handler) => handler(selectionDetails));
  }

  async fetchSelectionDetails() {
    return this.syncEngine.perform(new GetSelection());
  }

  addSelectionChangedEventListener(handler: (event: SelectionDetails) => void) {
    if (!this.isRegistered) {
      this.boundHandler = (event) => this.handler(event);
      Office.context.document.addHandlerAsync(Office.EventType.DocumentSelectionChanged, this.boundHandler);
      this.isRegistered = true;
    }

    this.selectionChangedHandlers.push(handler);

    // Trigger the handler immediately.
    void (async () => handler(await this.fetchSelectionDetails()))();
  }

  removeSelectionChangedEventListener(handler: (event: SelectionDetails) => void) {
    const index = this.selectionChangedHandlers.indexOf(handler);

    if (index !== -1) {
      this.selectionChangedHandlers.splice(index, 1);
    }

    if (this.selectionChangedHandlers.length === 0) {
      Office.context.document.removeHandlerAsync(Office.EventType.DocumentSelectionChanged, this.boundHandler);
      this.isRegistered = false;
    }
  }
}
