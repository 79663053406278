import { IfJsElement, JsElementProps } from "@src/lib/liquidx/internal";
import { BaseUiData, IfRenderData, JsTag } from "./ParsedTag";

export interface UiData extends BaseUiData {
  type: "advanced";
}

export class AdvancedIfElement extends IfJsElement<IfRenderData, UiData> {
  static supports({ render, ui }: JsTag) {
    return render.type === "if" && ui.type === "advanced";
  }

  render: IfRenderData;
  ui: UiData;
  defaultTag: JsTag<IfRenderData, UiData> = {
    render: { type: "if", code: "" },
    ui: { type: "advanced", label: "new advanced if" },
    valid: true,
  };

  constructor();
  constructor(props?: JsElementProps) {
    super();

    this.id = props?.id ?? null;
    this.parentId = props?.parentId;

    const tag = props?.tag ?? this.defaultTag;
    this.render = {
      ...this.defaultTag.render,
      ...tag.render,
      type: "if",
    };

    this.ui = {
      ...this.defaultTag.ui,
      ...tag.ui,
      type: "advanced",
    };
  }

  async rebuild() {
    return this.update({ code: this.render.code, elseBranch: !!this.elseBranch });
  }

  async update({ code, elseBranch }: { code: string; elseBranch?: boolean }) {
    this.render.code = code;
    this.shouldHaveElse = elseBranch ?? !!this.elseBranch;
    this.ui.label = this.buildLabel();

    await this.save();
  }

  buildLabel() {
    if (this.render.code) {
      const codeLines = this.render.code.split("\n");
      return codeLines[codeLines.length - 1];
    } else {
      return "new advanced if";
    }
  }
}
