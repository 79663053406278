import { DynamicElement, JsElement } from "@src/lib/liquidx/internal";
import { JsTag, VariableRenderData } from "./ParsedTag";
import { JsElementProps } from "./JsElement";
import { humanize, unicodeToString } from "@src/taskpane/helpers/formatData";
import { propertyPathToFieldCode } from "./BasicVariableElement";

interface UpdateProps {
  property: string;
  signTabType: SignTabType;
  group?: string;
  required?: boolean;
  width?: number;
}

export type SignTabType = "signature" | "initials" | "dateSigned" | "text" | "radio" | "checkbox";

export interface UiData {
  type: "signTab";
  label: string;
  signTabType: SignTabType;
  property: string;
  required?: boolean;
  width?: number;
  group?: string;
  optionLabel: string;
}

export class SignTabJsElement extends JsElement<VariableRenderData, UiData> {
  static supports({ render, ui }: JsTag) {
    return render.type === "variable" && ui.type === "signTab";
  }

  static placeholderByType: Record<string, string | undefined> = {
    text: "[TEXT FIELD]",
    checkbox: unicodeToString("25A2"),
    radio: unicodeToString("25EF"),
  };

  get options() {
    return this.schemaStore.fetch("signTab", (store) => {
      const options = store
        .query({ ref: "#/definitions/sign_tab", includeHidden: true })
        .filter((node) => node.parent)
        .map((node) => ({ label: node.parent!.fullTitle, value: node.path }))
        .sort((a, b) => a.label.localeCompare(b.label));

      return options;
    });
  }

  render: VariableRenderData;
  ui: UiData;
  defaultTag: JsTag<VariableRenderData, UiData> = {
    render: { type: "variable", code: "" },
    ui: {
      type: "signTab",
      signTabType: "signature",
      property: "",
      label: "New sign tab",
      optionLabel: "",
    },
    valid: true,
  };

  constructor();
  constructor(props: JsElementProps);
  constructor(props?: JsElementProps) {
    super();

    // Provide default values
    const tag = props?.tag ?? this.defaultTag;

    // Assign values
    this.id = props?.id ?? null;
    this.parentId = props?.parentId;
    this.render = {
      ...this.defaultTag.render,
      ...(tag?.render ?? {}),
      type: "variable",
    };

    this.ui = {
      ...this.defaultTag.ui,
      ...tag.ui,
      type: "signTab",
    };

    // Apply other defaults
    this.font = { highlightColor: DynamicElement.colors.highlight };
  }

  async rebuild() {
    return this.update({
      property: this.ui.property,
      required: this.ui.required,
      signTabType: this.ui.signTabType,
      width: this.ui.width,
      group: this.ui.group,
    });
  }

  validate(): void {
    super.validate();

    const validProperties = this.options.map(({ value }) => value);
    if (!validProperties.includes(this.ui.property) && !this.isNew) {
      this.errors.push(`${JSON.stringify(this.ui.property)} is not a valid property`);
    }
  }

  buildCode() {
    const props = {
      required: this.ui.required,
      width: this.ui.width,
      group: this.ui.group,
    };

    return `SignTab.${this.ui.signTabType}(${propertyPathToFieldCode(this.ui.property)}, ${this.toJavaScript(props)})`;
  }

  get innerText(): string | undefined {
    const suffix = this.ui.required ? "*" : "";

    if (this.ui.signTabType === "signature") return `Sign: ${this.ui.optionLabel}${suffix}`;
    if (this.ui.signTabType === "initials") return `Initials: ${this.ui.optionLabel}${suffix}`;
    if (this.ui.signTabType === "dateSigned") return `Date signed: ${this.ui.optionLabel}${suffix}`;

    const placeholder = SignTabJsElement.placeholderByType[this.ui.signTabType];
    return `${placeholder}${suffix}`;
  }

  async update({ property, required, width, signTabType, group }: Partial<UpdateProps>) {
    property ??= this.ui.property;
    signTabType ??= this.ui.signTabType;
    required ??= this.ui.required;
    width ??= this.ui.width;
    group ??= this.ui.group;

    const selectedOption = this.options.find((option) => option.value === property);
    const optionLabel = selectedOption?.label ?? "sign tab";

    this.ui = {
      type: "signTab",
      label: `${humanize(signTabType)}: ${optionLabel}${required ? "*" : ""}`,
      property,
      optionLabel,
      required,
      width,
      signTabType,
      group,
    };

    this.render = {
      type: "variable",
      code: this.buildCode(),
    };

    return this.save();
  }

  get configureRequired(): boolean {
    return this.ui.signTabType !== "checkbox" && this.ui.signTabType !== "dateSigned";
  }

  get configureWidth(): boolean {
    return this.ui.signTabType !== "radio" && this.ui.signTabType !== "checkbox";
  }

  get focusedTitle() {
    return this.ui.label;
  }

  get hasGroup(): boolean {
    return this.ui.signTabType === "radio";
  }

  get iconType() {
    return this.ui.signTabType;
  }
}
