import { makeStyles } from "@fluentui/react-components";

const useStyles = makeStyles({
  root: {
    "> *": {
      marginTop: "15px",
    },
  },
});

export default function usePageLoadTransition() {
  return useStyles().root;
}
