import { Operation } from "./Operation";
import { OperationContext } from "../OperationContext";

/**
 * Sets the current selection to the body of a content control in the document.
 */
export class SelectContentControl implements Operation<void> {
  constructor(private id: number) {}

  async commit(context: OperationContext): Promise<void> {
    context.trace("SelectContentControl#commit");

    const contentControl = context.document.contentControls.getById(this.id);
    contentControl.select(Word.SelectionMode.select);
    await context.sync();
  }

  merge(other: Operation<unknown>) {
    if (other instanceof SelectContentControl && other.id === this.id) {
      return this;
    }
  }
}
