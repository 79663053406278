import { OperationContext } from "../OperationContext";
import { CustomXmlStore } from "./CustomXmlStore";
import { Operation } from "./Operation";

type WalterPartWithBlob = {
  part: Word.CustomXmlPart;
  customXmlStore: CustomXmlStore;
};

export class SetValueInCustomXmlStore implements Operation<void> {
  constructor(public key: string, public value: unknown) {}

  merge(other: Operation<unknown>): Operation<void> | undefined {
    if (other instanceof SetValueInCustomXmlStore && other.key === this.key) {
      return other as unknown as Operation<void>;
    }
  }

  async commit(context: OperationContext): Promise<void> {
    let customXmlStore: CustomXmlStore;
    let part: Word.CustomXmlPart;

    const partAndDocument = await findWalterCustomXmlPart(context);
    if (partAndDocument) {
      part = partAndDocument.part;
      customXmlStore = partAndDocument.customXmlStore;
    } else {
      customXmlStore = new CustomXmlStore();
      part = context.document.customXmlParts.add(customXmlStore.xml);
    }

    customXmlStore.setProperty(this.key, this.value);

    part.setXml(customXmlStore.xml);
    await context.sync();
  }
}

export async function findWalterCustomXmlPart(context: OperationContext) {
  const parts = context.document.customXmlParts.load("items");
  await context.sync();
  const partsWithBlob: { part: Word.CustomXmlPart; result: OfficeExtension.ClientResult<string> }[] = parts.items.map(
    (part) => ({
      part: part.load("id"),
      result: part.getXml(),
    }),
  );
  await context.sync();

  let walterPartWithBlob: WalterPartWithBlob | null | undefined;

  for (const { part, result } of partsWithBlob) {
    const customXmlStore = CustomXmlStore.parse(result.value);

    if (customXmlStore) {
      walterPartWithBlob = { part, customXmlStore };
      break;
    }
  }

  return walterPartWithBlob;
}
