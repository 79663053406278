import { PluralizeUiData } from "@src/lib/liquidx/PluralizeElement";
import { documentSchemaStore, SchemaNode, SchemaStore } from "@src/lib/schemas";
import { ComputedFieldSchema } from "./EditVariableField";
import { VariableRenderData } from "@src/lib/liquidx/ParsedTag";
import { ComputedField } from "./ComputedField";
import { PluralizeSnippet } from "../PluralizeSnippet";

export class PluralizeComputedField extends ComputedField<VariableRenderData, PluralizeUiData> {
  static defaultSchema: ComputedFieldSchema<VariableRenderData, PluralizeUiData> = {
    title: "",
    type: "string",
    computed: {
      render: { type: "variable", code: "" },
      ui: {
        type: "pluralize",
        property: "",
        singular: "",
        plural: "",
        showCount: false,
      },
    },
  };

  snippet: PluralizeSnippet;

  constructor({
    schemaNode,
    schemaStore = documentSchemaStore,
  }: {
    schemaNode?: SchemaNode;
    schemaStore?: SchemaStore;
  } = {}) {
    super({ defaultSchema: PluralizeComputedField.defaultSchema, schemaNode, schemaStore });
    this.snippet = new PluralizeSnippet(this);
  }
}
