import { FunctionComponent, useState, useEffect } from "react";
import { Button } from "@fluentui/react-components";
import GroupedCombobox from "./shared/GroupedCombobox";
import { group } from "../helpers/formatData";
import { InputOption } from "../../lib/walter";
import { useInputOptions } from "../hooks/useInputOptions";
import { useDynamicElementFromParams } from "../hooks/useDynamicElement";
import { VariableElement } from "../../lib/liquidx/VariableElement";
import { useHistory } from "react-router-dom";
import DeleteButton from "./shared/DeleteButton";
import usePageLoadTransition from "../hooks/usePageLoadTransition";
import { DateFormat } from "./shared/DateFormat";

interface VariableProps {
  label: string;
  submitLabel: string;
}

export const Variable: FunctionComponent<VariableProps> = ({ label, submitLabel }) => {
  const pageTransitionClassName = usePageLoadTransition();
  const variableElement = useDynamicElementFromParams(new VariableElement());
  const [value, setValue] = useState<string | undefined>(variableElement?.variable.fullName);
  const [dateFormat, setDateFormat] = useState<string>("");
  const { inputOptions } = useInputOptions();
  const options = inputOptions.variableOptions;
  const history = useHistory();

  useEffect(() => {
    if (variableElement.isNew) return;

    setValue(variableElement.variable.fullName), [variableElement.variable.fullName];

    const filterValue = variableElement.variable.leafFilters.get("date");
    if (filterValue) {
      setDateFormat(filterValue);
    }
  }, [variableElement]);

  const input = options.find((option: InputOption) => option.value === value);

  const handleSubmit = () => {
    if (input?.variable) {
      const variable = input.variable;

      if (dateFormat) {
        variable.removeFilter("date");
        variable.addFilter("date", dateFormat);
      }

      variableElement.applyVariable(input.variable);
      void variableElement.save();
      history.push("/");
    }
  };

  const handleDelete = async () => {
    await variableElement.delete();
    void VariableElement.load();
    history.push("/");
  };

  const onSelect = (value: string) => {
    setDateFormat(value);
  };

  return (
    <div className={pageTransitionClassName}>
      <GroupedCombobox
        label={label}
        groupedOptions={group(options)}
        onSelect={(value) => setValue(value)}
        selectedOption={value}
        comboboxProps={{ placeholder: "Search for variable" }}
      ></GroupedCombobox>
      {input?.format === "date" && (
        <DateFormat placeholder="Choose date format" onSelect={onSelect} dateFormat={dateFormat} />
      )}
      <Button appearance="primary" onClick={handleSubmit} disabled={!value}>
        {submitLabel}
      </Button>
      {variableElement && <DeleteButton onClick={handleDelete} />}
    </div>
  );
};
