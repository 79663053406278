import { FC, useEffect, useState } from "react";
import { Checkbox, Input, Label, makeStyles, shorthands } from "@fluentui/react-components";
import { SignTabJsElement } from "@src/lib/liquidx/internal";
import { TextCombobox } from "./TextCombobox";
import { Option } from "@src/lib/schemas";
import { WidthSlider } from "./shared/WidthSlider";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    ...shorthands.gap("8px"),
  },
});

type UpdateProps = Parameters<SignTabJsElement["update"]>[0];
export const SignTab: FC<{ element: SignTabJsElement }> = ({ element }) => {
  const [options, setOptions] = useState<Option[]>([]);
  const [updateProps, setUpdateProps] = useState<UpdateProps>({});
  const styles = useStyles();

  useEffect(() => {
    setOptions(element.options);
    setUpdateProps({
      property: element.ui.property,
      required: element.ui.required,
      group: element.ui.group,
      width: element.ui.width,
    });
  }, [element]);

  function handleUpdate(props: UpdateProps) {
    setUpdateProps({ ...updateProps, ...props });
    void element.update(props);
  }

  return (
    <div className={styles.root}>
      <TextCombobox
        autoFocus={element.isNew}
        options={options}
        value={updateProps.property ?? ""}
        onChange={(property) => handleUpdate({ property })}
      />
      {element.configureRequired && (
        <Checkbox
          checked={updateProps.required ?? false}
          onChange={(_ev, data) => handleUpdate({ required: data.checked as boolean })}
          label="Required"
        />
      )}
      {element.hasGroup && (
        <>
          <Label>Group name</Label>
          <Input
            value={updateProps.group ?? ""}
            placeholder="Group name"
            spellCheck={false}
            onChange={(_ev, { value }) => handleUpdate({ group: value })}
          />
        </>
      )}
      {element.configureWidth && (
        <WidthSlider
          value={updateProps.width ?? 0}
          onChange={(value) => handleUpdate({ width: value })}
          defaultValue={140}
        />
      )}
    </div>
  );
};
