import { OperationContext } from "@src/lib/sync-engine/OperationContext";
import { ContentControlUpdate, Params } from "@src/lib/sync-engine/operations/ContentControlUpdate";
import { InsertConditionalExpressionContentControl } from "@src/lib/sync-engine/operations/InsertConditionalExpressionContentControl";

export class ConditionalContentControlUpdate extends ContentControlUpdate {
  private addElseBranch?: boolean;
  private elseBranchIdToDelete?: number | null;

  constructor({
    params,
    addElseBranch,
    elseBranchIdToDelete,
  }: {
    params: Params;
    addElseBranch?: boolean;
    elseBranchIdToDelete?: number | null;
  }) {
    super(params);
    this.addElseBranch = addElseBranch;
    this.elseBranchIdToDelete = elseBranchIdToDelete;
  }

  public async commit(context: OperationContext) {
    if (this.elseBranchIdToDelete) {
      const elseBranch = context.getContentControl(this.elseBranchIdToDelete);

      elseBranch.delete(true);
    }

    if (this.addElseBranch) {
      const wrapper = await this.getContentControl(context);
      const range = wrapper.getRange("Whole").load(["isEmpty"]);
      await context.sync();
      const { isBlockSelection } = await InsertConditionalExpressionContentControl.getRangeInfo(range, context);

      InsertConditionalExpressionContentControl.insertElseBranch({
        wrapper,
        insertAsBlock: isBlockSelection,
        context,
        color: this.color,
      });
    }

    await super.commit(context);
  }
}
