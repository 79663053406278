import { Label, Input, Caption1, tokens, Button, makeStyles } from "@fluentui/react-components";
import { FC, useState, useEffect } from "react";
import { Pluralize } from "../Pluralize";
import { Snippet } from "../Snippet";
import { BaseRenderData, BaseUiData } from "@src/lib/liquidx/ParsedTag";
import { JSONSchema9 } from "@src/lib/types/jsonSchema";
import { PluralizeSnippet } from "../PluralizeSnippet";
import { ComputedField } from "./ComputedField";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
  },
});

export interface ComputedFieldSchema<R extends BaseRenderData = BaseRenderData, U extends BaseUiData = BaseUiData>
  extends JSONSchema9 {
  /**
   * The text to display as a placeholder in the content control.
   */
  placeholder?: string;
  /**
   * Holds the data for the element that this field uses (e.g., pluralize, conditional, etc.).
   */
  computed: { render: R; ui: U };
}

export const EditComputedField: FC<{ computedField: ComputedField; onSubmit: () => void }> = ({
  computedField: computedField,
  onSubmit,
}) => {
  const styles = useStyles();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [placeholder, setPlaceholder] = useState("");
  const [errors, setErrors] = useState<string[]>([]);
  function handleSubmit() {
    void computedField.saveSchema();
    onSubmit();
  }

  useEffect(() => {
    setTitle(computedField.schema.title ?? "");
    setDescription(computedField.schema.description ?? "");
    setPlaceholder(computedField.schema.placeholder ?? "");
  }, []);

  function updateSchema({
    title,
    description,
    placeholder,
  }: Partial<{ title: string; description: string; placeholder: string }>) {
    if (title !== undefined) {
      computedField.schema.title = title;
      setTitle(title);
    }

    if (description !== undefined) {
      computedField.schema.description = description;
      setDescription(description);
    }

    if (placeholder !== undefined) {
      computedField.schema.placeholder = placeholder;
      setPlaceholder(placeholder);
    }

    computedField.validate();
    setErrors(computedField.errors);
  }

  return (
    <form className={styles.root}>
      <Label size="small">Name</Label>
      <Input type="text" onChange={(_, { value }) => updateSchema({ title: value })} value={title} />
      {errors && <Caption1 style={{ color: tokens.colorPaletteRedForeground1 }}>{errors.join(" ")}</Caption1>}
      <Label size="small">Placeholder</Label>
      <Input
        type="text"
        placeholder={title}
        onChange={(_, { value }) => updateSchema({ placeholder: value })}
        value={placeholder}
      />
      <Caption1 color={tokens.colorNeutralForeground2}>Placeholder text to display in the document</Caption1>
      <Label size="small">Description</Label>
      <Input type="text" onChange={(_, { value }) => updateSchema({ description: value })} value={description} />
      <EditSnippet snippet={computedField.snippet} />
      <Button appearance="primary" onClick={handleSubmit} disabled={computedField.hasErrors || !title}>
        {computedField.isNew ? "Add field" : "Update field"}
      </Button>
    </form>
  );
};

const EditSnippet: FC<{ snippet: Snippet }> = ({ snippet }) => {
  if (snippet instanceof PluralizeSnippet) {
    return <Pluralize snippet={snippet} />;
  }

  return null;
};
