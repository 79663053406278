/**
 * A simple custom XML store that stores key-value pairs as properties in a custom XML part.
 *
 * This provides access to properties (read/write) and is responsible for XML de/serialization.
 *
 * @example new XML
 * const store = new CustomXmlStore();
 * store.setProperty("key", "value");
 * store.xml; // => "<walter><property name='key'>'value'</property></walter>"
 *
 * @example existing XML
 * const store = new CustomXmlStore("<walter><property name='key'>'old value'</property></walter>");
 * store.setProperty("key", "new value");
 * store.xml; // => "<walter><property name='key'>'new value'</property></walter>"
 */
export class CustomXmlStore {
  static serializer = new XMLSerializer();
  static parser = new DOMParser();
  document: Document;
  root: Element;

  /**
   * Parse a custom XML string into a CustomXmlStore instance.
   *
   * Returns undefined if the XML isn't invalid.
   * @param xml
   * @returns
   */
  static parse(xml: string): CustomXmlStore | undefined {
    try {
      return new CustomXmlStore(xml);
    } catch (error) {
      if (error instanceof InvalidDocumentError) {
        return undefined;
      }

      throw error;
    }
  }

  constructor(xml: string = "<walter></walter>") {
    this.document = CustomXmlStore.parser.parseFromString(xml, "text/xml");

    const root = this.document.querySelector("walter");
    if (root === null) {
      throw new InvalidDocumentError(`<walter> element not found: ${xml}`);
    }

    this.root = root;
  }

  setProperty(key: string, value: unknown) {
    let propertyElement = this.#getPropertyElement(key);
    if (!propertyElement) {
      propertyElement = this.document.createElement("property");
      propertyElement.setAttribute("name", key);
      this.root.appendChild(propertyElement);
    }

    if (value === undefined) {
      propertyElement.remove();
    } else {
      propertyElement.replaceChildren(JSON.stringify(value));
    }
  }

  getProperty(key: string): unknown | undefined {
    const propertyElement = this.#getPropertyElement(key);
    if (propertyElement) {
      try {
        return JSON.parse(propertyElement.textContent ?? "");
      } catch (error) {
        throw new Error(`Failed to parse property contents as JSON: ${propertyElement.innerHTML}`);
      }
    }

    return undefined;
  }

  get xml() {
    return CustomXmlStore.serializer.serializeToString(this.document);
  }

  #getPropertyElement(key: string): Element | null {
    return this.root.querySelector(`property[name='${key}']`);
  }
}

class InvalidDocumentError extends Error {
  constructor(message: string) {
    super(message);
    this.name = "InvalidDocumentError";
  }
}
