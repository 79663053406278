import { JSONSchema9 } from "../../types/jsonSchema";
import { Variable } from "..";
import { VariableParser, type Property } from ".";

export class LoopLoopParser extends VariableParser {
  public buildWithoutRoot(schema: JSONSchema9): Variable[] {
    return this.buildFromObjectSchema(schema).filter((variable) => variable.isLoopVariable());
  }

  protected handleArraySchema(property: Property): Variable[] {
    const { name, schema } = property;
    const { description, title, type, deprecated } = schema;
    const items = schema.items as JSONSchema9;

    // TODO: fix typescript type error. Remove @ts-ignore comment to see the error
    // @ts-ignore
    const result = [new Variable(name, title, description, type as string, undefined, undefined, deprecated)];

    const children = this.buildFromObjectSchema(items);

    children.map((childLoop) => {
      const loopMarker = new Variable(
        "[]",
        // TODO: fix typescript type error. Remove @ts-ignore comment to see the error
        // @ts-ignore
        items.title,
        items.description,
        items.type as string,
        childLoop,
        undefined,
        items.deprecated,
      );
      // TODO: fix typescript type error. Remove @ts-ignore comment to see the error
      // @ts-ignore
      result.push(new Variable(name, title, description, type as string, loopMarker, undefined, deprecated));
    });

    return result;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected handleStringSchema(_property: Property): Variable[] {
    return [];
  }
}
