import { Dropdown, Field, Option, type OptionOnSelectData, type SelectionEvents } from "@fluentui/react-components";
import { IDropdownOption, operatorOptions } from "@src/taskpane/components/Conditional";

type ConditionalDropdownProps = {
  onSelect: (event: SelectionEvents, data: OptionOnSelectData) => void;
  selectedOption?: IDropdownOption;
};

export const ConditionsDropdown = ({ onSelect, selectedOption }: ConditionalDropdownProps) => {
  return (
    <Field label="Condition">
      <Dropdown
        value={selectedOption ? selectedOption.text : ""}
        selectedOptions={selectedOption ? [selectedOption?.key] : []}
        onOptionSelect={onSelect}
        placeholder="Choose condition"
      >
        {operatorOptions.map((conditionalOption) => (
          <Option key={conditionalOption.key} value={conditionalOption.key}>
            {conditionalOption.text}
          </Option>
        ))}
      </Dropdown>
    </Field>
  );
};
