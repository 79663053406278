import { DynamicElement } from "@src/lib/liquidx/internal";
import { ContentControlType, InsertLocation } from "../../liquidx";

/**
 * A utility class for inserting content controls.
 *
 * These operations are not batched and will run until completion.
 */
export class ContentControlInsertionHelper {
  /**
   * Insert content control at the current selection.
   *
   * NOTE: Because this mutates the selection, it calls context.sync directly to avoid batch operations.
   *       This means that this patch will run until completion without pausing for other batches.
   *
   * @param transaction
   * @returns newly inserted content control
   */
  static async insertContentControl(
    context: Word.RequestContext,
    insertLocation: InsertLocation,
  ): Promise<Word.ContentControl> {
    let selection = context.document.getSelection().load(["parentContentControlOrNullObject"]);

    // NOTE: parentContentControlOrNullObject can be called immediately after because it is a navigation property
    // eslint-disable-next-line office-addins/call-sync-after-load, office-addins/call-sync-before-read, office-addins/no-navigational-load
    const parentContentControl = selection.parentContentControlOrNullObject.load(["id", "tag", "title"]);
    await context.sync();

    // Check if we're within a variable content control and if so, insert the variable content control after it
    if (!parentContentControl.isNullObject) {
      // TODO: Use the tag parser, once it lands in the pluralize PR.
      const type = DynamicElement.from({
        id: parentContentControl.id,
        tag: parentContentControl.tag,
        title: parentContentControl.title,
        parentId: undefined,
      })?.type;

      if (type == ContentControlType.VARIABLE) {
        const atStart = await isAtStartOfContentControl(selection.getRange("Start"), parentContentControl);

        if (atStart) {
          parentContentControl.getRange("Before").select("Start");
        } else {
          parentContentControl.getRange("After").select("End");
        }
        await context.sync();

        // Try again with new selection
        return this.insertContentControl(context, insertLocation);
      }
    }

    return this.safelyInsertContentControl(context, insertLocation);
  }

  static async safelyInsertContentControl(
    context: Word.RequestContext,
    insertLocation?: InsertLocation,
  ): Promise<Word.ContentControl> {
    if (Office.context.diagnostics.platform === Office.PlatformType.OfficeOnline) {
      return this.insertContentControlForOfficeOnline(context);
    }

    const selection = context.document.getSelection();
    await context.sync();

    switch (insertLocation) {
      case InsertLocation.INLINE:
        return this.insertContentControlInline(selection);
      case InsertLocation.PARENT_PARAGRAPH:
        return this.insertContentControlAroundParentParagraph(selection);
      case InsertLocation.TABLE_CELL:
        return this.insertContentControlInTableCell(selection);
      case InsertLocation.TABLE_ROW:
        return this.insertContentControlAroundTableRow(selection);
      case InsertLocation.SELECTION:
      default:
        return this.insertContentControlAroundSelection(selection);
    }
  }

  static async insertContentControlForOfficeOnline(context: Word.RequestContext): Promise<Word.ContentControl> {
    let contentControl: Word.ContentControl;

    const selection = context.document.getSelection().load(["parentTableCellOrNullObject", "isEmpty", "text"]);
    await context.sync();

    const parentTableCell = selection.parentTableCellOrNullObject;
    parentTableCell.load(["value"]);
    await context.sync();

    const selectionWithinTableCell = !parentTableCell.isNullObject;

    if (selectionWithinTableCell) {
      contentControl = await this.insertContentControlIntoTableCell(selection, parentTableCell, context);
    } else {
      contentControl = selection.insertContentControl();
    }
    return contentControl;
  }

  /**
   * Inserts a content control inline at the end of the specified selection range.
   *
   * @param selection - The selection range where the content control will be inserted.
   * @returns A promise that resolves to the inserted content control.
   */
  private static async insertContentControlInline(selection: Word.Range): Promise<Word.ContentControl> {
    return selection.getRange(Word.RangeLocation.end).insertContentControl();
  }

  /**
   * Inserts a content control around the parent paragraph of the given selection.
   * If there is already a content control at the paragraph level, it inserts a content control within that existing content control.
   * If there is no content control at the paragraph level, it inserts a content control around the entire paragraph.
   *
   * @param selection - The range selection within the document.
   * @returns A promise that resolves to the inserted content control.
   */
  private static async insertContentControlAroundParentParagraph(selection: Word.Range): Promise<Word.ContentControl> {
    const paragraph = selection.paragraphs.getFirst().getRange(Word.RangeLocation.whole);
    const contentControls = paragraph.getContentControls().load("items");

    await selection.context.sync();

    if (contentControls.items.length > 0) {
      const locationRelations: Record<number, OfficeExtension.ClientResult<Word.LocationRelation>> = {};

      contentControls.items.forEach((contentControl) => {
        locationRelations[contentControl.id] = contentControl.getRange().compareLocationWith(paragraph);
      });

      await selection.context.sync();

      const paragraphLevelContentControl = Object.entries(locationRelations).find(([_id, locationRelation]) => {
        return locationRelation.value === Word.LocationRelation.equal;
      });

      if (paragraphLevelContentControl) {
        const id = Number.parseInt(paragraphLevelContentControl[0]);
        const contentControl = contentControls.getById(id);
        return contentControl.getRange(Word.RangeLocation.content).insertContentControl();
      }
    }

    return paragraph.insertContentControl();
  }

  /**
   * Inserts a content control around the specified selection.
   *
   * @param selection - The Word.Range object representing the selection.
   * @returns A Promise that resolves to the inserted Word.ContentControl object.
   */
  private static async insertContentControlAroundSelection(selection: Word.Range): Promise<Word.ContentControl> {
    return selection.insertContentControl();
  }

  /**
   * Inserts a content control in a table cell within the given selection.
   *
   * @param selection - The range of the selection.
   * @returns A promise that resolves to the inserted content control.
   */
  private static async insertContentControlInTableCell(selection: Word.Range): Promise<Word.ContentControl> {
    const tableCell = selection.parentTableCell.load("cellIndex");
    const tableRow = tableCell.parentRow.load("cellCount");

    await selection.context.sync();

    const cellIndex = tableCell.cellIndex;
    const cellCount = tableRow.cellCount;

    // A content control can't be inserted in the first and last table cell of the row when the row is wrapped in a content control.
    // It raises a RichApi error with no explanation. This is a workaround.

    if (cellIndex === cellCount - 1) {
      return tableCell.body
        .insertParagraph("", Word.InsertLocation.start)
        .getRange(Word.RangeLocation.start)
        .insertContentControl();
    } else {
      return tableCell.body
        .insertParagraph("", Word.InsertLocation.end)
        .getRange(Word.RangeLocation.end)
        .insertContentControl();
    }
  }

  /**
   * Inserts a content control around a table row.
   *
   * @param selection - The range of the table row to insert the content control around.
   * @returns A promise that resolves to the inserted content control.
   */
  private static async insertContentControlAroundTableRow(selection: Word.Range): Promise<Word.ContentControl> {
    selection.parentTableCell.parentRow.select();
    return selection.context.document.getSelection().insertContentControl();
  }

  static async insertContentControlIntoTableCell(
    selection: Word.Range,
    parentTableCell: Word.TableCell,
    context: Word.RequestContext,
  ): Promise<Word.ContentControl> {
    let contentControl: Word.ContentControl;

    const sentences = selection.getTextRanges(["."], true).load(["items"]);
    await context.sync();

    const item = sentences.items[0];

    if (item === undefined) {
      // [x] when blinking cursor is in a table cell with no text
      // [x] when blinking cursor is at the start of a new paragraph within a table cell
      const paragraph = selection.paragraphs.getFirst();
      paragraph.select();
      contentControl = paragraph.insertContentControl();
    } else {
      const match = parentTableCell.value.trim() === selection.text.trim();

      if (match) {
        // [x] when selecting all text within a table cell (e.g. ">Hello World<")
        // [x] when selecting all content (multiple paragraphs) within a table cell
        const tableCellBody = parentTableCell.body;
        tableCellBody.select();
        contentControl = tableCellBody.insertContentControl();
      } else {
        contentControl = selection.insertContentControl();
      }
    }

    return contentControl;
  }
}

/**
 * This determines where the cursor is located in relation to the content control
 *
 * My preference would be to return "start" or "end" depending on which side of the
 * content control the cursor is closest to but I wasn't able to find a way to do this.
 *
 * @param range
 * @param contentControl
 * @returns true when range is the start of the content control
 */
async function isAtStartOfContentControl(range: Word.Range, contentControl: Word.ContentControl): Promise<boolean> {
  const context = range.context;
  const contentControlRange = contentControl.getRange("Start");
  const startComparison = range.compareLocationWith(contentControlRange);
  await context.sync();

  return startComparison.value === Word.LocationRelation.equal;
}
