import {
  Comparison,
  ComparisonOperator,
  ConditionalContentControlAddProperties,
  ContentControlType,
  If,
  InsertLocation,
  Operator,
  VariableCondition,
  VariableData,
} from "@src/lib/liquidx";
import { LiquidElement, BranchElement, DynamicElement, findValidInputOption } from "@src/lib/liquidx/internal";
import { InsertConditionalContentControl } from "@src/lib/sync-engine/operations/InsertConditionalContentControl";
import { syncEngine } from "@src/lib/sync-engine/SyncEngine";
import { QuickAddOption, Variable } from "@src/lib/walter";
import { LiquidTag } from "./ParsedTag";

export class IfElement extends LiquidElement {
  static supports({ type }: LiquidTag) {
    return type === "if";
  }

  // Prior to insertion, this value stores whether or not an else branch should be inserted
  #hasElse: boolean = false;
  #label: string = "";

  constructor();
  constructor(tag?: LiquidTag, id?: number | null, title?: string, parentId?: number) {
    tag ??= { valid: true, type: ContentControlType.IF, data: {} };
    super(tag, id, title, parentId);
  }

  get label() {
    return this.#label;
  }

  get color() {
    return this.hasErrors ? "red" : DynamicElement.defaultColor;
  }

  public async save({ isSystemUpdate }: { isSystemUpdate: boolean } = { isSystemUpdate: true }) {
    this.validate();

    if (this.id) {
      return super.save({ isSystemUpdate });
    } else {
      const insert = new InsertConditionalContentControl({
        title: this.title,
        color: this.color,
        tag: this.buildTag(),
        hasElse: this.hasElse,
        insertLocation: this.insertLocation,
      });

      await syncEngine.perform(insert);
      return DynamicElement.load();
    }
  }

  public async delete() {
    if (this.id) {
      // Delete wrapping element (wait for it to be deleted before deleting children, to ensure the children are editable)
      await syncEngine.delete({ id: this.id, keepContents: true });

      // Delete in the same sync
      await Promise.all([this.thenBranch?.delete(), this.elseBranch?.delete()].filter(Boolean));

      return DynamicElement.load();
    } else {
      return Promise.resolve();
    }
  }

  get thenBranch(): BranchElement | undefined {
    const thenBranch = this.children.find((child) => child.type === ContentControlType.IF_BRANCH);
    return thenBranch ? (thenBranch as BranchElement) : undefined;
  }

  get elseBranch(): BranchElement | undefined {
    const elseBranch = this.children.find((child) => child.type === ContentControlType.ELSE_BRANCH);
    return elseBranch ? (elseBranch as BranchElement) : undefined;
  }

  get hasElse() {
    return this.id ? !!this.elseBranch : this.#hasElse;
  }

  applyEdit(formData: ConditionalContentControlAddProperties) {
    const prevLabel = this.label;
    this.data = this.buildData(formData);
    this.#label = prevLabel;
    this.#hasElse = formData.elseBranch;
  }

  applyQuickAdd(_: QuickAddOption, _insert: InsertLocation): void {
    throw new Error("Method not implemented.");
  }

  get cannotEdit() {
    return true;
  }

  get cannotDelete() {
    return true;
  }

  /**
   * @deprecated
   */
  get left() {
    return this.variable;
  }

  /**
   * @deprecated
   */
  get right() {
    return (this.data as Comparison).right as string;
  }

  /**
   * @deprecated
   */
  get variable() {
    const conditionalType = (this.data as If).type;

    if (conditionalType === "variable") {
      return Variable.buildFromVariableData((this.data as VariableCondition).variable);
    } else {
      return Variable.buildFromVariableData((this.data as Comparison).left as VariableData);
    }
  }

  /**
   * @deprecated
   */
  get _operator() {
    return (this.data as Comparison).operator;
  }

  // Normalize operator for blank/notBlank special case
  get operator() {
    if (this._operator === ComparisonOperator.EQUALS && this.right === "") return "blank";
    if (this._operator === ComparisonOperator.NOT_EQUALS && this.right === "") return "notBlank";
    if (this._operator === undefined && (this.data as { negate?: boolean }).negate) return "false";
    if (this._operator === undefined) return "true";

    return this._operator;
  }

  get title() {
    switch (this.operator as string) {
      case "true":
        return `if ${this.variable.fullName}`;
      case "false":
        return `if not ${this.variable.fullName}`;
      case "blank":
        return `if ${this.left.fullName} is blank`;
      case "notBlank":
        return `if ${this.left.fullName} is not blank`;
      default:
        return `if ${this.left.fullName} ${this.operator} "${this.right}"`;
    }
  }

  public buildData(formData: ConditionalContentControlAddProperties) {
    const { left, operator, right } = formData;
    // TODO: fix typescript type error. Remove @ts-ignore comment to see the error
    // @ts-ignore
    const variable = left.variable.tagFormat;

    let ifTag: If | null;

    switch (operator) {
      case "true":
        ifTag = {
          type: "variable",
          variable,
        };

        break;
      case "false":
        ifTag = {
          type: "variable",
          negate: true,
          variable,
        };

        break;
      case "blank":
        ifTag = {
          type: "comparison",
          left: variable,
          operator: ComparisonOperator.EQUALS,
          right: "",
        };
        break;
      case "notBlank":
        ifTag = {
          type: "comparison",
          left: variable,
          operator: ComparisonOperator.NOT_EQUALS,
          right: "",
        };
        break;
      default:
        ifTag = {
          type: "comparison",
          left: variable,
          operator: operator as Operator,
          right: right ?? "",
        };
    }

    return ifTag;
  }

  validate() {
    super.validate();

    if (this.isNew) {
      return;
    }

    const inputOption = findValidInputOption(this.variable);
    if (!inputOption) {
      this.errors.push(`"${this.variable.fullName}" can't be found—edit or delete this variable`);
    } else if (inputOption.variable?.isDeprecated) {
      this.errors.push(inputOption.variable.deprecatedMessage);
    }
  }
}
