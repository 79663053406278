import { captureErrorInfo } from "@src/taskpane/helpers/errorHandler";

function handleError(error: unknown) {
  captureErrorInfo(error);
  throw error;
}

export class WordWrapper {
  static async run<T>(callback: (context: Word.RequestContext) => Promise<T>) {
    try {
      return Word.run(callback);
    } catch (error) {
      handleError(error);
    }
  }
}
