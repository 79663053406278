import { JSX, FC, useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Menu,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
  makeStyles,
  shorthands,
  tokens,
} from "@fluentui/react-components";
import { BasicVariableElement } from "@src/lib/liquidx/internal";
import { TextCombobox } from "./TextCombobox";
import { AsteriskSquare, Calendar, CircleDollarSign, CirclePlus, PercentCircle, Trash2 } from "lucide-react";
import { FormatterData } from "@src/lib/liquidx/BasicVariableElement";
import { DateFormatter } from "./DateFormatter";
import { NumberFormatter } from "./NumberFormatter";
import { documentSchemaStore, Option } from "@src/lib/schemas";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    ...shorthands.gap("8px"),
  },
  transformMenuItem: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    ...shorthands.gap("8px"),
  },
  buttonTransparent: {
    display: "flex",
    justifyContent: "start",
    ...shorthands.gap("6px"),
  },
  dangerButtonTransparent: {
    display: "flex",
    justifyContent: "start",
    ":hover": {
      color: tokens.colorStatusDangerForeground1,
    },
    ...shorthands.gap("6px"),
  },
});

interface Formatter {
  icon: JSX.Element;
  label: string;
  name: string;
  children: (data: FormatterData | undefined, handleUpdate: (_: FormatterData) => void) => JSX.Element;
}

const formatters: Formatter[] = [
  {
    name: "date",
    icon: <Calendar size={16} strokeWidth={1.5} />,
    label: "Date",
    children: (data, handleChange) => {
      if (data?.name !== "date") {
        handleChange({
          name: "date",
          details: {
            dateFormat: "yyyy-MM-dd",
            timeFormat: "",
            customFormat: false,
          },
          props: {
            format: "yyyy-MM-dd",
          },
        });

        return <></>;
      } else {
        return <DateFormatter value={data?.name === "date" ? data : undefined} onChange={handleChange} />;
      }
    },
  },
  {
    name: "currency",
    icon: <CircleDollarSign size={16} strokeWidth={1.5} />,
    label: "Currency",
    children: (data, handleChange) => {
      if (data?.name !== "currency") {
        handleChange({
          name: "currency",
          props: {
            style: "currency",
            thousandsSeparator: true,
            currency: "USD",
          },
        });

        return <></>;
      } else {
        return <NumberFormatter value={data} onChange={handleChange} />;
      }
    },
  },
  {
    name: "number",
    icon: <AsteriskSquare size={16} strokeWidth={1.5} />,
    label: "Number",
    children: (data, handleChange) => {
      if (data?.name !== "number") {
        handleChange({ name: "number", props: { style: "decimal" } });

        return <></>;
      } else {
        return <NumberFormatter value={data} onChange={handleChange} />;
      }
    },
  },
  {
    name: "percent",
    icon: <PercentCircle size={16} strokeWidth={1.5} />,
    label: "Percent",
    children: (data, handleChange) => {
      if (data?.name !== "percent") {
        handleChange({
          name: "percent",
          props: {
            style: "percent",
            thousandsSeparator: true,
          },
        });

        return <></>;
      } else {
        return <NumberFormatter value={data} onChange={handleChange} />;
      }
    },
  },
];

export const BasicVariable: FC<{ element: BasicVariableElement }> = ({ element }) => {
  const [property, setProperty] = useState("");
  const [required, setRequired] = useState(false);
  const [formatter, setFormatter] = useState<Formatter>();
  const [formatterData, setFormatterData] = useState<FormatterData>();
  const [options, setOptions] = useState<Option[]>([]);
  const styles = useStyles();

  useEffect(() => {
    setOptions(element.options);
    setProperty(element.ui.property);
    setRequired(element.ui.required ?? false);

    const formatterName = element.ui.formatter?.name;
    setFormatterData(element.ui.formatter);
    setFormatter(formatters.find((f) => f.name === formatterName));
  }, [element]);

  function updateFormatterData(data: FormatterData) {
    setFormatterData(data);
    void element.update({ property, formatter: data, required });
  }

  function updateRequired(checked: boolean) {
    setRequired(checked);
    void element.update({ property, formatter: formatterData, required: checked });
  }

  function clearFormatter() {
    setFormatter(undefined);
    setFormatterData(undefined);
    void element.update({ property, required });
  }

  function handlePropertyChange(newProperty: string) {
    setProperty(newProperty);

    const node = documentSchemaStore.find(newProperty);
    if (node && node.format === "date") {
      setFormatter(formatters.find((f) => f.name === "date"));
    }

    void element.update({ property: newProperty, formatter: formatterData, required });
  }

  return (
    <div className={styles.root}>
      <TextCombobox autoFocus={element.isNew} options={options} value={property} onChange={handlePropertyChange} />

      {formatter?.children(formatterData, updateFormatterData)}
      {formatter && (
        <Button appearance="transparent" onClick={clearFormatter} className={styles.dangerButtonTransparent}>
          <Trash2 size={16} />
          Remove {formatter.label.toLowerCase()} formatting
        </Button>
      )}
      {!formatter && (
        <Menu>
          <MenuTrigger>
            <Button size="small" appearance="transparent" className={styles.buttonTransparent}>
              <CirclePlus size={16} />
              Format
            </Button>
          </MenuTrigger>
          <MenuPopover>
            <MenuList>
              {Object.values(formatters).map((formatter) => (
                <MenuItem key={formatter.name} onClick={() => setFormatter(formatter)}>
                  <div className={styles.transformMenuItem}>
                    {formatter.icon}
                    {formatter.label}
                  </div>
                </MenuItem>
              ))}
            </MenuList>
          </MenuPopover>
        </Menu>
      )}
      <Checkbox checked={required} onChange={(_, { checked }) => updateRequired(!!checked)} label="Required" />
    </div>
  );
};
