import { ContentControlType, InsertLocation } from "@src/lib/liquidx";
import { LiquidElement, findValidInputOption } from "@src/lib/liquidx/internal";
import { QuickAddOption, Variable } from "@src/lib/walter";
import { LoopFormData } from "@src/taskpane/components/loops/Loop";
import { LiquidTag } from "./ParsedTag";

export class LoopElement extends LiquidElement {
  static supports({ type }: LiquidTag) {
    return type === "loop";
  }

  constructor();
  constructor(tag?: LiquidTag, id?: number | null, title?: string, parentId?: number) {
    tag ??= { valid: true, type: ContentControlType.LOOP, data: {} };

    super(tag, id, title, parentId);
  }

  public applyLoopForm(loopForm: LoopFormData) {
    const { variable, parameters, insertLocation } = loopForm;

    this.title = `Loop: ${variable.leafTitle}`;
    this.insertLocation = insertLocation;
    this.data = {
      enumerable: variable.tagFormat,
      parameters,
    };
  }

  public applyQuickAdd(quickAddOption: QuickAddOption, insertLocation: InsertLocation) {
    this.applyLoopForm({
      // TODO: fix typescript type error. Remove @ts-ignore comment to see the error
      // @ts-ignore
      variable: quickAddOption.option.variable,
      parameters: [],
      insertLocation,
    });
  }

  get appearance() {
    return Word.ContentControlAppearance.tags;
  }

  // @deprecated
  get variable() {
    return Variable.buildFromVariableData((this.data as any).enumerable);
  }

  // @deprecated
  get offset() {
    return (
      (this.data as any).parameters
        .find((param: string) => param.startsWith("offset:"))
        ?.split(":")[1]
        ?.trim() ?? ""
    );
  }

  // @deprecated
  get limit() {
    return (
      (this.data as any).parameters
        .find((param: string) => param.startsWith("limit:"))
        ?.split(":")[1]
        ?.trim() ?? ""
    );
  }

  validate() {
    super.validate();

    if (this.isNew) {
      return;
    }

    const inputOption = findValidInputOption(this.variable);

    if (!inputOption) {
      this.errors.push(`"${this.variable.fullName}" can't be found—edit or delete this variable`);
    } else if (inputOption.variable?.isDeprecated) {
      this.errors.push(inputOption.variable.deprecatedMessage);
    }
  }
}
