import { FC, useState } from "react";
import { ConditionData } from "./ConditionCodeGen";
import { TextCombobox } from "../TextCombobox";
import { useConditionBuilder } from "./useConditionBuilder";
import { ConditionModel } from "./ConditionModel";
import { Token } from "./Token";

export const Operator: FC<{ condition: ConditionData; onChange?: (_: string) => void }> = ({ condition, onChange }) => {
  const { nodes } = useConditionBuilder();
  const model = new ConditionModel(condition, nodes);
  const [editing, setEditing] = useState(false);

  function handleChange(operator: string) {
    if (!operator) return;

    setEditing(false);
    onChange?.(operator);
  }

  // Don't show the operator when the left side has no value
  if (!condition.left.value) return null;

  if (editing) {
    return (
      <TextCombobox
        autoFocus
        // TODO: Make a custom options with icons and description label (= equals, != not equals, etc.)
        options={model?.operators ?? []}
        placeholder="Operator"
        value={condition.operator ?? ""}
        onChange={handleChange}
        onKeyDown={(e) => e.key === "Escape" && setEditing(false)}
      />
    );
  } else if (condition.operator && model?.operatorLabel) {
    return <Token onClick={() => setEditing(true)} label={model.operatorLabel} appearance="secondary" />;
  } else {
    return <Token onClick={() => setEditing(true)} label={"Operator"} appearance="placeholder" />;
  }
};
