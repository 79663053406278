import { SchemaStore, SchemaNode } from "@src/lib/schemas";

/**
 * A field is a pointer to a top-level property in the schema.
 *
 * It can be contextual or persistent. Contextual fields come from parent loop
 * elements and are only available when the cursor is within a parent loop element.
 *
 * Persistent fields are always available and are stored in the document settings.
 */
export class Field {
  public readonly key: string;

  /**
   * Creates a new instance of the Field class.
   *
   * @param key
   * @param store - the object used to store the field and fetch property schemas
   */
  constructor(keyOrNode: string | SchemaNode, public readonly store: SchemaStore) {
    if (typeof keyOrNode === "string") {
      const schemaNode = store.findNode(keyOrNode);

      this.key = keyOrNode;
      this.node = schemaNode;
    } else {
      this.key = keyOrNode.path;
      this.node = keyOrNode;
    }
  }

  /**
   * Provides read-only access to the field's schema via SchemaStoreProperty,
   * which handles $ref lookup and provides schema traversal functionality.
   */
  public readonly node: SchemaNode;

  get label() {
    return this.node.title ?? "";
  }

  set label(value: string | undefined) {
    if (value === undefined) {
      delete this.node.schema.title;
    } else {
      this.node.schema.title = value;
    }
  }

  get description() {
    return this.node.schema.description ?? "";
  }

  set description(value: string | undefined) {
    if (value === undefined) {
      delete this.node.schema.description;
    } else {
      this.node.schema.description = value;
    }
  }

  get fieldType() {
    if ((this.node.schema as { contextual?: boolean }).contextual) {
      return "contextual";
    } else if ((this.node.schema as { computed?: unknown }).computed) {
      return "computed";
    } else {
      return "document";
    }
  }

  get isComputed() {
    return this.fieldType === "computed";
  }

  get isContextual() {
    return !!(this.node.schema as { contextual?: boolean }).contextual;
  }

  set isContextual(value: boolean) {
    (this.node.schema as { contextual?: boolean }).contextual = value;
  }

  /**
   * Removes a field from the collection of fields.
   * @param field - The field to remove.
   */
  public async delete() {
    await this.store.deleteField(this.key);
  }

  public async moveBefore(keyOrField: string | Field) {
    const key = typeof keyOrField === "object" ? keyOrField.key : keyOrField;
    return this.store.move(this.key, key, -1);
  }

  public async moveAfter(keyOrField: string | Field) {
    const key = typeof keyOrField === "object" ? keyOrField.key : keyOrField;
    return this.store.move(this.key, key, 1);
  }
}
